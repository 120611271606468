import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  headerName: string;
}

const initialState: HeaderState = {
  headerName: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<string>) => {
      state.headerName = action.payload;
    },
  },
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
