import { ContentLoader } from '@/components';
import {
  useGetDataSourcesQuery,
  useGetReportQuery,
  useGetReportWidgetsQuery,
  useUpdateReportMutation,
} from '@/redux/reports/reports.api';
import {
  DropWidget,
  ReportLayout,
  ReportWidget,
  WidgetAction,
} from '@/types/report.type';
import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from 'react-router-dom';
import ReportBuilder from './ReportBuilder';
import {
  getWidgetsList,
  manageWidget,
  moveWidgetToAnotherTarget,
  updateWidgets,
} from './service';

const ReportBuilderContainer = () => {
  const [structure, setStructure] = useState<ReportLayout>([]);
  const [sortable, setSortable] = useState<boolean>(false);
  const { id: reportId = '' } = useParams();
  const navigate = useNavigate();

  const { data: report, isFetching: isLoadingReport } = useGetReportQuery(
    reportId,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: widgets, isFetching: isLoadingWidgets } =
    useGetReportWidgetsQuery({});
  const { isFetching: isLoadingDataSources } = useGetDataSourcesQuery();

  const [onUpdateReport, { isLoading: isUpdateLoading }] =
    useUpdateReportMutation();

  useEffect(() => {
    if (report) {
      if (isLoadingReport) {
        setStructure([]);
      } else {
        setStructure(report.structure);
      }
    }
  }, [report, isLoadingReport]);

  const onAddWidget = (drop: DropWidget, layoutId: string | number) => {
    setStructure((data: ReportLayout) => {
      const updatedLayout = JSON.parse(JSON.stringify(data));
      manageWidget(updatedLayout, layoutId, drop.widget, WidgetAction.ADD);
      return updatedLayout;
    });
  };

  const onRemoveWidget = (
    widget: ReportWidget<unknown>,
    layoutId: string | number,
  ) => {
    setStructure((data: ReportLayout) => {
      const updatedLayout = JSON.parse(JSON.stringify(data));
      manageWidget(updatedLayout, layoutId, widget, WidgetAction.DELETE);
      return updatedLayout;
    });
  };

  const onUpdateWidget = (
    widget: ReportWidget<unknown>,
    layoutId: string | number,
  ) => {
    setStructure((data: ReportLayout) => {
      const updatedLayout = JSON.parse(JSON.stringify(data));
      manageWidget(updatedLayout, layoutId, widget, WidgetAction.UPDATE);
      return updatedLayout;
    });
  };

  const onMoveWidget = (drop: DropWidget, layoutId: string | number) => {
    if (drop.layoutId === layoutId) {
      return;
    }
    setStructure((data: ReportLayout) =>
      moveWidgetToAnotherTarget(
        data,
        drop.layoutId as string | number,
        layoutId,
        drop.widget.id,
      ),
    );
  };

  const onSortWidgets = (
    layoutId: string | number,
    newWidgets: ReportWidget<unknown>[],
  ) => {
    setStructure((data: ReportLayout) => {
      const layoutCopy = JSON.parse(JSON.stringify(data));
      const updatedLayout = updateWidgets(layoutCopy, layoutId, newWidgets);
      return updatedLayout;
    });
  };

  const onPressSave = async () => {
    await onUpdateReport({
      id: Number(reportId),
      name: report.name,
      structure,
      layoutId: report.layoutId || 11,
    }).unwrap();
  };

  const onPressRun = async () => {
    await onPressSave();
    navigate(`/app/reports/${report.id}/running`);
  };

  const widgetsList = useMemo(
    () => getWidgetsList(widgets, structure),
    [widgets, structure],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ContentLoader active={isUpdateLoading} />
      <ReportBuilder
        sortable={sortable}
        setSortable={setSortable}
        structure={structure}
        onAddWidget={onAddWidget}
        onMoveWidget={onMoveWidget}
        onSortWidgets={onSortWidgets}
        onRemoveWidget={onRemoveWidget}
        onUpdateWidget={onUpdateWidget}
        pageNumber={undefined}
        loading={isLoadingWidgets || isLoadingReport || isLoadingDataSources}
        report={report}
        widgets={widgetsList}
        onPressSave={onPressSave}
        onPressRun={onPressRun}
      />
    </DndProvider>
  );
};

export default ReportBuilderContainer;
