import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './style';
import IconInsight from '@/components/IconInsight/IconInsight';
import moment from 'moment';

interface WaypointHeadingsProps {
  waypointID: string;
  correlations: { id: number; session_a: string; session_b: string }[];
  consumptionProfileIds: number[];
  sessions: { id: string; status: string; end_time: string; type: string }[];
  jobs: { id: number; work_order: string }[];
  topSoundIds: number[];
  active?: string;
}

const WaypointHeadings: FC<WaypointHeadingsProps> = ({
  active,
  waypointID,
  sessions,
  topSoundIds,
  correlations,
  jobs,
  consumptionProfileIds,
}) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.WAYPOINT_HEADINGS');
  const navigate = useNavigate();
  const handleLink = (option: DropDownOption, location: string): void => {
    switch (location) {
      case 'session':
        navigate(`/app/session?waypoint=${waypointID}&session=${option.value}`);
        break;
      case 'consumptionProfile':
        navigate(
          `/app/consumption-profile?waypoint=${waypointID}&consumptionProfile=${option.value}`,
        );
        break;
      case 'topSound':
        navigate(
          `/app/top-sound?waypoint=${waypointID}&topSound=${option.value}`,
        );
        break;
      case 'correlation':
        navigate(
          `/app/correlation?waypoint=${waypointID}&correlation=${option.value}`,
        );
        break;
      case 'job':
        navigate(`/app/job?waypoint=${waypointID}&job=${option.value}`);
        break;
    }
  };

  return (
    <div>
      <Styled.WaypointLinkContainer>
        <a href={`/app/waypoint?waypoint=${waypointID}`}>
          {prefixedT('WAYPOINT_LINK')}
        </a>
      </Styled.WaypointLinkContainer>
      <Styled.Wrapper>
        <Styled.DropdownContainer>
          <DropDown
            value={'1'}
            options={sessions.map(({ id, status, end_time, type }) => ({
              value: `${id}`,
              label: `${prefixedT('SESSION.DROPDOWN.ID')}: ${id}\n${prefixedT('SESSION.DROPDOWN.STATUS')}: ${status}\n${prefixedT('SESSION.DROPDOWN.LAST_UPDATE')}: ${moment(end_time).format('YYYY-MM-DD HH:mm:ss')}\n${prefixedT('SESSION.DROPDOWN.TYPE')}: ${type}`,
            }))}
            onChange={(value) => handleLink(value as DropDownOption, 'session')}
            placeholder={prefixedT('SESSIONS')}
            disabled={sessions.length > 0 ? false : true}
            active={active === 'Session'}
          />
        </Styled.DropdownContainer>
        <Styled.DropdownContainer>
          <DropDown
            value={'1'}
            options={consumptionProfileIds.map((consumptionProfileId) => ({
              value: consumptionProfileId.toString(),
              label: `Consumption Profile: ${consumptionProfileId}`,
            }))}
            onChange={(value) =>
              handleLink(value as DropDownOption, 'consumptionProfile')
            }
            placeholder={prefixedT('CONSUMPTION_PROFILE')}
            disabled={consumptionProfileIds.length > 0 ? false : true}
            active={active === 'Consumption Profile'}
          />
        </Styled.DropdownContainer>
        <Styled.DropdownContainer>
          <DropDown
            value={'1'}
            options={topSoundIds.map((topSoundId) => ({
              value: topSoundId.toString(),
              label: `Top Sound: ${topSoundId}`,
            }))}
            onChange={(value) =>
              handleLink(value as DropDownOption, 'topSound')
            }
            placeholder={prefixedT('TOP_SOUND')}
            disabled={topSoundIds.length > 0 ? false : true}
            active={active === 'Top Sound'}
          />
        </Styled.DropdownContainer>
        <Styled.DropdownContainer>
          <DropDown
            value={'1'}
            options={correlations.map(({ id, session_a, session_b }) => ({
              value: `${id}`,
              label: `${id} : ${session_a} - ${session_b}`,
            }))}
            onChange={(value) =>
              handleLink(value as DropDownOption, 'correlation')
            }
            placeholder={prefixedT('CORRELATION')}
            disabled={correlations.length > 0 ? false : true}
            active={active === 'Correlation'}
          />
        </Styled.DropdownContainer>
        <IconInsight
          title={prefixedT('CORRELATION_TOOLTIP.TITLE')}
          label={prefixedT('CORRELATION_TOOLTIP.CONTENT').replace(/\\n/g, '\n')}
          location={'bottom right'}
        />
        <Styled.DropdownContainer>
          <DropDown
            value={'-1'}
            options={jobs.map(({ id, work_order }) => ({
              value: `${id}`,
              label: work_order,
            }))}
            onChange={(value) => handleLink(value as DropDownOption, 'job')}
            placeholder={prefixedT('JOB')}
            disabled={jobs.length > 0 ? false : true}
            active={active === 'Job'}
          />
        </Styled.DropdownContainer>
      </Styled.Wrapper>
    </div>
  );
};

export default WaypointHeadings;
