import styled from 'styled-components';

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div``;

export const ButtonContainer = styled.div`
  justify-content: right;
  display: flex;
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 20px;
`;

export const ReturnContainer = styled.div``;

export const ReturnText = styled.p``;
