import DateRange from '@/components/DateRange/DateRange';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import { DeliveryPerformanceDropdownType } from '@/types/report.type';
import { Field, Form, Formik } from 'formik';
import React, { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import BreadCrumbContainer from './Components/BreadcrumbContainer/BreadcrumbContainer';
import * as Styled from './style';
import {
  setContextFromUrlParams,
  setUrlFromStateUpdates,
  setDeliveryPerformanceContext,
  setContextFromUrlParamsProps,
  setDeliveryPerformanceContextProps,
} from './utils';

interface DeliveryPerformanceFiltersProps {
  dropdown: DeliveryPerformanceDropdownType | undefined;
}

const DeliveryPerformanceFilters: FC<DeliveryPerformanceFiltersProps> = ({
  dropdown,
}) => {
  const { prefixedT } = useCustomTranslation(
    'COMPONENTS.DELIVERY_PERFORMANCE_FITLERS',
  );

  const location = useLocation();

  const {
    partnerActiveValue,
    companyActiveValue,
    projectActiveValue,
    engineerActiveValue,
    dmaActiveValue,
    dateFilters,
    setDateFilters,
    setPartnerActiveValue,
    setCompanyActiveValue,
    setProjectActiveValue,
    setEngineerActiveValue,
    setDmaActiveValue,
  } = useContext(DeliveryPerformanceContext);

  useEffect(() => {
    const contextProps: setContextFromUrlParamsProps = {
      context: {
        setPartnerActiveValue,
        setCompanyActiveValue,
        setProjectActiveValue,
      },
    };
    setContextFromUrlParams(contextProps);
  }, [location.search]);

  const handleLink = (option: DropDownOption, keyValue: string): void => {
    const contextProps: setDeliveryPerformanceContextProps = {
      option,
      keyValue,
      context: {
        setPartnerActiveValue,
        setCompanyActiveValue,
        setProjectActiveValue,
        setEngineerActiveValue,
        setDmaActiveValue,
      },
    };

    setDeliveryPerformanceContext(contextProps);

    setUrlFromStateUpdates({
      context: { projectActiveValue, partnerActiveValue, companyActiveValue },
    });
  };

  const ValidationSchema = Yup.object().shape({
    dateFrom: Yup.date().required('Date is required'),
    dateTo: Yup.date().required('Date is required'),
  });

  return (
    <Styled.Wrapper>
      <Styled.RowOne>
        <Styled.FilterContainer>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={ValidationSchema}
            onSubmit={() => {}}
            initialValues={dateFilters}
            enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <Field
                    as={DateRange}
                    name="dateRange"
                    minMax={true}
                    dateRangeBtn
                    onChange={(dateRange: Date[]) => {
                      const dateFrom =
                        new Date(dateRange[0]) < new Date(dateRange[1])
                          ? dateRange[0]
                          : dateRange[1];
                      const dateTo =
                        new Date(dateRange[0]) > new Date(dateRange[1])
                          ? dateRange[0]
                          : dateRange[1];

                      setFieldValue('dateFrom', dateFrom);
                      setFieldValue('dateTo', dateTo);

                      setDateFilters({ dateFrom, dateTo });
                    }}
                    value={[values.dateFrom, values.dateTo]}
                    disableFuture
                  />
                </div>
              </Form>
            )}
          </Formik>
          <>
            <Styled.DropdownWrapper>
              <DropDown
                value={partnerActiveValue.value.toString()}
                options={dropdown?.partners.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
                onChange={(value) =>
                  handleLink(value as DropDownOption, 'partners')
                }
                placeholder={prefixedT('PLACEHOLDERS.PARTNERS')}
              />
            </Styled.DropdownWrapper>
            <Styled.DropdownWrapper>
              <DropDown
                value={companyActiveValue.value.toString()}
                options={dropdown?.companies.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
                onChange={(value) =>
                  handleLink(value as DropDownOption, 'companies')
                }
                placeholder={prefixedT('PLACEHOLDERS.COMPANIES')}
              />
            </Styled.DropdownWrapper>
            <Styled.DropdownWrapper>
              <DropDown
                value={projectActiveValue.value.toString()}
                options={dropdown?.projects.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
                onChange={(value) =>
                  handleLink(value as DropDownOption, 'projects')
                }
                placeholder={prefixedT('PLACEHOLDERS.PROJECTS')}
              />
            </Styled.DropdownWrapper>

            <Styled.DropdownWrapper>
              <DropDown
                value={engineerActiveValue.value.toString()}
                options={dropdown?.engineers.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(value) =>
                  handleLink(value as DropDownOption, 'engineers')
                }
                placeholder={prefixedT('PLACEHOLDERS.ENGINEERS')}
              />
            </Styled.DropdownWrapper>

            <Styled.DropdownWrapper>
              <DropDown
                value={dmaActiveValue.value.toString()}
                options={dropdown?.dmas.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(value) =>
                  handleLink(value as DropDownOption, 'DMAs')
                }
                placeholder={'DMAs'}
              />
            </Styled.DropdownWrapper>
          </>
        </Styled.FilterContainer>
      </Styled.RowOne>
      <BreadCrumbContainer />
    </Styled.Wrapper>
  );
};

export default DeliveryPerformanceFilters;
