import React, { FC } from 'react';

type Props = {
  audioUrl: string;
  audioKey: number;
  style?: React.CSSProperties;
};

export const AudioPlayer: FC<Props> = ({ audioUrl, audioKey, style }) => (
  <audio controls style={style}>
    <source
      src={audioUrl}
      type="audio/mpeg"
      data-testid={`audio-player ${audioKey}`}
    />
    Your browser does not support the audio element.
  </audio>
);

export default AudioPlayer;
