import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

export const CardValue = styled.h3`
  font-size: 2rem;
  font-weight: 600;
`;

export const CardText = styled.p`
  font-size: 0.8125rem;
  color: #5e6c84;
  line-height: 1.5rem;
`;
