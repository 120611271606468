import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useArchiveConversationMutation,
  useCreateConversationMutation,
  useSendMessageMutation,
} from '@/redux/aiAssistant/aiAssistant.api';
import { animated, useTransition } from '@react-spring/web';
import React, { FC, useEffect, useRef, useState } from 'react';
import ChatbotMessages from './ChatbotMessages/ChatbotMessages';
import * as Styled from './style';
import { ListOfMessages } from './types';
import {
  formattedTimestamp,
  timezoneOffset,
  updateMessages,
} from './utils/utils';

const Chatbot: FC = () => {
  const { prefixedT } = useCustomTranslation('CHATBOT');

  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const defaultMsg = [
    {
      user_msg: '',
      assistant_msg: prefixedT('DEFAULT_ASSISTANT_MSG'),
      msgId: '',
    },
  ];
  const [listOfMessages, setListOfMessages] =
    useState<ListOfMessages[]>(defaultMsg);

  const [createConversationMutate, { data: createConversation, isError }] =
    useCreateConversationMutation();
  const [archiveConversationMutate] = useArchiveConversationMutation();
  const [sendMessageMutate, { data: returnedMsg, isLoading }] =
    useSendMessageMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isError) {
      setListOfMessages([
        {
          user_msg: '',
          assistant_msg: prefixedT('OFFLINE_MSG'),
          msgId: '',
        },
      ]);
    }
  }, [isError]);

  useEffect(() => {
    if (returnedMsg) {
      setListOfMessages((prevList) => updateMessages(prevList, returnedMsg));
      setMessage('');
    }
  }, [returnedMsg]);

  useEffect(() => {
    if (open) {
      createConversationMutate();
    } else if (!open && createConversation) {
      archiveConversationMutate({ convo_id: createConversation.convo_id });
      setListOfMessages(defaultMsg);
    }
  }, [open]);

  const toggleChatbot = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const sendMessage = () => {
    if (isLoading || !message) {
      return;
    }

    setListOfMessages((prevList) => [
      ...prevList,
      {
        user_msg: message,
        assistant_msg: '',
        msgId: '',
      },
    ]);

    if (createConversation) {
      sendMessageMutate({
        query: { convo_id: createConversation.convo_id },
        body: {
          user_msg: message,
          timestamp: formattedTimestamp(),
          timezone_offset: timezoneOffset(),
          device: 'portal',
        },
      });
    }

    setMessage('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const disappearToBottomTransition = useTransition([open], {
    from: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0%, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
  });

  const appearFromBottomTransition = useTransition(open, {
    from: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0%, 0)',
      position: 'fixed',
      bottom: '20px',
      right: '20px',
    },
    leave: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
    trail: 400,
  });

  return (
    <Styled.Container>
      {disappearToBottomTransition(
        (styles, item) =>
          !item && (
            <animated.div style={styles}>
              <Styled.Circle
                data-testid="chatbot-toggle"
                onClick={toggleChatbot}
              >
                <Styled.ChatBotIcon />
              </Styled.Circle>
            </animated.div>
          ),
      )}
      {appearFromBottomTransition(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <Styled.Wrapper>
                <div>
                  <Styled.HeaderContainer>
                    <Styled.CloseContainer>
                      <Styled.CrossImg
                        data-testid="chatbot-close"
                        onClick={toggleChatbot}
                      />
                    </Styled.CloseContainer>
                    <Styled.Header>
                      <Styled.AskFido />
                      <Styled.HeaderTextContainer>
                        <Styled.Title>askFIDO</Styled.Title>
                        <Styled.Subheading>
                          {isError
                            ? prefixedT('SUBHEADING_ERROR')
                            : prefixedT('SUBHEADING')}
                        </Styled.Subheading>
                      </Styled.HeaderTextContainer>
                    </Styled.Header>
                  </Styled.HeaderContainer>
                  <ChatbotMessages
                    convoId={createConversation?.convo_id}
                    messages={listOfMessages}
                    isLoading={isLoading}
                  />
                </div>
                <Styled.MessageContainer>
                  <Styled.InputContainer>
                    <Styled.MsgInput
                      type="text"
                      ref={inputRef}
                      disabled={isError}
                      placeholder={prefixedT('INPUT.PLACEHOLDER')}
                      value={message}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  </Styled.InputContainer>
                  <Styled.SubmitButton
                    disabled={isError}
                    data-testid="submitBtn"
                    onClick={sendMessage}
                  >
                    <Styled.SubmitImg />
                  </Styled.SubmitButton>
                </Styled.MessageContainer>
              </Styled.Wrapper>
            </animated.div>
          ),
      )}
    </Styled.Container>
  );
};

export default Chatbot;
