import { RefObject, useEffect } from 'react';

const useOutsideScroll = (
  elementRef: RefObject<HTMLElement> | undefined,
  handler: (arg0: boolean) => void,
  attached: boolean = true,
) => {
  useEffect(() => {
    if (!attached || !elementRef) {
      return;
    }

    const handleOutsideScroll = () => {
      if (!elementRef.current) {
        return;
      }
      handler(false);
    };

    elementRef.current?.addEventListener('scroll', handleOutsideScroll);
    return () => {
      document.body.removeEventListener('scroll', handleOutsideScroll);
    };
  }, [elementRef, handler, attached]);
};

export default useOutsideScroll;
