import styled from 'styled-components';

export const Title = styled.div`
  line-height: 30px;
  font-size: 12px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
`;

export const SelectedBorder = styled.div`
  width: 5px;
  content: '';
  border-radius: 10px;
  background: #00699d;
  position: absolute;
  left: -10px;
  top: 3px;
  bottom: 3px;
`;

export const LayerTitle = styled.div<{
  selected: boolean;
}>`
  position: relative;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background: ${({ selected }) => (selected ? '#F8F9FA' : '#FFF')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  display: flex;
  align-items: center;

  :hover {
    background: #f8f9fa;
  }
`;

export const Image = styled.img`
  margin-right: 10px;
  height: 25px;
  width: 25px;
  margin-left: 10px;
`;

export const Wrapper = styled.div<{
  firstChild: boolean;
}>`
  border-top: ${({ firstChild }) =>
    firstChild ? 'none' : '1px solid #e7e7e7'};
  margin-bottom: 5px;
`;
