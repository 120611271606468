import { ReactComponent as PencilIcon } from '@/assets/images/pencil.svg';
import { ReactComponent as TargetIcon } from '@/assets/images/target.svg';
import styled from 'styled-components';

export const TargetImg = styled(TargetIcon)`
  width: 40px;
  height: 40px;
  margin-left: -10px;
`;

export const PencilImg = styled(PencilIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  background-color: #f3f3f3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Input = styled.input`
  outline: none;
  border: none;
  background-color: #fff;
  border: none;
  color: #f18400;
  font-weight: bold;
  font-size: 20px;

  :disabled {
    background-color: #f3f3f3;
  }
`;

export const Span = styled.span`
  color: #f18400;
  font-weight: bold;
  font-size: 20px;
`;
