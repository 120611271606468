import {
  ActiveAccount,
  setActiveAccount,
} from '@/redux/activeAccount/activeAccount.slice';
import { PortalPartnersResponse } from '@/types/report.type';
import { IUser } from '@/types/user.type';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';

type AppAction = ReturnType<typeof setActiveAccount>;

export const HandleAccountSwitch = (
  data: PortalPartnersResponse[] | undefined,
  activeAccount: ActiveAccount | null,
  profile: IUser | undefined,
  dispatch: Dispatch<AppAction>,
  navigate: NavigateFunction,
) => {
  const queryParams = new URLSearchParams(location.search);
  const partner = queryParams.get('partner');
  const company = queryParams.get('company');

  if (partner && data) {
    const partnerId = Number(partner);
    const partnerValues = data.find(
      (portalPartner) => portalPartner.id === partnerId,
    );
    if (!partnerValues) {
      return;
    }
    dispatch(
      setActiveAccount({
        id: partnerValues.id,
        name: partnerValues.name,
        type: 'partner',
      }),
    );
  }
  if (company && data) {
    const companyId = Number(company);
    const partnerWithCompany = data.find((portalPartner) =>
      portalPartner.companies.some((company1) => company1.id === companyId),
    );

    if (!partnerWithCompany) {
      return;
    }

    const companyValues = partnerWithCompany.companies.find(
      (company2) => company2.id === companyId,
    );
    if (!companyValues) {
      return;
    }

    dispatch(
      setActiveAccount({
        id: companyValues.id,
        name: companyValues.name,
        type: 'company',
      }),
    );
  }
  if (!company && !partner) {
    if (activeAccount) {
      const newUrl = `${window.location.pathname}?${activeAccount.type}=${activeAccount.id}`;
      navigate(newUrl, { replace: true });
    }

    if (profile?.partner?.partner_name && !activeAccount) {
      dispatch(
        setActiveAccount({
          id: Number(profile.partner.id),
          name: profile.partner.partner_name,
          type: 'partner',
        }),
      );

      const newUrl = `${window.location.pathname}?partner=${profile.partner.id}`;
      navigate(newUrl, { replace: true });
    }

    if (profile?.company?.company_name && !activeAccount) {
      dispatch(
        setActiveAccount({
          id: Number(profile.company.id),
          name: profile.company.company_name,
          type: 'company',
        }),
      );

      const newUrl = `${window.location.pathname}?company=${profile.company.id}`;
      navigate(newUrl, { replace: true });
    }
  }
};
