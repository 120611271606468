import styled from 'styled-components';

export const Container = styled.div<{
  backgroundColor?: string;
  icon?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#fff'};
  padding: ${({ icon }) => (icon ? '5px 5px 20px 20px' : '30px')};
  justify-content: ${({ icon }) => (icon ? 'space-between' : 'center')};
  text-align: ${({ icon }) => (icon ? 'left' : 'center')};
  display: ${({ icon }) => (icon ? 'flex' : 'initial')};
  flex: 1;
  flex-basis: ${({ icon }) => (icon ? '0' : 'initial')};
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const FirstDiv = styled.div<{
  icon?: string;
}>`
  padding-top: ${({ icon }) => (icon ? '15px' : '0px')};
  flex: ${({ icon }) => (icon ? '0 0 70%;' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: inherit;
`;

export const SecondDiv = styled.div`
  display: flex;
  justify-content: end;
`;

export const NumberContainer = styled.div<{
  icon?: string;
}>`
  font-size: ${({ icon }) => (icon ? '30px' : '20px')};
  margin-top: ${({ icon }) => (icon ? '10px' : '0')};
`;

export const BoxHeader = styled.h3<{
  icon?: string;
}>`
  font-size: 14px;
  font-weight: ${({ icon }) => (icon ? 'normal' : 'bold')};
  margin-bottom: ${({ icon }) => (icon ? '0' : '10px')};
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  opacity: 0.65;
`;

export const Description = styled.p`
  font-size: 12px;
`;
