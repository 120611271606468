import { createApi } from '@reduxjs/toolkit/query/react';
import { gisApiBaseQuery } from '../api';
import {
  ApproveGISUploadBody,
  DeleteGisUploadBody,
  GISAdminPageResponse,
} from '@/types/GISAdmin.type';
import {
  IPaginationResponse,
  IPaginationSnakeCaseProps,
  iSearchProps,
  iSortBy,
} from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';

export const GISAdminApi = createApi({
  reducerPath: 'GISAdminApi',
  baseQuery: gisApiBaseQuery,
  tagTypes: ['PostDeleteGISUpload', 'PostApproveGISUpload', 'getGISAdminPage'],
  endpoints: (builder) => ({
    PostDeleteGISUpload: builder.mutation<
      undefined,
      { body: DeleteGisUploadBody }
    >({
      query: ({ body }) => ({
        url: `/gis_admin/delete_gis_upload`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['PostDeleteGISUpload'],
    }),
    PostApproveGISUpload: builder.mutation<
      undefined,
      { body: ApproveGISUploadBody }
    >({
      query: ({ body }) => ({
        url: `/gis_admin/approve_gis_upload`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['PostApproveGISUpload'],
    }),
    GetGISAdminPage: builder.query<
      IPaginationResponse<GISAdminPageResponse>,
      IPaginationSnakeCaseProps & iSearchProps & iSortBy
    >({
      query: (params) =>
        `/gis_admin/gis_admin_page?${buildQueryParams(params || {})}`,
      providesTags: ['getGISAdminPage'],
    }),
  }),
});

export const {
  usePostDeleteGISUploadMutation,
  usePostApproveGISUploadMutation,
  useGetGISAdminPageQuery,
} = GISAdminApi;
