import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  SelectedValue,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import * as Styled from './style';
import React, { FC, useMemo } from 'react';
import { updateSelectedValues } from './utils';

interface SelectedItemsProps {
  fileName: string;
}

export const SelectedItems: FC<SelectedItemsProps> = ({ fileName }) => {
  const { prefixedT } = useCustomTranslation('GIS_UPLOAD.TABLE');
  const { selectedValues, setSelectedValues, currentStep } =
    useGISUploadValues();

  const selectedFileValues = useMemo(
    () => selectedValues.find((f) => f.file === fileName),
    [selectedValues, fileName],
  );

  const removeItem = (column: SelectedValue) => {
    setSelectedValues((prevValues) =>
      updateSelectedValues(prevValues, fileName, column, currentStep),
    );
  };

  return (
    <Styled.SelectedItemWrapper>
      <Styled.SelectedItemTitle>
        {prefixedT('MAPPING_TITLE')}
      </Styled.SelectedItemTitle>
      {(selectedFileValues?.data.length === 0 || !selectedFileValues?.data) && (
        <p>{prefixedT('SELECT_COLUMN')}</p>
      )}
      <Styled.Wrapper>
        {selectedFileValues?.data.map((value, index) => {
          const duplicateColumn =
            selectedFileValues.data.filter((file) => file.title === value.title)
              .length > 1;
          return (
            <Styled.SelectedItemContainer
              key={index}
              duplicateColumn={duplicateColumn}
            >
              <Styled.Items>
                <p>{value.name}</p>
                <p>→</p>
                <p>{value.title}</p>
              </Styled.Items>
              <Styled.Close
                duplicateColumn={duplicateColumn}
                onClick={() => removeItem(value)}
              >
                x
              </Styled.Close>
            </Styled.SelectedItemContainer>
          );
        })}
      </Styled.Wrapper>
    </Styled.SelectedItemWrapper>
  );
};
