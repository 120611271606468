import StockTable from './StockTable/StockTable';
import React, { FC } from 'react';
import * as Styled from './style';
import StockDistribution from '@/components/Stock/StockDistribution/StockDistribution';
import { UserRoles } from '@/types/user.type';
import { useGetProfileQuery } from '@/redux/user/user.api';

interface StockManagementContainerProps {
  isSensorManagement?: boolean;
}

const StockManagementContainer: FC<StockManagementContainerProps> = ({
  isSensorManagement = true,
}) => {
  const { data: profile } = useGetProfileQuery();

  return (
    <Styled.Wrapper>
      {/* Only render if super admin */}
      {profile?.role?.role_code === UserRoles.SUPER_ADMIN && (
        <StockDistribution isSensorManagement={isSensorManagement} />
      )}

      <StockTable isSensorManagement={isSensorManagement} />
    </Styled.Wrapper>
  );
};

export default StockManagementContainer;
