import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './style';
import Plot from 'react-plotly.js';
import useCustomTranslation from '@/localization/useCustomTranslation';
interface PieChartsProps {
  data: { values: number; labels: string | null }[][];
  layout?: { rows: number; columns: number };
  type?: string[];
  height?: number;
  total?: number;
}

/**
 * PieCharts component renders a set of pie charts using Plotly.js.
 *
 * @component
 * @param {Array} data - An array of arrays containing the data for each pie chart. Each inner array represents a pie chart and contains objects with `values` and `labels`.
 * @param {Array} [type] - An optional array of strings representing the type or name of each pie chart.
 * @param {number} [total] - An optional total value to be displayed in the summary section.
 * @param {Object} layout - An object defining the layout of the pie charts grid with `rows` and `columns`. Defaults to `{ rows: 1, columns: 1 }`.
 * @param {number} [height=800] - An optional height for the pie charts container. Defaults to 800.
 *
 * @returns {JSX.Element} The rendered PieCharts component.
 *
 * @example
 * <PieCharts
 *   data={[
 *     [{ values: 10, labels: 'A' }, { values: 20, labels: 'B' }],
 *     [{ values: 30, labels: 'C' }, { values: 40, labels: 'D' }]
 *   ]}
 *   layout={{ rows: 1, columns: 2 }}
 *   type={['Chart 1', 'Chart 2']}
 *   height={600}
 *   total={100}
 * />
 */
const PieCharts: FC<PieChartsProps> = ({
  data,
  type,
  layout = { rows: 1, columns: 1 },
  height = 800,
  total,
}) => {
  const { prefixedT } = useCustomTranslation('PIE_CHART');
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(800);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentBoxSize[0].inlineSize);
    });

    resizeObserver.observe(ref.current as Element);
  }, []);

  const formattedData: Partial<Plotly.PlotData>[] = useMemo(() => {
    const colorArray = [
      '#77D2E5',
      '#424242',
      '#1A947E',
      '#846BCE',
      '#192A3E',
      '#007CBB',
      '#34B639',
      '#009FE3',
      '#9FA2B4',
      '#86DF6C',
      '#00699D',
      '#469DDD',
      '#5DC6FB',
      '#BCD0F8',
      '#21CCFF',
      '#007CBB',
    ];

    const colorArrayExtended = Array(
      Math.ceil(
        Math.max(...data.map((datum) => datum.length)) / colorArray.length,
      ),
    )
      .fill([...colorArray])
      .reduce((a: string, b: string) => a.concat(b)) as string[];

    return data.map((datum, idx) => ({
      values: datum.map((item) => item.values),
      labels: datum.map((item) => item.labels),
      domain: { column: idx },
      marker: {
        colors: colorArrayExtended,
        line: {
          color: 'white',
          width: 2,
        },
      },
      textinfo: 'label+value+percent',
      textposition: 'inside',
      name: type?.[idx],
      hoverinfo: 'all',
      hole: 0.3,
      title: {
        text: `${type?.[idx]}`,
        font: {
          size: 20,
        },
      },
      type: 'pie',
      legendgroup: type?.[idx],
    }));
  }, [data]);

  return (
    <Styled.PlotContainer data-testid="pie-chart-container" ref={ref}>
      {total && (
        <p>
          {prefixedT('SUMMARY_TOTAL')}: {total}
        </p>
      )}
      <Plot
        data={formattedData}
        layout={{
          width,
          height,
          showlegend: false,
          grid: layout,
        }}
      />
    </Styled.PlotContainer>
  );
};

export default PieCharts;
