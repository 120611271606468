import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background: white;
`;

export const DropDownWrapper = styled.div`
  width: 400px;
  padding-left: 5%;
  padding-top: 5%;
  background: white;
`;

export const ContentWrapper = styled.div`
  width: 80%;
  padding: 5%;
  background: white;
`;

export const DropDownTitle = styled.h2`
  margin-bottom: 10px;
`;
