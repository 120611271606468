import { BuildFunnelChartData } from '@/containers/Dashboard/Components/utils';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import { leakTypes } from '@/types/report.type';
import React, { FC, useContext } from 'react';
import FunnelChartComponent from '../FunnelChart/FunnelChart';
import * as Styled from './style';

interface LeakSizeFunnelsProps {
  smallLeaks: leakTypes;
  mediumLeaks: leakTypes;
  LargeLeaks: leakTypes;
}

export const LeakSizeFunnels: FC<LeakSizeFunnelsProps> = ({
  smallLeaks,
  mediumLeaks,
  LargeLeaks,
}) => {
  const { setSize } = useContext(WaypointFilterContext);
  const { prefixedT } = useCustomTranslation('COMPONENTS.LEAK_SIZE_FUNNEL');

  const smallLeakData = BuildFunnelChartData(
    smallLeaks.leaks_identified,
    smallLeaks.number_scheduled,
    smallLeaks.number_marked,
    smallLeaks.number_repaired,
  );

  const mediumLeakData = BuildFunnelChartData(
    mediumLeaks.leaks_identified,
    mediumLeaks.number_scheduled,
    mediumLeaks.number_marked,
    mediumLeaks.number_repaired,
  );

  const largeLeakData = BuildFunnelChartData(
    LargeLeaks.leaks_identified,
    LargeLeaks.number_scheduled,
    LargeLeaks.number_marked,
    LargeLeaks.number_repaired,
  );

  const handleClick = (value: string) => {
    setSize(value);
  };

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <p>{prefixedT('SMALL')}</p>
        <FunnelChartComponent
          onClick={() => handleClick('Small')}
          dimensions={200}
          data={smallLeakData}
        />
      </Styled.Container>
      <Styled.Container>
        <p>{prefixedT('MEDIUM')}</p>
        <FunnelChartComponent
          onClick={() => handleClick('Medium')}
          dimensions={200}
          data={mediumLeakData}
        />
      </Styled.Container>
      <Styled.Container>
        <p>{prefixedT('LARGE')}</p>
        <FunnelChartComponent
          onClick={() => handleClick('Large')}
          dimensions={200}
          data={largeLeakData}
        />
      </Styled.Container>
    </Styled.Wrapper>
  );
};
