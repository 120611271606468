import GraphToggle from '@/components/GraphToggle/GraphToggle';
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetDropdownQuery,
  useGetTopSoundInformationQuery,
} from '@/redux/reports/reports.api';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';
import { MapProvider } from '@/Providers/MapProvider';

interface ConditionalListItemProps {
  item: string | number | null;
  text: string;
}

const TopSoundContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation(
    'TOP_SOUND_PAGE.ENGINEERING_DETAILS',
  );
  const location = useLocation();
  const [waypointID, setWaypointID] = useState<string>('');
  const [topSoundID, setTopSoundID] = useState<string>('');

  const { data: dropDownData } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedTopSoundID = queryParams.get('topSound') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setTopSoundID(updatedTopSoundID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: topSoundData } = useGetTopSoundInformationQuery(topSoundID, {
    refetchOnMountOrArgChange: true,
    skip: !topSoundID,
  });

  const imagesExist =
    topSoundData &&
    (topSoundData.top_sounding_files.audio_urls.length > 0 ||
      topSoundData.top_sounding_files.psd_urls.length > 0);

  const ConditionalListItem: React.FC<ConditionalListItemProps> = ({
    item,
    text,
  }) => (
    <li>
      {text}: {item && <Styled.ListObject>{item}</Styled.ListObject>}
    </li>
  );

  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlations={dropDownData.correlations}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessions={dropDownData.sessions}
          jobs={dropDownData.jobs}
          active="Top Sound"
        />
      )}

      {topSoundData && (
        <>
          {topSoundData.map_info?.data && (
            <Styled.MapAndGraphContainer>
              <Styled.MapContainer>
                <MapProvider>
                  <GenerateMap
                    fullMapData={topSoundData.map_info?.data}
                    viewportMapData={topSoundData.map_info?.data}
                    readonly={true}
                    doNotAllowClickThrough={true}
                  />
                </MapProvider>
              </Styled.MapContainer>
            </Styled.MapAndGraphContainer>
          )}
          {topSoundData?.top_sounding_files?.top_urls.length > 0 && (
            <Styled.GraphContainer>
              <img
                width="50%"
                src={topSoundData?.top_sounding_files?.top_urls[0]}
              />
            </Styled.GraphContainer>
          )}
          {imagesExist && (
            <GraphToggle
              audioUrls={topSoundData.top_sounding_files?.audio_urls}
              psdUrls={topSoundData.top_sounding_files?.psd_urls}
            />
          )}
          <div>
            <Styled.ListContainer>
              <Styled.ListWrapper>
                <Styled.ListContainerItem>
                  <Styled.ListTitle>
                    {prefixedT('LOCATION_DETAILS')}
                  </Styled.ListTitle>
                  <Styled.List>
                    <ConditionalListItem
                      item={topSoundData?.company}
                      text={prefixedT('COMPANY')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.engineer}
                      text={prefixedT('ENGINEER')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.location}
                      text={prefixedT('LOCATION')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.postcode}
                      text={prefixedT('POSTCODE')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.what3words}
                      text={prefixedT('WHAT3WORDS')}
                    />
                  </Styled.List>
                </Styled.ListContainerItem>
                <Styled.ListContainerItem>
                  <Styled.ListTitle>
                    {prefixedT('AUDIO_INFORMATION')}
                  </Styled.ListTitle>
                  <Styled.List>
                    <ConditionalListItem
                      item={topSoundData?.HF_cutoff}
                      text={t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.HIGH_FREQUENCY')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.LF_cutoff}
                      text={t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.LOW_FREQUENCY')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.number_of_paths}
                      text={t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.NUMBER_PATHS')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.spacing}
                      text={t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.SPACING')}
                    />
                    <ConditionalListItem
                      item={topSoundData?.starting_distance}
                      text={t(
                        'TOP_SOUND_PAGE.FREQUENCY_CUTOFF.STARTING_DISTANCE',
                      )}
                    />
                    <ConditionalListItem
                      item={topSoundData?.time_of_top_sounding}
                      text={t(
                        'TOP_SOUND_PAGE.FREQUENCY_CUTOFF.TIME_TOP_SOUNDING',
                      )}
                    />
                  </Styled.List>
                </Styled.ListContainerItem>
              </Styled.ListWrapper>
            </Styled.ListContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default TopSoundContainer;
