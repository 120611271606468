import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Field, Formik } from 'formik';
import { DropEvent, FileRejection } from 'react-dropzone';
import * as Styled from './style';
import { Button, DropDown } from '@/components';
import FileUpload, {
  FileUploadStatus,
} from '@/components/FileUpload/FileUpload';
import { ButtonVariant } from '@/components/Button/Button';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetCompanyListQuery } from '@/redux/portalCompanies/portalCompanies.api';
import { DropDownOption } from '@/components/DropDown/DropDown';
import withPageLoader from '@/HOCs/withPageLoader';
import { usePostUploadGISFilesMutation } from '@/redux/gisUpload/gisUpload.api';
import { GISUploadProps } from '../types/types';
import { ValidationSchema } from './ValidationSchema';
import { useGISUploadValues } from '@/Providers/GISUploadProvider';
import { goForwardStep } from '../utils';

const UploadShapefile: FC = () => {
  const { setCompanyId, setUploadId, setIsLoading, setCurrentStep } =
    useGISUploadValues();
  const [mutate, { data, isSuccess, isError, isLoading }] =
    usePostUploadGISFilesMutation();

  const { prefixedT } = useCustomTranslation('GIS_UPLOAD');
  const { data: companyList } = useGetCompanyListQuery();

  const formikValuesRef = useRef<GISUploadProps | null>(null);

  const initialValues = useMemo<GISUploadProps>(
    () => ({
      company_id: '',
      company_name: '',
      file: [],
    }),
    [],
  );

  const onSubmit = async (values: GISUploadProps) => {
    formikValuesRef.current = values;
    await mutate({
      params: {
        company_id: Number(values.company_id),
      },
      file: values.file[0],
    });
  };

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && formikValuesRef.current) {
      setCurrentStep((prevStep: number) => goForwardStep(prevStep));
      setUploadId(data);
      setCompanyId(Number(formikValuesRef.current.company_id));
    }
  }, [isSuccess]);

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      _fileRejections: FileRejection[],
      _event: DropEvent,
      setValues: (values: GISUploadProps) => void,
      values: GISUploadProps,
    ) => {
      setValues({
        ...values,
        file: acceptedFiles,
      });
    },
    [],
  );

  return (
    <Styled.GISWrapper>
      <Styled.WrapperTitle>
        <h3>{prefixedT('PAGE_TITLE')}</h3>
      </Styled.WrapperTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          setValues,
          setFieldValue,
          submitForm,
          resetForm,
        }) => (
          <div>
            <Styled.CompanyDropDownContainer>
              <Field
                as={DropDown}
                required={true}
                styles={{ border: true, width: '50%' }}
                options={companyList?.map((company) => ({
                  label: company.name,
                  value: company.id,
                }))}
                label={prefixedT('INPUT.COMPANY.LABEL')}
                placeholder={
                  values?.company_name || prefixedT('INPUT.COMPANY.PLACEHOLDER')
                }
                onChange={(option: DropDownOption) => {
                  const companyId = parseInt(option.value);
                  void setFieldValue('company_id', companyId);
                  void setFieldValue('company_name', option.label);
                }}
                countOptions={4}
                error={errors.company_id?.toString()}
                value={values.company_id?.toString()}
                testId="company"
              />
            </Styled.CompanyDropDownContainer>
            <FileUpload
              label={prefixedT('UPLOAD.LABEL')}
              required={true}
              onDrop={(acceptedFiles, fileRejections, event) =>
                onDrop(acceptedFiles, fileRejections, event, setValues, values)
              }
              files={values.file}
              fileTypes={['.zip']}
              error={errors.file as unknown as FileUploadStatus | null}
            />
            {isError && (
              <Styled.ErrorLabel>
                {prefixedT('API.GENERIC_ERROR')}
              </Styled.ErrorLabel>
            )}
            <Styled.ButtonWrapper>
              <Button
                variant={ButtonVariant.outline}
                disabled={!values.file.length}
                onClick={() => resetForm()}
              >
                {prefixedT('BUTTON.DISCARD')}
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  void (async () => {
                    await submitForm();
                  })();
                }}
              >
                {prefixedT('BUTTON.NEXT')}
              </Button>
            </Styled.ButtonWrapper>
          </div>
        )}
      </Formik>
    </Styled.GISWrapper>
  );
};

export default withPageLoader(UploadShapefile);
