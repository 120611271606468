import { Loader } from '@/components';
import { useGISUploadValues } from '@/Providers/GISUploadProvider';
import React, { useContext, useEffect, useMemo } from 'react';
import { FC } from 'react';
import * as Styled from './style';
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import { useGetPreviewGISQuery } from '@/redux/gisUpload/gisUpload.api';
import { formatMapData } from './utils';
import { FilterContext, FilterName } from '@/Providers/FilterProvider';
import { MapProvider } from 'react-map-gl';
import useCustomTranslation from '@/localization/useCustomTranslation';

const MapPreview: FC = () => {
  const { prefixedT } = useCustomTranslation('GIS_UPLOAD.MAP_PREVIEW');
  const { companyId, uploadId } = useGISUploadValues();

  const { setFilterMapValues, setMapFilters, mapFilters } =
    useContext(FilterContext);

  const defaultFilterMapValues: FilterName[] = ['DMAS', 'PIPES', 'ASSETS'];

  const { data, isFetching, isError } = useGetPreviewGISQuery({
    companyId,
    uploadId,
  });

  useEffect(() => {
    setFilterMapValues(defaultFilterMapValues);
    setMapFilters({ filters: ['DMAS', 'PIPES', 'ASSETS'] });
  }, []);

  const mapData = useMemo(
    () => formatMapData(data, mapFilters),
    [data, mapFilters],
  );

  return (
    <Styled.Wrapper data-testid="gis-map-preview">
      {isError && (
        <Styled.ErrorText>{prefixedT('API.GENERAL_ERROR')}</Styled.ErrorText>
      )}
      {isFetching && (
        <Styled.ErrorText>
          <Loader />
        </Styled.ErrorText>
      )}
      {data && (
        <div data-testid="map-preview">
          <MapProvider>
            <GenerateMap
              fullMapData={mapData}
              readonly={false}
              viewportMapData={mapData}
              showMapFilters={true}
              isLoading={isFetching}
            />
          </MapProvider>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default MapPreview;
