import { Map } from 'mapbox-gl';
import * as turf from '@turf/turf';
import { MapMoveEvent } from './type';
import { SessionRelocationObject } from '@/Providers/FilterProvider';
import { getSourceData } from '../utils';
import { createPolyArea } from './createPolyArea';
import { findNearestSession } from './findNearestSession';
import { resetFeatLocation } from './resetFeatLocation';

export const onUp = (
  map: Map,
  isDraggingRef: React.MutableRefObject<boolean>,
  polygonCircleCoordinatesRef: React.MutableRefObject<
    turf.helpers.Position[][] | undefined
  >,
  event: MapMoveEvent,
  originalCoordsRef: React.MutableRefObject<{
    lng: number;
    lat: number;
  } | null>,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  setSessionRelocationObject: React.Dispatch<
    React.SetStateAction<SessionRelocationObject>
  >,
) => {
  map.getCanvas().style.cursor = '';
  isDraggingRef.current = false;

  if (polygonCircleCoordinatesRef.current) {
    const pt = turf.point([event.lngLat.lng, event.lngLat.lat]);
    const poly = turf.polygon(polygonCircleCoordinatesRef.current);

    const insidePoint = turf.booleanPointInPolygon(pt, poly);

    const data = getSourceData(map);

    createPolyArea(map);

    if (!insidePoint && originalCoordsRef.current) {
      resetFeatLocation(map, data, originalCoordsRef, draggedFeatureIndexRef);
    } else {
      const assetInfo = findNearestSession(data, pt, draggedFeatureIndexRef);

      if (assetInfo) {
        setSessionRelocationObject({
          assetId: assetInfo?.assetId,
          sessionId: assetInfo?.sessionId,
          assetKey: assetInfo?.assetKey,
        });
      }
    }
  }
};
