import React, { FC } from 'react';
import { Success, Error, Warning } from '@/assets/icons/statusIcons';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';

type Props = {
  title: string;
  description: string;
  status: 'success' | 'error' | 'warning';
};

const StatusMessage: FC<Props> = ({ title, description, status }) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.STATUS_MESSAGE');
  return (
    <Styled.Wrapper data-testid="status-message" status={status}>
      <Styled.ImageContainer>
        {status === 'success' && (
          <img alt={prefixedT('ICON.ALT.SUCCESS')} src={Success as string} />
        )}
        {status === 'error' && (
          <img alt={prefixedT('ICON.ALT.ERROR')} src={Error as string} />
        )}
        {status === 'warning' && (
          <img alt={prefixedT('ICON.ALT.WARNING')} src={Warning as string} />
        )}
      </Styled.ImageContainer>
      <div>
        <Styled.TitleContainer>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleContainer>
        <Styled.DescriptionContainer>
          <Styled.Description>{description}</Styled.Description>
        </Styled.DescriptionContainer>
      </div>
    </Styled.Wrapper>
  );
};

export default StatusMessage;
