import { Column, SampleData } from '@/types/gisUpload.type';
import React, { FC, useMemo } from 'react';
import * as Styled from './style';
import Table from '../Table/Table';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FileInformation } from './Components/FileInformation/FileInformation';
import { GISHook } from '../Hooks/GISHook';
import SystemFields from './Components/SystemFields/SystemFields';
import { MapUnits } from './Components/MapUnits/MapUnits';
import { useGISUploadValues } from '@/Providers/GISUploadProvider';

export enum GISType {
  DMA = 'DMA',
  PIPES = 'Pipes',
  ASSETS = 'Assets',
}

interface ProcessFilesProps {
  sampleData: SampleData[];
  tableColumns: Column[];
  type: GISType;
}

const ProcessFiles: FC<ProcessFilesProps> = ({
  tableColumns,
  sampleData,
  type,
}) => {
  const { prefixedT } = useCustomTranslation('PROCESS_FILES');
  const {
    insufficientSampleData,
    unselectedColumns,
    hasNonRequiredField,
    currentFile,
    getSelectedItems,
  } = GISHook({
    sampleData,
    tableColumns,
  });
  const { fileIndex } = useGISUploadValues();

  const memoizedGetItems = useMemo(
    () => getSelectedItems(),
    [sampleData, tableColumns],
  );

  const diameterUnits = ['diameter', 'units'];
  return (
    <Styled.Wrapper data-testid="process-files">
      <Styled.Container>
        {sampleData.length === 0 ? (
          <p>{prefixedT('ERROR.NO_FILE_TO_MAP', { type })}</p>
        ) : (
          <FileInformation
            insufficientSampleData={insufficientSampleData}
            unselectedColumns={unselectedColumns}
            hasNonRequiredField={hasNonRequiredField}
          />
        )}
      </Styled.Container>

      {sampleData.length > 0 &&
        sampleData[fileIndex]?.data &&
        sampleData[fileIndex]?.file_name && (
          <Table
            tableColumns={tableColumns}
            sampleData={sampleData[fileIndex]?.data}
            fileName={sampleData[fileIndex]?.file_name}
          />
        )}

      {currentFile && memoizedGetItems.length > 0 && (
        <Styled.SystemContainer>
          <Styled.HR />

          <Styled.DiameterUnitWrapper>
            {memoizedGetItems.map((selectedValue) => {
              const isDiameter = selectedValue.title === 'diameter';
              const isDiameterOrUnit = diameterUnits.includes(
                selectedValue.title,
              );

              return (
                <React.Fragment key={selectedValue.name}>
                  {isDiameterOrUnit && (
                    <>
                      {isDiameter && (
                        <Styled.TitleContainer>
                          <h2>{prefixedT('TITLE.DIAMETER')}</h2>
                          <p>{prefixedT('DESC.DIAMETER')}</p>
                        </Styled.TitleContainer>
                      )}

                      {selectedValue.title === diameterUnits[0] && (
                        <Styled.ContainerA>
                          <MapUnits
                            sampleData={sampleData[fileIndex]?.data}
                            file={currentFile}
                          />
                        </Styled.ContainerA>
                      )}

                      {selectedValue.title === diameterUnits[1] && (
                        <Styled.ContainerB>
                          <SystemFields
                            selectedFileValue={selectedValue}
                            file={currentFile}
                          />
                        </Styled.ContainerB>
                      )}
                    </>
                  )}

                  {!isDiameterOrUnit && (
                    <Styled.SystemFieldContainer>
                      <SystemFields
                        selectedFileValue={selectedValue}
                        file={currentFile}
                      />
                    </Styled.SystemFieldContainer>
                  )}
                </React.Fragment>
              );
            })}
          </Styled.DiameterUnitWrapper>
        </Styled.SystemContainer>
      )}
    </Styled.Wrapper>
  );
};

export default ProcessFiles;
