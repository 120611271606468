import styled, { css } from 'styled-components';

export const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const Label = styled.label<{ isChecked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 25px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2.5px;
    width: 21px;
    height: 21px;
    background: #fff;
    border-radius: 21px;
    transition: 0.3s;
  }
  
  ${({ isChecked }) =>
    isChecked &&
    css`
      background: #bada55;
      &:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    `}};

  ${Input}:checked + &:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  &:active:after {
    width: 30px;
  }
`;

export const Container = styled.div`
  display: flex;
`;
