import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    min-width: 600px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 5px;
  justify-content: right;
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;
