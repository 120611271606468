import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const DropDownContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
`;

export const Container = styled.div`
  display: flex;
  align-items: baseline;
`;
