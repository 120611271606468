import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div``;

export const SubmitBtnContainer = styled.div`
  margin-top: 20px;
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    min-width: 400px;

    path {
      /* Adjust the width of the SVG itself */
      width: 100%; /* This will scale the SVG to fit the container */
      fill: white;
      width: 100%;
    }
  }
`;

export const NotesContainer = styled.div`
  margin-top: 10px;
`;

export const ErrorMsgContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.artyClickRed};
  padding: 10px;
  color: white;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
export const ErrorMsg = styled.p`
  width: 70%;
`;

export const NoDataContainer = styled.div`
  margin-bottom: 10px;
`;
