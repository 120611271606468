import ThumbsDown from '@/assets/icons/thumbs/thumbs-down.png';
import ThumbsUp from '@/assets/icons/thumbs/thumbs-up.png';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useMessageStarRatingMutation } from '@/redux/aiAssistant/aiAssistant.api';
import { MessageStarRatingQuery } from '@/types/aiAssistant.type';
import React, { FC, useState } from 'react';
import * as Styled from './style';

interface ChatbotMessagesProps {
  convoId: string;
  msgId: string;
}

const Rating: FC<ChatbotMessagesProps> = ({ convoId, msgId }) => {
  const { prefixedT } = useCustomTranslation('CHATBOT');
  const [isCalled, setIsCalled] = useState<boolean>(false);
  const [mutate] = useMessageStarRatingMutation();

  const handleClick = (isPositive: boolean) => {
    const positive = 5;
    const negative = 0;
    const rating = isPositive ? positive : negative;

    const query: MessageStarRatingQuery = {
      convo_id: convoId,
      msg_id: msgId,
      star_rating: rating,
    };

    mutate(query);

    setIsCalled(true);
  };

  return (
    <Styled.ThumbsWrapper>
      {!isCalled ? (
        <>
          <Styled.ThumbsContainer
            data-testid="thumbs-up"
            onClick={() => handleClick(true)}
          >
            <Styled.ThumbImage src={ThumbsUp as string} />
          </Styled.ThumbsContainer>
          <Styled.ThumbsContainer
            data-testid="thumbs-down"
            onClick={() => handleClick(false)}
          >
            <Styled.ThumbImage src={ThumbsDown as string} />
          </Styled.ThumbsContainer>
        </>
      ) : (
        <Styled.FeedbackContainer>
          <p>{prefixedT('RATING.RESPONSE')}</p>
        </Styled.FeedbackContainer>
      )}
    </Styled.ThumbsWrapper>
  );
};

export default Rating;
