import { Button } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import PostProjectReportButton from '../PostProjectReportButton/PostProjectReportButton';
import * as Styled from './style';
import UploadHealthCheckButton from '../UploadHealthCheckButton/UploadHealthCheckButton';
import { UserRoles } from '@/types/user.type';
import { makeAbsoluteUrl } from './utils';

interface ReportLinksProps {
  projectId: number;
  companyId: string;
  companyName: string;
  projectName: string;
  roleCode: UserRoles | undefined;
  healthCheckUrl?: string | null;
}

const ReportLinks: FC<ReportLinksProps> = ({
  companyId,
  companyName,
  projectName,
  projectId,
  roleCode,
  healthCheckUrl,
}) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');

  const reporturls = (type: string): string => {
    let url = '';

    if (type === 'sensor-technician') {
      url += '/app/delivery-performance-sensor-technician?';
    }
    if (type === 'le') {
      url += '/app/delivery-performance-le?';
    }
    if (companyId && companyName) {
      url += `company=${companyId}&company-name=${companyName}`;
    }
    if (projectId && projectName) {
      url += `&project=${projectId}&project-name=${projectName}`;
    }
    return url;
  };

  return (
    <Styled.ReportContainer>
      <div>
        <Styled.Subheading>
          {prefixedT('SUMMARY.REPORTS.TITLE')}
        </Styled.Subheading>
        <Styled.ReportsContainer>
          <Button>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/app/project-status?project=${projectId}`}
            >
              <Styled.ReportLinks>
                {prefixedT('SUMMARY.REPORTS.PROJECT')}
              </Styled.ReportLinks>
            </Link>
          </Button>
          <Button>
            <Link
              style={{ textDecoration: 'none' }}
              to={reporturls('sensor-technician')}
            >
              <Styled.ReportLinks>
                {prefixedT('SUMMARY.REPORTS.DELIVERY')} ST
              </Styled.ReportLinks>
            </Link>
          </Button>
          <Button>
            <Link style={{ textDecoration: 'none' }} to={reporturls('le')}>
              <Styled.ReportLinks>
                {prefixedT('SUMMARY.REPORTS.DELIVERY')} LE
              </Styled.ReportLinks>
            </Link>
          </Button>
          <PostProjectReportButton projectId={projectId} />
          {roleCode === UserRoles.SUPER_ADMIN && (
            <UploadHealthCheckButton projectId={projectId} />
          )}
          {healthCheckUrl !== null && healthCheckUrl && (
            <Styled.LinkContainer>
              <Styled.LinkBtn
                href={makeAbsoluteUrl(healthCheckUrl)}
                data-testid="health-check-url-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.ReportLinks>
                  {prefixedT('SUMMARY.REPORTS.HEALTH_CHECK')}
                </Styled.ReportLinks>
              </Styled.LinkBtn>
            </Styled.LinkContainer>
          )}
        </Styled.ReportsContainer>
      </div>
    </Styled.ReportContainer>
  );
};

export default ReportLinks;
