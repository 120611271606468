import { GIS_COLUMN_MAPPING } from '@/types/gisUpload.type';
import React, {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useEffect,
} from 'react';

export interface SelectedValue {
  value: string;
  title: string;
  name: string;
  type?: GIS_COLUMN_MAPPING;
  required?: boolean;
}

interface Item {
  title: string;
  type: string;
}

export interface FileSelectedValues {
  file: string;
  material?: Item[];
  asset_type?: Item[];
  pipe_type?: Item[];
  status?: Item[];
  units?: Item[] | number;
  data: SelectedValue[];
}

export enum ErrorModalText {
  DEFAULT_ERROR_MESSAGE = 1,
  COLUMNS_NOT_SELECTED = 2,
  SYSTEM_FIELDS_NOT_MAPPED = 3,
  SYSTEM_FIELD_NUMERIC = 4,
}

export enum GISRoutes {
  UPLOAD_SHAPEFILE = 0,
  PROCESS_DMA = 1,
  PROCESS_PIPES = 2,
  PROCESS_ASSETS = 3,
  MAP_PREVIEW = 4,
}

export interface MappedSystemFields {
  material: boolean;
  asset_type: boolean;
  pipe_type: boolean;
  status: boolean;
  units: boolean;
}
export interface GISUploadContextType {
  selectedValues: FileSelectedValues[];
  setSelectedValues: React.Dispatch<React.SetStateAction<FileSelectedValues[]>>;
  companyId: number | null;
  setCompanyId: React.Dispatch<React.SetStateAction<number | null>>;
  fileIndex: number;
  setFileIndex: React.Dispatch<React.SetStateAction<number>>;
  currentStep: GISRoutes;
  setCurrentStep: React.Dispatch<React.SetStateAction<GISRoutes>>;
  uploadId: number | null;
  setUploadId: React.Dispatch<React.SetStateAction<number | null>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorModalVisible: boolean;
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isError: Array<ErrorModalText> | null;
  setIsError: React.Dispatch<
    React.SetStateAction<Array<ErrorModalText> | null>
  >;
  mappedSystemFields: MappedSystemFields;
  setMappedSystemFields: React.Dispatch<
    React.SetStateAction<MappedSystemFields>
  >;
  resetProvider: () => void;
}

export const GISUploadContext = createContext<GISUploadContextType | undefined>(
  undefined,
);

export const defaultSystemFields: MappedSystemFields = {
  material: false,
  asset_type: false,
  pipe_type: false,
  status: false,
  units: false,
};

export const GISUploadProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedValues, setSelectedValues] = useState<FileSelectedValues[]>(
    [],
  );
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<GISRoutes>(
    GISRoutes.UPLOAD_SHAPEFILE,
  );
  const [isErrorModalVisible, setIsErrorModalVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<Array<ErrorModalText> | null>(null);
  const [mappedSystemFields, setMappedSystemFields] =
    useState<MappedSystemFields>(defaultSystemFields);
  const [uploadId, setUploadId] = useState<number | null>(null);

  const resetProvider = () => {
    setSelectedValues([]);
    setMappedSystemFields(defaultSystemFields);
    setUploadId(null);
    setCompanyId(null);
    setCurrentStep(GISRoutes.UPLOAD_SHAPEFILE);
    setIsLoading(false);
    setIsError(null);
    setFileIndex(0);
  };

  useEffect(() => {
    if (currentStep === GISRoutes.UPLOAD_SHAPEFILE) {
      resetProvider();
    }
  }, [currentStep]);

  return (
    <GISUploadContext.Provider
      value={{
        isError,
        setIsError,
        mappedSystemFields,
        setMappedSystemFields,
        fileIndex,
        setFileIndex,
        selectedValues,
        setSelectedValues,
        setCurrentStep,
        currentStep,
        companyId,
        setCompanyId,
        uploadId,
        setUploadId,
        isLoading,
        setIsLoading,
        resetProvider,
        isErrorModalVisible,
        setIsErrorModalVisible,
      }}
    >
      {children}
    </GISUploadContext.Provider>
  );
};

export const useGISUploadValues = () => {
  const context = useContext(GISUploadContext);
  if (!context) {
    throw new Error(
      'useSelectedValues must be used within a SelectedValuesProvider',
    );
  }

  return context;
};
