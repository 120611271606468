import { Button, DatePicker, Loader } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useInsertDatesMutation } from '@/redux/portalUsers/portalUsers.api';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import * as Styled from './style';

interface InsertDateProps {
  dateType: 'start' | 'end';
  id: number;
  type: 'project' | 'dma';
  startDate: string | undefined | null;
  endDate: string | undefined | null;
}

interface insertDateValues {
  date: Date | null;
}

const InsertDate: FC<InsertDateProps> = ({
  startDate,
  endDate,
  dateType,
  id,
  type,
}) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.INSERT_DATE');
  const [mutate, { isSuccess, isLoading, isError }] = useInsertDatesMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);

    if (success) {
      window.location.reload();
    }
  };

  const handleSubmit = (values: insertDateValues) => {
    const formattedDate = moment(values.date).format('YYYY-MM-DD');

    const dateField = dateType === 'start' ? 'start_date' : 'end_date';
    const updatedValues = { [dateField]: formattedDate };

    setSubmitted(true);

    mutate({ label: type, id, ...updatedValues });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setSuccess(true);
    } else if (isError) {
      setSuccess(false);
    }
  }, [isSuccess, isError]);

  const initValues = { date: null };

  const startLabel =
    dateType === 'start' && startDate ? startDate : prefixedT('ADD.START_DATE');
  const endLabel =
    dateType === 'end' && endDate ? endDate : prefixedT('ADD.END_DATE');

  const label = dateType === 'start' ? startLabel : endLabel;

  const parsedEndDate = moment(endDate, 'YYYY-MM-DD').toDate();
  const maxDate = dateType === 'start' ? parsedEndDate : null;

  const parsedStartDate = moment(startDate, 'YYYY-MM-DD').toDate();
  const minDate = dateType === 'end' ? parsedStartDate : null;

  return (
    <>
      <Styled.ShowDateContainer onClick={handleToggle}>
        <Styled.ShowDateText>{label}</Styled.ShowDateText>
        <Styled.PencilImg />
      </Styled.ShowDateContainer>

      {open && (
        <>
          <Styled.Underlay onClick={() => setOpen(false)} />
          <Styled.DialogBox open>
            {!submitted ? (
              <Formik
                enableReinitialize
                initialValues={initValues}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Field
                      as={DatePicker}
                      name="date"
                      label={label}
                      placeholder={prefixedT('DATE.PLACEHOLDER')}
                      onChange={(date: Date) => setFieldValue('date', date)}
                      maxDate={maxDate}
                      disableFuture={!!maxDate}
                      minDate={minDate}
                      disablePast={!!minDate}
                      styles={{ label: Styled.Label }}
                      testId="change-log-date"
                    />
                    <Styled.BtnContainer>
                      <Button
                        disabled={!values.date}
                        type="submit"
                        width="200px"
                        height="50px"
                      >
                        {prefixedT('SUBMIT')}
                      </Button>
                    </Styled.BtnContainer>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                {isLoading && <Loader />}
                {success && (
                  <div>
                    <Styled.SvgContainer positiveIcon={true}>
                      <Styled.CheckPostiveIcon />
                    </Styled.SvgContainer>
                    <Styled.AcknowledgementTitle>
                      {prefixedT('SUCCESS_TITLE')}
                    </Styled.AcknowledgementTitle>
                    <Styled.AcknowledgementDesc>
                      {prefixedT('SUCCESS_DESC')}
                    </Styled.AcknowledgementDesc>
                  </div>
                )}
                {!success && !isLoading && (
                  <div>
                    <Styled.SvgContainer positiveIcon={false}>
                      <Styled.CrossNegativeIcon />
                    </Styled.SvgContainer>
                    <Styled.AcknowledgementTitle>
                      {prefixedT('FAILURE_TITLE')}
                    </Styled.AcknowledgementTitle>
                    <Styled.AcknowledgementDesc>
                      {prefixedT('FAILURE_DESC')}
                    </Styled.AcknowledgementDesc>
                  </div>
                )}

                {!isLoading && (
                  <Styled.SubmitBtn isSuccess={success} onClick={handleClose}>
                    {prefixedT('OK')}
                  </Styled.SubmitBtn>
                )}
              </>
            )}
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default InsertDate;
