import { Button } from '@/components';
import { LeakSizeFunnels } from '@/components/LeakSizeFunnels/LeakSizeFunnels';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { AllocateLeakEngineer } from '@/components/WaypointFilter/AllocateLeakEngineer/AllocateLeakEngineer';
import { WaypointFilter } from '@/components/WaypointFilter/WaypointFilter';
import { parseDate } from '@/containers/Dashboard/Components/TimeRangeSelect/TimeRangeSelect';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterContext } from '@/Providers/FilterProvider';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import { useGetStatusesQuery } from '@/redux/mobileApp/mobileApp.api';
import { useGetEngineersQuery } from '@/redux/portalUsers/portalUsers.api';
import { useGetWaypointInfoMutation } from '@/redux/reports/reports.api';
import { DmaItem, WaypointInfoQuery } from '@/types/report.type';
import React, { FC, useContext, useEffect } from 'react';
import * as Styled from './style';

interface SummaryProps {
  summary: DmaItem;
  metricSystem: string | undefined;
}

const Summary: FC<SummaryProps> = ({ summary }) => {
  const { data: engineers } = useGetEngineersQuery();
  const { data: statuses } = useGetStatusesQuery();
  const { waypointSearch, size, status, page, perPage } = useContext(
    WaypointFilterContext,
  );
  const { setDMASearch, dateFilters } = useContext(FilterContext);
  const [mutate, { data }] = useGetWaypointInfoMutation();
  const { t } = useCustomTranslation('');

  useEffect(() => {
    if (summary?.id) {
      const queryParams: WaypointInfoQuery = {
        waypoint_search: waypointSearch,
        date_to: parseDate(dateFilters.dateTo),
        date_from: parseDate(dateFilters.dateFrom),
        dma_id: Number(summary?.id),
        ...(status.id && { status_id: status.id }),
        ...(size && { size }),
        page,
        perPage,
      };

      void mutate(queryParams);
    }
  }, [perPage, page, dateFilters, waypointSearch, summary.id, status.id, size]);

  const handleClick = (DMA: string) => {
    const element = document.getElementById('map-container');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setDMASearch(DMA);
  };

  return (
    <Styled.Wrapper>
      <Styled.DMAContainer>
        <Styled.DMAHeading>DMA: {summary.name}</Styled.DMAHeading>
        <Button onClick={() => handleClick(summary.name)}>
          {t('DASHBOARD_PAGE.SUMMARY.LOCATE_DMA_BUTTON')}
        </Button>
        {/* TODO add this back in when the feature is ready
        <AddFlowData dmaId={summary.id} metricSystem={metricSystem} /> */}
      </Styled.DMAContainer>
      <Styled.DetailWrapper>
        <Styled.ProgressBarWrapper>
          <ProgressBar
            insightLabel={{
              title: t('COMPONENTS.PROGRESS_BAR.PIPELINE_COVERAGE'),
              location: 'left',
            }}
            title={t('COMPONENTS.PROGRESS_BAR.PIPELINE_TITLE')}
            keyValues={[
              {
                name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_A'),
                color: '#81CFF5',
              },
              {
                name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_B'),
                color: '#1681ff',
              },
              {
                name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_C'),
                color: '#34b639',
              },
            ]}
            progressValues={[
              {
                value: summary?.info?.actual_collected_pipeline?.value,
                color: '#59C4E9',
                hover: summary?.info?.total_collected_pipeline?.value,
                type: summary?.info?.total_collected_pipeline.type,
              },
              {
                value: summary?.info?.actual_deployed_pipeline.value,
                color: '#009FE3',
                hover: summary?.info?.total_deployed_pipeline.value,
                type: summary?.info?.total_deployed_pipeline.type,
              },
              {
                value: summary?.info?.target_progress,
                color: '#34b639',
                hover: summary?.info?.target_progress,
                type: '%',
              },
            ]}
          />
        </Styled.ProgressBarWrapper>
        <div>
          <Styled.TextContainer>
            <Styled.Subheading>
              {t('DASHBOARD_PAGE.WATER.COST') +
                ` (${summary.info.cost_year.type})`}
            </Styled.Subheading>
            <Styled.Value>{summary.info.cost_year.value}</Styled.Value>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <Styled.Subheading>
              {t('DASHBOARD_PAGE.WATER.ANALYSED_BY')}
            </Styled.Subheading>
            <Styled.Value>{summary.info.sessions_analised}</Styled.Value>
          </Styled.TextContainer>
        </div>
      </Styled.DetailWrapper>
      <Styled.RowWrapper>
        <div>
          <LeakSizeFunnels
            smallLeaks={summary.info.small_leaks}
            mediumLeaks={summary.info.medium_leaks}
            LargeLeaks={summary.info.large_leaks}
          />
        </div>
      </Styled.RowWrapper>
      <Styled.WaypointFilterContainer>
        <WaypointFilter
          dmas={undefined}
          waypointData={data}
          projects={undefined}
          statuses={statuses}
        />
        {engineers && <AllocateLeakEngineer engineers={engineers} />}
      </Styled.WaypointFilterContainer>
    </Styled.Wrapper>
  );
};

export default Summary;
