import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '@/assets/icons/cross.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ReportLinks = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the underlay is behind the dialog */
`;

export const DialogBox = styled.dialog`
   {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    max-width: 350px;

    path {
      /* Adjust the width of the SVG itself */
      width: 100%; /* This will scale the SVG to fit the container */
      fill: white;
      width: 100%;
    }
  }
`;

export const OptionTitle = styled.h3`
  margin-bottom: 5px;
`;

export const OptionDesc = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const SvgContainer = styled.div<{
  positiveIcon?: boolean;
}>`
  position: absolute;
  top: -85px;
  left: 0;
  background-color: ${({
    theme,
    positiveIcon,
  }: ThemeProps<Theme> & { positiveIcon?: boolean }) =>
    theme.colors[positiveIcon ? 'lightGreen' : 'artyClickRed']};
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  right: 0;
`;

export const CheckPostiveIcon = styled(CheckIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const CrossNegativeIcon = styled(CrossIcon)`
  position: absolute;
  top: 0;
  width: 100px;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 100%;
`;

export const PostProjectTitle = styled.h3`
  padding: 40px 0 20px 0;
  font-size: 36px;
  color: #676767;
`;

export const PostProjectDesc = styled.p`
  padding: 20px 0 40px 0;
  font-size: 18px;
  color: #959595;
  line-height: 30px;
`;

export const PostProjectBtn = styled.button<{
  isSuccess?: boolean;
}>`
  padding: 20px;
  background-color: ${({ isSuccess }) => (isSuccess ? '#38e030' : '#FF0101')};
  width: 100%;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  color: white;
  cursor: pointer;

  :hover {
    background-color: ${({ isSuccess }) => (isSuccess ? '#2eb026' : '#d80000')};
  }
`;
