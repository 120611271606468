import React, { FC, useMemo } from 'react';
import { DropDown } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { Column } from '@/types/gisUpload.type';
import * as Styled from './style';
import { createDropdown, updateSelectedValues } from './utils';
import {
  ErrorModalText,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import { SelectedItems } from './SelectedItems/SelectedItems';
import { addOrReplaceError } from '../utils';

interface TableProps {
  tableColumns: Column[];
  sampleData: unknown[];
  fileName: string;
}

const Table: FC<TableProps> = ({ tableColumns, sampleData, fileName }) => {
  const {
    selectedValues,
    setSelectedValues,
    currentStep,
    isError,
    setIsError,
  } = useGISUploadValues();

  const dropdownOptions = createDropdown(tableColumns);
  const typedSampleData = sampleData as Record<string, any>[];

  const handleChange = (columnName: string, option: DropDownOption) => {
    if (Array.isArray(option)) {
      return;
    }

    setSelectedValues((prevValues) =>
      updateSelectedValues(
        prevValues,
        fileName,
        columnName,
        option,
        currentStep,
      ),
    );

    if (isError?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.SYSTEM_FIELD_NUMERIC, true),
      );
    }
    if (isError?.includes(ErrorModalText.COLUMNS_NOT_SELECTED)) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.COLUMNS_NOT_SELECTED, true),
      );
    }
  };

  const selectedFileValues = useMemo(
    () => selectedValues.find((f) => f.file === fileName),
    [selectedValues, fileName],
  );

  return (
    <>
      <Styled.Wrapper>
        <Styled.Table cellSpacing="0">
          <Styled.Thread>
            <Styled.HeaderTR>
              {typedSampleData.length > 0 &&
                Object.keys(typedSampleData[0]).map((key, _index) => (
                  <Styled.Th key={key}>
                    <DropDown
                      options={dropdownOptions}
                      label={undefined}
                      placeholder={key}
                      onChange={(option) =>
                        handleChange(key, option as DropDownOption)
                      }
                      value={
                        selectedFileValues?.data.find(
                          (item) => item.name === key,
                        )?.value || ''
                      }
                      styles={{
                        center: true,
                        background: 'transparent',
                        textColor: '#ffffff',
                        dropdownFitWidth: true,
                        dropdownTextColor: '#b3b3b3',
                        backgroundHover: '#302e36',
                        backgroundDropdown: '#26252a',
                      }}
                    />
                  </Styled.Th>
                ))}
            </Styled.HeaderTR>
          </Styled.Thread>
          <Styled.Tbody>
            {typedSampleData.map((row, rowIndex) => {
              const isLastRow = rowIndex === typedSampleData.length - 1;
              return isLastRow ? (
                <Styled.LastTR key={rowIndex}>
                  {Object.entries(row).map(([_key, value], colIndex) => (
                    <Styled.TD key={colIndex}>{String(value)}</Styled.TD>
                  ))}
                </Styled.LastTR>
              ) : (
                <Styled.TR key={rowIndex}>
                  {Object.entries(row).map(([_key, value], colIndex) => (
                    <Styled.TD key={colIndex}>{String(value)}</Styled.TD>
                  ))}
                </Styled.TR>
              );
            })}
          </Styled.Tbody>
        </Styled.Table>
      </Styled.Wrapper>
      <SelectedItems fileName={fileName} />
    </>
  );
};

export default Table;
