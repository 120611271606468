import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  border-bottom: 1px solid #bbb;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const Img = styled.img`
  width: 20px;
`;

export const TextContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalBtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled.button`
  background: #fff;
  border: none;
  display: flex;
  align-items: baseline;
  gap: 10px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const DetailContainer = styled.div`
  display: flex;
  font-size: 1.7rem;
`;

export const UploadText = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Step = styled.span`
  font-weight: 500;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  margin-right: 30px;
`;

export const MappingTxt = styled.span`
  font-weight: 500;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 18px;
`;

export const LoadingTxt = styled.p`
  font-weight: 500;
`;

export const Loading = styled.div`
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  @keyframes l5 {
    0% {
      box-shadow:
        14px 0 #000,
        -14px 0 #0002;
      background: #000;
    }
    33% {
      box-shadow:
        14px 0 #000,
        -14px 0 #0002;
      background: #0002;
    }
    66% {
      box-shadow:
        14px 0 #0002,
        -14px 0 #000;
      background: #0002;
    }
    100% {
      box-shadow:
        14px 0 #0002,
        -14px 0 #000;
      background: #000;
    }
  }
`;
