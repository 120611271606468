import styled from 'styled-components';

export const Paragraph = styled.p`
  font-size: 13px;
  white-space: pre-wrap;
`;

export const Title = styled.h3`
  color: #469ddd;
`;

export const IconInsightWrapper = styled.div<{
  location: string;
}>`
  position: absolute;
  bottom: ${({ location }) => (location.includes('bottom') ? 'unset' : '36px')};
  top: ${({ location }) => (location.includes('bottom') ? '36px' : 'unset')};
  left: ${({ location }) => (location.includes('left') ? '-23px' : 'unset')};
  right: ${({ location }) => (location.includes('right') ? '-37px' : 'unset')};
  background: white;
  border: 1px solid rgb(236, 236, 236);
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  height: fit-content;
  z-index: 99999;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;

export const Arrow = styled.div<{
  location: string;
}>`
  position: absolute;
  bottom: ${({ location }) =>
    location.includes('bottom') ? 'unset' : '-17px'};
  top: ${({ location }) => (location.includes('bottom') ? '-17px' : 'unset')};
  left: ${({ location }) => (location.includes('left') ? '10%' : 'unset')};
  right: ${({ location }) => (location.includes('right') ? '10%' : 'unset')};
  transform: translateX(-50%);
  font-size: 15px;
  color: rgb(236, 236, 236);
`;
