const config = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  COOKIE_PATH: process.env.REACT_APP_COOKIE_PATH,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  MAPBOX_KEY: process.env.REACT_APP_MAPBOX_KEY,
  API_URL: process.env.REACT_APP_API,
  GIS_API_URL: process.env.REACT_APP_GIS_API,
  ASK_FIDO_URL: process.env.REACT_APP_ASK_FIDO_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
};

export default config;
