import i18n from '@/localization/i18n';
import countries from 'i18n-iso-countries';

const alpha2Codes = async () => {
  countries.registerLocale(await import('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(await import('i18n-iso-countries/langs/it.json'));
  countries.registerLocale(await import('i18n-iso-countries/langs/es.json'));

  return countries.getAlpha2Codes();
};

export const dropDownCountryData = async () => {
  const TwoCodes = await alpha2Codes();

  return Object.entries(TwoCodes).map(([alpha2Code, alpha3Code]) => ({
    label: countries.getName(alpha2Code, i18n.language) ?? '',
    value: alpha3Code,
  }));
};
