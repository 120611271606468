import React, { FC, useRef, useState } from 'react';
import * as Styled from './style';
import { ITableHeader, TableSortOrders } from '@/components/Table/types';
import useOutsideClick from '@/hooks/useOutsideClick';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface FilterColumnProps {
  header: ITableHeader;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  order: TableSortOrders;
  setOrder: React.Dispatch<React.SetStateAction<TableSortOrders>>;
  showSortOption?: boolean;
  isLastColumn?: boolean;
}

const FilterColumn: FC<FilterColumnProps> = ({
  header,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  showSortOption = true,
  isLastColumn = false,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const { prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE');

  useOutsideClick(filterRef, setShowFilter, showFilter);

  return (
    <Styled.ColumnHeader ref={filterRef}>
      {header.title}
      {showSortOption && (
        <>
          <Styled.FilterButton
            data-testid={'filter-button'}
            onClick={() => setShowFilter(!showFilter)}
          >
            <Styled.FilterIcon />
          </Styled.FilterButton>
          {orderBy === header.id && (
            <Styled.CountContainer>
              {order === TableSortOrders.asc ? '↑' : '↓'}
            </Styled.CountContainer>
          )}
          {showFilter && (
            <Styled.FilterContainer
              data-testid="filter-container"
              align={`${isLastColumn && 'right'}`}
            >
              {showSortOption && (
                <>
                  {prefixedT('SORT')}:
                  <Styled.SortOption>
                    <input
                      data-testid="asc"
                      type="radio"
                      checked={
                        orderBy === header.id && order === TableSortOrders.asc
                      }
                      onChange={() => {
                        setOrder(TableSortOrders.asc);
                        setOrderBy(header.id);
                      }}
                      id="asc"
                    />
                    <label htmlFor="asc">{prefixedT('SORT.ASC')}</label>
                  </Styled.SortOption>
                  <Styled.SortOption>
                    <input
                      data-testid="desc"
                      type="radio"
                      checked={
                        orderBy === header.id && order === TableSortOrders.desc
                      }
                      onChange={() => {
                        setOrder(TableSortOrders.desc);
                        setOrderBy(header.id);
                      }}
                      id="desc"
                    />
                    <label htmlFor="desc">{prefixedT('SORT.DESC')}</label>
                  </Styled.SortOption>
                  <Styled.SortOption>
                    <input
                      data-testid="none"
                      type="radio"
                      checked={orderBy !== header.id}
                      onChange={() => {
                        setOrder(TableSortOrders.desc);
                        setOrderBy('');
                      }}
                      id="none"
                    />
                    <label htmlFor="none">{prefixedT('SORT.NONE')}</label>
                  </Styled.SortOption>
                </>
              )}
            </Styled.FilterContainer>
          )}
        </>
      )}
    </Styled.ColumnHeader>
  );
};

export default FilterColumn;
