import moment from 'moment';
import React, { createContext, FC, useState } from 'react';

export type DayKey =
  | 'day1'
  | 'day2'
  | 'day3'
  | 'day4'
  | 'day5'
  | 'day6'
  | 'day7'
  | 'total';

export type DateFilter = {
  dateFrom: Date;
  dateTo: Date;
};

export interface DeliveryPerformanceContextProps {
  partnerActiveValue: { value: number; name: string };
  setPartnerActiveValue: React.Dispatch<
    React.SetStateAction<{ value: number; name: string }>
  >;
  companyActiveValue: { value: number; name: string };
  setCompanyActiveValue: React.Dispatch<
    React.SetStateAction<{ value: number; name: string }>
  >;
  projectActiveValue: { value: number; name: string };
  setProjectActiveValue: React.Dispatch<
    React.SetStateAction<{ value: number; name: string }>
  >;
  engineerActiveValue: { value: number; name: string };
  setEngineerActiveValue: React.Dispatch<
    React.SetStateAction<{ value: number; name: string }>
  >;
  dmaActiveValue: { value: number; name: string };
  setDmaActiveValue: React.Dispatch<
    React.SetStateAction<{ value: number; name: string }>
  >;
  dateFilters: {
    dateFrom: Date;
    dateTo: Date;
  };
  setDateFilters: React.Dispatch<React.SetStateAction<DateFilter>>;
}

export const DeliveryPerformanceContext =
  createContext<DeliveryPerformanceContextProps>({
    partnerActiveValue: { value: 0, name: '' },
    setPartnerActiveValue: () => {},
    companyActiveValue: { value: 0, name: '' },
    setCompanyActiveValue: () => {},
    projectActiveValue: { value: 0, name: '' },
    setProjectActiveValue: () => {},
    engineerActiveValue: { value: 0, name: '' },
    setEngineerActiveValue: () => {},
    dmaActiveValue: { value: 0, name: '' },
    setDmaActiveValue: () => {},
    dateFilters: {
      dateFrom: moment().subtract(7, 'days').toDate(),
      dateTo: new Date(),
    },
    setDateFilters: () => {},
  });

interface DeliveryPerformanceProviderProps {
  children: React.ReactNode;
}

export const DeliveryPerformanceProvider: FC<
  DeliveryPerformanceProviderProps
> = ({ children }) => {
  const [projectActiveValue, setProjectActiveValue] = useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: '',
  });
  const [engineerActiveValue, setEngineerActiveValue] = useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: '',
  });
  const [dmaActiveValue, setDmaActiveValue] = useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: '',
  });
  const [companyActiveValue, setCompanyActiveValue] = useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: '',
  });
  const [partnerActiveValue, setPartnerActiveValue] = useState<{
    value: number;
    name: string;
  }>({
    value: 0,
    name: '',
  });
  const [dateFilters, setDateFilters] = useState<DateFilter>({
    dateFrom: moment().subtract(14, 'days').toDate(),
    dateTo: new Date(),
  });

  return (
    <DeliveryPerformanceContext.Provider
      value={{
        partnerActiveValue,
        setPartnerActiveValue,
        companyActiveValue,
        setCompanyActiveValue,
        projectActiveValue,
        setProjectActiveValue,
        engineerActiveValue,
        setEngineerActiveValue,
        dmaActiveValue,
        setDmaActiveValue,
        dateFilters,
        setDateFilters,
      }}
    >
      {children}
    </DeliveryPerformanceContext.Provider>
  );
};
