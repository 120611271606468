import {
  Company,
  GetListResponse,
  PatchUpdateCompanyBody,
  PostCreateCompanyBody,
} from '@/types/portalCompany.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';
import {
  IPaginationProps,
  IPaginationResponse,
  iSearchProps,
} from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';

export const portalCompaniesApi = createApi({
  reducerPath: 'portalCompaniesQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['List', 'Company', 'postCreateCompany', 'patchUpdateCompany'],
  endpoints: (builder) => ({
    getCompanyList: builder.query<GetListResponse[], void>({
      query: () => '/portal_companies/list',
      providesTags: ['List'],
    }),
    getCompanies: builder.query<
      IPaginationResponse<Company>,
      IPaginationProps & iSearchProps
    >({
      query: (params) =>
        `/portal_companies/companies/?${buildQueryParams(params || {})}`,
      providesTags: ['List'],
    }),
    postCreateCompany: builder.mutation<
      undefined,
      { body: PostCreateCompanyBody }
    >({
      query: ({ body }) => ({
        url: '/portal_companies/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['postCreateCompany'],
    }),
    patchUpdateCompany: builder.mutation<
      undefined,
      { id: number; body: PatchUpdateCompanyBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_companies/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['patchUpdateCompany'],
    }),
  }),
});

export const {
  useGetCompanyListQuery,
  useLazyGetCompanyListQuery,
  useLazyGetCompaniesQuery,
  usePostCreateCompanyMutation,
  usePatchUpdateCompanyMutation,
} = portalCompaniesApi;
