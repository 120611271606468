import { Map } from 'mapbox-gl';
import { onMove } from './onMove';

export const unbindMouseTouchEvents = (
  map: Map,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  isDraggingRef: React.MutableRefObject<boolean>,
) => {
  map.off('mousemove', (e) =>
    onMove(map, draggedFeatureIndexRef, isDraggingRef, e),
  );
  map.off('touchmove', (e) =>
    onMove(map, draggedFeatureIndexRef, isDraggingRef, e),
  );
};
