import { FilterBreadcrumb } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import * as Styled from './style';

const removeQueryParameters = (paramsToRemove: string[], search: string) => {
  const queryParams = new URLSearchParams(search);

  paramsToRemove.forEach((param) => {
    queryParams.delete(param);
  });

  return queryParams.toString();
};

const removeParameters = (paramsToRemove: string[]) => {
  const newQueryString = removeQueryParameters(paramsToRemove, location.search);

  const newUrl = `${location.pathname}?${newQueryString}`;
  window.history.replaceState({}, '', newUrl);
};

const BreadCrumbContainer: FC = () => {
  const { prefixedT } = useCustomTranslation('BREADCRUMB.FILTERS');
  const project = 'project';
  const partner = 'partner';
  const company = 'company';
  const dma = 'dma';
  const engineer = 'engineer';
  const {
    dateFilters,
    projectActiveValue,
    setProjectActiveValue,
    dmaActiveValue,
    setDmaActiveValue,
    engineerActiveValue,
    setEngineerActiveValue,
    companyActiveValue,
    setCompanyActiveValue,
    partnerActiveValue,
    setPartnerActiveValue,
  } = useContext(DeliveryPerformanceContext);

  const removeBreadCrumb = (value: string) => {
    if (value === project) {
      setProjectActiveValue({
        name: '',
        value: 0,
      });
    }
    if (value === partner) {
      setPartnerActiveValue({
        name: '',
        value: 0,
      });
      const paramsToRemove = ['partner', 'partner-name'];
      removeParameters(paramsToRemove);
    }
    if (value === company) {
      setCompanyActiveValue({
        name: '',
        value: 0,
      });
      const paramsToRemove = ['company', 'company-name'];
      removeParameters(paramsToRemove);
    }
    if (value === dma) {
      setDmaActiveValue({
        name: '',
        value: 0,
      });
    }
    if (value === engineer) {
      setEngineerActiveValue({
        name: '',
        value: 0,
      });
    }
  };

  return (
    <Styled.Container>
      <FilterBreadcrumb
        title={prefixedT('DATE_FROM') + ': '}
        value={moment(dateFilters.dateFrom).format('YYYY-MM-DD')}
      />
      <FilterBreadcrumb
        title={prefixedT('DATE_TO') + ': '}
        value={moment(dateFilters.dateTo).format('YYYY-MM-DD')}
      />
      {partnerActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('PARTNER') + ': '}
          icon={true}
          value={partnerActiveValue.name}
          onClick={() => removeBreadCrumb(partner)}
        />
      )}
      {companyActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('COMPANY') + ': '}
          icon={true}
          value={companyActiveValue.name}
          onClick={() => removeBreadCrumb(company)}
        />
      )}
      {projectActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('PROJECT') + ': '}
          icon={true}
          value={projectActiveValue.name}
          onClick={() => removeBreadCrumb(project)}
        />
      )}
      {dmaActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={'DMA' + ': '}
          icon={true}
          value={dmaActiveValue.name.toString()}
          onClick={() => removeBreadCrumb(dma)}
        />
      )}
      {engineerActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('ENGINEER') + ': '}
          icon={true}
          value={engineerActiveValue.name.toString()}
          onClick={() => removeBreadCrumb(engineer)}
        />
      )}
    </Styled.Container>
  );
};

export default BreadCrumbContainer;
