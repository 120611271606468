import React, { FC } from 'react';
import { ProgressValues } from '../ProgressBar';
import * as Styled from './style';

type Props = {
  progressValues: ProgressValues[];
};

const Tunnel: FC<Props> = ({ progressValues }) => {
  const sortedProgressValues = progressValues.slice().sort((a, b) => {
    if (a.value === null && b.value !== null) {
      return 1;
    }
    if (a.value !== null && b.value === null) {
      return -1;
    }
    return (b.value || 0) - (a.value || 0);
  });

  return (
    <Styled.ProgressBarContainer data-testid="tunnel">
      {sortedProgressValues.map((progress, key) => (
        <Styled.Progress
          data-testid="progress"
          key={key}
          color={progress.color}
          completion={
            progress.value && progress.value > 100 ? 100 : progress.value || 0
          }
          style={{ zIndex: key + 1 }}
        />
      ))}
    </Styled.ProgressBarContainer>
  );
};

export default Tunnel;
