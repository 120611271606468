import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.form`
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  margin: 0 auto;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 14px;
  border: 1px solid #D0CFCE;
  border-radius: 10px;
  outline: none;
  &:focus{
    border: 1px solid #008ABF;
    transition: 0.35s ease;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue}
    &::-webkit-input-placeholder{
      transition: opacity 0.45s ease;
      opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 0.45s ease; 
      opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 0.45s ease; 
      opacity: 0;
    }    
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 12px;
  right: -30px;
`;

export const DropdownContainer = styled.div<{
  containerWidth: number | null;
}>`
  border: 1px solid #d0cfce;
  width: ${({ containerWidth }) => containerWidth + 'px'};
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 10px;
  background: white;
  position: absolute;
  z-index: 1;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    background-color: #f3f3f3;
  }
`;
