import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  min-height: auto;
  padding-left: 40px;
  padding-top: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const UploadText = styled.p`
  margin-bottom: 10px;
`;

export const ErrorText = styled.p``;
