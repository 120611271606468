export enum TableSortOrders {
  desc = 'desc',
  asc = 'asc',
}

export enum TableActionTypes {
  delete = 'delete',
  edit = 'edit',
  run = 'run',
}

export enum TableCellAlignment {
  left = 'left',
  right = 'right',
  center = 'center',
  centerLeft = 'centerLeft',
}

export interface ITablePaginate {
  selected: number;
}

export interface ITableHeader {
  sortable: boolean;
  id: string;
  align: TableCellAlignment;
  title: string;
  hidden?: boolean;
}
