import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const errorMsg = styled.p`
  font-weight: 600;
  color: #027cbb;
`;

export const ToolTipContainer = styled.div`
  position: absolute;
  z-index: 5;
  background: #fff;
  right: -200px;
  top: 50px;
  font-size: 14px;
  padding: 10px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const block = styled.div<{
  color: string;
}>`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
`;

export const ToolTipItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
`;
