import { Map } from 'mapbox-gl';
import { clusterLayerID, getSourceData } from '../utils';

export const updateDraggedFeature = (
  map: Map,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  coordinates: number[],
) => {
  if (draggedFeatureIndexRef.current !== -1) {
    const data = getSourceData(map);
    if (data) {
      data[draggedFeatureIndexRef.current].geometry.coordinates = coordinates;

      (map.getSource(clusterLayerID) as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: data,
      });
    }
  }
};
