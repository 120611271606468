import React, { FC } from 'react';
import MultiLevelDropdown, { MenuItem } from './MultiLevelDropdown';
import * as Styled from './style';

interface DropdownProps {
  submenus: MenuItem[];
  dropdown: boolean;
  depthLevel: number;
  onChange?: (items: MenuItem) => void; // Include onChange prop
}

const Dropdown: FC<DropdownProps> = ({
  submenus,
  dropdown,
  depthLevel,
  onChange,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? true : false;

  return (
    <Styled.Dropdown show={dropdown} depth={dropdownClass}>
      {submenus.map((submenu, index) => (
        <MultiLevelDropdown
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          onChange={onChange}
        />
      ))}
    </Styled.Dropdown>
  );
};

export default Dropdown;
