import {
  ChangeLogContainer,
  DashboardContainer,
  ForgotPasswordContainer,
  LoginContainer,
  ProjectStatusContainer,
  RecoveryContainer,
  RecoveryExpiredContainer,
  ReportBuilderContainer,
  ReportsContainer,
  RunningReportContainer,
} from '@/containers';
import ConsumptionProfileContainer from '@/containers/ConsumptionProfile/ConsumptionProfileContainer';
import CorrelationContainer from '@/containers/Correlation/CorrelationContainer';
import DeliveryPerformanceLEContainer from '@/containers/DeliveryPerformanceLE/DeliveryPerformanceLEContainer';
import DeliveryPerformanceSTContainer from '@/containers/DeliveryPerformanceST/DeliveryPerformanceSTContainer';
import { ProfileContainer } from '@/containers/Profile';
import { RunningReportOutlet } from '@/containers/RunningReport';
import SessionContainer from '@/containers/Session/SessionContainer';
import StockManagementContainer from '@/containers/StockManagement/StockManagementContainer';
import TopSoundContainer from '@/containers/TopSound/TopSoundContainer';
import UserManagementContainer from '@/containers/UserManagement/UserManagementContainer';
import WaypointContainer from '@/containers/Waypoint/WaypointContainer';
import JobContainer from '@/containers/JobContainer/JobContainer';
import { AuthGuard } from '@/guards/AuthGuard';
import { PermissionGuard } from '@/guards/PermissionGuard';
import { AppLayout } from '@/layouts';
import { DashboardProvider } from '@/Providers/DashboardProvider';
import { DeliveryPerformanceProvider } from '@/Providers/DeliveryPerformanceProvider';
import type { AppRoute, AppRoutes } from '@/types/router.type';
import { UserRoles } from '@/types/user.type';
import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { FilterProvider } from '../Providers/FilterProvider';
import GISUploadContainer from '@/containers/GISUpload/GISUploadContainer';
import GISAdminContainer from '@/containers/GISAdmin/GISAdminContainer';
import BrowseContainer from '@/containers/Browse/BrowseContainer';

const LOGIN_REDIRECT = '/auth/login';

export const routes: AppRoutes = [
  {
    path: '/',
    element: <Navigate to={LOGIN_REDIRECT} />,
  },
  {
    path: 'auth',
    guards: [{ Guard: AuthGuard, props: { redirect: '/app/dashboard' } }],
    children: [
      {
        index: true,
        element: <Navigate to="login" />,
      },
      {
        path: 'login',
        element: <LoginContainer />,
      },
      {
        path: 'forgot',
        element: <ForgotPasswordContainer />,
      },
      {
        path: 'recovery/expired',
        element: <RecoveryExpiredContainer />,
      },
      {
        path: 'recovery/:token',
        element: <RecoveryContainer />,
      },
      {
        path: 'confirm',
        element: <></>,
      },
    ],
  },
  {
    path: 'app',
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <DashboardProvider>
            <DashboardContainer />
          </DashboardProvider>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: (
              <DashboardProvider>
                <DashboardContainer />
              </DashboardProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <DashboardProvider>
                <DashboardContainer />
              </DashboardProvider>
            ),
          },
        ],
      },
      {
        path: 'user-management',
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'view-users',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'view-companies',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'view-partners',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'view-projects',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'add-user',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'add-company',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'add-partner',
            index: true,
            element: <UserManagementContainer />,
          },
          {
            path: 'add-project',
            index: true,
            element: <UserManagementContainer />,
          },
        ],
      },
      {
        path: 'gis-upload',
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: {
              roles: [UserRoles.SUPER_ADMIN],
            },
          },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <GISUploadContainer />,
          },
        ],
      },
      {
        path: 'gis-admin',
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: {
              roles: [UserRoles.SUPER_ADMIN],
            },
          },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <GISAdminContainer />,
          },
        ],
      },
      {
        path: 'reports',
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <ReportsContainer />,
          },
          {
            path: ':id',
            element: <ReportBuilderContainer />,
          },
          {
            path: ':id/running',
            element: <RunningReportOutlet />,
          },
        ],
      },
      {
        path: 'project-status',
        element: (
          <FilterProvider>
            <ProjectStatusContainer />
          </FilterProvider>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: (
              <FilterProvider>
                <ProjectStatusContainer />
              </FilterProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <FilterProvider>
                <ProjectStatusContainer />
              </FilterProvider>
            ),
          },
        ],
      },
      {
        path: 'delivery-performance-sensor-technician',
        element: (
          <DeliveryPerformanceProvider>
            <DeliveryPerformanceSTContainer />
          </DeliveryPerformanceProvider>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: (
              <DeliveryPerformanceProvider>
                <DeliveryPerformanceSTContainer />
              </DeliveryPerformanceProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <DeliveryPerformanceProvider>
                <DeliveryPerformanceSTContainer />
              </DeliveryPerformanceProvider>
            ),
          },
        ],
      },
      {
        path: 'delivery-performance-le',
        element: (
          <DeliveryPerformanceProvider>
            <DeliveryPerformanceLEContainer />
          </DeliveryPerformanceProvider>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: (
              <DeliveryPerformanceProvider>
                <DeliveryPerformanceLEContainer />
              </DeliveryPerformanceProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <DeliveryPerformanceProvider>
                <DeliveryPerformanceLEContainer />
              </DeliveryPerformanceProvider>
            ),
          },
        ],
      },
      {
        path: 'logs',
        element: <ChangeLogContainer />,
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
      },
      {
        path: 'waypoint',
        element: (
          <FilterProvider>
            <WaypointContainer />
          </FilterProvider>
        ),
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: (
              <FilterProvider>
                <WaypointContainer />
              </FilterProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <FilterProvider>
                <WaypointContainer />
              </FilterProvider>
            ),
          },
        ],
      },
      {
        path: 'job',
        element: <JobContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
      },
      {
        path: 'session',
        element: <SessionContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <SessionContainer />,
          },
          {
            path: ':id',
            element: <SessionContainer />,
          },
        ],
      },
      {
        path: 'top-sound',
        element: <TopSoundContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <TopSoundContainer />,
          },
          {
            path: ':id',
            element: <TopSoundContainer />,
          },
        ],
      },
      {
        path: 'correlation',
        element: <CorrelationContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <CorrelationContainer />,
          },
          {
            path: ':id',
            element: <CorrelationContainer />,
          },
        ],
      },
      {
        path: 'consumption-profile',
        element: <ConsumptionProfileContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            index: true,
            element: <ConsumptionProfileContainer />,
          },
          {
            path: ':id',
            element: <ConsumptionProfileContainer />,
          },
        ],
      },
      {
        path: 'logs',
        element: <ChangeLogContainer />,
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
      },
      {
        path: 'profile',
        element: <ProfileContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
      },
      {
        path: 'sensor-management',
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        element: <StockManagementContainer isSensorManagement={true} />,
      },
      {
        path: 'relay-management',
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        element: <StockManagementContainer isSensorManagement={false} />,
      },
      {
        path: 'browse',
        guards: [
          {
            Guard: PermissionGuard,
            props: {
              roles: [
                UserRoles.SUPER_ADMIN,
                UserRoles.COMPANY_ADMIN,
                UserRoles.PARTNER_ADMIN,
              ],
            },
          },
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
        ],
        children: [
          {
            path: '',
            element: <BrowseContainer dataType="" />,
          },
          {
            path: 'waypoints',
            element: <BrowseContainer dataType="waypoints" />,
          },
          {
            path: 'sessions',
            element: <BrowseContainer dataType="sessions" />,
          },
          {
            path: 'jobs',
            element: <BrowseContainer dataType="jobs" />,
          },
          {
            path: 'waypoint-logs',
            element: <BrowseContainer dataType="waypoint-logs" />,
          },
          {
            path: 'relays',
            element: <BrowseContainer dataType="relays" />,
          },
        ],
      },
    ],
  },
  {
    path: 'reports/:id/running',
    guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
    element: <RunningReportContainer />,
  },
];

export const generateRouterObject = (router: AppRoutes): RouteObject[] =>
  // @ts-expect-error fix at a later date
  router.map(({ children, element, guards = [], ...rest }: AppRoute) => {
    const outlet = element || <Outlet />;
    const component = guards.reduce(
      (acc, { Guard, props }) => <Guard {...props}>{acc}</Guard>,
      outlet,
    );

    return {
      ...rest,
      element: component,
      children: children && generateRouterObject(children),
    };
  });
