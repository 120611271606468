import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 30px;
  position: relative;
  margin: 20px 0px 45px 0px;
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const PipelineTitle = styled.h3`
  color: #469ddd;
`;
