import React from 'react';
import * as Styled from '../style';

import EditIcon from '@/assets/icons/edit-icon.png';
import { Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Partner } from '@/types/portalPartner.type';

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('VIEW_PARTNERS.TITLE');

  return [
    {
      id: 'partner-name',
      title: prefixedT('NAME'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'country-code',
      title: prefixedT('COUNTRY'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'partner-code',
      title: prefixedT('PARTNER_CODE'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'action',
      title: prefixedT('ACTION'),
      sortable: false,
      align: TableCellAlignment.center,
    },
  ];
};

export const getRowStructure = (
  row: Partner,
  idx: number,
  handleEditClick?: (idx: number) => void,
  _handleDeleteClick?: (idx: number) => void,
) => {
  const { prefixedT } = useCustomTranslation('VIEW_COMPANIES');

  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.centerLeft} headerId="partner-name">
        {row.partner_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="country-code">
        {row.country_code}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="partner-code">
        {row.partner_code}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Styled.ImgContainer>
          <Styled.Img
            onClick={() => handleEditClick && handleEditClick(idx)}
            alt={prefixedT('EDIT_ICON')}
            src={EditIcon as string}
          />
          {/* <Styled.Img
            onClick={() => handleDeleteClick && handleDeleteClick(idx)}
            alt={prefixedT('DELETE_ICON')}
            src={DeleteIcon as string}
          /> */}
        </Styled.ImgContainer>
      </Table.Cell>
    </Table.Row>
  );
};
