import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div<{
  isDragging: boolean;
  tooltip: string;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 4px;
  background: transparent;
  transition: 0.3s ease background;
  border-radius: 4px;
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  &:hover {
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.softBlue}40;
  }
  &:after {
    content: ${({ tooltip }) => `'${tooltip}'`};
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    padding: 8px;
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    height: 14px;
    font-size: 10px;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
    display: ${({ isDragging }) => (isDragging ? 'none' : 'flex')};
    align-items: center;
    width: max-content;
    border-radius: 2px;
    visibility: hidden;
    opacity: 0;
    transition:
      0.3s ease opacity,
      0.3s ease visibility;
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent
      ${({ theme }: ThemeProps<Theme>) => theme.colors.black} transparent
      transparent;
    display: ${({ isDragging }) => (isDragging ? 'none' : 'flex')};
    visibility: hidden;
    opacity: 0;
    transition:
      0.3s ease opacity,
      0.3s ease visibility;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% - 5px);
  }

  &:hover:after,
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
`;

export const Name = styled.p`
  display: none;
`;
