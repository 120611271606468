import Table from '@/components/Table';
import {
  ITableHeader,
  TableCellAlignment,
  TableSortOrders,
} from '@/components/Table/types';
import useModalStatus from '@/hooks/useModalStatus';
import { WidgetLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDataSourcesQuery } from '@/redux/reports/reports.api';
import { ReportResultsSettings, ReportWidget } from '@/types/report.type';
import { ContainerVariant } from '@/types/widget.type';
import React, { FC, useMemo } from 'react';
import useDragMove from '../useDragMove';
import * as Styled from './style';
import TableOfResultsSettings from './TableOfResultsSettings';

type Props = {
  data: ReportWidget<ReportResultsSettings>;
  id: string | number;
  readonly: boolean;
  tableResultsCount: number | undefined;
  pageNumber: number | undefined;
  order: TableSortOrders | undefined;
  orderBy: string | undefined;
  onRemoveWidget: (
    widget: ReportWidget<ReportResultsSettings>,
    layoutId: string | number,
  ) => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportResultsSettings>,
    layoutId: string | number,
  ) => void;
  handleUpdateRunningQuery?: (query: {
    page: number;
    perPage: number;
    orderBy: string | undefined;
    order: string | undefined;
    type: string;
  }) => void;
};

type Data = {
  id: string;
};

const TableOfResults: FC<Props> = ({
  data,
  id,
  readonly,
  tableResultsCount,
  pageNumber,
  order,
  orderBy,
  onRemoveWidget,
  onUpdateWidget,
  handleUpdateRunningQuery,
}) => {
  const { data: sourceData } = data;
  const { ref } = useDragMove(data, id, Boolean(readonly));
  const modal = useModalStatus();
  const { data: sources } = useGetDataSourcesQuery();
  const { prefixedT } = useCustomTranslation('WIDGET_LAYOUT');

  const getHeaderStructure = (header: ITableHeader) => header.title;

  const onOpenSettings = () => {
    modal.show();
  };

  const headers: ITableHeader[] = (data.settings?.columns || []).map((item) => {
    const columns =
      sources?.find((el) => el.id === data.settings?.datasource)?.ds_columns ||
      [];
    const column = columns.find((el) => el.column?.id === item);

    return {
      sortable: true,
      align: TableCellAlignment.left,
      id: (column?.column?.id && column?.column?.id.toString()) || '',
      title: column?.column?.name || '',
    };
  });

  const items = useMemo(
    () => [
      {
        title: prefixedT('SETTINGS'),
        handler: onOpenSettings,
        icon: Styled.GearIcon,
      },
    ],
    [],
  );

  const getRowStructure = (row: any, index: number) => (
    <Table.Row key={`${row?.id}-${index}`}>
      {headers.map((header, headerIndex) => (
        <Table.Cell
          key={headerIndex}
          align={TableCellAlignment.left}
          headerId={header.id}
        >
          {row[header.id] ? row[header.id].toString() : row[header.id]}
        </Table.Cell>
      ))}
    </Table.Row>
  );

  return (
    <WidgetLayout
      dropRef={ref}
      name={prefixedT('TABLE_OF_RESULTS')}
      readonly={readonly}
      items={items}
      onDelete={() => onRemoveWidget(data, id)}
      variant={ContainerVariant.overflow}
    >
      <TableOfResultsSettings
        open={modal.isOpen}
        sources={sources}
        onClose={modal.hide}
        onUpdateWidget={onUpdateWidget}
        widget={data}
        layoutId={id}
      />
      <Styled.Container>
        <Table
          data={(sourceData || []) as Data[]}
          order={order ?? TableSortOrders.asc}
          orderBy={(orderBy ?? 'id') as keyof Data}
          headers={headers}
          onSort={(orderBy, order) => {
            handleUpdateRunningQuery?.({
              page: pageNumber as number,
              perPage: 10,
              orderBy,
              order,
              type: '0',
            });
          }}
        >
          <Table.Head getHeaderStructure={getHeaderStructure} />
          {readonly && <Table.Body getRowStructure={getRowStructure} striped />}
        </Table>
      </Styled.Container>
      {tableResultsCount && (
        <Table.Pagination
          pages={Math.ceil(tableResultsCount / 10)}
          onChange={(newPage) => {
            if (pageNumber !== newPage.selected + 1) {
              handleUpdateRunningQuery?.({
                page: newPage.selected + 1,
                perPage: 10,
                orderBy,
                order,
                type: '0',
              });
            }
          }}
          initialPage={pageNumber}
        />
      )}
    </WidgetLayout>
  );
};

export default React.memo(TableOfResults);
