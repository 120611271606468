import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog/Dialog';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import { Modal } from '@/components/Modal';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import {
  ReportDataSource,
  ReportFilterSettings,
  ReportWidget,
} from '@/types/report.type';
import { Field, Form, useFormikContext } from 'formik';
import React, { FC, useRef, useState } from 'react';
import { v4 as guid } from 'uuid';
import FilterItem from './FilterItem';
import { getSubTitle, getTitle, InitialFilterValues } from './service';
import * as Styled from './style';

type Props = {
  open: boolean;
  sources: ReportDataSource[] | undefined;
  onClose: () => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportFilterSettings>,
    layoutId: string | number,
  ) => void;
  widget: ReportWidget<ReportFilterSettings>;
  layoutId: string | number;
};

const emptyFilter = {
  columnId: null,
  typeId: null,
  value: null,
};

const style = {
  color: {
    confirm: theme.colors.coral,
    cancel: theme.colors.blue,
  },
};

const FilterSettings: FC<Props> = ({ open, sources = [], onClose }) => {
  const [dialogState, setDialogState] = useState<null | string>(null);
  const tempSource = useRef('');
  const contentRef = useRef(null);
  const { t } = useCustomTranslation('');

  const { setFieldValue, values, dirty, resetForm, errors, submitForm } =
    useFormikContext<InitialFilterValues>();
  const onAddFilter = () => {
    setFieldValue('filters', [
      ...values.filters,
      { ...emptyFilter, id: guid() },
    ]);
  };
  const applySourceChange = (option: string) => {
    setFieldValue('source', option);
    if (dialogState) {
      setDialogState(null);
      setFieldValue('filters', []);
    }
  };
  const onChangeSource = (option: DropDownOption) => {
    if (!values.filters.length) {
      applySourceChange(option.value);
    } else {
      tempSource.current = option.value;
      setDialogState('change');
    }
  };
  const onModalClose = () => {
    if (dirty) {
      setDialogState('close');
    } else {
      onClose();
    }
  };
  const onConfirm = () => {
    if (dialogState === 'change') {
      applySourceChange(tempSource.current);
    } else {
      setDialogState(null);
      resetForm();
      onClose();
    }
  };
  const isOverflow = values.filters.length > 1;

  return (
    <Form>
      <Modal
        styles={{ maxWidth: '600px' }}
        title={t('FILTERS.MODAL_TITLE')}
        isOpen={open}
        onClose={onModalClose}
      >
        <Styled.Container>
          <Dialog
            open={!!dialogState}
            title={getTitle(String(dialogState))}
            subtitle={getSubTitle(String(dialogState))}
            onConfirm={onConfirm}
            onCancel={() => setDialogState(null)}
            style={style}
          />
          <Styled.DataSourceContainer>
            <Field
              as={DropDown}
              options={sources.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              label={t('FILTERS.FORM.SOURCE.LABEL')}
              placeholder={t('FILTERS.FORM.SOURCE.PLACEHOLDER')}
              name="source"
              onChange={onChangeSource}
              error={errors.source && t(errors.source)}
            />
            <Button
              onClick={onAddFilter}
              width="48px"
              height="44px"
              disabled={!values.source}
            >
              +
            </Button>
          </Styled.DataSourceContainer>
          <Styled.FiltersContainer ref={contentRef} isOverflow={isOverflow}>
            {values.filters.map((filter, index) => (
              <FilterItem
                key={`${filter.id}_${index}`}
                index={index}
                filter={filter}
                source={sources.find((el) => el.id === values.source)}
                blockRef={contentRef}
              />
            ))}
          </Styled.FiltersContainer>
          <Styled.ButtonContainer>
            <Button
              disabled={!dirty}
              width="120px"
              height="34px"
              type="submit"
              onClick={submitForm}
            >
              {t('REPORT_BUILDER.APPLY')}
            </Button>
          </Styled.ButtonContainer>
        </Styled.Container>
      </Modal>
    </Form>
  );
};

export default FilterSettings;
