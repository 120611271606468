import { Button, DatePicker, Loader, ToggleSwitch } from '@/components';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Plot from 'react-plotly.js';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetRelaySessionDataMutation } from '@/redux/reports/reports.api';
import { SessionInformationType } from '@/types/report.type';

interface LeakMapperProps {
  sessionData?: SessionInformationType;
  sessionID: string;
}

const LeakMapper: FC<LeakMapperProps> = ({ sessionData, sessionID }) => {
  const [graphType, setGraphType] = useState<'old' | 'new'>('new');

  const [mutateRelayData, { data: relaySessionData, isError, isLoading }] =
    useGetRelaySessionDataMutation();

  const { t, prefixedT } = useCustomTranslation(
    'SESSION_PAGE.ENGINEERING_DETAILS',
  );

  useEffect(() => {
    void (async () => {
      const relayExists = sessionData?.map_info.data.find(
        (item) => item.tooltip.Relay_session === 'True',
      );
      if (relayExists) {
        await mutateRelayData({
          id: sessionID,
          query: { date_from: '', date_to: '' },
        });
      }
    })();
  }, [sessionData]);

  const dataLength = relaySessionData?.date_range?.length || 0;

  const minDate = useMemo(() => {
    return moment(relaySessionData?.date_range?.[0], 'YYYY-MM-DD').toDate();
  }, [relaySessionData]);

  const maxDate = useMemo(() => {
    return moment(
      relaySessionData?.date_range?.[dataLength - 1],
      'YYYY-MM-DD',
    ).toDate();
  }, [relaySessionData]);

  const fromDate = useMemo(() => {
    return moment(relaySessionData?.audio_data?.date[0], 'YYYY-MM-DD').toDate();
  }, [relaySessionData]);

  const toDate = useMemo(() => {
    return moment(
      relaySessionData?.audio_data?.date[
        relaySessionData?.audio_data.date.length - 1
      ],
      'YYYY-MM-DD',
    ).toDate();
  }, [relaySessionData]);

  // for plotting seperate surfaces for each date
  const plotData: Plotly.Data[] = useMemo(() => {
    return new Array(dataLength).fill({}).map((_, idx) => {
      const startIdx = idx * 5;
      const endIdx = (idx + 1) * 5;

      if (relaySessionData?.audio_data !== null) {
        return {
          x: relaySessionData?.audio_data.frequency.slice(startIdx, endIdx),
          y: relaySessionData?.audio_data.date
            .map((date) => new Date(date))
            .slice(startIdx, endIdx),
          z: relaySessionData?.audio_data.power.slice(startIdx, endIdx),
          type: 'surface',
          colorscale: [
            [0, 'rgb(166,206,227)'],
            [0.25, 'rgb(31,120,180)'],
            [0.45, 'rgb(178,223,138)'],
            [0.65, 'rgb(51,160,44)'],
            [0.85, 'rgb(251,154,153)'],
            [1, 'rgb(227,26,28)'],
          ],
          showscale: false,
        };
      } else {
        return {
          x: undefined,
          y: undefined,
          z: undefined,
          type: 'surface',
          colorscale: [
            [0, 'rgb(166,206,227)'],
            [0.25, 'rgb(31,120,180)'],
            [0.45, 'rgb(178,223,138)'],
            [0.65, 'rgb(51,160,44)'],
            [0.85, 'rgb(251,154,153)'],
            [1, 'rgb(227,26,28)'],
          ],
          showscale: false,
        };
      }
    });
  }, [relaySessionData, dataLength]);

  return isError ? (
    <Styled.GraphWrapper>
      <h2>{prefixedT('RELAY_ERROR')}</h2>
    </Styled.GraphWrapper>
  ) : isLoading ? (
    <Loader />
  ) : relaySessionData?.audio_data === null ? (
    <Styled.GraphWrapper>
      <h2>{prefixedT('RELAY_NO_DATA')}</h2>
    </Styled.GraphWrapper>
  ) : relaySessionData !== undefined ? (
    <Styled.GraphWrapper data-testid={'relay-plot'}>
      <Styled.ControlsContainer>
        <Styled.ToggleContainer>
          <p>{prefixedT('3D_PLOT_TOGGLE_TITLE')}</p>
          <ToggleSwitch
            isChecked={graphType === 'new'}
            idPrefix={'graphType'}
            onToggle={() => setGraphType(graphType === 'old' ? 'new' : 'old')}
          />
        </Styled.ToggleContainer>

        <Formik
          enableReinitialize
          initialValues={{ mindate: fromDate, maxdate: toDate }}
          onSubmit={(values: { mindate: Date; maxdate: Date }) => {
            const formattedMinDate = moment(values.mindate).format(
              'YYYY-MM-DD',
            );
            const formattedMaxDate = moment(values.maxdate).format(
              'YYYY-MM-DD',
            );

            void mutateRelayData({
              id: sessionID,
              query: { date_from: formattedMinDate, date_to: formattedMaxDate },
            });
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Styled.Row>
                <Field
                  as={DatePicker}
                  name="mindate"
                  label={t('COMPONENTS.DATE_VIEWER.START_DATE')}
                  onChange={(date: Date) => setFieldValue('mindate', date)}
                  maxDate={maxDate}
                  disableFuture
                  minDate={minDate}
                  disablePast
                  width="100%"
                />
                <Field
                  as={DatePicker}
                  name="maxdate"
                  label={t('COMPONENTS.DATE_VIEWER.END_DATE')}
                  onChange={(date: Date) => setFieldValue('maxdate', date)}
                  maxDate={maxDate}
                  disableFuture
                  minDate={minDate}
                  disablePast
                  width="100%"
                />
              </Styled.Row>
              <Styled.Row>
                <Button type="submit" width="200px" height="50px">
                  {prefixedT('APPLY_DATES')}
                </Button>
              </Styled.Row>
            </Form>
          )}
        </Formik>
      </Styled.ControlsContainer>
      <Styled.RelayGraphContainer>
        <Plot
          data={
            graphType === 'old'
              ? [
                  {
                    x: relaySessionData.audio_data.frequency,
                    y: relaySessionData.audio_data.date,
                    z: relaySessionData.audio_data.power,
                    type: 'surface',
                    colorscale: [
                      [0, 'rgb(166,206,227)'],
                      [0.25, 'rgb(31,120,180)'],
                      [0.45, 'rgb(178,223,138)'],
                      [0.65, 'rgb(51,160,44)'],
                      [0.85, 'rgb(251,154,153)'],
                      [1, 'rgb(227,26,28)'],
                    ],
                    showscale: false,
                  },
                ]
              : plotData
          }
          layout={{
            autosize: true,
            height: 900,
            title: 'Leakmapper',
            scene: {
              xaxis: { title: t('SESSION_PAGE.RELAY_GRAPH.X') },
              yaxis: {
                title: t('SESSION_PAGE.RELAY_GRAPH.Y'),
                tickwidth: 2,
              },
              zaxis: { title: t('SESSION_PAGE.RELAY_GRAPH.Z') },
            },
          }}
        />
      </Styled.RelayGraphContainer>
    </Styled.GraphWrapper>
  ) : null;
};

export default LeakMapper;
