import styled from 'styled-components';

export const TD = styled.td`
  font-size: 12px;
  box-shadow: 0 2px 2px -2px #0e1119;
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
  color: #f8f8f8;
`;

export const TR = styled.tr`
  font-weight: 600;
`;
