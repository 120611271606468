import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  background-color: #fff;
`;

export const SelectionWrapper = styled.label`
  display: inline-flex;
  margin-bottom: 20px;
`;
