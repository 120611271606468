import { useEditUserMutation } from '@/redux/user/user.api';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { EditUserBody, EditUserValues } from '@/types/user.type';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { useState } from 'react';
import Profile from './Profile';

const ProfileContainer = () => {
  const { data: user } = useGetProfileQuery();
  const [onEditUser, { status }] = useEditUserMutation();
  const [isEditable, setEditable] = useState(false);

  const initialValue: EditUserValues = {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    metric_system: user?.metric_system || '',
    companyId: user?.company_id || '',
    roleId: user?.role_id || '',
    id: user?.id || '',
  };

  const handleEdit = async (values: EditUserValues) => {
    if (status === QueryStatus.pending) {
      return;
    }

    const data: EditUserBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      metric_system: values.metric_system,
      email: values.email,
    };
    if (user?.id) {
      await onEditUser({ id: user?.id, body: data }).unwrap();
    }
    setEditable(false);
  };

  return (
    <Profile
      profile={user}
      initialValues={initialValue}
      onEditActive={() => setEditable(true)}
      onDiscard={() => setEditable(false)}
      isEditable={isEditable}
      onEditUser={handleEdit}
    />
  );
};

export default ProfileContainer;
