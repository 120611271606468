import styled from 'styled-components';
import { Link as reactLink } from 'react-router-dom';

export const Link = styled(reactLink)`
  text-decoration: none;
  color: #fff;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const SearchContainer = styled.div`
  margin-bottom: 20px;
  width: 50%;
  margin-right: 80px;
`;

export const SearchInput = styled.input``;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-size: 20px;
`;

export const Container = styled.div`
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
`;

export const ImgContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Img = styled.img`
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Email = styled.span`
  color: #8f9bb3;
`;

export const Name = styled.span`
  width: 100%;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-top: 10px;
`;

export const PagniationContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DropDownContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-right: 10px;
`;

export const BtnContainer = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const PlusImg = styled.img`
  margin-left: 10px;
  height: 14px;
  width: 14px;
`;
