import React, { FC } from 'react';

import * as Styled from './style';

interface CardsRowProps {
  cards: {
    title: string;
    value: number;
  }[];
}

const CardsRow: FC<CardsRowProps> = ({ cards }) => {
  return (
    <Styled.Wrapper data-testid="card-row">
      {cards.map(({ title, value }, idx) => (
        <Styled.Card data-testid="card-element" key={idx}>
          <Styled.CardValue>{value}</Styled.CardValue>
          <Styled.CardText>{title}</Styled.CardText>
        </Styled.Card>
      ))}
    </Styled.Wrapper>
  );
};

export default CardsRow;
