import { TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { EditUserValues } from '@/types/user.type';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import * as Styled from './style';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';

export interface AccountSettingsProps {
  isEditable: boolean;
}

const AccountSettings: FC<AccountSettingsProps> = ({ isEditable }) => {
  const { t, prefixedT } = useCustomTranslation('PROFILE_PAGE');
  const { errors, setFieldValue, values } = useFormikContext<EditUserValues>();

  const measurementType: DropDownOption[] = [
    {
      label: t('MEASUREMENT_TYPE.METRIC'),
      value: 'Metric',
    },
    {
      label: t('MEASUREMENT_TYPE.IMPERIAL'),
      value: 'Imperial',
    },
  ];

  return (
    <Styled.Card>
      <Styled.CardWrapper>
        <Field
          disabled={!isEditable}
          as={TextField}
          name="firstName"
          error={errors.firstName && t(errors.firstName)}
          label={prefixedT('FORM.FIRST_NAME.LABEL')}
          placeholder={prefixedT('FORM.FIRST_NAME.PLACEHOLDER')}
          testId="firstName-field"
        />
        <Field
          disabled={!isEditable}
          as={TextField}
          name="lastName"
          error={errors.lastName && t(errors.lastName)}
          label={prefixedT('FORM.LAST_NAME.LABEL')}
          placeholder={prefixedT('FORM.LAST_NAME.PLACEHOLDER')}
          testId="lastName-field"
        />
        <Field
          disabled
          as={TextField}
          name="email"
          error={errors.email && t(errors.email)}
          label={prefixedT('FORM.EMAIL.LABEL')}
          placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
          testId="email-field"
        />
        <Field
          disabled={!isEditable}
          as={DropDown}
          required={true}
          styles={{ border: true }}
          options={measurementType as DropDownOption | DropDownOption[]}
          label={prefixedT('FORM.MEASUREMENT_TYPE.LABEL')}
          placeholder={prefixedT('FORM.MEASUREMENT_TYPE.PLACEHOLDER')}
          onChange={(option: DropDownOption) =>
            setFieldValue('metric_system', option.value)
          }
          testId="metric_system"
          countOptions={4}
          error={errors.metric_system?.toString()}
          value={values.metric_system?.toString()}
        />
      </Styled.CardWrapper>
    </Styled.Card>
  );
};

export default AccountSettings;
