import styled from 'styled-components';

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const CheckboxContainer = styled.div<{
  mapContainerWidth: number;
}>`
  display: flex;
  padding: ${({ mapContainerWidth }) =>
    mapContainerWidth < 600 ? '0px' : '20px'};
  width: ${({ mapContainerWidth }) => `${mapContainerWidth * 0.7}px`};
  max-width: ${({ mapContainerWidth }) => `${mapContainerWidth * 0.9}px`};
  flex-wrap: wrap;
`;
