import { ReactComponent as LogoIcon } from '@/assets/icons/image-logo.svg';
import styled from 'styled-components';

export const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgb(25, 42, 62);
`;

export const Required = styled.span`
  color: #e11d48;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed #dee2e6;
  background: #f8f9fa;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ImageContainer = styled.div``;

export const TextContainer = styled.div`
  text-align: center;
  flex: auto;
  color: #9499a1;
  line-height: 30px;
`;

export const ImageLogo = styled(LogoIcon)``;

export const UploadedImage = styled.img`
  width: 100%;
  max-height: 144px;
`;

export const UploadedImageContainer = styled.div`
  width: 100%;
`;

export const DefaultImageWrapper = styled.div`
  padding: 10%;
  border-radius: 8px;
  background: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
`;

export const SmallText = styled.p`
  color: #9499a1;
  font-size: 12px;
`;

export const MediumText = styled.p`
  color: #9499a1;
  font-size: 14px;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: #5151f9;
  cursor: pointer;
`;

export const ErrorText = styled.p`
  color: #ff0000;
`;
