import { BranchsByCompanyResponse } from '@/types/branches.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const branchApi = createApi({
  reducerPath: 'branchQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Branch'],
  endpoints: (builder) => ({
    getBranchesByCompany: builder.query<
      BranchsByCompanyResponse[],
      number | string
    >({
      query: (companyId: number | string) => `/portal_branches/${companyId}`,
      providesTags: ['Branch'],
    }),
  }),
});

export const { useLazyGetBranchesByCompanyQuery } = branchApi;
