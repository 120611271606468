import React, { FC, useRef, useState } from 'react';
import * as Styled from './style';
import useOutsideClick from '@/hooks/useOutsideClick';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { PostRelaysResponse } from '@/types/stock.type';
import { arrayIncludes } from '@/utils/array';

interface SelectColumnProps {
  selectedStock: number[];
  setSelectedStock: React.Dispatch<React.SetStateAction<number[]>>;
  currentStock?: PostRelaysResponse[];
}

const SelectColumn: FC<SelectColumnProps> = ({
  selectedStock,
  setSelectedStock,
  currentStock,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const { prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE');

  useOutsideClick(filterRef, setShowFilter, showFilter);

  const currentStockIds = currentStock?.map((item) => item.id) || [];

  const checked =
    currentStockIds.length > 0 && arrayIncludes(currentStockIds, selectedStock);

  return (
    <Styled.ColumnHeader ref={filterRef}>
      <label htmlFor="select-all">{prefixedT('SELECT_ALL')}</label>
      <input
        data-testid="select-all"
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (checked) {
            setSelectedStock(
              selectedStock.filter((id) => {
                return currentStockIds.indexOf(id) === -1;
              }),
            );
          } else {
            setSelectedStock([
              ...Array.from(new Set([...selectedStock, ...currentStockIds])),
            ]);
          }
        }}
        id="select-all"
      />
    </Styled.ColumnHeader>
  );
};

export default SelectColumn;
