import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  padding: 20px;
  background: white;
`;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1400px;
  margin: 0 auto;
`;

export const SpectralTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 20px;
`;

export const CorrelationImg = styled.img<{
  itemNumber?: number;
  itemNumberLength?: number;
}>`
  width: 380px;
  height: 285px;
  object-fit: cover;
  object-position: ${({ itemNumber, itemNumberLength }) =>
    itemNumber != null && itemNumberLength != null
      ? `0% ${(itemNumber / itemNumberLength) * 100}%`
      : '0% 0%'};
`;

export const PSDImg = styled.img`
  width: 350px;
`;

export const AudioAndImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
`;

export const SessionTitle = styled.div`
  margin-bottom: 20px;
`;

export const CorrelationImgContainer = styled.div`
  height: 400px;
`;

export const SessionText = styled.h3``;

export const Timeline = styled.p<{
  noImg: boolean;
}>`
  align-self: flex-end;
  font-size: 12px;
  margin-bottom: ${({ noImg }) => (noImg ? '10px' : '0')};
`;

export const CorrelationContainer = styled.div`
  margin-top: 25px;
`;

export const Session = styled.span`
  font-weight: bold;
`;
