import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';
import { MapFilters } from '@/Providers/FilterProvider';
import { PreviewValuesResponse } from '@/types/gisUpload.type';

export const formatMapData = (
  data: PreviewValuesResponse | undefined,
  mapFilters: MapFilters,
) => {
  const mapData: MapElement[] = [];

  if (data) {
    const filterMap = {
      DMAS: 'dmas',
      PIPES: 'pipes',
      ASSETS: 'assets',
    } as const;

    for (const filter of mapFilters.filters) {
      const key = filterMap[
        filter as keyof typeof filterMap
      ] as keyof PreviewValuesResponse;

      if (key && data[key]?.data) {
        mapData.push(...data[key].data);
      }
    }
  }

  return mapData;
};
