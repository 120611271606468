import filterIcon from '@/assets/images/filter.png';
import Button from '@/components/Button/Button';
import useOutsideClick from '@/hooks/useOutsideClick';
import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC, useRef } from 'react';
import MapViewContext from '../../context/MapViewContext';
import * as Styled from '../../style';
import FilterList from '../FilterList/FilterList';
import { MapFilters } from '@/Providers/FilterProvider';

type FilterComponentProps = {
  showFilters: boolean;
  mapContainerWidth: number;
  onSubmit: (values: MapFilters) => void;
  toggleFilters: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterMap: FC<FilterComponentProps> = ({
  setShowFilters,
  showFilters,
  mapContainerWidth,
  onSubmit,
  toggleFilters,
}) => {
  const { prefixedT } = useCustomTranslation('MAP_VIEW.FILTERS');
  const buttonRef = useRef(null);
  const filterListRef = useRef(null);

  useOutsideClick(filterListRef, () => setShowFilters(false), true, buttonRef);

  return (
    <>
      <Styled.FilterBtn ref={buttonRef}>
        <Button onClick={toggleFilters} icon={filterIcon as string}>
          {prefixedT('BUTTONTITLE')}
        </Button>
      </Styled.FilterBtn>
      <div ref={filterListRef}>
        <MapViewContext.Provider value={{ mapContainerWidth, onSubmit }}>
          <FilterList showFilters={showFilters} />
        </MapViewContext.Provider>
      </div>
    </>
  );
};

export default FilterMap;
