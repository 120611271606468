import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background: white;
  padding: 40px;
`;

export const Sidebar = styled.div``;

export const CompanyDropDownContainer = styled.div`
  margin-bottom: 20px;
  width: fit-content;
`;

export const FormInput = styled.div``;

export const NavWrapper = styled.div``;

export const GISWrapper = styled.div`
  flex: 1;
  padding-left: 10%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
`;

export const WrapperTitle = styled.div`
  margin-bottom: 30px;
`;

export const ErrorLabel = styled.div``;
