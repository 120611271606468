import React, { FC } from 'react';
import { useLazyGetCompaniesQuery } from '@/redux/portalCompanies/portalCompanies.api';
import SearchableTable from '@/components/SearchableTable/SearchableTable';
import * as Styled from './style';
import { getRowStructure, headers } from './utils/utils';
import { Button } from '@/components';
import PlusIcon from '@/assets/icons/plus-icon.png';
import useCustomTranslation from '@/localization/useCustomTranslation';
import EditCompany from './EditCompany/EditCompany';

const ViewCompanies: FC = () => {
  const [trigger, { data: companyListData, isFetching, isError }] =
    useLazyGetCompaniesQuery();

  const { prefixedT } = useCustomTranslation('VIEW_COMPANIES');

  return (
    <SearchableTable
      queryResponse={companyListData}
      isFetching={isFetching}
      isError={isError}
      ExtraHeaderComponent={
        <Styled.BtnContainer data-testid="add-companies-btn">
          <Styled.Link to={'/app/user-management/add-company'}>
            <Button>
              {prefixedT('BUTTON.ADD')}{' '}
              <Styled.PlusImg src={PlusIcon as string} alt="plus-icon" />
            </Button>
          </Styled.Link>
        </Styled.BtnContainer>
      }
      trigger={trigger}
      editModal={(idx, closeOverlay) => {
        const currentCompany = companyListData?.data[idx];
        if (currentCompany !== undefined) {
          return (
            <EditCompany
              key={idx}
              company_id={currentCompany.id}
              company_name={currentCompany.name}
              metric_system={currentCompany.unit}
              currency={currentCompany.currency}
              country_code={''}
              company_code={currentCompany.code}
              partner_id={currentCompany.partner_id}
              logo={''}
              closeOverlay={closeOverlay}
            />
          );
        }
      }}
      // deleteModal={() => <h1>TODO FS-926</h1>}
      getRowStructure={getRowStructure}
      headers={headers}
      translationPrefix="VIEW_COMPANIES"
    />
  );
};

export default ViewCompanies;
