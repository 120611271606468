import { useEffect } from 'react';
import { debounce } from '../utils/debounce';

const useDebounce = <A = unknown, R = void>(
  fn: (args: A) => R,
  delay: number,
): ((args: A) => Promise<R>) => {
  const [debouncedFun, teardown] = debounce<A, R>(fn, delay);

  useEffect(() => () => teardown(), [teardown]);

  return debouncedFun;
};

export default useDebounce;
