/**
 * Given an array of image URLs, this function will convert them to base64
 * and return an array of objects with the original URL and the base64 string.
 */
export const urlToBase64 = async (imageUrls: string[]) => {
  return Promise.all(
    imageUrls.map(async (imageURL) => {
      const fetched = await fetch(imageURL);
      const blob = await fetched.blob();
      const result = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      return { url: imageURL, base64: result as string };
    }),
  );
};
