import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC, useEffect, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import * as Styled from './style';

interface FileWithPreview extends File {
  preview: string;
}

interface ImageUploadBoxProps {
  onImageUpload: (imageString: string[]) => void;
  label?: string;
  required?: boolean;
  maxFiles?: number;
  showPreview?: boolean;
}

const ImageUploadBox: FC<ImageUploadBoxProps> = ({
  onImageUpload,
  label,
  required,
  maxFiles = 1,
  showPreview = true,
}) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.IMAGE_UPLOAD_BOX');
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [error, setError] = useState<string | null>(null);

  const triggerOnImageUpload = async (files: File[]) => {
    const fileResults = files.map((file) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(file);
      });
    });

    const dataUrls = await Promise.all(fileResults);
    onImageUpload(dataUrls);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    noClick: true,
    noKeyboard: true,
    maxFiles,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const rejectionError = fileRejections[0].errors[0].message;
        setError(`File rejected: ${rejectionError}`);
      } else {
        setError(null);
        if (acceptedFiles.length > 0) {
          void triggerOnImageUpload(acceptedFiles);

          setFiles([
            ...files,
            ...acceptedFiles.map((newFile) => {
              return {
                ...newFile,
                preview: URL.createObjectURL(newFile),
              };
            }),
          ]);
        } else {
          setFiles([]);
          onImageUpload(['']);
        }
      }
    },
  });

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [files],
  );

  return (
    <>
      {label && (
        <Styled.Label>
          {label + ' '}
          {required && <Styled.Required>*</Styled.Required>}
        </Styled.Label>
      )}
      <Styled.Container>
        {showPreview && (
          <Styled.ImageContainer>
            <div>
              {files.length === 0 ? (
                <Styled.DefaultImageWrapper>
                  <Styled.ImageLogo />
                </Styled.DefaultImageWrapper>
              ) : (
                files.map((file, idx) => (
                  <Styled.UploadedImageContainer
                    key={idx}
                    data-testid="uploaded-image-container"
                  >
                    <Styled.UploadedImage
                      src={file.preview}
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                      alt={file.name}
                    />
                  </Styled.UploadedImageContainer>
                ))
              )}
            </div>
          </Styled.ImageContainer>
        )}
        <Styled.TextContainer {...getRootProps()}>
          <input {...getInputProps()} data-testid="dropzone-input" />
          <Styled.MediumText>{prefixedT('DRAG_TEXT')}</Styled.MediumText>
          <Styled.SmallText>{prefixedT('OR')}</Styled.SmallText>
          <Styled.Button type="button" onClick={open}>
            {prefixedT('BUTTON_TEXT')}
          </Styled.Button>
          <Styled.SmallText>{prefixedT('IMAGE_TYPE')}</Styled.SmallText>
          {error && <Styled.ErrorText>{error}</Styled.ErrorText>}
        </Styled.TextContainer>
      </Styled.Container>
    </>
  );
};

export default ImageUploadBox;
