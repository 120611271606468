import { DeliveryPerformanceBugDeploymentEngineerType } from '@/types/report.type';

export const generateHistoryTableData = (
  bugDeploymentData: DeliveryPerformanceBugDeploymentEngineerType | undefined,
): { [key: string]: string | number | null }[] => {
  return (bugDeploymentData ?? [])
    .map((item) => {
      const roundedHours =
        typeof item.info.total?.hours_worked === 'number'
          ? Math.round(item.info.total.hours_worked)
          : null;
      const distanceCovered =
        item.info.total &&
        item.info.total.KM_covered &&
        item.info.total.KM_covered.value != null &&
        item.info.total.KM_covered.type
          ? item.info.total.KM_covered.value + item.info.total.KM_covered.type
          : undefined;
      const data = {
        option1: item.name ?? null,
        option2: distanceCovered ?? null,
        option3: item.info.total?.collections ?? null,
        option4: item.info.total?.deployments ?? null,
        option5: roundedHours,
      };

      if (
        data.option2 === null &&
        data.option3 === null &&
        data.option4 === null &&
        data.option5 === null
      ) {
        return null;
      } else {
        return data;
      }
    })
    .filter(
      (
        item,
      ): item is {
        option1: string;
        option2: string | null;
        option3: number | null;
        option4: number | null;
        option5: number | null;
      } => !!item,
    );
};
