import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';
import { WaypointFilterProvider } from '@/Providers/WaypointFilterProvider';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  FilterContext,
  defaultFilterMapValues,
} from '@/Providers/FilterProvider';
import { RootState } from '@/redux';
import { usePostProjectStatusMutation } from '@/redux/reports/reports.api';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import StickyMenu from './Components/StickyMenu/StickyMenu';
import Summary from './Components/Summary/Summary';
import * as Styled from './style';
import {
  alphabeticallySortDmas,
  concatenateMapData,
  requestBody,
} from './utils';
import { MapProvider } from '@/Providers/MapProvider';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { DmaItem } from '@/types/report.type';

interface ProjectStatusProps {
  projectID: string;
}

const ProjectStatus: FC<ProjectStatusProps> = ({ projectID }) => {
  const [mutate, { data, isLoading, isError }] = usePostProjectStatusMutation();
  const { data: profile } = useGetProfileQuery();

  const [firstLoaded, setFirstLoaded] = useState(false);

  const {
    mapFilters,
    dateFilters,
    filterAttributes,
    setFilterMapValues,
    DMASearch,
    waypointSearch,
  } = useContext(FilterContext);

  const activeAccount = useSelector(
    (state: RootState) => state.activeAccount.currentAccount,
  );

  useEffect(() => {
    setFilterMapValues(defaultFilterMapValues);
  }, []);

  useEffect(() => {
    void mutate({
      body: requestBody(mapFilters, dateFilters, filterAttributes),
      query: {
        branch_id: projectID,
      },
    });
  }, [mutate, mapFilters, dateFilters, filterAttributes, activeAccount]);

  // everything that the map will plot
  const [fullMapData, setFullMapData] = useState<MapElement[]>([]);
  // filtered data for the map bounding box
  const [viewportMapData, setViewportMapData] = useState<MapElement[]>([]);
  // data for the waypoint tables
  const [alphabeticallySortedDmas, setAlphabeticallySortedDmas] =
    useState<DmaItem[]>();

  useEffect(() => {
    // Data is updated, do not update the current position of the map
    if (!isLoading && data) {
      setFullMapData(concatenateMapData(data));
      setAlphabeticallySortedDmas(alphabeticallySortDmas(data));
      setFirstLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    // Filters are updated (or first load), update the current position of the map
    if (!isLoading && data) {
      let currentViewportMapData: MapElement[] = concatenateMapData(data);

      if (DMASearch) {
        const DMASearchUpper = DMASearch.toLocaleUpperCase();
        currentViewportMapData = currentViewportMapData.filter((dmaMapData) => {
          const dmaName = String(
            dmaMapData.tooltip.Dma_key,
          ).toLocaleUpperCase();
          return dmaName.includes(DMASearchUpper);
        });
      }

      if (waypointSearch) {
        const waypointSearchUpper = waypointSearch.toLocaleUpperCase();
        currentViewportMapData = currentViewportMapData.filter(
          (waypointMapData) => {
            const waypointName = String(
              waypointMapData.tooltip.Waypoint_name,
            ).toLocaleUpperCase();
            return waypointName.includes(waypointSearchUpper);
          },
        );
      }
      setViewportMapData(currentViewportMapData);
    }
  }, [firstLoaded, DMASearch, waypointSearch]);

  if (isError) {
    localStorage.removeItem('mapFilters');
    localStorage.removeItem('dateFilters');
    localStorage.removeItem('filterAttributes');
  }

  const { t } = useCustomTranslation('');

  const dmaIds: string[] = useMemo(() => {
    const dmaIds: string[] = [];
    data?.dmas.map((dma) => {
      if (dma.name) {
        dmaIds.push(dma.name || '');
      }
    });
    return dmaIds;
  }, [data]);

  const waypointIds: string[] = useMemo(() => {
    const waypointIds: string[] = [];
    data?.map?.waypointsinfo?.data.map((waypoint) => {
      if (waypoint.tooltip.Waypoint_name) {
        waypointIds.push(waypoint.tooltip.Waypoint_name || '');
      }
    });
    return waypointIds;
  }, [data]);

  return (
    <div data-testid="project-status">
      <div>
        <StickyMenu
          filters={data?.select_options}
          dmaIds={dmaIds}
          waypointIds={waypointIds}
          isLoading={isLoading}
        />
      </div>
      <Styled.Container id="map-container">
        {isError ? (
          <div>
            <p>{t('ERROR.REQUEST')}</p>
          </div>
        ) : (
          <div>
            <Styled.MapContainer data-testid="map-container">
              <MapProvider>
                <GenerateMap
                  fullMapData={fullMapData}
                  viewportMapData={viewportMapData}
                  readonly={false}
                  modal={true}
                  showMapFilters={true}
                  isLoading={isLoading}
                />
              </MapProvider>
            </Styled.MapContainer>
            <div>
              {alphabeticallySortedDmas?.map((summary, key) => (
                <WaypointFilterProvider key={key}>
                  <Styled.SummaryContainer>
                    <Summary
                      summary={summary}
                      metricSystem={profile?.metric_system}
                    />
                  </Styled.SummaryContainer>
                </WaypointFilterProvider>
              ))}
            </div>
          </div>
        )}
      </Styled.Container>
    </div>
  );
};

export default ProjectStatus;
