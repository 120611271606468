import FilterBreadcrumb from '@/components/FilterBreadcrumb/FilterBreadcrumb';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import React, { FC, useContext } from 'react';
import * as Styled from './style';

export const Breadcrumbs: FC = () => {
  const { dma, setDma, project, setProject, size, setSize, status, setStatus } =
    useContext(WaypointFilterContext);

  const items = {
    dma: 'dma',
    project: 'project',
    status: 'status',
    size: 'size',
  };

  const removeBreadcrumb = (value: string) => {
    if (value === items.size) {
      setSize('');
    } else if (value === items.status) {
      setStatus({
        id: null,
        name: '',
      });
    } else if (value === items.dma) {
      setDma({
        id: null,
        name: '',
      });
    } else if (value === items.project) {
      setProject({
        id: null,
        name: '',
      });
    }
  };

  return (
    <Styled.BreadCrumbContainer>
      {size && (
        <FilterBreadcrumb
          title={'Size: '}
          icon={true}
          value={size}
          valueTitle={size}
          onClick={() => removeBreadcrumb(items.size)}
        />
      )}
      {status.id && (
        <FilterBreadcrumb
          title={'Status: '}
          icon={true}
          value={status.name}
          valueTitle={status.name}
          onClick={() => removeBreadcrumb(items.status)}
        />
      )}
      {dma.id && (
        <FilterBreadcrumb
          title={'DMA: '}
          icon={true}
          value={dma.name}
          valueTitle={dma.name}
          onClick={() => removeBreadcrumb(items.dma)}
        />
      )}
      {project.id && (
        <FilterBreadcrumb
          title={'Project: '}
          icon={true}
          value={project.name}
          valueTitle={project.name}
          onClick={() => removeBreadcrumb(items.project)}
        />
      )}
    </Styled.BreadCrumbContainer>
  );
};
