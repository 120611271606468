import { DropDownOption } from '@/components/DropDown/DropDown';
import withPageLoader from '@/HOCs/withPageLoader';
import i18n from '@/localization/i18n';
import { useGetCompanyListQuery } from '@/redux/portalCompanies/portalCompanies.api';
import { useGetPartnerListQuery } from '@/redux/portalPartners/portalPartners.api';
import { useGetRolesQuery } from '@/redux/portalUsers/portalUsers.api';
import { GetListResponse } from '@/types/portalCompany.type';
import { GetPartnerListResponse } from '@/types/portalPartner.type';
import { UserRoles } from '@/types/user.type';
import { dropDownCountryData } from '@/utils/countryData';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddCompany from './AddCompany/AddCompany';
import AddPartner from './AddPartner/AddPartner';
import AddProject from './AddProject/AddProject';
import AddUser from './AddUser/AddUser';
import * as Styled from './style';
import UserManagementDropdown from './UserManagementDropdown/UserManagementDropdown';
import { CreateUserManagementDropDownRoutes } from './utils';
import ViewUsers from './ViewUsers/ViewUsers';
import ViewCompanies from './ViewCompanies/ViewCompanies';
import ViewProjects from './ViewProjects/ViewProjects';
import ViewPartners from './ViewPartners/ViewPartners';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface UserManagementProps {
  role: UserRoles;
}

export interface PartnerAndCompany {
  partners: { label: string; value: string }[];
  companies: { label: string; value: string }[];
}

const UserManagement: FC<UserManagementProps> = ({ role }) => {
  const { prefixedT } = useCustomTranslation('USER_MANAGEMENT');
  const [countryCodes, setCountryCodes] = useState<DropDownOption[]>([]);
  const roleQueryResult = useGetRolesQuery();

  const shouldFetchPartners =
    role === UserRoles.SUPER_ADMIN || role === UserRoles.PARTNER_ADMIN;

  const { data: partnerData, refetch: refetchPartners } =
    useGetPartnerListQuery(undefined, {
      skip: !shouldFetchPartners,
    });

  const { data: companyData, refetch: refetchCompanies } =
    useGetCompanyListQuery(undefined, {
      skip: shouldFetchPartners,
    });

  const partnersAndCompanies: PartnerAndCompany = {
    partners: [],
    companies: [],
  };

  if (shouldFetchPartners) {
    partnerData?.forEach((partner: GetPartnerListResponse) => {
      partnersAndCompanies.partners.push({
        label: partner.partner_name,
        value: partner.id.toString(),
      });

      partner.companies.forEach((company) => {
        partnersAndCompanies.companies.push({
          label: company.company_name,
          value: company.id.toString(),
        });
      });
    });
  } else {
    companyData?.forEach((company: GetListResponse) => {
      partnersAndCompanies.companies.push({
        label: company.name,
        value: company.id.toString(),
      });
    });
  }

  const listOfRoles = roleQueryResult?.data;

  const location = useLocation();
  const pathnameArray = location.pathname.split('/');
  const lastPathname = pathnameArray[pathnameArray.length - 1];

  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await dropDownCountryData();

      setCountryCodes(countryData);
    };

    void fetchData();
  }, [i18n.language]);

  useEffect(() => {
    if (lastPathname !== 'user-management') {
      setSelectedOption(lastPathname);
    } else {
      setSelectedOption('');
    }
  }, [lastPathname]);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
  };

  const dropDownRoutes = CreateUserManagementDropDownRoutes(role);

  const handleUpdate = async (): Promise<void> => {
    if (shouldFetchPartners) {
      await refetchPartners();
    } else {
      await refetchCompanies();
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.DropDownWrapper>
        <Styled.DropDownTitle>
          {prefixedT('DROP_DOWN.TITLE')}
        </Styled.DropDownTitle>
        {dropDownRoutes.map((dropdownData, index) => (
          <UserManagementDropdown
            key={index}
            keyValue={index}
            selected={selectedOption}
            {...dropdownData}
            onOptionSelect={handleOptionSelect}
          />
        ))}
      </Styled.DropDownWrapper>
      <Styled.ContentWrapper>
        {selectedOption === 'view-users' && (
          <ViewUsers
            roles={listOfRoles}
            partnersAndCompanies={partnersAndCompanies}
            role={role}
          />
        )}
        {selectedOption === 'view-partners' &&
          role === UserRoles.SUPER_ADMIN && <ViewPartners />}
        {selectedOption === 'view-companies' &&
          (role === UserRoles.SUPER_ADMIN ||
            role === UserRoles.PARTNER_ADMIN) && <ViewCompanies />}
        {selectedOption === 'view-projects' && <ViewProjects />}
        {selectedOption === 'add-user' && (
          <AddUser
            roles={listOfRoles}
            partnersAndCompanies={partnersAndCompanies}
          />
        )}
        {selectedOption === 'add-project' && (
          <AddProject partnersAndCompanies={partnersAndCompanies} />
        )}
        {selectedOption === 'add-company' &&
          (role === UserRoles.SUPER_ADMIN ||
            role === UserRoles.PARTNER_ADMIN) && (
            <AddCompany
              refetch={handleUpdate}
              partnersAndCompanies={partnersAndCompanies}
              countryData={countryCodes}
            />
          )}
        {selectedOption === 'add-partner' && role === UserRoles.SUPER_ADMIN && (
          <AddPartner refetch={handleUpdate} countryData={countryCodes} />
        )}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

export default withPageLoader(UserManagement);
