import React, { FC, useMemo } from 'react';
import * as Styled from './style';
import {
  postWaypointJobBody,
  WaypointInformationType,
} from '@/types/report.type';
import { Field, Formik } from 'formik';
import TextArea from '@/components/TextArea/TextArea';
import { Button, DatePicker, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import * as Yup from 'yup';
import { jobStatuses, leakTypes } from '../../utils';
import {
  useGetLeakTypesQuery,
  usePostWaypointJobMutation,
} from '@/redux/reports/reports.api';
import StatusModal from '@/components/StatusModal/StatusModal';
import useCustomTranslation from '@/localization/useCustomTranslation';
import ImageUploadBox from '@/components/ImageUploadBox/ImageUploadBox';
import theme from '@/theme';

export interface AddJobProps {
  waypointId: number;
  data: WaypointInformationType;
  closeOverlay: () => void;
  isUpdated: () => Promise<void>;
}

const AddJob: FC<AddJobProps> = ({
  data,
  closeOverlay,
  waypointId,
  isUpdated,
}) => {
  const [mutate, { isLoading, isSuccess, isError }] =
    usePostWaypointJobMutation();

  const { data: leakTypesData } = useGetLeakTypesQuery();

  const { prefixedT } = useCustomTranslation('WAYPOINT_PAGE.ADD_JOB');

  const initialValues: postWaypointJobBody = useMemo(
    () => ({
      waypoint_job_info: {
        waypoint_id: waypointId,
        job_status: '',
        work_order: null,
        repair_date: null,
        leak_type_id: null,
        agreed_flow_reduction: null,
        road_speed: null,
        date_raised_internally: null,
        date_raised_externally: null,
        address: data.address,
        w3w: data.what3words,
        surface_type: data.surface_type,
        estimate_leak_size: data.estimated_size_engineer,
        material: data.pipe_material,
        pipe_diameter: null,
        comment: data.comment,
        environmental_notes: data.environmental_notes,
      },
      base64_images: [],
    }),
    [],
  );

  const ValidationSchema = Yup.object().shape({
    waypoint_job_info: Yup.object().shape({
      job_status: Yup.string().required(prefixedT('JOB_STATUS.VALIDATION')),
      comment: Yup.string().nullable(true),
      work_order: Yup.string().nullable(true),
      repair_date: Yup.date().nullable(true),
      leak_type_id: Yup.number()
        .nullable(true)
        .required(prefixedT('LEAK_TYPE.VALIDATION')),
      agreed_flow_reduction: Yup.number().nullable(true),
      road_speed: Yup.number().nullable(true),
      date_raised_internally: Yup.date().nullable(true),
      date_raised_externally: Yup.date().nullable(true),
      address: Yup.string().nullable(true),
      w3w: Yup.string().nullable(true),
      surface_type: Yup.string().nullable(true),
      estimate_leak_size: Yup.number().nullable(true),
      material: Yup.string().nullable(true),
      pipe_diameter: Yup.number().nullable(true),
      environmental_notes: Yup.string().nullable(true),
    }),
  });

  const onSubmit = (values: postWaypointJobBody) => {
    if (data.waypoint_name) {
      void mutate({
        body: values,
        query: { waypoint_id: waypointId },
      });
    }
  };

  return (
    <>
      <Styled.Underlay data-testid={'overlay'} onClick={closeOverlay} />
      <Styled.DialogBox scroll={!isError && !isSuccess} open={true}>
        {isSuccess ? (
          <StatusModal
            isSuccess={isSuccess}
            title={prefixedT('SUCCESS')}
            desc={''}
            handleClose={() => void isUpdated()}
          />
        ) : isError ? (
          <StatusModal
            isSuccess={isSuccess}
            title={prefixedT('ERROR.TITLE')}
            desc={prefixedT('ERROR.DESC')}
            handleClose={closeOverlay}
          />
        ) : (
          <>
            <h1>{prefixedT('TITLE')}</h1>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ values, setFieldValue, submitForm, errors }) => {
                return (
                  <>
                    <Styled.FormContainer>
                      <Styled.FormCol>
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.address'}
                          label={prefixedT('ADDRESS')}
                          type={'text'}
                          value={values.waypoint_job_info.address}
                          error={errors.waypoint_job_info?.address}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.w3w'}
                          label={prefixedT('W3W')}
                          type={'text'}
                          value={values.waypoint_job_info.w3w}
                          error={errors.waypoint_job_info?.w3w}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.surface_type'}
                          label={prefixedT('SURFACE_TYPE')}
                          type={'text'}
                          value={values.waypoint_job_info.surface_type}
                          error={errors.waypoint_job_info?.surface_type}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.estimate_leak_size'}
                          label={prefixedT('ESTIMATED_SIZE')}
                          type={'number'}
                          value={values.waypoint_job_info.estimate_leak_size}
                          error={errors.waypoint_job_info?.estimate_leak_size}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.material'}
                          label={prefixedT('PIPE_MATERIAL')}
                          type={'text'}
                          value={values.waypoint_job_info.material}
                          error={errors.waypoint_job_info?.material}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.pipe_diameter'}
                          label={prefixedT('PIPE_DIAMETER')}
                          type={'number'}
                          value={values.waypoint_job_info.pipe_diameter}
                          error={errors.waypoint_job_info?.pipe_diameter}
                        />
                        <Field
                          as={TextArea}
                          name={'waypoint_job_info.comment'}
                          label={prefixedT('JOB_COMMENT')}
                          type={'text'}
                          value={values.waypoint_job_info.comment}
                          error={errors.waypoint_job_info?.comment}
                        />
                        <Field
                          as={TextArea}
                          name={'waypoint_job_info.environmental_notes'}
                          label={prefixedT('ENVIRONMENTAL_NOTE')}
                          type={'text'}
                          value={values.waypoint_job_info.environmental_notes}
                          error={errors.waypoint_job_info?.environmental_notes}
                        />
                      </Styled.FormCol>

                      <Styled.FormCol>
                        <Field
                          as={DropDown}
                          name={'waypoint_job_info.job_status'}
                          label={prefixedT('JOB_STATUS')}
                          styles={{ border: true }}
                          options={jobStatuses.map((jobStatus) => ({
                            value: jobStatus.value,
                            label: prefixedT(jobStatus.label),
                          }))}
                          required
                          placeholder={prefixedT('JOB_STATUS.PLACEHOLDER')}
                          onChange={(optionDropdown: DropDownOption) =>
                            setFieldValue(
                              'waypoint_job_info.job_status',
                              optionDropdown.value,
                            )
                          }
                          testId={'jobStatus'}
                          error={errors.waypoint_job_info?.job_status}
                        />
                        <Field
                          as={DropDown}
                          name={'waypoint_job_info.leak_type_id'}
                          label={prefixedT('LEAK_TYPE')}
                          styles={{ border: true }}
                          options={leakTypesData?.map((leak_type) => ({
                            value: leak_type.id,
                            label: prefixedT(
                              leakTypes[leak_type.id] || leak_type.type,
                            ),
                          }))}
                          required
                          placeholder={prefixedT('LEAK_TYPE.PLACEHOLDER')}
                          onChange={(optionDropdown: DropDownOption) =>
                            setFieldValue(
                              'waypoint_job_info.leak_type_id',
                              optionDropdown.value,
                            )
                          }
                          testId={'leakType'}
                          error={errors.waypoint_job_info?.leak_type_id}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.work_order'}
                          label={prefixedT('WORK_ORDER')}
                          type={'text'}
                          value={values.waypoint_job_info.work_order}
                          error={errors.waypoint_job_info?.work_order}
                          testId={'work-order-input'}
                        />
                        <Field
                          as={DatePicker}
                          name={'waypoint_job_info.repair_date'}
                          label={prefixedT('REPAIR_DATE')}
                          onChange={(dateValue: Date) => {
                            void setFieldValue(
                              'waypoint_job_info.repair_date',
                              dateValue ? dateValue : null,
                            );
                          }}
                          value={values.waypoint_job_info.repair_date}
                          error={errors.waypoint_job_info?.repair_date}
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.flow_reduction'}
                          label={prefixedT('FLOW_REDUCTION')}
                          type={'number'}
                          value={values.waypoint_job_info.agreed_flow_reduction}
                          error={
                            errors.waypoint_job_info?.agreed_flow_reduction
                          }
                        />
                        <Field
                          as={DatePicker}
                          name={'waypoint_job_info.date_raised_internally'}
                          label={prefixedT('DATE_INTERNALLY')}
                          onChange={(dateValue: Date) => {
                            void setFieldValue(
                              'waypoint_job_info.date_raised_internally',
                              dateValue ? dateValue : null,
                            );
                          }}
                          value={
                            values.waypoint_job_info.date_raised_internally
                          }
                          error={
                            errors.waypoint_job_info?.date_raised_internally
                          }
                        />
                        <Field
                          as={DatePicker}
                          name={'waypoint_job_info.date_raised_externally'}
                          label={prefixedT('DATE_EXTERNALLY')}
                          onChange={(dateValue: Date) => {
                            void setFieldValue(
                              'waypoint_job_info.date_raised_externally',
                              dateValue ? dateValue : null,
                            );
                          }}
                          value={
                            values.waypoint_job_info.date_raised_externally
                          }
                          error={
                            errors.waypoint_job_info?.date_raised_externally
                          }
                        />
                        <Field
                          as={TextField}
                          name={'waypoint_job_info.road_speed'}
                          label={prefixedT('ROAD_SPEED')}
                          type={'number'}
                          value={values.waypoint_job_info.road_speed}
                          error={errors.waypoint_job_info?.road_speed}
                        />
                      </Styled.FormCol>
                    </Styled.FormContainer>
                    <ImageUploadBox
                      onImageUpload={(imageString: string[]) => {
                        void setFieldValue('base64_images', [
                          ...(values.base64_images || []),
                          ...imageString,
                        ]);
                      }}
                      maxFiles={5}
                      showPreview={false}
                    ></ImageUploadBox>
                    <Styled.ImagesContainer>
                      {values.base64_images
                        ? values.base64_images.map((image, idx) => (
                            <Styled.ImageContainer key={idx}>
                              <Styled.CrossImg
                                onClick={() => {
                                  void setFieldValue('base64_images', [
                                    ...(values.base64_images?.filter(
                                      (_, i) => i !== idx,
                                    ) || []),
                                  ]);
                                }}
                              ></Styled.CrossImg>
                              <Styled.Image key={idx} src={image} />
                            </Styled.ImageContainer>
                          ))
                        : null}
                    </Styled.ImagesContainer>
                    <Styled.ButtonContainerOuter>
                      <Styled.ButtonContainerInner>
                        <Button
                          color={theme.colors.coral}
                          onClick={() => {
                            closeOverlay();
                          }}
                        >
                          {prefixedT('DISCARD')}
                        </Button>
                      </Styled.ButtonContainerInner>
                      <Styled.ButtonContainerInner>
                        <Button
                          type="submit"
                          onClick={() => {
                            void submitForm();
                          }}
                          disabled={isLoading}
                          testId="submit"
                        >
                          {prefixedT('SUBMIT')}
                        </Button>
                      </Styled.ButtonContainerInner>
                    </Styled.ButtonContainerOuter>
                    {Object.keys(errors).length > 0 && (
                      <Styled.Error>
                        {prefixedT('SUBMIT.VALIDATION')}
                      </Styled.Error>
                    )}
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </Styled.DialogBox>
    </>
  );
};

export default AddJob;
