import { DropDown } from '@/components';
import * as Styled from './style';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { updateSelectedValues } from '@/containers/GISUpload/Table/utils';
import {
  ErrorModalText,
  FileSelectedValues,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import React, { FC, useEffect } from 'react';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { addOrReplaceError } from '@/containers/GISUpload/utils';

interface MapUnitsProps {
  file: FileSelectedValues;
  sampleData: unknown[];
}

export const MapUnits: FC<MapUnitsProps> = ({ file, sampleData }) => {
  const { prefixedT } = useCustomTranslation('MAP_UNITS');
  const {
    setSelectedValues,
    currentStep,
    selectedValues,
    isError,
    setIsError,
    setMappedSystemFields,
  } = useGISUploadValues();
  const [value, setValue] = React.useState('');
  const [columnValue, setColumnValue] = React.useState<DropDownOption>();
  const typedSampleData = sampleData as Record<string, any>[];

  const filename = file.file;

  useEffect(() => {
    if (selectedValues) {
      selectedValues.map((values) => {
        if (
          values.file === filename &&
          !Array.isArray(values.units) &&
          values.units
        ) {
          if (!value) {
            setValue(values.units.toString());
          }
          setMappedSystemFields((prevValues) => ({
            ...prevValues,
            units: true,
          }));
        }

        if (
          values.file === filename &&
          Array.isArray(values.units) &&
          values.units.length > 0
        ) {
          if (!columnValue) {
            const unitValues = values.data?.filter(
              (item) => item.title === 'units',
            )[0];
            if (unitValues) {
              setColumnValue({
                label: unitValues.name,
                value: unitValues.value,
              });
            }
          }
        }

        if (
          values.file === filename &&
          values.data.filter((item) => item.title === 'units').length === 0
        ) {
          setColumnValue(undefined);
        }
      });
    }
  }, [selectedValues]);

  const dropdownOptions: DropDownOption[] = [
    { label: 'inch', value: '1' },
    { label: 'mm', value: '2' },
  ];

  const handleColumnChange = (option: DropDownOption | DropDownOption[]) => {
    if (Array.isArray(option)) return;
    setValue('');
    setColumnValue(option);

    const value: DropDownOption = {
      value: option.value,
      label: 'units',
    };

    setSelectedValues((prevValues) =>
      updateSelectedValues(
        prevValues,
        filename,
        option.label,
        value,
        currentStep,
        false,
      ),
    );
  };

  const handleChange = (option: DropDownOption | DropDownOption[]) => {
    if (Array.isArray(option)) return;
    setColumnValue(undefined);
    setValue(option.value);

    setSelectedValues((prevValues) =>
      prevValues.map((values) =>
        values.file === filename
          ? {
              ...values,
              units: Number(option.value),
              data: values.data.filter((item) => item.title !== 'units'),
            }
          : values,
      ),
    );

    if (isError?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.SYSTEM_FIELD_NUMERIC, true),
      );
    }
    if (isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)) {
      setIsError(
        addOrReplaceError(
          isError,
          ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
          true,
        ),
      );
    }
  };

  const dropdownOptionsColumn: DropDownOption[] = Object.entries(
    typedSampleData[0] || {},
  ).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  const displayError =
    !!isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) &&
    !value &&
    !columnValue;

  return (
    <Styled.Container>
      <Styled.DropdownContainer>
        <Styled.DropdownLabel error={displayError}>
          {prefixedT('TITLE.COLUMN')}
        </Styled.DropdownLabel>
        <DropDown
          options={dropdownOptionsColumn}
          label={undefined}
          onChange={(option) => handleColumnChange(option as DropDownOption)}
          value={columnValue?.label || ''}
          styles={{ background: '#eff4fa', center: true }}
          placeholder={columnValue?.label || 'Select Column'}
        />
      </Styled.DropdownContainer>
      <Styled.DropdownContainer>
        <Styled.DropdownLabel error={displayError}>
          {prefixedT('TITLE.UNITS')}
        </Styled.DropdownLabel>
        <DropDown
          options={dropdownOptions}
          label={undefined}
          placeholder={'units'}
          onChange={(option) => handleChange(option)}
          value={value}
          styles={{ background: '#eff4fa', center: true }}
        />
      </Styled.DropdownContainer>
    </Styled.Container>
  );
};
