import { DropDownOption } from '@/components/DropDown/DropDown';
import {
  FileSelectedValues,
  MappedSystemFields,
} from '@/Providers/GISUploadProvider';
import { SelectedValue } from '@/Providers/GISUploadProvider';
import {
  GIS_COLUMN_MAPPING,
  STANDARDISING,
  UniqueValuesResponse,
} from '@/types/gisUpload.type';

export const updateSelectedValues = (
  prevValues: FileSelectedValues[],
  filename: string,
  key: number,
  option: { label: string },
  data: { unique_values: string[] } | undefined,
  selectedFileValue: SelectedValue,
) => {
  const updatedValues = [...prevValues];
  const selectedFile = updatedValues.find((el) => el.file === filename);

  if (selectedFile) {
    const getValue = data?.unique_values[key];
    const newEntry = {
      title: getValue !== undefined && getValue !== null ? getValue : '',
      type: option.label,
    };

    const updateField = (field: { title: string; type: string }[]) => {
      const existingIndex = field.findIndex(
        (item) => item.title === newEntry.title,
      );
      if (existingIndex !== -1) {
        field[existingIndex] = newEntry;
      } else {
        field.push(newEntry);
      }
    };

    const ensureFieldExists = (
      field: { title: string; type: string }[] | number | undefined,
    ) => {
      return Array.isArray(field) ? [...field] : [];
    };

    switch (selectedFileValue.type) {
      case GIS_COLUMN_MAPPING.MATERIAL:
        selectedFile.material = ensureFieldExists(selectedFile.material);
        updateField(selectedFile.material);
        break;
      case GIS_COLUMN_MAPPING.ASSET_TYPE:
        selectedFile.asset_type = ensureFieldExists(selectedFile.asset_type);
        updateField(selectedFile.asset_type);
        break;
      case GIS_COLUMN_MAPPING.PIPE_TYPE:
        selectedFile.pipe_type = ensureFieldExists(selectedFile.pipe_type);
        updateField(selectedFile.pipe_type);
        break;
      case GIS_COLUMN_MAPPING.STATUS:
        selectedFile.status = ensureFieldExists(selectedFile.status);
        updateField(selectedFile.status);
        break;
      case GIS_COLUMN_MAPPING.UNITS:
        if (typeof selectedFile.units === 'number') {
          selectedFile.units = Number(newEntry.title) || selectedFile.units;
        } else {
          selectedFile.units = ensureFieldExists(selectedFile.units);
          updateField(selectedFile.units);
        }
        break;
      default:
        break;
    }

    return updatedValues;
  }

  return prevValues;
};

export const standardising = (selectedFileValue: SelectedValue) => {
  switch (selectedFileValue.type) {
    case GIS_COLUMN_MAPPING.MATERIAL:
      return STANDARDISING.MATERIAL;
    case GIS_COLUMN_MAPPING.ASSET_TYPE:
      return STANDARDISING.TYPE;
    case GIS_COLUMN_MAPPING.PIPE_TYPE:
      return STANDARDISING.TYPE;
    case GIS_COLUMN_MAPPING.UNITS:
      return STANDARDISING.UNITS;
    case GIS_COLUMN_MAPPING.STATUS:
      return STANDARDISING.STATUS;
    default:
      return STANDARDISING.MATERIAL;
  }
};

export const createDropdown = (
  selectedFileValue: SelectedValue,
  data: UniqueValuesResponse | undefined,
): DropDownOption[] => {
  const mapDataToDropdown = (
    items: {
      id: number;
      standardised_type?: string;
      standardised_material?: string;
      standardised_status?: string;
      standardised_unit?: string;
    }[],
    labelKey:
      | 'standardised_material'
      | 'standardised_type'
      | 'standardised_status'
      | 'standardised_unit',
  ) => {
    return items.map((el) => ({
      value: el.id.toString(),
      label: el[labelKey] || '',
    }));
  };

  switch (selectedFileValue.type) {
    case GIS_COLUMN_MAPPING.MATERIAL:
      return data?.pipe_material
        ? mapDataToDropdown(data.pipe_material, 'standardised_material')
        : [];
    case GIS_COLUMN_MAPPING.ASSET_TYPE:
      return data?.asset_type
        ? mapDataToDropdown(data.asset_type, 'standardised_type')
        : [];
    case GIS_COLUMN_MAPPING.PIPE_TYPE:
      return data?.pipe_type
        ? mapDataToDropdown(data.pipe_type, 'standardised_type')
        : [];
    case GIS_COLUMN_MAPPING.STATUS:
      return data?.pipe_status
        ? mapDataToDropdown(data.pipe_status, 'standardised_status')
        : [];
    case GIS_COLUMN_MAPPING.UNITS:
      return data?.diameter_units
        ? mapDataToDropdown(data.diameter_units, 'standardised_unit')
        : [];
    default:
      return [];
  }
};

export const updateMappedFields = (
  fieldType: GIS_COLUMN_MAPPING,
  file: FileSelectedValues,
  data: UniqueValuesResponse,
  setMappedSystemFields: React.Dispatch<
    React.SetStateAction<MappedSystemFields>
  >,
) => {
  const checkFieldLength = (field: GIS_COLUMN_MAPPING) => {
    const fieldValue = file?.[field];

    if (typeof fieldValue === 'number') {
      return true;
    }

    return (
      Array.isArray(fieldValue) &&
      fieldValue.length > 0 &&
      fieldValue.length === data?.unique_values?.length
    );
  };

  setMappedSystemFields((prevState) => ({
    ...prevState,
    [fieldType]: checkFieldLength(fieldType),
  }));
};
