import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue}
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 20px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  border-radius: 5px;
`;

export const HistoryLogWrapper = styled.div``;

export const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #fff842;
`;

export const Table = styled.table`
  text-align: left;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;

  tr:nth-child(odd) {
    background-color: #108dcc;
  }

  tr:nth-child(even) {
    background-color: #0779b1;
  }

  tr:hover {
    background-color: #a8e2ff;
    td {
      color: black;
    }
  }

  td:hover {
    background-color: #fff842;
    color: #403e10;
  }
`;

export const TableHeading = styled.h2`
  font-weight: bold;
  font-size: 1em;
  color: #fff;
`;

export const TH = styled.th`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  padding-bottom: 2%;
  padding-top: 2%;
  text-align: center;
`;

export const TBody = styled.tbody``;
