import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const SearchContainer = styled.div`
  margin-bottom: 20px;
  width: 50%;
  margin-right: 80px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-size: 20px;
`;

export const Container = styled.div`
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-top: 10px;
`;

export const PagniationContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DropDownContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-right: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LoaderContainer = styled.tbody`
  width: 100%;
  position: relative;
  height: 300px;
`;

export const InnerLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
