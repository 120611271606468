import React, { FC } from 'react';
import * as Styled from './style';
import ProcessA from '@/assets/gifs/processA.gif';
import ProcessB from '@/assets/gifs/processB.gif';
import { Button } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  ErrorModalText,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';

const ErrorModal: FC = () => {
  const { isError, isErrorModalVisible, setIsErrorModalVisible } =
    useGISUploadValues();
  const { prefixedT } = useCustomTranslation('GIS_UPLOAD.ERROR_MODAL');

  const closeOverlay = () => {
    setIsErrorModalVisible(false);
  };

  return (
    <>
      {isErrorModalVisible && (
        <>
          <Styled.Underlay data-testid="overlay" onClick={closeOverlay} />
          <Styled.DialogBox data-testid="error-modal" open={true}>
            <Styled.Wrapper>
              {isError?.includes(ErrorModalText.DEFAULT_ERROR_MESSAGE) && (
                <>
                  <Styled.Bold>{prefixedT('TITLE.A')}</Styled.Bold>
                  <Styled.Normal>{prefixedT('TITLE.B')}</Styled.Normal>
                  <Styled.Normal>{prefixedT('TITLE.C')}</Styled.Normal>
                </>
              )}
              {isError?.includes(
                ErrorModalText.COLUMNS_NOT_SELECTED ||
                  ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
              ) && <Styled.Bold>{prefixedT('TITLE.D')}</Styled.Bold>}
              {isError?.includes(ErrorModalText.COLUMNS_NOT_SELECTED) && (
                <Styled.Container>
                  <Styled.Normal>{prefixedT('DESC.A')}</Styled.Normal>
                  <Styled.GIF
                    src={ProcessA as string}
                    alt={prefixedT('ALT.GIF_A')}
                  />
                </Styled.Container>
              )}
              {isError?.includes(ErrorModalText.COLUMNS_NOT_SELECTED) &&
                isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) && (
                  <Styled.Container>
                    <Styled.Bold>{prefixedT('DESC.C')}</Styled.Bold>
                  </Styled.Container>
                )}
              {isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED) && (
                <Styled.Container>
                  <Styled.Normal>{prefixedT('DESC.B')}</Styled.Normal>
                  <Styled.GIF
                    src={ProcessB as string}
                    alt={prefixedT('ALT.GIF_B')}
                  />
                </Styled.Container>
              )}
              <Styled.ButtonContainer>
                <Button onClick={closeOverlay}>
                  {prefixedT('BUTTON.CLOSE')}
                </Button>
              </Styled.ButtonContainer>
            </Styled.Wrapper>
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default ErrorModal;
