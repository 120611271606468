import React, { createContext, FC, useState } from 'react';

export interface SelectedWaypoints {
  id: number;
  name: string;
}

interface project {
  id: number | null;
  name: string;
}

interface Dma {
  id: number | null;
  name: string;
}

interface Status {
  id: number | null;
  name: string;
}

export interface WaypointFilterContextProps {
  waypointSearch: string;
  setWaypointSearch: React.Dispatch<React.SetStateAction<string>>;
  size: string;
  setSize: React.Dispatch<React.SetStateAction<string>>;
  status: Status;
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  dma: Dma;
  setDma: React.Dispatch<React.SetStateAction<Dma>>;
  project: project;
  setProject: React.Dispatch<React.SetStateAction<project>>;
  targetLeakageReduction: number | null;
  setTargetLeakageReduction: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  targetPipeline: number | null;
  setTargetPipeline: React.Dispatch<React.SetStateAction<number | null>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  selectedWaypoints: SelectedWaypoints[];
  setSelectedWaypoints: React.Dispatch<
    React.SetStateAction<SelectedWaypoints[]>
  >;
}

const defaultProject = {
  id: null,
  name: '',
};

const defaultDMA = {
  id: null,
  name: '',
};

const defaultStatus = {
  id: null,
  name: '',
};

export const WaypointFilterContext = createContext<WaypointFilterContextProps>({
  waypointSearch: '',
  setWaypointSearch: () => '',
  size: '',
  setSize: () => '',
  status: defaultStatus,
  setStatus: () => defaultStatus,
  dma: defaultDMA,
  setDma: () => defaultDMA,
  project: defaultProject,
  setProject: () => defaultProject,
  page: 1,
  setPage: () => 1,
  perPage: 10,
  setPerPage: () => 10,
  targetLeakageReduction: null,
  setTargetLeakageReduction: () => null,
  targetPipeline: null,
  setTargetPipeline: () => null,
  selectedWaypoints: [],
  setSelectedWaypoints: () => [],
});

interface WaypointFilterProviderProps {
  children: React.ReactNode;
}

export const WaypointFilterProvider: FC<WaypointFilterProviderProps> = ({
  children,
}) => {
  const [waypointSearch, setWaypointSearch] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [status, setStatus] = useState<Status>(defaultStatus);
  const [dma, setDma] = useState<Dma>(defaultDMA);
  const [project, setProject] = useState<project>(defaultProject);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [selectedWaypoints, setSelectedWaypoints] = useState<
    SelectedWaypoints[]
  >([]);
  const [targetLeakageReduction, setTargetLeakageReduction] = useState<
    number | null
  >(null);
  const [targetPipeline, setTargetPipeline] = useState<number | null>(null);

  return (
    <WaypointFilterContext.Provider
      value={{
        targetPipeline,
        setTargetPipeline,
        targetLeakageReduction,
        setTargetLeakageReduction,
        waypointSearch,
        setWaypointSearch,
        size,
        setSize,
        status,
        setStatus,
        dma,
        setDma,
        project,
        setProject,
        page,
        setPage,
        perPage,
        setPerPage,
        selectedWaypoints,
        setSelectedWaypoints,
      }}
    >
      {children}
    </WaypointFilterContext.Provider>
  );
};
