import { AnimatePresence } from 'framer-motion';
import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import Loader, { LoaderType } from './Loader';
import * as Styled from './style';

type Props = {
  active?: boolean;
};

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const SplashScreen: FC<Props> = ({ active = false }) =>
  createPortal(
    <AnimatePresence>
      {active ? (
        <Styled.SplashScreenWrapper {...animationProps}>
          <Loader type={LoaderType.light} />
        </Styled.SplashScreenWrapper>
      ) : null}
    </AnimatePresence>,
    document.body,
  );
export default SplashScreen;
