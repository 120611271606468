import {
  FileSelectedValues,
  GISRoutes,
  SelectedValue,
} from '@/Providers/GISUploadProvider';

export const updateSelectedValues = (
  prevValues: FileSelectedValues[],
  fileName: string,
  column: SelectedValue,
  currentStep: GISRoutes,
): FileSelectedValues[] => {
  const updatedValues = [...prevValues];
  const fileIndex = updatedValues.findIndex((f) => f.file === fileName);

  if (fileIndex !== -1) {
    const data = updatedValues[fileIndex].data.filter(
      (item) => item.name !== column.name,
    );

    if (currentStep === GISRoutes.PROCESS_ASSETS && column.title === 'type') {
      updatedValues[fileIndex].asset_type = undefined;
    }

    if (column.title === 'material') {
      updatedValues[fileIndex].material = undefined;
    }

    if (column.title === 'units') {
      updatedValues[fileIndex].units = undefined;
    }

    updatedValues[fileIndex] = { ...updatedValues[fileIndex], data };
  }

  return updatedValues;
};
