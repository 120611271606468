import { EditUserBody, IRole, IUser } from '@/types/user.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const userApi = createApi({
  reducerPath: 'userQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['User', 'Profile', 'Roles'],
  endpoints: (builder) => ({
    getProfile: builder.query<IUser, void>({
      query: () => ({
        url: '/portal_users/profile_from_id',
        method: 'GET',
      }),
      providesTags: ['Profile'],
    }),
    editUser: builder.mutation<
      void,
      { id: string | number; body: EditUserBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getRoles: builder.query<IRole[], void>({
      query: () => '/portal_users/roles',
      providesTags: ['Roles'],
    }),
    deleteUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `/portal_users/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useDeleteUserMutation,
  useGetProfileQuery,
  useEditUserMutation,
  useGetRolesQuery,
} = userApi;
