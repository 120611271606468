import { Loader } from '@/components';
import CompanySelect from '@/components/CompanySelect/CompanySelect';
import DeliveryPerformanceFilters from '@/components/DeliveryPerformanceFilters/DeliveryPerformanceFilters';
import HistoryTable from '@/components/HistoryTable/HistoryTable';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import {
  useGetDeliveryPerformanceBugDeploymentEngineerMutation,
  useGetDeliveryPerformanceDropdownMutation,
} from '@/redux/reports/reports.api';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  QueryParams,
  extractEngineerIds,
} from '../DeliveryPerformanceLE/utils/utils';
import { generateHistoryTableData } from './utils/utils';
import * as Styled from './style';

interface ParamsType {
  partner_id?: number;
  company_id?: number;
}

const DeliveryPerformanceSTContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('DELIVERY_PERFORMANCE_PAGE');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [companyId, setCompanyId] = useState<string>('');
  const [mutateDropdown, { data: dropdown }] =
    useGetDeliveryPerformanceDropdownMutation();

  const {
    partnerActiveValue,
    companyActiveValue,
    projectActiveValue,
    dmaActiveValue,
    engineerActiveValue,
    dateFilters,
  } = useContext(DeliveryPerformanceContext);

  useEffect(() => {
    const urlCompanyId = queryParams.get('company') as string;

    setCompanyId(urlCompanyId);
  }, [location.search]);

  useEffect(() => {
    const params: ParamsType = {};

    if (companyActiveValue.name) {
      params.company_id = companyActiveValue.value;
    } else if (partnerActiveValue.name) {
      params.partner_id = partnerActiveValue.value;
    }

    const fetchData = async () => {
      await mutateDropdown(params);
    };

    void fetchData();
  }, [partnerActiveValue, companyActiveValue]);

  const [dropdownEngineers, setDropdownEngineers] = useState<
    (string | number)[]
  >([]);

  const [
    mutateBugDeployment,
    { data: bugDeploymentData, isSuccess, isLoading, isError },
  ] = useGetDeliveryPerformanceBugDeploymentEngineerMutation();

  useEffect(() => {
    setDropdownEngineers(extractEngineerIds(dropdown));
  }, [dropdown]);

  useEffect(() => {
    const fetchData = async () => {
      if (dropdownEngineers.length > 0) {
        await mutateBugDeployment({
          body: engineerActiveValue.value
            ? [engineerActiveValue.value]
            : dropdownEngineers,
          query: QueryParams(
            projectActiveValue.value,
            dmaActiveValue.value,
            dateFilters,
            companyActiveValue.value,
            partnerActiveValue.value,
          ),
        });
      }
    };
    void fetchData();
  }, [
    mutateBugDeployment,
    dropdownEngineers,
    partnerActiveValue,
    companyActiveValue,
    projectActiveValue,
    dmaActiveValue,
    engineerActiveValue,
    dateFilters,
    dropdownEngineers,
  ]);

  const headingList = [
    prefixedT('NAME'),
    prefixedT('COVERED'),
    prefixedT('COLLECTIONS'),
    prefixedT('DEPLOYMENTS'),
    prefixedT('HOURS_WORKED'),
  ];

  const historyTableData = generateHistoryTableData(bugDeploymentData);

  return (
    <>
      {!companyId ? (
        <Styled.CompanySelectWrapper>
          <CompanySelect reportName="delivery-performance-sensor-technician" />
        </Styled.CompanySelectWrapper>
      ) : (
        <Styled.Wrapper>
          <DeliveryPerformanceFilters dropdown={dropdown} />
          {isLoading && (
            <Styled.MainContainer data-testid="Loader">
              <Loader />
            </Styled.MainContainer>
          )}
          {isError && (
            <Styled.MainContainer>
              <p>{t('ERROR.REQUEST')}</p>
            </Styled.MainContainer>
          )}
          {!isLoading && historyTableData && historyTableData.length > 0 ? (
            <Styled.DescWrapper>
              <HistoryTable data={historyTableData} headingList={headingList} />
            </Styled.DescWrapper>
          ) : isSuccess ? (
            <Styled.MainContainer>
              <p>{t('ERROR.REPORT.NO_DATA')}</p>
            </Styled.MainContainer>
          ) : null}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default DeliveryPerformanceSTContainer;
