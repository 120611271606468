import * as Yup from 'yup';

export const EmailValidator = Yup.string().email('VALIDATION.EMAIL');
export const LoginPasswordValidator = Yup.string()
  .min(8, 'VALIDATION.PASSWORD.SHORT')
  .max(128, 'VALIDATION.PASSWORD.LONG');

export const PasswordValidator = Yup.string()
  .min(8, 'VALIDATION.PASSWORD.SHORT')
  .max(128, 'VALIDATION.PASSWORD.LONG')
  .matches(/^(?=.*[!@#$%^&*])/, 'VALIDATION.PASSWORD.SPECIAL');
