import { DropDownOption } from '@/components/DropDown/DropDown';
import { GetLeakTypeResponse, GetStatusResponse } from '@/types/mobileApp.type';
import { GetEngineersResponse } from '@/types/portalUsers.type';
import { WaypointInformationType } from '@/types/report.type';
import moment from 'moment';

export const leakTypeDropDownValues = (
  leakTypes: GetLeakTypeResponse[] | undefined,
): DropDownOption[] =>
  leakTypes?.map((leakType) => ({
    value: leakType.id.toString(),
    label: leakType.type,
  })) || [];

export const engineerDropDownValues = (
  engineers: GetEngineersResponse[] | undefined,
): DropDownOption[] =>
  engineers?.map((engineer) => ({
    value: engineer.id.toString(),
    label: engineer.name,
  })) || [];

export const statusDropDownValues = (
  statuses: GetStatusResponse[] | undefined,
): DropDownOption[] =>
  statuses?.map((status) => ({
    value: status.id.toString(),
    label: status.status,
  })) || [];

export const createInitialValues = (data: WaypointInformationType) => ({
  address: data?.address || '',
  estimated_size_engineer: data.estimated_size_engineer ?? undefined,
  surface_type: data?.surface_type || '',
  estimated_size_client: data.estimated_size_client ?? undefined,
  estimated_size: data.estimated_size ?? undefined,
  comment: data.comment || '',
  environmental_notes: data.environmental_notes || '',
  repair_time: data.repair_time ? new Date(data.repair_time) : null,
  last_scheduled_engineer_id: undefined,
  status_id: undefined,
  material: data.pipe_material || '',
  predicted_leak_type_id: undefined,
  actual_leak_type_id: undefined,
});

export const ISO8601DateFormat = (date: Date) => {
  const timestamp = moment(date);
  return timestamp.format('YYYY-MM-DD HH:mm:ssZ');
};

export function getChangedValues<T>(
  currentValues: T,
  initialValues: Partial<T>,
): Partial<T> {
  const changedValues: Partial<T> = {};

  for (const key in currentValues) {
    if (currentValues[key as keyof T] !== initialValues?.[key as keyof T]) {
      changedValues[key as keyof T] = currentValues[key as keyof T];
    }
  }

  return changedValues;
}
