import React, { FC } from 'react';
import * as Styled from '../style';

type Props = {
  tooltip: object;
};

const GeneralToolTip: FC<Props> = ({ tooltip }) => {
  const newToolTip = new Map<string, string>(Object.entries(tooltip));
  const titles: JSX.Element[] = [];
  let imageKey = '';

  newToolTip.forEach((value, key) => {
    if (!key.includes('graph')) {
      if (value) {
        titles.push(
          <Styled.Title key={key}>
            <span>{key}: </span>
            {value}
          </Styled.Title>,
        );
      }
    } else {
      imageKey = key;
    }
  });

  return (
    <>
      {imageKey && newToolTip.get(imageKey) && (
        <Styled.Image source={newToolTip.get(imageKey) as string} />
      )}
      {titles}
    </>
  );
};

export default GeneralToolTip;
