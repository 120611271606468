import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div<{
  readonly: boolean;
}>`
  width: calc(100% - ${({ readonly }) => (readonly ? '0px' : '50px')});
  padding: ${({ readonly }) => (readonly ? '0px' : '24px')};
  border-radius: 8px;
  border: ${({ theme, readonly }: ThemeProps<Theme> & { readonly: boolean }) =>
    readonly ? 'none' : `1px solid${theme.colors.lightGray}`};
  background-color: ${({
    theme,
    readonly,
  }: ThemeProps<Theme> & { readonly: boolean }) =>
    readonly ? 'transparent' : theme.colors.white};
  flex-grow: 1;
`;

export const Row = styled.div<{
  count: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: inherit;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    margin-right: 20px;
    max-width: ${({ count }) =>
      `calc(100% / ${count} - (${20 * (count - 1)}px / ${count}))`};
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const ColumnWrapper = styled.div<{
  items: number;
}>`
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 150px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    max-width: none;
  }

  & > * {
    margin-bottom: 20px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-bottom: 20px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;
