import { ReportWidget, WidgetAction } from '@/types/report.type';
import { DragLayerMonitor, useDrag } from 'react-dnd';

const useDragMove = (
  data: ReportWidget<unknown>,
  id: string | number,
  disabled: boolean,
) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'widget',
    item: { widget: data, type: WidgetAction.MOVE, layoutId: id },
    end: () => {},
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return { ref: disabled ? null : drag, isDragging };
};

export default useDragMove;
