export const CalculatePercentage = (part: number, total: number) => {
  if (
    typeof part !== 'number' ||
    typeof total !== 'number' ||
    part < 0 ||
    total < 0 ||
    total === 0
  ) {
    return 0;
  }

  return (part / total) * 100;
};
