import { TableSortOrders } from '@/components/Table/types';
import { MapElement } from '../components/ReportLayout/widgets/MapView/utils/types';
import { ReactSVGIcon } from './common.type';
import { IJobFilter } from './rtk.type';
export type Widget = {
  id: number;
  key: WidgetType;
  type: string;
  name: string;
  icon: ReactSVGIcon;
  disabled?: boolean;
};

export interface getRunningReportQuery {
  partner_id?: number;
  company_id?: number;
  branch_id?: number;
  page?: number;
  perPage?: number;
  sorted_by?: string;
  sorted_order?: string;
}

export interface createReportBody {
  name: string;
  structure?: object;
  layout_id?: number | null | undefined;
}
export interface ProjectStatusRequestBody {
  options: number[];
  filters: {
    status: string[];
    predicted_size: string[];
    dates: string[];
  };
}

export interface ProjectStatusParams {
  userID?: string | null | undefined;
  branch_id?: string | null | undefined;
}

export type RelaySessionData = {
  session_id: string;
  date_range: string[] | null;
  audio_data: {
    date: string[];
    frequency: number[];
    power: number[];
  } | null;
};

export interface getWaypointInformationQuery {
  pipes: string;
  assets: string;
}

export type ReportSettingsValue = string | number;

export type ReportFilterSettings = {
  control_field: number[];
  filter_type: number[];
  filter_options: (ReportSettingsValue | ReportSettingsValue[])[];
  datasource: number;
};

export type ReportSummarySettings = {
  column: number[];
  summary_title: string[];
  summary_type: number[];
  datasource: number;
};

export type ReportMapSettings = {
  datasource: number;
};

export type ReportResultsSettings = {
  columns: number[];
  datasource: number;
};

export type ReportWidget<S> = {
  id: string | number;
  key: WidgetType;
  data?: MapElement[];
  settings?: S;
};

export type DropWidget = {
  widget: ReportWidget<unknown>;
  type: WidgetAction;
  layoutId?: number | string;
};

export enum WidgetType {
  RESULTS = 'results',
  MAP = 'map',
  FILTERS = 'filters',
  SUMMARY = 'summary',
}

export enum WidgetAction {
  ADD = 'add',
  MOVE = 'move',
  DELETE = 'delete',
  UPDATE = 'update',
}

export type ReportLayoutItem = {
  layoutId: number;
  children?: ReportLayoutItem[];
  widgets?: ReportWidget<unknown>[];
  type: string;
};

export type ReportLayout = ReportLayoutItem[];

export type PaginatedReport = {
  count: number;
  data: Report;
};
export type Report = {
  id: number | string;
  name: string;
  update_at: string;
  structure: ReportLayout;
  layout_id: number;
};

export type CreateReportValues = {
  name: string;
  redirectToEdit: boolean;
  layoutId?: number | null;
};

export type UpdateReportValues = {
  name: string;
  structure: ReportLayout;
  layoutId?: number | null;
};

export type Layout = {
  id: number;
  description: string;
  name: string;
  image: string;
  structure?: ReportLayout;
};

export type Layouts = Layout[];

export type ReportDataSource = {
  id: number;
  description: string;
  name: string;
  link: string;
  ds_columns: ReportDataSourceColumn[];
};

export type ReportDataSourceFilterOption = {
  option: string;
  id: number;
};

export type ReportDataSourceFilterType = {
  name: string;
  id: number;
};

export type ReportDataSourceColumn = {
  id: number;
  column: {
    id: number;
    name: string;
    type: string;
    link: string;
    filters: ReportDataSourceFilterOption[];
    filter_types: ReportDataSourceFilterType[];
  };
};

export type MapData = {
  data: MapElement[];
};

type Map = {
  waypointsinfo?: MapData;
  dmas?: MapData;
  pipes?: MapData;
  assets?: MapData;
  sweep_in_progress_session?: MapData;
  sweep_awaiting_upload_session?: MapData;
  sweep_large_leak_session?: MapData;
  bugdeploymentview?: MapData;
  sweep_medium_leak_session?: MapData;
  sweep_small_leak_session?: MapData;
  sweep_non_leak_session?: MapData;
  sweep_deleted_session?: MapData;
};

export interface SelectOption {
  predicted_size: string[];
  status: Status[];
}

interface Status {
  id: number;
  status: string;
}

type SummaryData = {
  label: string;
  value: string;
};

type Summary = {
  data: SummaryData[];
};

export interface leakTypes {
  leaks_identified: number;
  number_scheduled: number;
  number_marked: number;
  number_repaired: number;
}

export interface InfoItem {
  large_leaks: leakTypes;
  medium_leaks: leakTypes;
  small_leaks: leakTypes;
  start_date: string | null;
  end_date: string | null;
  total_leakage: {
    value: number | null;
    type: string;
  };
  sessions_analised: number;
  cost_year: {
    value: number | null;
    type: string;
  };
  co2_savings: {
    value: number | null;
    type: string;
  };
  total_collected_pipeline: {
    value: number | null;
    type: string;
  };
  total_deployed_pipeline: {
    value: number | null;
    type: string;
  };
  actual_collected_pipeline: {
    value: number | null;
    type: string;
  };
  actual_deployed_pipeline: {
    value: number | null;
    type: string;
  };
  deployed_sessions: number;
  collected_sessions: number;
  target_progress: number;
}

export type DmaItem = {
  id: number;
  info: InfoItem;
  branch_dma_id: number;
  name: string;
};

export type ProjectStatusReportType = {
  user_id: number;
  name: string;
  select_options: SelectOption;
  map: Map;
  summary: Summary;
  dmas: DmaItem[];
};

export interface HistoryLog {
  status_changed_from_id?: number | null;
  time_elapsed?: string | null;
  user_id?: number | null;
  datetime?: string | null;
  reference_waypoint_id?: number | null;
  scheduled_date?: string | null;
  user_name?: string | null;
  changed_to_status_name?: string | null;
  id?: number | null;
  waypoint_id?: number | null;
  status_changed_to_id?: number | null;
  notes?: string | null;
  waypoint_name?: string | null;
  reference_waypoint?: number | null;
}

export interface WaypointInformationType {
  current_status: string | null;
  dma_key: string | null;
  last_scheduled_engineer: string | null;
  waypoint_name: string | null;
  surface_type: string | null;
  estimated_size_client: number | null;
  estimated_size_engineer: number | null;
  estimated_size: number | null;
  repair_time: string | null;
  comment: string | null;
  environmental_notes: string | null;
  pipe_material: string | null;
  days_from_last_update: number | null;
  predicted_leak_type: string | null;
  actual_leak_type: string | null;
  AI_size: string | null;
  address: string | null;
  what3words: string | null;
  CNS: number | null;
  map_info: WaypointMapInfo;
  session_ids: string[];
  history_log: HistoryLog[];
  overnight: boolean;
  top_sound: boolean;
  correlation: boolean;
  scheduled: boolean;
  marked_up: boolean;
  repair_scheduled: boolean;
  repaired: boolean;
  link_to_assets: string[];
  validated: boolean;
}

export interface WaypointMapInfo {
  area_of_interest: MapData;
  leak_location: MapData;
  Assets?: MapData;
  Pipes?: MapData;
  sessions?: MapData;
}

export interface SessionDataWithArray {
  type: string;
  urls: string[] | null;
  timeline: string[] | null;
}

export interface SessionData {
  type: string;
  urls: string;
  timeline: string;
}

export interface SessionInformationType {
  address: string | null;
  assets: string[];
  bug_id: string | null;
  collected_engineer: string | null;
  company: string | null;
  created: string | null;
  current_status: string | null;
  last_update: string | null;
  days_from_last_update: number;
  deployed_engineer: string | null;
  dma_key: string | null;
  end_time: string | null;
  expected_finish: string | null;
  fido_result: string | null;
  latitude: number | null;
  leak_size: string | null;
  location: string | null;
  longitude: number | null;
  map_info: MapData;
  number_of_recording: number;
  postcode: string | null;
  relay_info?: {
    status: 'on' | 'off';
    device: 'portal' | 'auto';
  };
  session_id: string | null;
  start_time: string | null;
  waypoint_name: string | null;
  type: string | null;
  session_data: (SessionDataWithArray | SessionData)[] | null;
  what3words: string | null;
}

export interface DropdownType {
  sessions: { id: string; status: string; end_time: string; type: string }[];
  consumption_profile_ids: number[];
  topsound_ids: number[];
  correlations: { id: number; session_a: string; session_b: string }[];
  jobs: { id: number; work_order: string }[];
}

export interface TopSoundInformationtype {
  company: string;
  engineer: string;
  HF_cutoff: number;
  LF_cutoff: number;
  list_of_recordings: number[];
  location: string;
  map_info: MapData;
  number_of_paths: number;
  postcode: string;
  spacing: number;
  starting_distance: number;
  time_of_top_sounding: number;
  what3words: string;
  top_sounding_files: {
    audio_urls: string[];
    psd_urls: string[];
    top_urls: string[];
  };
}

export interface CorrelationInformationType {
  correlation_files: {
    correlation_urls: string[];
  };
  company: string | null;
  engineer: string | null;
  correlation_outcome:
    | 'detected'
    | 'Suspicious pipe info or its Out of bracket towards point A'
    | 'Suspicious pipe info or its Out of bracket towards point B'
    | 'Inconclusive'
    | 'inconclusive'
    | 'Central position suspected'
    | 'Not found'
    | 'not_found'
    | null;
  leak_distance: string | null;
  location_point_a: string | null;
  location_point_b: string | null;
  map_info: MapData | null;
  pipe_segments: PipeSegments[];
  session_data: sessionData[];
  session_files: {
    audio_urls: string[];
    psd_urls: string[];
    pollen_urls: string[];
    stft_urls: string[];
    correlation_urls: string[];
  };
  session_ids: string[];
  speed_of_sound: number;
  timestamp: string;
  total_length: number;
}

export interface sessionData {
  session_id: string;
  files: files[];
}

export interface files {
  timeline: string[];
  type: string;
  urls: string[];
}

interface PipeSegments {
  pipe_seg_diameter: number;
  pipe_seg_length: number;
  pipe_seg_material: string;
}

export interface ConsumptionProfileInformationType {
  collected_engineer: string | null;
  company: string | null;
  deployed_engineer: string | null;
  end_time: string | null;
  expected_samples: number | null;
  id: number | null;
  infinite_mode: boolean | null;
  link: {
    graph_urls: string[];
    csv_url: string[];
  } | null;
  location: string | null;
  map_info: MapData | null;
  postcode: string | null;
  start_time: string | null;
  uploaded_samples: number | null;
  what3words: string | null;
}

export interface LeakEngineerDayInfo {
  waypoints_investigated: number | null;
  leaks_marked_up: number | null;
  non_leaks_marked_up: number | null;
  in_progress_waypoints: number | null;
  not_found_waypoints: number | null;
  conversion_rate: number | null;
  start_time: string;
  end_time: string;
}

export interface BugDeploymentDayInfo {
  KM_covered: {
    value: number | null;
    type: string;
  };
  distance_per_hour: {
    value: number | null;
    type: 'Km';
  };
  actions_per_hour: number | null;
  collections: number | null;
  deployment_accuracy: number | null;
  deployments: number | null;
  end_time: string;
  hours_worked: number | null;
  start_time: string;
}

export interface LeakEngineerInfo {
  [date: string]: LeakEngineerDayInfo | null;
  total: LeakEngineerDayInfo | null;
}

export interface BugDeploymentInfo {
  [date: string]: BugDeploymentDayInfo | null;
  total: BugDeploymentDayInfo | null;
}

export type DeliveryPerformanceLeakEngineerType =
  DeliveryPerformanceLeakEngineerTypes[];
export interface DeliveryPerformanceLeakEngineerTypes {
  id: number;
  name: string;
  info: LeakEngineerInfo;
}

interface DeliveryPerformanceBugDeploymentEngineerTypes {
  id: number;
  name: string;
  info: BugDeploymentInfo;
}

export type DeliveryPerformanceBugDeploymentEngineerType =
  DeliveryPerformanceBugDeploymentEngineerTypes[];

interface DMAS {
  name: string;
  id: string;
}

interface Engineers {
  name: string;
  id: string;
}

export interface DeliveryPerformanceDropdownType {
  partners: Partners[];
  companies: Companies[];
  projects: Projects[];
  dmas: DMAS[];
  engineers: Engineers[];
}

export interface getDeliveryPerformanceDropdownQuery {
  partner_id?: number;
  company_id?: number;
}

interface Partners {
  id: string;
  name: string;
}

interface Companies {
  id: string;
  name: string;
}
interface Projects {
  id: string;
  name: string;
}

export interface DMAPopUpType {
  confirmed_leaks: number;
  id: number;
  name: string;
  region: string;
}

export interface WaypointInfoQuery {
  partner_id?: number;
  company_id?: number;
  waypoint_search?: string;
  status_id?: number;
  size?: string;
  date_to?: string;
  date_from?: string;
  dma_id?: number;
  project_id?: number;
  page?: number;
  perPage?: number;
}
export interface WaypointInfoResponse {
  count: number;
  data: WaypointInfoData;
}

export interface SessionInfo {
  session_id: string;
  bug_id: string | null;
  timestamp: string | null;
  outcome: string | null;
}
export interface AssetSessionsInfoResponse {
  id: number;
  standardised_asset_type: string | null;
  session_info: SessionInfo[] | [];
}

export interface getAssetSessionsInfoQuery {
  asset_id: number | null;
}

export interface WaypointInfoData {
  id: number;
  data: WaypointInfo[];
}

export interface WaypointInfo {
  id: number;
  name: string;
  size: string;
  status: string;
  largest: boolean;
  latest: boolean;
  engineer_name: string;
  date_updated: string;
  date_created: string;
}

export type WaypointJob = {
  leak_type_id: number;
  w3w: string;
  date_raised_externally: string;
  dma_id: number;
  id: number;
  leak_type: string;
  surface_type: string;
  last_scheduled_engineer_id: number;
  dma_key: string;
  waypoint_id: number;
  leak_estimated_size: number | null;
  estimate_leak_size: number;
  last_scheduled_engineer: string;
  dma_name: string;
  agreed_flow_reduction: number;
  material: string;
  branch_dma_id: number;
  dma_long: string;
  job_status: string;
  created_at: string;
  pipe_diameter: number;
  branch_id: number;
  comment: string;
  updated_at: string;
  environmental_notes: string;
  branch_name: string;
  work_order?: string;
  address: string;
  road_speed: number;
  company_id: number;
  repair_date: string;
  date_raised_internally: string;
  urls: string[];
};

export type WaypointJobRequestBody = {
  [key: string]: unknown;
  id: string;
  page: number;
  per_page: number;
  sorted_by: string;
  sorted_order: TableSortOrders;
  search: string;
  filter: IJobFilter;
  date_type: string;
  date_to: string;
  date_from: string;
};

interface PortalCompanies {
  id: number;
  name: string;
}

export interface PortalPartnersResponse {
  id: number;
  name: string;
  companies: PortalCompanies[] | [];
}

export interface getPostProjectReportQuery {
  file_format: string;
  include_waypoints: string;
}

export interface getDMAPopupQuery {
  dma_key: string;
  date_from?: string;
  date_to?: string;
}

export interface postSessionRelocation {
  sessionId: string;
  assetId: string;
}

export interface getDeliveryPerformanceQuery {
  branch_id?: number;
  company_id?: number;
  partner_id?: number;
  dma_id?: number;
  start_date?: string;
  end_date?: string;
}

export interface WaypointJobQuery {
  waypoint_id: number;
}

export interface EditWaypointJobQuery {
  job_id: number;
}

export interface postWaypointJobBody {
  waypoint_job_info: {
    waypoint_id: number;
    job_status: string;
    comment: string | null;
    work_order?: string | null;
    repair_date: Date | null;
    leak_type_id: number | null;
    agreed_flow_reduction: number | null;
    road_speed: number | null;
    date_raised_internally: Date | null;
    date_raised_externally: Date | null;
    address: string | null;
    w3w: string | null;
    surface_type: string | null;
    estimate_leak_size: number | null;
    material: string | null;
    pipe_diameter: number | null;
    environmental_notes: string | null;
  };
  base64_images?: string[];
}

export interface patchWaypointJobInfo {
  job_status: string;
  comment: string;
  work_order?: string;
  repair_date: Date | null;
  leak_type_id: number;
  agreed_flow_reduction: number;
  road_speed: number;
  date_raised_internally: Date | null;
  date_raised_externally: Date | null;
  address: string;
  w3w: string;
  surface_type: string;
  estimate_leak_size: number;
  material: string;
  pipe_diameter: number;
  environmental_notes: string;
}

export interface patchWaypointJobBody {
  waypoint_job_info: patchWaypointJobInfo;
  base64_images?: string[];
  url_images_remove?: string[];
}

export interface imageUrlAndBase64 {
  url: string;
  base64: string;
}

export interface patchWaypointJobForm {
  waypoint_job_info: patchWaypointJobInfo;
  images_current: imageUrlAndBase64[];
  images_remove: string[];
}

export interface leakType {
  id: number;
  type: string;
}

export interface DistinctJobValues {
  [key: string]: string[];
  work_order: string[];
  job_status: string[];
  leak_type: string[];
}

export interface WaypointJobLogsQuery {
  [key: string]: string | number;
  page: number;
  per_page: number;
  date_from: string;
  date_to: string;
  sorted_by: string;
  sorted_order: string;
  search: string;
}

export interface WaypointJobLogChanges {
  id?: number | null;
  w3w?: string | null;
  address?: string | null;
  comment?: string | null;
  material?: string | null;
  job_status?: string | null;
  road_speed?: number | null;
  work_order?: string | null;
  repair_date?: string | null;
  waypoint_id?: number | null;
  leak_type_id?: number | null;
  surface_type?: string | null;
  pipe_diameter?: number | null;
  estimate_leak_size?: number | null;
  environmental_notes?: string | null;
  agreed_flow_reduction?: number | null;
  date_raised_externally?: string | null;
  date_raised_internally?: string | null;
  urls?: string[] | string | null;
}

export interface WaypointJobLog {
  id: number;
  job_id: number;
  waypoint_id: number;
  full_name: string;
  status: string;
  action: string;
  created_at: string;
  changes_info: {
    new: WaypointJobLogChanges | null;
    old: WaypointJobLogChanges | null;
  } | null;
  notes: null | string;
}
