import { ReactComponent as Delete } from '@/assets/icons/delete.svg';
import { ReactComponent as Gear } from '@/assets/icons/gear.svg';
import { Theme } from '@/theme';
import { Form as FormComponent } from 'formik';
import styled, { ThemeProps } from 'styled-components';

export const DeleteIcon = styled(Delete)`
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  }
`;
export const GearIcon = styled(Gear)`
  width: 23px;
  height: 20px;
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  }
`;
export const DeleteLight = styled(Delete)`
  width: 30px;
  height: 30px;
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const Form = styled(FormComponent)`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  & > * {
    max-width: 180px;
    width: 100%;
    margin-right: 20px;
  }
  & > *:last-child {
    max-width: 100%;
    margin-right: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const DataSourceContainer = styled.div`
  display: flex;
  width: 100%;

  button {
    font-size: 30px;
    margin-top: 24px;
  }
  & > * {
    margin-right: 20px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`;

export const FiltersContainer = styled.div<{
  isOverflow: boolean;
}>`
  display: grid;
  row-gap: 15px;
  max-height: 340px;
  overflow-y: ${({ isOverflow }) => (isOverflow ? 'auto' : 'initial')};
  position: relative;
`;

export const FilterItemContainer = styled.div<{
  error: boolean;
}>`
  display: flex;
  height: 100px;
  position: relative;
  ${({ error, theme }: ThemeProps<Theme> & { error: boolean }) =>
    error ? `border: 1px solid ${theme.colors.coral}` : null};
  border-radius: 4px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.body};
`;

export const FilterItem = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px 0 5px 10px;
  border-radius: 4px 0 0 4px;
  margin-right: 10px;
  width: 100%;
  & > * {
    max-width: 165px;
    width: 100%;
  }
`;

export const FilterDeleteContainer = styled.div`
  margin-right: 8px;
  border-radius: 0 4px 4px 0;
  transition: 0.3s ease width;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FilterDelete = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
`;

export const ButtonContainer = styled.div`
  align-self: end;
  margin-top: 30px;
`;
