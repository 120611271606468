import { Button, TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { usePostUploadHealthCheckUrlMutation } from '@/redux/portalProjects/portalProjects.api';
import { Field, Formik, Form } from 'formik';
import React, { FC, useState } from 'react';
import * as Styled from './style';
import StatusModal from '@/components/StatusModal/StatusModal';

interface Params {
  health_check_url: string;
}

interface UploadHealthCheckButtonProps {
  projectId: number;
}

const UploadHealthCheckButton: FC<UploadHealthCheckButtonProps> = ({
  projectId,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { prefixedT } = useCustomTranslation('UPLOAD_HEALTH_CHECK');
  const [mutate, { isError, isSuccess, reset }] =
    usePostUploadHealthCheckUrlMutation();

  const handleToggle = () => {
    setOpen(!open);
    reset();
  };

  const handleSubmit = async (values: Params) => {
    await mutate({
      id: projectId,
      body: values.health_check_url,
    });
  };

  const initValues = { health_check_url: '' };
  return (
    <>
      <Button testId="health-check-btn" onClick={handleToggle}>
        <Styled.ReportLinks>{prefixedT('BTN')}</Styled.ReportLinks>
      </Button>

      {open && (
        <>
          <Styled.Underlay onClick={() => setOpen(false)} />
          <Styled.DialogBox open>
            {!isError && !isSuccess && (
              <Formik<Params>
                enableReinitialize
                initialValues={initValues}
                onSubmit={handleSubmit}
              >
                {({ values, errors }) => (
                  <Form>
                    <Styled.OptionTitle>
                      {prefixedT('MODAL.TITLE')}
                    </Styled.OptionTitle>
                    <Styled.OptionDesc>
                      {prefixedT('MODAL.DESC')}
                    </Styled.OptionDesc>

                    <Field
                      as={TextField}
                      required
                      name="health_check_url"
                      testId="health_check_url"
                      error={errors.health_check_url}
                      label={prefixedT('MODAL.INPUT.LABEL')}
                    />

                    <Styled.BtnContainer>
                      <Button
                        disabled={values.health_check_url.length < 1}
                        type="submit"
                        width="200px"
                        height="50px"
                      >
                        {prefixedT('MODAL.BTN.SUBMIT')}
                      </Button>
                    </Styled.BtnContainer>
                  </Form>
                )}
              </Formik>
            )}
            {isError && (
              <StatusModal
                isSuccess={isSuccess}
                title={prefixedT('MODAL.ERROR.TITLE')}
                desc={prefixedT('MODAL.ERROR.DESC')}
                handleClose={handleToggle}
              />
            )}
            {isSuccess && (
              <StatusModal
                isSuccess={isSuccess}
                title={prefixedT('MODAL.SUCCESS.TITLE')}
                desc={prefixedT('MODAL.SUCCESS.DESC')}
                handleClose={handleToggle}
              />
            )}
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default UploadHealthCheckButton;
