import React, { createContext, FC, useEffect, useState } from 'react';
import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
export interface DateFilters {
  dateFrom: Date;
  dateTo: Date;
}

export interface SessionRelocationObject {
  sessionId: string;
  assetId: string;
  assetKey: string;
}

export const defaultSessionRelocationValues: SessionRelocationObject = {
  sessionId: '',
  assetId: '',
  assetKey: '',
};

export const defaultFilterMapValues: FilterName[] = [
  'WAYPOINTS',
  'DMAS',
  'PIPES',
  'ASSETS',
  'IN_PROGRESS_SESSION',
  'AWAITING_UPLOAD_SESSION',
  'LARGE_LEAK_SESSION',
  'MEDIUM_LEAK_SESSION',
  'SMALL_LEAK_SESSION',
  'NON_LEAK_SESSION',
  'ODL',
];

export type FilterName =
  | 'WAYPOINTS'
  | 'DMAS'
  | 'PIPES'
  | 'ASSETS'
  | 'IN_PROGRESS_SESSION'
  | 'AWAITING_UPLOAD_SESSION'
  | 'LARGE_LEAK_SESSION'
  | 'MEDIUM_LEAK_SESSION'
  | 'SMALL_LEAK_SESSION'
  | 'NON_LEAK_SESSION'
  | 'ODL';

export interface Status {
  id: number;
  name: string;
  selected: boolean;
}
export interface FilterAttributes {
  [key: string]:
    | string
    | { Small: boolean; Medium: boolean; Large: boolean }
    | { [key: string]: boolean }
    | Status[];
  predicted_size: {
    Small: boolean;
    Medium: boolean;
    Large: boolean;
  };
  status: Status[];
}

export interface MapFilters {
  filters: string[];
}
export interface FilterContextProps {
  mapFilters: MapFilters;
  setMapFilters: (values: MapFilters) => void;
  dateFilters: DateFilters;
  setDateFilters: (values: DateFilters) => void;
  filterAttributes: FilterAttributes;
  setFilterAttributes: React.Dispatch<React.SetStateAction<FilterAttributes>>;
  filterMapValues: FilterName[];
  setFilterMapValues: React.Dispatch<React.SetStateAction<FilterName[]>>;
  DMASearch: string;
  setDMASearch: React.Dispatch<React.SetStateAction<string>>;
  waypointSearch: string;
  setWaypointSearch: React.Dispatch<React.SetStateAction<string>>;
  sessionRelocationObject: SessionRelocationObject;
  setSessionRelocationObject: React.Dispatch<
    React.SetStateAction<SessionRelocationObject>
  >;
}

export const FilterContext = createContext<FilterContextProps>({
  mapFilters: {
    filters: ['DMAS'],
  },
  setMapFilters: () => [],
  dateFilters: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  setDateFilters: () => {},
  filterAttributes: {
    predicted_size: {
      Small: false,
      Medium: false,
      Large: false,
    },
    status: [],
  },
  setFilterAttributes: () => {},
  filterMapValues: [],
  setFilterMapValues: () => [],
  DMASearch: '',
  setDMASearch: () => '',
  waypointSearch: '',
  setWaypointSearch: () => '',
  sessionRelocationObject: defaultSessionRelocationValues,
  setSessionRelocationObject: () => {},
});

interface FilterProviderProps {
  children: React.ReactNode;
}

export const defaultDaysBeforeToday = 14;

const StatusCodec = t.type({
  id: t.number,
  name: t.string,
  selected: t.boolean,
});

const FilterAttributesCodec = t.type({
  predicted_size: t.type({
    Small: t.boolean,
    Medium: t.boolean,
    Large: t.boolean,
  }),
  status: t.array(StatusCodec),
});

export const FilterProvider: FC<FilterProviderProps> = ({ children }) => {
  const currentDate = new Date();
  const previousDate = new Date();

  previousDate.setDate(currentDate.getDate() - defaultDaysBeforeToday);
  const isoString = previousDate.toISOString();
  const formattedDate = new Date(isoString);

  const [DMASearch, setDMASearch] = useState<string>('');
  const [waypointSearch, setWaypointSearch] = useState<string>('');
  const [filterMapValues, setFilterMapValues] = useState<FilterName[]>(
    defaultFilterMapValues,
  );
  const [sessionRelocationObject, setSessionRelocationObject] =
    useState<SessionRelocationObject>(defaultSessionRelocationValues);

  const [filterAttributes, setFilterAttributes] = useState<FilterAttributes>(
    () => {
      const storedFilterAttributes = localStorage.getItem('filterAttributes');
      if (storedFilterAttributes) {
        const parsedFilterAttributes = JSON.parse(
          storedFilterAttributes,
        ) as FilterAttributes;
        const validationResult = FilterAttributesCodec.decode(
          parsedFilterAttributes,
        );

        if (isRight(validationResult)) {
          return parsedFilterAttributes;
        } else {
          localStorage.removeItem('filterAttributes');
        }
      }

      return {
        predicted_size: {
          Small: false,
          Medium: false,
          Large: false,
        },
        status: [],
      };
    },
  );

  const [mapFilters, setMapFilters] = useState<MapFilters>(() => {
    const storedMapFilters = localStorage.getItem('mapFilters');
    if (storedMapFilters) {
      return JSON.parse(storedMapFilters) as MapFilters;
    } else {
      return { filters: ['DMAS'] };
    }
  });

  const [dateFilters, setDateFilters] = useState<DateFilters>(() => {
    const storedDateFilters = localStorage.getItem('dateFilters');

    if (storedDateFilters) {
      const parsedDates = JSON.parse(storedDateFilters) as DateFilters;
      try {
        const parsedDatesObject = {
          dateFrom: new Date(parsedDates.dateFrom),
          dateTo: new Date(parsedDates.dateTo),
        };
        return parsedDatesObject;
      } catch (e) {
        localStorage.removeItem('dateFilters');
      }
    }

    return {
      dateFrom: formattedDate,
      dateTo: new Date(),
    };
  });

  useEffect(() => {
    localStorage.setItem('filterAttributes', JSON.stringify(filterAttributes));
  }, [filterAttributes]);

  useEffect(() => {
    localStorage.setItem('dateFilters', JSON.stringify(dateFilters));
  }, [dateFilters]);

  useEffect(() => {
    localStorage.setItem('mapFilters', JSON.stringify(mapFilters));
  }, [mapFilters]);

  return (
    <FilterContext.Provider
      value={{
        filterMapValues,
        setFilterMapValues,
        mapFilters,
        setMapFilters,
        dateFilters,
        setDateFilters,
        filterAttributes,
        setFilterAttributes,
        DMASearch,
        setDMASearch,
        waypointSearch,
        setWaypointSearch,
        sessionRelocationObject,
        setSessionRelocationObject,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
