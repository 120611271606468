import {
  ErrorModalText,
  FileSelectedValues,
  SelectedValue,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import { useGetUniqueValuesQuery } from '@/redux/gisUpload/gisUpload.api';
import * as Styled from './style';
import React, { FC, useEffect, useMemo } from 'react';
import { addOrReplaceError } from '@/containers/GISUpload/utils';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  createDropdown,
  standardising,
  updateMappedFields,
} from './Utils/utils';
import { Loader } from '@/components';
import SystemDropDown from './Components/RenderDropdown/SystemDropDown';
import { GIS_COLUMN_MAPPING } from '@/types/gisUpload.type';

interface SystemFieldsProps {
  file: FileSelectedValues;
  selectedFileValue: SelectedValue;
}

const SystemFields: FC<SystemFieldsProps> = ({ file, selectedFileValue }) => {
  const { t, prefixedT } = useCustomTranslation('SYSTEM_FIELDS');
  const {
    selectedValues,
    setMappedSystemFields,
    setIsError,
    isError,
    companyId,
    uploadId,
    setIsLoading,
    setSelectedValues,
  } = useGISUploadValues();

  const filename = file.file;

  const {
    data,
    isError: UniqueValuesError,
    isFetching,
  } = useGetUniqueValuesQuery(
    {
      companyId,
      uploadId,
      filename,
      columnTitle: selectedFileValue.name,
      standardising: standardising(selectedFileValue),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (UniqueValuesError) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.DEFAULT_ERROR_MESSAGE),
      );
    }
  }, [UniqueValuesError]);

  const dropdownOptions = createDropdown(selectedFileValue, data);

  const selectedFileValues = useMemo(() => {
    if (file && data && selectedFileValue.type) {
      updateMappedFields(
        selectedFileValue.type,
        file,
        data,
        setMappedSystemFields,
      );
    }

    return selectedValues.find((f) => f.file === filename);
  }, [selectedValues, file, data, filename]);

  const cellValues = useMemo((): number => {
    switch (selectedFileValue.type) {
      case GIS_COLUMN_MAPPING.MATERIAL:
        return (file?.material && file?.material.length) || 0;
      case GIS_COLUMN_MAPPING.ASSET_TYPE:
        return (file?.asset_type && file?.asset_type.length) || 0;
      case GIS_COLUMN_MAPPING.PIPE_TYPE:
        return (file?.pipe_type && file?.pipe_type.length) || 0;
      case GIS_COLUMN_MAPPING.STATUS:
        return (file?.status && file?.status.length) || 0;
      case GIS_COLUMN_MAPPING.UNITS:
        return Array.isArray(file?.units) ? file.units.length : 0;
      default:
        return 0;
    }
  }, [file, selectedFileValue]);

  return (
    <>
      {isFetching ? (
        <Styled.LoadContainer>
          <Loader />
        </Styled.LoadContainer>
      ) : (
        <Styled.Wrapper>
          {data?.unique_values?.length !== 0 ? (
            <>
              <Styled.TextContainer>
                <Styled.Title>
                  {t('PROCESS_FILES.MAP_FIELD_TITLE', {
                    title: selectedFileValue.title,
                  })}
                </Styled.Title>
                <Styled.CellText
                  error={
                    cellValues !== data?.unique_values?.length &&
                    isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)
                  }
                >
                  <Styled.BoldTxt>{selectedFileValue.title}</Styled.BoldTxt>{' '}
                  {prefixedT('CELLS_FILLED')} {cellValues}/
                  {data?.unique_values?.length}
                </Styled.CellText>
                {isError?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC) && (
                  <Styled.CellText
                    error={isError?.includes(
                      ErrorModalText.SYSTEM_FIELD_NUMERIC,
                    )}
                  >
                    {prefixedT('ERROR.NUMERIC_UNIQUE_FIELD')}
                  </Styled.CellText>
                )}
              </Styled.TextContainer>
              <table cellSpacing="0">
                <Styled.Thread>
                  <tr>
                    <Styled.Th>{prefixedT('ROW_1')}</Styled.Th>
                    <Styled.Th>{prefixedT('ROW_2')}</Styled.Th>
                  </tr>
                </Styled.Thread>
                <Styled.Tbody>
                  {data?.unique_values?.map((el: string, key: number) => (
                    <Styled.TR key={key}>
                      <Styled.TD>{el}</Styled.TD>
                      <Styled.TD>
                        <SystemDropDown
                          keyIndex={key}
                          el={el}
                          selectedFileValues={selectedFileValues}
                          selectedFileValue={selectedFileValue}
                          dropdownOptions={dropdownOptions}
                          filename={filename}
                          data={data}
                          setIsError={setIsError}
                          isError={isError}
                          setSelectedValues={setSelectedValues}
                        />
                      </Styled.TD>
                    </Styled.TR>
                  ))}
                </Styled.Tbody>
              </table>
            </>
          ) : (
            <Styled.CellText
              error={isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)}
            >
              {prefixedT('UNIQUE_VALUES_NOT_FOUND')}
            </Styled.CellText>
          )}
        </Styled.Wrapper>
      )}
    </>
  );
};

export default SystemFields;
