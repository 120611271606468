import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const SelectedItemWrapper = styled.div`
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const SelectedItemContainer = styled.div<{
  duplicateColumn?: boolean;
}>`
  display: flex;
  background-color: ${({
    theme,
    duplicateColumn,
  }: ThemeProps<Theme> & { duplicateColumn?: boolean }) =>
    duplicateColumn ? theme.colors.artyClickRed : '#19191c'};
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
  font-weight: 500;
  justify-content: space-between;
  width: fit-content;
`;

export const Items = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
`;

export const Close = styled.a<{
  duplicateColumn?: boolean;
}>`
  cursor: pointer;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  :hover {
    background-color: ${({
      theme,
      duplicateColumn,
    }: ThemeProps<Theme> & { duplicateColumn?: boolean }) =>
      duplicateColumn ? theme.colors.artyClickRed : theme.colors.blue};
  }
`;

export const SelectedItemTitle = styled.h2`
  margin-bottom: 10px;
`;
