import React, { FC } from 'react';
import SearchIcon from '@/assets/icons/search/magnifying-glass.png';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface SearchProps {
  placeholder: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testid?: string;
}

const Search: FC<SearchProps> = ({ testid, handleSearch, placeholder }) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.SEARCH');
  const preventEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Styled.Container>
      <Styled.Input
        type="search"
        id="search-bar"
        data-testid={testid}
        placeholder={placeholder}
        onChange={handleSearch}
        onKeyDown={preventEnterKeyPress}
        autoComplete="off"
      />
      <Styled.Icon>
        <Styled.Img src={SearchIcon as string} alt={prefixedT('IMG.ALT')} />
      </Styled.Icon>
    </Styled.Container>
  );
};

export default Search;
