import { PasswordValidator } from '@/utils/validators';
import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
  password: PasswordValidator.required('VALIDATION.REQUIRED'),
  repeat: PasswordValidator.required('VALIDATION.REQUIRED').oneOf(
    [Yup.ref('password')],
    'VALIDATION.PASSWORD.CONFIRM',
  ),
});
