import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Tabs = styled.div`
  width: 100%;
  border-bottom: 1px solid
    ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const Tab = styled.div<{
  selected: boolean;
  disabled?: boolean;
  count: number;
}>`
  width: ${({ count }) => `calc(100% / ${count})`};
  padding-bottom: 20px;
  align-self: flex-end;
  border-bottom: ${({
    selected,
    theme,
  }: ThemeProps<Theme> & { selected: boolean }) =>
    selected ? `2px solid ${theme.colors.blue}` : '2px solid transparent'};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'auto')};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-shadow: ${({ selected }) =>
    selected ? '0px 0px 1px black' : 'initial'};
  color: ${({ disabled, theme }: ThemeProps<Theme> & { disabled?: boolean }) =>
    !disabled ? `${theme.colors.black}` : `${theme.colors.lightGray}`};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  margin-right: 40px;
  text-align: center;
  transition: 0.3s ease border-bottom;
  :hover {
    border-bottom: 2px solid
      ${({ selected, theme }: ThemeProps<Theme> & { selected: boolean }) =>
        !selected ? theme.colors.sky : theme.colors.blue};
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) =>
      theme.breakpoints.xl}) {
    margin-right: 30px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
    margin-right: 20px;
  }
`;
