import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDMAPopupQuery } from '@/redux/reports/reports.api';
import React, { FC } from 'react';
import * as Styled from './style';

interface SessionInformationProps {
  id: string;
}

const SessionInformation: FC<SessionInformationProps> = ({ id }) => {
  const { data, isError } = useGetDMAPopupQuery({ dma_key: id });
  const { t, prefixedT } = useCustomTranslation(
    'COMPONENTS.SESSION_INFORMATION',
  );

  if (isError) {
    return <p>{t('SERVER_ERRORS.-1')}</p>;
  }

  return (
    <Styled.SessionContainer>
      {data && (
        <>
          <h2>
            {prefixedT('TITLE')}: {data.id}
          </h2>
          <Styled.List>
            <Styled.ListItem>
              {prefixedT('NAME')}: {data.name}
            </Styled.ListItem>
            <Styled.ListItem>
              {prefixedT('REGION')}: {data.region}
            </Styled.ListItem>
            <Styled.ListItem>
              {prefixedT('CONFIRMED_LEAKS')}: {data.confirmed_leaks}
            </Styled.ListItem>
          </Styled.List>
        </>
      )}
    </Styled.SessionContainer>
  );
};

export default SessionInformation;
