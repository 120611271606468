import { LngLatLike } from 'mapbox-gl';
import { MapElement } from './types';

export const getBoundingBox = (data: MapElement[]) => {
  if (!data.length) {
    return null;
  }

  let minLng = Number.POSITIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;
  let minLat = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;

  for (const value of data) {
    if (
      value.geometry &&
      Array.isArray(value.geometry[0]) &&
      value.geometry[0].length === 2
    ) {
      const [lng, lat] = value.geometry[0];
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
    }
  }

  const boundingBox = [
    [minLng, minLat],
    [maxLng, maxLat],
  ];

  return boundingBox as [LngLatLike, LngLatLike];
};

export default getBoundingBox;
