import styled, { ThemeProps } from 'styled-components';
import { Theme } from '@/theme';

export const Container = styled.div``;

export const DropdownContainer = styled.div`
  margin-bottom: 10px;
`;

export const DropdownLabel = styled.p<{
  error: boolean;
}>`
  margin-bottom: 10px;
  color: ${({ theme, error }: ThemeProps<Theme> & { error: boolean }) =>
    theme.colors[error ? 'artyClickRed' : 'white']};
`;
