import { Button, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  AddUserBody,
  useAddUserMutation,
} from '@/redux/portalUsers/portalUsers.api';
import { UserRole } from '@/types/portalUsers.type';
import { EmailValidator } from '@/utils/validators';
import { Field, Formik } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { PartnerAndCompany } from '../UserManagement';
import * as Styled from './style';

interface AddUserValues {
  first_name: string;
  last_name: string;
  email: string;
  partner_id: number | null;
  company_id: number | null;
  role_id: number | null;
}

interface AddUserProps {
  roles: UserRole[] | undefined;
  partnersAndCompanies: PartnerAndCompany;
}

const AddUser: FC<AddUserProps> = ({ roles, partnersAndCompanies }) => {
  const [mutate, { isSuccess, isLoading, isError, error }] =
    useAddUserMutation();

  const partnerRoleIds = [2, 3, 4, 5];
  const companyRoleIds = [10, 11, 12, 13, 14];
  const convertedRoles = roles?.map((role) => ({
    value: role.id.toString(),
    label: role.role_name,
  })) as DropDownOption[];

  const { t, prefixedT } = useCustomTranslation('ADD_USER');
  const onSubmit = (values: AddUserValues) => {
    const addRedirectValue: AddUserBody = {
      ...values,
      redirectUrl: `${window.location.origin}/auth/recovery`,
    };
    mutate({ body: addRedirectValue });
  };

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('FORM.VALIDATION.FIRST_NAME')),
    last_name: Yup.string().required(t('FORM.VALIDATION.LAST_NAME')),
    email: EmailValidator.required(t('FORM.VALIDATION.EMAIL')),
    role_id: Yup.number().required(t('FORM.VALIDATION.ROLE')),
  });

  const initialValues = useMemo(
    (): AddUserValues => ({
      first_name: '',
      last_name: '',
      email: '',
      partner_id: null,
      company_id: null,
      role_id: null,
    }),
    [],
  );

  const partnerAdminId = 2;
  const companyAdminId = 10;

  const isCompanyAdmin = roles?.some((role) => role.id === companyAdminId);
  const isPartnerAdmin = roles?.some((role) => role.id === partnerAdminId);

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
      </Styled.TitleContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, submitForm, setFieldValue }) => {
          useEffect(() => {
            if (values.role_id === 1) {
              setFieldValue('partner_id', null);
              setFieldValue('company_id', null);
              return;
            }
            if (values.role_id) {
              if (partnerRoleIds.includes(values.role_id)) {
                setFieldValue('company_id', null);
                return;
              }
              if (companyRoleIds.includes(values.role_id)) {
                setFieldValue('partner_id', null);
                return;
              }
            }
          }, [values.role_id]);

          return (
            <div>
              <Styled.FormRow>
                <Field
                  as={TextField}
                  required
                  name="first_name"
                  testId="first-name"
                  error={errors.first_name}
                  label={t('FORM.LABEL.FIRST_NAME')}
                />
                <Field
                  as={TextField}
                  required
                  name="last_name"
                  testId="last-name"
                  error={errors.last_name}
                  label={t('FORM.LABEL.LAST_NAME')}
                />
                <Field
                  as={TextField}
                  required
                  testId="email"
                  name="email"
                  error={errors.email}
                  label={t('FORM.LABEL.EMAIL')}
                />
                <Field
                  as={DropDown}
                  required
                  styles={{ border: true }}
                  options={convertedRoles as DropDownOption | DropDownOption[]}
                  label={t('FORM.LABEL.ROLE')}
                  placeholder={t('FORM.PLACEHOLDER.ROLE')}
                  onChange={(option: DropDownOption) =>
                    setFieldValue('role_id', Number(option.value))
                  }
                  countOptions={4}
                  error={errors.role_id?.toString()}
                  value={values.role_id?.toString()}
                  testId="role"
                />
                {values.role_id !== 1 && (
                  <>
                    {isPartnerAdmin &&
                      values.role_id &&
                      partnerRoleIds.includes(values.role_id) && (
                        <Field
                          as={DropDown}
                          required={
                            values.role_id &&
                            partnerRoleIds.includes(values.role_id)
                          }
                          styles={{ border: true }}
                          options={
                            partnersAndCompanies.partners as
                              | DropDownOption
                              | DropDownOption[]
                          }
                          label={t('FORM.LABEL.PARTNER')}
                          placeholder={t('FORM.PLACEHOLDER.PARTNER')}
                          onChange={(option: DropDownOption) =>
                            setFieldValue('partner_id', Number(option.value))
                          }
                          countOptions={4}
                          error={errors.partner_id?.toString()}
                          value={values.partner_id?.toString()}
                          testId="partner"
                        />
                      )}

                    {isCompanyAdmin &&
                      values.role_id &&
                      companyRoleIds.includes(values.role_id) && (
                        <Field
                          as={DropDown}
                          required={
                            values.role_id &&
                            companyRoleIds.includes(values.role_id)
                          }
                          styles={{ border: true }}
                          options={
                            partnersAndCompanies.companies as
                              | DropDownOption
                              | DropDownOption[]
                          }
                          label={t('FORM.LABEL.COMPANY')}
                          placeholder={t('FORM.PLACEHOLDER.COMPANY')}
                          onChange={(option: DropDownOption) =>
                            setFieldValue('company_id', Number(option.value))
                          }
                          countOptions={4}
                          error={errors.company_id?.toString()}
                          value={values.company_id?.toString()}
                          testId="company"
                        />
                      )}
                  </>
                )}
              </Styled.FormRow>
              <Styled.ButtonContainer>
                <Button
                  type="submit"
                  onClick={submitForm}
                  disabled={
                    (values.role_id !== 1 &&
                      !values.partner_id &&
                      !values.company_id) ||
                    isLoading
                  }
                >
                  {prefixedT('SUBMIT')}
                </Button>
              </Styled.ButtonContainer>
              {isSuccess && (
                <Styled.ReturnContainer>
                  <Styled.ReturnText>
                    {prefixedT('RESPONSE.SUCCESS')}
                  </Styled.ReturnText>
                </Styled.ReturnContainer>
              )}
              {isError &&
                (error && 'status' in error && error.status === 409 ? (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>
                      {t('ERROR.DUPLICATE_EMAIL')}
                    </Styled.ReturnText>
                  </Styled.ReturnContainer>
                ) : (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>{t('ERROR.REQUEST')}</Styled.ReturnText>
                  </Styled.ReturnContainer>
                ))}
            </div>
          );
        }}
      </Formik>
    </Styled.Wrapper>
  );
};

export default withPageLoader(AddUser);
