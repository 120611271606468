import { ReactComponent as Valve } from '../../assets/svgs/valve.svg';
import { ReactComponent as Circle } from '../../assets/svgs/circle.svg';
import { ReactComponent as Marker } from '../../assets/svgs/marker.svg';
import { ReactComponent as Pmv } from '../../assets/svgs/pmv.svg';
import { ReactComponent as Logger } from '../../assets/svgs/logger.svg';
import { ReactComponent as Unknown } from '../../assets/svgs/unknown.svg';
import { ReactComponent as Meter } from '../../assets/svgs/meter.svg';
import { ReactComponent as Pump } from '../../assets/svgs/pump.svg';
import { ReactComponent as Relay } from '../../assets/svgs/relay.svg';
import React from 'react';
import { Map } from 'mapbox-gl';
import * as Sentry from '@sentry/react';
import { renderToStaticMarkup } from 'react-dom/server';
import { relaySessions, sessions } from './utils';

const loadCustomIcon = (src: string) => {
  return new Promise((resolve, reject) => {
    const customIcon = new Image(24, 24);
    customIcon.onload = () => resolve(customIcon);
    customIcon.onerror = reject;
    customIcon.src = src;
  });
};

export const loadAndAddCustomIcon = async (
  map: Map,
  icon: string,
  svgValveData: string,
) => {
  try {
    const customIcon = await loadCustomIcon(
      `data:image/svg+xml;charset=utf-8,${svgValveData}`,
    );
    map.addImage(icon, customIcon as HTMLImageElement);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const processSvg = (svg: string, color: string): string => {
  const editedSvg = svg.replace(/displayColor/g, color);
  const encodedSvg = encodeURIComponent(editedSvg);
  return encodedSvg;
};

export const paintMapIcons = async (map: Map, icons: string[]) => {
  const valveSvg = renderToStaticMarkup(<Valve />);
  const circleSvg = renderToStaticMarkup(<Circle />);
  const markerSvg = renderToStaticMarkup(<Marker />);
  const pmvSvg = renderToStaticMarkup(<Pmv />);
  const loggerSvg = renderToStaticMarkup(<Logger />);
  const unknownSvg = renderToStaticMarkup(<Unknown />);
  const meterSvg = renderToStaticMarkup(<Meter />);
  const pumpSvg = renderToStaticMarkup(<Pump />);
  const relaySvg = renderToStaticMarkup(<Relay />);
  const waypoints = [
    'small_leak_waypoint',
    'medium_leak_waypoint',
    'large_leak_waypoint',
    'in_field_waypoint',
  ];

  for (const icon of icons) {
    if (!map.hasImage(icon)) {
      const [name, color] = icon.split('-');
      if (name === 'valve') {
        const svgValveData = processSvg(valveSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'hydrant') {
        const svgValveData = processSvg(circleSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'marker' || name === 'bug_deployments') {
        const svgValveData = processSvg(markerSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'pmv') {
        const svgValveData = processSvg(pmvSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'fitting') {
        const svgValveData = processSvg(circleSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'tapping') {
        const svgValveData = processSvg(circleSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'logger') {
        const svgValveData = processSvg(loggerSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'unknown') {
        const svgValveData = processSvg(unknownSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'pump') {
        const svgValveData = processSvg(pumpSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'meter') {
        const svgValveData = processSvg(meterSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (name === 'connection') {
        const svgValveData = processSvg(circleSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (sessions.includes(name)) {
        const svgValveData = processSvg(relaySvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (relaySessions.includes(name)) {
        const svgValveData = processSvg(relaySvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
      if (waypoints.includes(name)) {
        const svgValveData = processSvg(markerSvg, color);
        await loadAndAddCustomIcon(map, icon, svgValveData);
      }
    }
  }
};
