import styled from 'styled-components';

export const CurrentProgressContainer = styled.div<{
  completion: number;
  zIndex: number;
}>`
  position: absolute;
  left: calc(${({ completion }) => completion}% - 8px);
  font-size: 12px;
  bottom: -40px;
  z-index: ${({ zIndex }) => zIndex};
  cursor: pointer;
`;

export const TriangleBottom = styled.div<{
  color: string;
}>`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 13px solid ${({ color }) => color};
  cursor: pointer;
`;

export const CurrentProgressTextContainer = styled.div<{
  marginLeft: string;
}>`
  font-size: 12px;
  background: white;
  padding: 5px;
  margin-left: ${({ marginLeft }) => marginLeft};
  text-wrap: nowrap;
`;
