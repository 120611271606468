import React, { FC, useEffect } from 'react';
import * as Styled from './style';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Button, DropDown, TextField } from '@/components';
import { EmailValidator } from '@/utils/validators';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { UserRole } from '@/types/portalUsers.type';
import { PartnerAndCompany } from '../../UserManagement';
import { useEditUserMutation } from '@/redux/user/user.api';
import { isPartnerUser, isCompanyUser } from '@/utils/user';

interface EditUserProps {
  first_name: string | undefined;
  last_name: string | undefined;
  id: number | undefined;
  email: string | undefined;
  role_id: number | undefined;
  partner_id: number | undefined;
  company_id: number | undefined;
  roles: UserRole[] | undefined;
  partnersAndCompanies: PartnerAndCompany;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
}

interface EditUserValues {
  first_name: string;
  last_name: string;
  email: string;
  partner_id: number | undefined;
  company_id: number | undefined;
  role_id: number | undefined;
}

const EditUser: FC<EditUserProps> = ({
  first_name,
  last_name,
  email,
  id,
  role_id,
  partner_id,
  company_id,
  closeOverlay,
  partnersAndCompanies,
  roles,
}) => {
  const [mutate, { isSuccess, isError, reset }] = useEditUserMutation();
  const { t, prefixedT } = useCustomTranslation('EDIT_USER');
  const initialValues = {
    first_name: first_name || '',
    last_name: last_name || '',
    email: email || '',
    partner_id: partner_id || undefined,
    company_id: company_id || undefined,
    role_id: role_id || undefined,
  };

  const convertedRoles = roles?.map((role) => ({
    value: role.id.toString(),
    label: role.role_name,
  })) as DropDownOption[];

  const onSubmit = async (values: EditUserValues) => {
    if (id) {
      const formattedValues = {
        ...values,
        partner_id: isPartnerUser(values.role_id)
          ? values.partner_id
          : undefined,
        company_id: isCompanyUser(values.role_id)
          ? values.company_id
          : undefined,
      };

      await mutate({ id: id, body: formattedValues });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await closeOverlay(true);
        reset();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('FORM.VALIDATION.FIRST_NAME')),
    last_name: Yup.string().required(t('FORM.VALIDATION.LAST_NAME')),
    email: EmailValidator.required(t('FORM.VALIDATION.EMAIL')),
    role_id: Yup.number().required(t('FORM.VALIDATION.ROLE')),
    partner_id: Yup.number().when('role_id', (role_id) => {
      return isPartnerUser(role_id)
        ? Yup.number().required(t('FORM.VALIDATION.PARTNER'))
        : Yup.number().notRequired();
    }),
    company_id: Yup.number().when('role_id', (role_id) => {
      return isCompanyUser(role_id)
        ? Yup.number().required(t('FORM.VALIDATION.COMPANY'))
        : Yup.number().notRequired();
    }),
  });

  return (
    <>
      <Styled.Underlay
        data-testid={'overlay'}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          <Styled.TitleContainer>
            <h3>{prefixedT('PAGE_TITLE')}</h3>
          </Styled.TitleContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, errors, submitForm, setFieldValue }) => {
              return (
                <div>
                  <Styled.FormRow>
                    <Field
                      as={TextField}
                      required
                      name="first_name"
                      testId="first-name"
                      error={errors.first_name}
                      label={t('FORM.LABEL.FIRST_NAME')}
                    />
                    <Field
                      as={TextField}
                      required
                      name="last_name"
                      testId="last-name"
                      error={errors.last_name}
                      label={t('FORM.LABEL.LAST_NAME')}
                    />
                    <Field
                      as={TextField}
                      required
                      testId="email"
                      name="email"
                      error={errors.email}
                      label={t('FORM.LABEL.EMAIL')}
                    />
                    <Field
                      as={DropDown}
                      required
                      styles={{ border: true }}
                      options={
                        convertedRoles as DropDownOption | DropDownOption[]
                      }
                      label={t('FORM.LABEL.ROLE')}
                      placeholder={t('FORM.PLACEHOLDER.ROLE')}
                      onChange={(option: DropDownOption) =>
                        setFieldValue('role_id', Number(option.value))
                      }
                      countOptions={4}
                      error={errors.role_id?.toString()}
                      value={values.role_id?.toString()}
                      testId="role"
                    />
                    {isCompanyUser(values.role_id) && (
                      <Field
                        as={DropDown}
                        required
                        styles={{ border: true }}
                        options={
                          partnersAndCompanies.companies as
                            | DropDownOption
                            | DropDownOption[]
                        }
                        label={t('FORM.LABEL.COMPANY')}
                        placeholder={t('FORM.PLACEHOLDER.COMPANY')}
                        onChange={(option: DropDownOption) =>
                          setFieldValue('company_id', Number(option.value))
                        }
                        countOptions={4}
                        error={errors.company_id?.toString()}
                        value={values.company_id?.toString()}
                        testId="company"
                      />
                    )}
                    {isPartnerUser(values.role_id) && (
                      <Field
                        as={DropDown}
                        required
                        styles={{ border: true }}
                        options={
                          partnersAndCompanies.partners as
                            | DropDownOption
                            | DropDownOption[]
                        }
                        label={t('FORM.LABEL.PARTNER')}
                        placeholder={t('FORM.PLACEHOLDER.PARTNER')}
                        onChange={(option: DropDownOption) =>
                          setFieldValue('partner_id', Number(option.value))
                        }
                        countOptions={4}
                        error={errors.partner_id?.toString()}
                        value={values.partner_id?.toString()}
                        testId="partner"
                      />
                    )}
                  </Styled.FormRow>
                  <Styled.ButtonContainer>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await closeOverlay(false);
                        })();
                      }}
                    >
                      {prefixedT('RETURN_BTN')}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                    >
                      {prefixedT('SUBMIT_BTN')}
                    </Button>
                  </Styled.ButtonContainer>
                  {isError && (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>{t('ERROR.REQUEST')}</Styled.ErrorText>
                    </Styled.ErrorContainer>
                  )}
                </div>
              );
            }}
          </Formik>
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default EditUser;
