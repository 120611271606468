import { createBrowserRouter, useRoutes } from 'react-router-dom';
import { generateRouterObject, routes } from '.';
import { wrapUseRoutes } from '@sentry/react';
import * as Sentry from '@sentry/react';

const router = generateRouterObject(routes);

const AppRouter = () => {
  const reactRoutes = wrapUseRoutes(useRoutes);
  return reactRoutes(routes);
};

const sentryRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const appRouter = sentryRouter(router);

export default AppRouter;
