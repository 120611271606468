import React, { useMemo } from 'react';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import { addOrReplaceError } from '@/containers/GISUpload/utils';
import {
  ErrorModalText,
  FileSelectedValues,
  SelectedValue,
} from '@/Providers/GISUploadProvider';
import {
  GIS_COLUMN_MAPPING,
  UniqueValuesResponse,
} from '@/types/gisUpload.type';
import { updateSelectedValues } from '../../Utils/utils';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface SystemDropDownProps {
  keyIndex: number;
  el: string;
  selectedFileValues: FileSelectedValues | undefined;
  selectedFileValue: SelectedValue;
  dropdownOptions: DropDownOption[];
  filename: string;
  data: UniqueValuesResponse | undefined;
  setIsError: React.Dispatch<React.SetStateAction<ErrorModalText[] | null>>;
  isError: ErrorModalText[] | null;
  setSelectedValues: React.Dispatch<React.SetStateAction<FileSelectedValues[]>>;
}

const SystemDropDown: React.FC<SystemDropDownProps> = ({
  keyIndex,
  el,
  selectedFileValues,
  selectedFileValue,
  dropdownOptions,
  filename,
  data,
  setIsError,
  isError,
  setSelectedValues,
}) => {
  const { prefixedT } = useCustomTranslation('SYSTEM_FIELDS');

  const getDropdownInfo = useMemo(() => {
    if (!selectedFileValues) return '';

    switch (selectedFileValue.type) {
      case GIS_COLUMN_MAPPING.MATERIAL:
        return (
          selectedFileValues.material?.find((item) => item.title === el)
            ?.type || prefixedT('DROPDOWN.1.PLACEHOLDER')
        );
      case GIS_COLUMN_MAPPING.ASSET_TYPE:
        return (
          selectedFileValues.asset_type?.find((item) => item.title === el)
            ?.type || prefixedT('DROPDOWN.2.PLACEHOLDER')
        );
      case GIS_COLUMN_MAPPING.PIPE_TYPE:
        return (
          selectedFileValues.pipe_type?.find((item) => item.title === el)
            ?.type || prefixedT('DROPDOWN.3.PLACEHOLDER')
        );
      case GIS_COLUMN_MAPPING.STATUS:
        return (
          selectedFileValues.status?.find((item) => item.title === el)?.type ||
          prefixedT('DROPDOWN.4.PLACEHOLDER')
        );
      case GIS_COLUMN_MAPPING.UNITS:
        if (Array.isArray(selectedFileValues.units)) {
          const unit = selectedFileValues.units.find(
            (item) => item.title === el,
          );
          return unit?.type || prefixedT('DROPDOWN.5.PLACEHOLDER');
        }
        if (selectedFileValues.units) {
          return selectedFileValues.units.toString();
        }
        return prefixedT('DROPDOWN.5.PLACEHOLDER');
      default:
        return '';
    }
  }, [selectedFileValue, selectedFileValues, el]);

  const handleChange = (option: DropDownOption | DropDownOption[]) => {
    if (Array.isArray(option)) {
      return;
    }

    const valueAsNumber = parseFloat(el);

    if (!isNaN(valueAsNumber)) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.SYSTEM_FIELD_NUMERIC),
      );
      return;
    }
    setSelectedValues((prevValues) =>
      updateSelectedValues(
        prevValues,
        filename,
        keyIndex,
        option,
        data,
        selectedFileValue,
      ),
    );

    if (isError?.includes(ErrorModalText.SYSTEM_FIELD_NUMERIC)) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.SYSTEM_FIELD_NUMERIC, true),
      );
    }
    if (isError?.includes(ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED)) {
      setIsError(
        addOrReplaceError(
          isError,
          ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
          true,
        ),
      );
    }
  };

  return (
    <DropDown
      options={dropdownOptions}
      label={undefined}
      placeholder={getDropdownInfo}
      onChange={(option) => handleChange(option)}
      value={getDropdownInfo}
      styles={{
        center: true,
        background: 'transparent',
        backgroundDropdown: '#26252a',
        textColor: '#ffffff',
        dropdownTextColor: '#b3b3b3',
        dropdownFitWidth: true,
        backgroundHover: '#302e36',
      }}
    />
  );
};

export default SystemDropDown;
