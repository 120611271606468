import { Theme } from '@/theme';
import { animated } from 'react-spring';
import styled, { ThemeProps } from 'styled-components';

export const MapOptions = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  z-index: 1;
`;
