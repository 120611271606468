import React, { FC } from 'react';
import * as Styled from './style';
import moment from 'moment';
import useCustomTranslation from '@/localization/useCustomTranslation';

interface HistoryAndOvernightGraphsProps {
  history: {
    urls: string[] | null;
    timeline: string[] | null;
  };
  overnight: {
    urls: string | null;
    timeline: string | null;
  };
}

const HistoryAndOvernightGraphs: FC<HistoryAndOvernightGraphsProps> = ({
  history,
  overnight,
}) => {
  const { prefixedT } = useCustomTranslation('HISTORY_AND_OVERNIGHT_GRAPHS');
  const formatDate = (dateString: string | null) => {
    return moment(dateString).format('DD/MM/YY');
  };

  return (
    <Styled.Wrapper>
      {history.urls && history.urls.length === 0 && !overnight.urls ? (
        <Styled.ErrorContainer>
          <Styled.ErrorTxt>{prefixedT('ERROR_TXT.NO_GRAPHS')}</Styled.ErrorTxt>
        </Styled.ErrorContainer>
      ) : (
        <>
          {history.urls && history.urls.length > 0 ? (
            <Styled.HistoryContainer>
              <Styled.TitleContainer>
                <Styled.Title>{prefixedT('TITLES.HISTORY')}</Styled.Title>
              </Styled.TitleContainer>
              <Styled.GraphContainer>
                {history.urls.map((url, index) => (
                  <Styled.Graph key={index}>
                    <Styled.TimelineContainer>
                      {history.timeline && (
                        <Styled.Timeline>
                          {formatDate(history.timeline[index])}
                        </Styled.Timeline>
                      )}
                    </Styled.TimelineContainer>
                    <Styled.Image
                      src={url}
                      data-testid={'history-graph'}
                      alt={
                        history.timeline
                          ? prefixedT('IMG.ALT.HISTORICAL') +
                            ' ' +
                            formatDate(history.timeline[index])
                          : prefixedT('IMG.ALT.HISTORICAL')
                      }
                      title={
                        history.timeline
                          ? prefixedT('IMG.ALT.HISTORICAL') +
                            ' ' +
                            formatDate(history.timeline[index])
                          : prefixedT('IMG.ALT.HISTORICAL')
                      }
                    />
                  </Styled.Graph>
                ))}
              </Styled.GraphContainer>
            </Styled.HistoryContainer>
          ) : (
            <Styled.ErrorContainer>
              <Styled.ErrorTxt>
                {prefixedT('ERROR_TXT.NO_HISTORICAL_GRAPHS')}
              </Styled.ErrorTxt>
            </Styled.ErrorContainer>
          )}
          {overnight.urls ? (
            <Styled.OvernightContainer>
              <Styled.TitleContainer>
                <Styled.Title>{prefixedT('TITLES.OVERNIGHT')}</Styled.Title>
              </Styled.TitleContainer>
              <Styled.OvernightGraphContainer>
                <Styled.TimelineContainer>
                  <Styled.Timeline>
                    {formatDate(overnight.timeline)}
                  </Styled.Timeline>
                </Styled.TimelineContainer>
                <Styled.Image
                  src={overnight.urls}
                  data-testid={'overnight-graph'}
                  alt={
                    prefixedT('IMG.ALT.OVERNIGHT') +
                    ' ' +
                    formatDate(overnight.timeline)
                  }
                  title={
                    prefixedT('IMG.ALT.OVERNIGHT') +
                    ' ' +
                    formatDate(overnight.timeline)
                  }
                />
              </Styled.OvernightGraphContainer>
            </Styled.OvernightContainer>
          ) : (
            <Styled.ErrorContainer>
              <Styled.ErrorTxt>
                {prefixedT('ERROR_TXT.NO_OVERNIGHT_GRAPHS')}
              </Styled.ErrorTxt>
            </Styled.ErrorContainer>
          )}
        </>
      )}
    </Styled.Wrapper>
  );
};

export default HistoryAndOvernightGraphs;
