import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import { ReactComponent as RunIcon } from '@/assets/icons/play.svg';
import React, { FC, useMemo } from 'react';
import { TableActionTypes } from '../types';
import * as Styled from './style';

export interface IActionProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  type: TableActionTypes;
  disabled?: boolean;
}

const Action: FC<IActionProps> = ({ onClick, type, disabled = false }) => {
  const icon = useMemo(() => {
    switch (type) {
      case TableActionTypes.delete:
        return <DeleteIcon />;
      case TableActionTypes.edit:
        return <EditIcon />;
      case TableActionTypes.run:
        return <RunIcon />;
      default:
        return null;
    }
  }, [type]);
  return (
    <Styled.Container
      onClick={disabled ? () => {} : onClick}
      data-testid="table-action"
    >
      {icon}
    </Styled.Container>
  );
};

export default Action;
