import { Button, DatePicker, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import Select, { createFilter } from 'react-select';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useLazyGetUnassignedDMAsQuery,
  usePostCreateProjectMutation,
} from '@/redux/portalProjects/portalProjects.api';
import { CreateProjectBody } from '@/types/portalProject.type';
import { Field, Formik } from 'formik';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import * as Yup from 'yup';
import { PartnerAndCompany } from '../UserManagement';
import * as Styled from './style';
import theme from '@/theme';

export interface AddProjectValues {
  name: string;
  startDate: string;
  endDate: string;
  cost_per_distance: number;
  company_id: string;
  dmas: DropDownOption[];
}

interface AddProjectProps {
  partnersAndCompanies: PartnerAndCompany;
}

const AddProject: FC<AddProjectProps> = ({ partnersAndCompanies }) => {
  const [mutate, { isSuccess, isLoading, isError }] =
    usePostCreateProjectMutation();

  const [
    fetchUnassignedDMAs,
    { data: unassignedDMAsData, isSuccess: dmasIsSuccess },
  ] = useLazyGetUnassignedDMAsQuery();

  const { t, prefixedT } = useCustomTranslation('ADD_PROJECT');

  const onSubmit = async (values: AddProjectValues) => {
    const body: CreateProjectBody = {
      new_branch: {
        branch_name: values.name,
        start_date: moment(values.startDate).format('YYYY-MM-DD'),
        end_date: moment(values.endDate).format('YYYY-MM-DD'),
        cost_per_distance: values.cost_per_distance,
      },
      dmas: values.dmas.map((dma) => Number(dma.value)),
    };
    await mutate({ id: values.company_id, body });
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(prefixedT('VALIDATION.NAME')),
    startDate: Yup.date().max(
      Yup.ref('endDate'),
      t('EDIT_PROJECT.START_DATE.VALIDATION'),
    ),
    endDate: Yup.date().min(
      Yup.ref('startDate'),
      t('EDIT_PROJECT.END_DATE.VALIDATION'),
    ),
    cost_per_distance: Yup.number()
      .required(prefixedT('VALIDATION.COST_PER_KM'))
      .moreThan(0, prefixedT('VALIDATION.COST_PER_KM')),
    company_id: Yup.number().required(prefixedT('VALIDATION.COMPANY')),
  });

  const initialValues = useMemo(
    (): AddProjectValues => ({
      name: '',
      startDate: '',
      endDate: '',
      cost_per_distance: 0,
      company_id: '',
      dmas: [],
    }),
    [],
  );

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
      </Styled.TitleContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, submitForm, setFieldValue }) => (
          <div>
            <Styled.FormRow>
              <Styled.FormInput>
                <Field
                  as={TextField}
                  required
                  name="name"
                  testId="name"
                  error={errors.name}
                  label={prefixedT('NAME.LABEL')}
                />
              </Styled.FormInput>
              <Styled.FormInput>
                <Field
                  as={DropDown}
                  required={true}
                  styles={{ border: true, width: '50%' }}
                  options={
                    partnersAndCompanies.companies as
                      | DropDownOption
                      | DropDownOption[]
                  }
                  label={prefixedT('COMPANY.LABEL')}
                  placeholder={prefixedT('COMPANY.PLACEHOLDER')}
                  onChange={(option: DropDownOption) => {
                    const companyId = parseInt(option.value);
                    void setFieldValue('company_id', companyId);
                    void setFieldValue('dmas', []);
                    void fetchUnassignedDMAs({ company_id: companyId });
                  }}
                  countOptions={4}
                  error={errors.company_id?.toString()}
                  value={values.company_id?.toString()}
                  testId="company"
                />
              </Styled.FormInput>
            </Styled.FormRow>
            <Styled.FormRow>
              <Styled.FormInput>
                <Field
                  as={TextField}
                  required
                  name="cost_per_distance"
                  testId="cost_per_distance"
                  type="number"
                  error={errors.cost_per_distance}
                  label={'Cost per distance'}
                />
              </Styled.FormInput>
              <Styled.FormInput>
                <Field
                  as={DatePicker}
                  required={true}
                  name="startDate"
                  label={prefixedT('START_DATE.LABEL')}
                  placeholder={'DD/MM/YYYY'}
                  onChange={(date: Date) => setFieldValue('startDate', date)}
                  testId="start-date"
                  error={errors.startDate}
                />
              </Styled.FormInput>
              <Styled.FormInput>
                <Field
                  as={DatePicker}
                  required={true}
                  name="endDate"
                  label={prefixedT('END_DATE.LABEL')}
                  placeholder={'DD/MM/YYYY'}
                  onChange={(date: Date) => setFieldValue('endDate', date)}
                  testId="end-date"
                  error={errors.endDate}
                />
              </Styled.FormInput>
            </Styled.FormRow>
            <Styled.FormRow>
              <Styled.FormInput>
                <label data-testid="dmas">
                  {prefixedT('DMAS.LABEL')}
                  <Select
                    isMulti
                    isClearable={false}
                    id="dma-select-id"
                    inputId="dma-input-id"
                    name="dmas"
                    isDisabled={!dmasIsSuccess}
                    options={
                      unassignedDMAsData?.map((dma) => ({
                        value: `${dma.id}`,
                        label: dma.name,
                      })) || []
                    }
                    filterOption={createFilter({
                      matchFrom: 'any',
                      stringify: (option) => `${option.label}`,
                    })}
                    noOptionsMessage={() => prefixedT('DMAS.NO_MATCHING')}
                    styles={{
                      menuList: (base) => ({
                        ...base,
                        maxHeight: 200,
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        maxHeight: 100,
                        overflowY: 'auto',
                        gap: 4,
                      }),
                      multiValue: (base) => ({
                        ...base,
                        color: 'white',
                        backgroundColor: theme.colors.frenchBlue,
                        borderRadius: 10,
                        padding: 5,
                      }),
                      multiValueLabel: (base) => ({
                        ...base,
                        color: 'white',
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        cursor: 'pointer',
                      }),
                    }}
                    placeholder={prefixedT('DMAS.PLACEHOLDER')}
                    onChange={(selectedOptions) =>
                      void setFieldValue(
                        'dmas',
                        selectedOptions.toSorted((a, b) =>
                          a.label.localeCompare(b.label),
                        ),
                      )
                    }
                    value={values.dmas}
                  />
                </label>
              </Styled.FormInput>
            </Styled.FormRow>
            <Styled.ButtonContainer>
              <Button
                type="submit"
                onClick={() => {
                  void (async () => {
                    await submitForm();
                  })();
                }}
                disabled={isLoading}
              >
                {prefixedT('SUBMIT')}
              </Button>
            </Styled.ButtonContainer>
            {isSuccess && (
              <Styled.ReturnContainer>
                <Styled.ReturnText>
                  {prefixedT('RESPONSE.SUCCESS')}
                </Styled.ReturnText>
              </Styled.ReturnContainer>
            )}
            {isError && (
              <Styled.ReturnContainer>
                <Styled.ReturnText>{t('ERROR.REQUEST')}</Styled.ReturnText>
              </Styled.ReturnContainer>
            )}
          </div>
        )}
      </Formik>
    </Styled.Wrapper>
  );
};

export default withPageLoader(AddProject);
