import { createApi } from '@reduxjs/toolkit/query/react';
import { genericFetchQuery } from '../api';

export const genericFetchApi = createApi({
  reducerPath: 'genericFetch',
  baseQuery: genericFetchQuery,
  endpoints: (builder) => ({
    getFidoBlob: builder.query<string | undefined, string>({
      query: (url) => ({
        url,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useGetFidoBlobQuery, useLazyGetFidoBlobQuery } = genericFetchApi;
