import { Table } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import {
  ITableHeader,
  TableCellAlignment,
  TableSortOrders,
} from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { SelectedWaypoints } from '@/Providers/WaypointFilterProvider';
import { WaypointInfo } from '@/types/report.type';
import React from 'react';
import { Link } from 'react-router-dom';

export enum WaypointTableOrderBy {
  NAME = 'name',
  SIZE = 'size',
  STATUS = 'status',
  ENGINEER_NAME = 'engineer_name',
  DATE_CREATED = 'date_created',
  DATE_UPDATED = 'date_updated',
  SELECT_WAYPOINT = 'selectWaypoint',
}

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.WAYPOINT_TABLE');

  return [
    {
      id: 'name',
      title: prefixedT('TITLE.ROW_ONE'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'size',
      title: prefixedT('TITLE.ROW_TWO'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'status',
      title: prefixedT('TITLE.ROW_THREE'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'engineer_name',
      title: prefixedT('TITLE.ROW_FOUR'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'date_updated',
      title: prefixedT('TITLE.ROW_FIVE'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'date_created',
      title: prefixedT('TITLE.ROW_SIX'),
      sortable: true,
      align: TableCellAlignment.center,
    },
    {
      id: 'selectWaypoint',
      title: prefixedT('TITLE.ROW_SEVEN'),
      sortable: false,
      align: TableCellAlignment.center,
    },
  ];
};

export const getRowStructure = (
  waypoint: WaypointInfo,
  index: number,
  selectedWaypoints: SelectedWaypoints[],
  setSelectedWaypoints: React.Dispatch<
    React.SetStateAction<SelectedWaypoints[]>
  >,
) => {
  const isChecked = selectedWaypoints.some(
    (waypointInfo) => waypointInfo.id === waypoint.id,
  );

  const handleUpdateWaypoints = (
    waypointInfo: SelectedWaypoints,
    checked: boolean,
  ) => {
    if (checked) {
      setSelectedWaypoints([
        ...selectedWaypoints,
        { id: waypointInfo.id, name: waypointInfo.name },
      ]);
    } else {
      setSelectedWaypoints(
        selectedWaypoints.filter((Waypoint) => Waypoint.id !== waypointInfo.id),
      );
    }
  };

  return (
    <Table.Row key={index}>
      <Table.Cell align={TableCellAlignment.center} headerId="name">
        <Link
          style={{ textDecoration: 'none' }}
          target="_blank"
          to={`/app/waypoint?waypoint=${waypoint.id}`}
        >
          {waypoint.name}
        </Link>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="size">
        {waypoint.size}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="status">
        {waypoint.status}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="engineer">
        {waypoint.engineer_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="dateUpdated">
        {waypoint.date_updated}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="dateCreated">
        {waypoint.date_created}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="select">
        <input
          type="checkbox"
          data-testid={`waypoint-${waypoint.id}`}
          onChange={(e) => handleUpdateWaypoints(waypoint, e.target.checked)}
          checked={isChecked}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
  {
    value: '100',
    label: '100',
  },
];

export const sortDataAscAndDesc = (
  orderBy: WaypointTableOrderBy,
  order: TableSortOrders,
  a: WaypointInfo,
  b: WaypointInfo,
) => {
  const orderByKey = orderBy as keyof WaypointInfo;

  const compareDates = (dateA: string, dateB: string) => {
    const parsedDateA = new Date(dateA).getTime();
    const parsedDateB = new Date(dateB).getTime();

    return parsedDateA - parsedDateB;
  };

  if (orderByKey === 'date_updated' || orderByKey === 'date_created') {
    if (order === TableSortOrders.asc) {
      return compareDates(a[orderByKey], b[orderByKey]);
    } else {
      return compareDates(b[orderByKey], a[orderByKey]);
    }
  } else {
    if (order === TableSortOrders.asc) {
      return a[orderByKey] > b[orderByKey] ? 1 : -1;
    } else {
      return a[orderByKey] < b[orderByKey] ? 1 : -1;
    }
  }
};

export const toggleSelectAllWaypoints = (
  data: WaypointInfo[],
  selectedWaypoints: SelectedWaypoints[],
  setSelectedWaypoints: React.Dispatch<
    React.SetStateAction<SelectedWaypoints[]>
  >,
) => {
  const allSelected = data.every((waypoint) =>
    selectedWaypoints.some(
      (selectedWaypoint) => selectedWaypoint.id === waypoint.id,
    ),
  );

  if (allSelected) {
    const newData = selectedWaypoints.filter(
      (selectedWaypoint) =>
        !data.some((waypoint) => waypoint.id === selectedWaypoint.id),
    );
    setSelectedWaypoints(newData);
  } else {
    const newSelectedWaypoints = data.reduce((selected, waypoint) => {
      const isAlreadySelected = selectedWaypoints.some(
        (selectedWaypoint) => selectedWaypoint.id === waypoint.id,
      );
      if (!isAlreadySelected) {
        selected.push({ id: waypoint.id, name: waypoint.name });
      }
      return selected;
    }, [] as SelectedWaypoints[]);

    setSelectedWaypoints([...selectedWaypoints, ...newSelectedWaypoints]);
  }
};
