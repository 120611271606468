import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #000;
`;

export const Progress = styled.div<{
  completion: number;
  color: string;
}>`
  position: absolute;
  width: calc(${({ completion }) => completion}% - 2px);
  background: ${({ color }) => color};
  height: 28px;
`;
