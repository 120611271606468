import React from 'react';

import { Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import {
  PostSensorsResponse,
  PostRelaysResponse,
  sensorQuery,
} from '@/types/stock.type';
import useCustomTranslation from '@/localization/useCustomTranslation';

import * as Styled from './style';
import { arrayEquality } from '@/utils/array';
import { IStockFilter } from '@/types/rtk.type';

export const headers = (isSensorManagement: boolean): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE');

  if (isSensorManagement) {
    return [
      {
        id: 'select',
        title: prefixedT('SELECT_WAYPOINT'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'status',
        title: prefixedT('SENSOR.STATUS'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'bug_name',
        title: prefixedT('SENSOR.BUG_NAME'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'device_id',
        title: prefixedT('SENSOR.DEVICE_ID'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'person',
        title: prefixedT('SENSOR.PERSON'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'customer',
        title: prefixedT('SENSOR.CUSTOMER'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'bug_owner',
        title: prefixedT('SENSOR.BUG_OWNER'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'date',
        title: prefixedT('SENSOR.DATE'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'timestamp',
        title: prefixedT('SENSOR.TIMESTAMP'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'comment',
        title: prefixedT('SENSOR.COMMENT'),
        sortable: false,
        align: TableCellAlignment.center,
      },
    ];
  } else {
    return [
      {
        id: 'select',
        title: prefixedT('SELECT_WAYPOINT'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'status',
        title: prefixedT('RELAY.STATUS'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'relay-name',
        title: prefixedT('RELAY.BUG_NAME'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'imei',
        title: prefixedT('RELAY.IMEI'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'sim-id',
        title: prefixedT('RELAY.SIM'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'person',
        title: prefixedT('RELAY.PERSON'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'customer',
        title: prefixedT('RELAY.CUSTOMER'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'relay_owner',
        title: prefixedT('RELAY.BUG_OWNER'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'date',
        title: prefixedT('RELAY.DATE'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'timestamp',
        title: prefixedT('RELAY.TIMESTAMP'),
        sortable: false,
        align: TableCellAlignment.center,
      },
      {
        id: 'comment',
        title: prefixedT('RELAY.COMMENT'),
        sortable: false,
        align: TableCellAlignment.center,
      },
    ];
  }
};

export const getRowStructure = (
  isSensorManagement: boolean,
  currentStock: PostSensorsResponse | PostRelaysResponse,
  index: number,
  selectedStockIds: number[],
  setSelectedStockIds: React.Dispatch<React.SetStateAction<number[]>>,
  prefixedT: (path: string) => string,
) => {
  const isChecked = selectedStockIds.some(
    (sensorId) => sensorId === currentStock.id,
  );

  const handleUpdateStock = (currentStockId: number, checked: boolean) => {
    if (checked) {
      setSelectedStockIds([...selectedStockIds, currentStockId]);
    } else {
      setSelectedStockIds(
        selectedStockIds.filter((sensorId) => sensorId !== currentStockId),
      );
    }
  };

  if (isSensorManagement) {
    const currentSensor = currentStock as PostSensorsResponse;
    return (
      <Table.Row key={index}>
        <Table.Cell align={TableCellAlignment.center} headerId="select">
          <input
            type="checkbox"
            onChange={(e) =>
              handleUpdateStock(currentSensor.id, e.target.checked)
            }
            checked={isChecked}
          />
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="status">
          {prefixedT(currentSensor.status)}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="bug-name">
          {currentSensor.bug_name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="device-id">
          {currentSensor.device_id}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="person">
          {currentSensor.person}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="customer">
          {currentSensor.customer}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="bug-owner">
          {currentSensor.bug_owner}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="date">
          {currentSensor.date}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="timestamp">
          {currentSensor.timestamp}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="comment">
          <Styled.WrapCell>{currentSensor.comment}</Styled.WrapCell>
        </Table.Cell>
      </Table.Row>
    );
  } else {
    const currentRelay = currentStock as PostRelaysResponse;
    return (
      <Table.Row key={index}>
        <Table.Cell align={TableCellAlignment.center} headerId="select">
          <input
            type="checkbox"
            onChange={(e) =>
              handleUpdateStock(currentRelay.id, e.target.checked)
            }
            checked={isChecked}
          />
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="status">
          {prefixedT(currentRelay.status)}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="relay-name">
          {currentRelay.relay_name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="imei">
          {currentRelay.IMEI}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="sim-id">
          {currentRelay.SIM_ID}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="person">
          {currentRelay.person}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="customer">
          {currentRelay.customer}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="relay_owner">
          {currentRelay.relay_owner}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="date">
          {currentRelay.date}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="timestamp">
          {currentRelay.timestamp}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="comment">
          <Styled.WrapCell>{currentRelay.comment}</Styled.WrapCell>
        </Table.Cell>
      </Table.Row>
    );
  }
};

/**
 * Possible sensor statuses
 */
export const sensorStatuses = [
  'customer',
  'new',
  'retired',
  'returned',
  'faulty',
  'stock',
  'refurb',
];

export const filterColumns = [
  'status',
  'person',
  'customer',
  'bug_owner',
  'relay_owner',
];

export const sortColumns = [
  'status',
  'bug_name',
  'device_id',
  'person',
  'customer',
  'bug_owner',
  'relay_owner',
  'date',
  'comment',
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

export const isQueryEqual = (
  query1: sensorQuery,
  query2: sensorQuery,
): boolean => {
  const keys1 = Object.keys(query1);
  const keys2 = Object.keys(query2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (key === 'filter') {
      if (!isFilterEqual(query1.filter, query2.filter)) {
        return false;
      }
    } else if (query1[key] !== query2[key]) {
      return false;
    }
  }

  return true;
};

const isFilterEqual = (
  filter1: IStockFilter,
  filter2: IStockFilter,
): boolean => {
  const properties: (keyof IStockFilter)[] = [
    'status',
    'person',
    'customer',
    'bug_owner',
    'relay_owner',
  ];

  return properties.every(
    (prop) =>
      filter1[prop] !== undefined &&
      filter2[prop] !== undefined &&
      arrayEquality(filter1[prop] as unknown[], filter2[prop] as unknown[]),
  );
};
