import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ReportContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
`;

export const Subheading = styled.p`
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ReportsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const ReportLinks = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const LinkBtn = styled.a`
  text-decoration: none;
  display: flex;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
      padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-style: none;
  text-wrap: nowrap;
  border-radius: 4px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
  outline: none;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition:
    0.3s ease background,
    0.3s ease box-shadow,
    0.3s ease color,
    0.3s ease fill;

  :hover {
    box-shadow: 0px 8px 16px ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};80;
  }
`;

export const LinkContainer = styled.div`
  width: auto;
  height: auto;
`;
