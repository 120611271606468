import React, { FC, useEffect, useRef, useState } from 'react';
import { ProgressValues } from '../ProgressBar';
import * as Styled from './style';

type CurrentProgressKeyProps = {
  progress: ProgressValues;
};

const CurrentProgressKey: FC<CurrentProgressKeyProps> = ({ progress }) => {
  const [marginFromLeft, setMarginFromLeft] = useState('0px');
  const [clickedIndex, setClickedIndex] = useState<boolean>(false);

  const handleClick = () => {
    setClickedIndex(!clickedIndex);
  };

  const progressContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (progressContainerRef.current) {
      const containerAWidth =
        progressContainerRef.current.getBoundingClientRect().width;
      setMarginFromLeft('-' + (containerAWidth / 2).toString() + 'px');
    }
  }, []);

  const maxProgress = 100;
  const minimumProgress = 0;

  const progressValue =
    progress.value && progress.value > maxProgress
      ? maxProgress
      : progress.value || minimumProgress;

  return (
    <Styled.CurrentProgressContainer
      completion={progressValue}
      data-testid="current-progress-container"
      zIndex={clickedIndex ? 2 : 0}
    >
      <Styled.TriangleBottom
        onClick={() => handleClick()}
        color={progress.color}
        data-testid="triangle-bottom"
      />
      <Styled.CurrentProgressTextContainer
        ref={progressContainerRef}
        marginLeft={marginFromLeft}
        onClick={() => handleClick()}
      >
        <p>
          {progress?.hover || 0} {progress?.type}
        </p>
      </Styled.CurrentProgressTextContainer>
    </Styled.CurrentProgressContainer>
  );
};

export default CurrentProgressKey;
