import { DropDown, Table } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import { WaypointInfo, WaypointInfoResponse } from '@/types/report.type';
import React, { FC, useContext, useEffect, useState } from 'react';
import * as Styled from './style';
import {
  WaypointTableOrderBy,
  getRowStructure,
  headers,
  pageValues,
  sortDataAscAndDesc,
  toggleSelectAllWaypoints,
} from './WaypointTableUtils';

interface WaypointFilterProps {
  waypointData: WaypointInfoResponse | undefined;
}

const WaypointTable: FC<WaypointFilterProps> = ({ waypointData }) => {
  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.asc);
  const [orderBy, setOrderBy] = useState<WaypointTableOrderBy>(
    WaypointTableOrderBy.SELECT_WAYPOINT,
  );
  const [data, setData] = useState<WaypointInfo[] | undefined>(undefined);

  useEffect(() => {
    if (waypointData?.data?.data) {
      setData(waypointData.data.data);
    }
  }, [waypointData]);

  const { prefixedT } = useCustomTranslation('COMPONENTS.WAYPOINT_TABLE');

  const {
    page,
    setPage,
    perPage,
    setPerPage,
    selectedWaypoints,
    setSelectedWaypoints,
  } = useContext(WaypointFilterContext);

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleRowAmountChange = (value: DropDownOption) => {
    setPerPage(Number(value.value));
  };

  const HandleSelectWaypointHeaderClick = (headerId: string | undefined) => {
    if (headerId === 'selectWaypoint' && data) {
      toggleSelectAllWaypoints(data, selectedWaypoints, setSelectedWaypoints);
    }
  };

  const setTableSorting = (OrderBy: string, Order: string) => {
    setOrder(Order as TableSortOrders);
    setOrderBy(OrderBy as WaypointTableOrderBy);
  };

  useEffect(() => {
    if (data) {
      const sortedData = [...data].sort((a, b) =>
        sortDataAscAndDesc(orderBy, order, a, b),
      );

      setData(sortedData);
    }
  }, [order]);

  const defaultPageCount = 1;

  const currentPageCount = Math.ceil(
    (waypointData?.count || defaultPageCount) / perPage,
  );

  return (
    <div data-testid="waypoint-table">
      <Table
        data={data || []}
        headers={headers()}
        onSort={setTableSorting}
        order={order}
        orderBy={orderBy as keyof WaypointInfo | undefined}
      >
        <Table.Head
          onClick={HandleSelectWaypointHeaderClick}
          getHeaderStructure={(header) => header.title}
        />
        {data && (
          <Table.Body
            getRowStructure={(waypoint: WaypointInfo, index: number) =>
              getRowStructure(
                waypoint,
                index,
                selectedWaypoints,
                setSelectedWaypoints,
              )
            }
            striped
          />
        )}
      </Table>
      {data && (
        <Styled.PaginationWrapper>
          <Styled.Container>
            <Styled.DropDownContainer>
              <p>{prefixedT('NUMBER_ROWS')}</p>
              <DropDown
                value={perPage.toString()}
                options={pageValues.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={(value) =>
                  handleRowAmountChange(value as DropDownOption)
                }
                placeholder={'Number of rows'}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={currentPageCount}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.Container>
        </Styled.PaginationWrapper>
      )}
    </div>
  );
};

export default withPageLoader(WaypointTable);
