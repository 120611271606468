import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ActiveAccount = {
  id: number;
  name: string;
  type: string;
};

export interface ActiveAccountState {
  currentAccount: ActiveAccount | null;
}

const initialState: ActiveAccountState = {
  currentAccount: null,
};

const activeAccountSlice = createSlice({
  name: 'activeAccount',
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<ActiveAccount | null>) => {
      state.currentAccount = action.payload;
    },
  },
});

export const { setActiveAccount } = activeAccountSlice.actions;

export default activeAccountSlice.reducer;
