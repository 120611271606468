import React, { ChangeEvent, FC } from 'react';
import * as Styled from './style';

type Props = {
  label?: string;
  error?: string;
  value: [number, number];
  onChange: (val: [number, number]) => void;
};

const NumberRange: FC<Props> = ({ label, error, value, onChange }) => {
  const handleChange = (val: number, type: 'from' | 'to') => {
    if (type === 'from') {
      onChange([val, value[1]]);
    } else {
      onChange([value[0], val]);
    }
  };

  return (
    <Styled.Container>
      {label && <Styled.Label isError={!!error}>{label}</Styled.Label>}
      <Styled.Field>
        {value && (
          <>
            <Styled.NumberInput
              type="number"
              value={value[0]}
              data-testid="numberRange-from"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(+e.target.value, 'from')
              }
            />
            <Styled.Separator />
            <Styled.NumberInput
              type="number"
              value={value[1]}
              data-testid="numberRange-to"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(+e.target.value, 'to')
              }
            />
          </>
        )}
      </Styled.Field>
      {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
    </Styled.Container>
  );
};

export default NumberRange;
