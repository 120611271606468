import { ReactComponent as LargestIcon } from '@/assets/images/largest.svg';
import { ReactComponent as LatestIcon } from '@/assets/images/latest.svg';
import { ReactComponent as LongestIcon } from '@/assets/images/longest.svg';
import styled from 'styled-components';
import { Link as reactLink } from 'react-router-dom';

export const Link = styled(reactLink)`
  text-decoration: none;
  color: #fff;
`;

export const LargestImg = styled(LargestIcon)`
  width: 60px;
  height: 60px;
  margin-left: -10px;
`;

export const LatestImg = styled(LatestIcon)`
  width: 60px;
  height: 60px;
  margin-left: -10px;
`;

export const LongestImg = styled(LongestIcon)`
  width: 60px;
  height: 60px;
  margin-left: -10px;
`;

export const Wrapper = styled.div`
  margin: 0 20px 20px 20px;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 20px;
`;

export const WaypointWrapper = styled.div`
  background-color: #77c2e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-top: 10px;
`;

export const Column = styled.div`
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
`;

export const TitleContainer = styled.div`
  color: #007cbb;
  font-size: 20px;
  font-weight: 700;
`;

export const ColumnTitle = styled.h3`
  font-weight: 700;
  color: #424242;
`;

export const ColumnName = styled.p<{
  isAnimated: boolean;
}>`
  font-size: 20px;
  font-weight: 700;
  color: #fff;

  @keyframes move {
    0% {
      transform: translate(10%, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  white-space: nowrap;
  animation: ${({ isAnimated }) => isAnimated && 'move 8s linear infinite;'};
`;

export const ColumnDays = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #424242;
`;

export const TextContainer = styled.div`
  padding: 10px 10px 10px 5px;
  position: relative;
  overflow: hidden;
  max-width: 120px;
`;
