import { LeakSizeFunnels } from '@/components/LeakSizeFunnels/LeakSizeFunnels';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { AllocateLeakEngineer } from '@/components/WaypointFilter/AllocateLeakEngineer/AllocateLeakEngineer';
import { WaypointFilter } from '@/components/WaypointFilter/WaypointFilter';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import {
  useGetDMAsQuery,
  useGetProjectsQuery,
} from '@/redux/portalProjects/portalProjects.api';
import { useGetWaypointInfoMutation } from '@/redux/reports/reports.api';
import { GetStatusResponse } from '@/types/mobileApp.type';
import {
  GetEngineersResponse,
  GetDashboardResponse,
} from '@/types/portalUsers.type';
import { WaypointInfoQuery } from '@/types/report.type';
import { IUser, UserRoles } from '@/types/user.type';
import { CalculatePercentage } from '@/utils/calculatePercentage';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DateChoiceWidget from './DateChoiceWidget/DateChoiceWidget';
import DetailWidget from './DetailWidget/DetailWidget';
import MetricWidget from './MetricWidget/MetricWidget';
import ReportLinks from './ReportLinks/ReportLinks';
import * as Styled from './style';
import WaypointSizeWidget from './WaypointSizeWidget/WaypointSizeWidget';
import { ActiveDashboard } from '../../DashboardContainer';
import { useGetProfileQuery } from '@/redux/user/user.api';

type UserInfoProps = {
  user: IUser;
};

type DashboardInfoDataProps = {
  activeDashboard: ActiveDashboard;
  dashboard: GetDashboardResponse;
  statuses: GetStatusResponse[] | undefined;
  engineers: GetEngineersResponse[] | undefined;
};

type DashboardInfoProps = Partial<UserInfoProps> &
  Partial<DashboardInfoDataProps>;

const DashboardInfo: FC<DashboardInfoProps> = ({
  activeDashboard,
  engineers,
  dashboard,
  statuses,
}) => {
  const { prefixedT, t } = useCustomTranslation('DASHBOARD_PAGE');

  const {
    waypointSearch,
    size,
    status,
    dma,
    page,
    perPage,
    targetLeakageReduction,
    targetPipeline,
  } = useContext(WaypointFilterContext);

  const { data: profile } = useGetProfileQuery();

  const companyOrProjectParams = {
    ...(activeDashboard?.partner && { company_id: dashboard?.id }),
    ...(activeDashboard?.company && { project_id: dashboard?.id }),
  };

  const { data: dmas } = useGetDMAsQuery(companyOrProjectParams);
  const [mutate, { data }] = useGetWaypointInfoMutation();
  const { data: projects } = useGetProjectsQuery(
    { company_id: dashboard?.id },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeDashboard?.partner,
    },
  );

  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get('company') as string;
  const companyName = queryParams.get('company-name') as string;

  useEffect(() => {
    if (dashboard?.id) {
      const params: WaypointInfoQuery = {
        ...companyOrProjectParams,
        waypoint_search: waypointSearch,
        ...(dma.id && { dma_id: dma.id }),
        ...(status.id && { status_id: status.id }),
        ...(size && { size }),
        page,
        perPage,
      };

      void mutate(params);
    }
  }, [perPage, page, waypointSearch, dma.id, status.id, size, dashboard?.id]);

  const colors = {
    uploadedToFidoAI: '#59C4E9',
    deployedPipeline: '#009fe3',
    validatedLeakage: '#B1B1B1',
    markedUpLeakage: '#F18400',
    predictedLeakage: '#009FE3',
  };

  const companyLink = `/app/dashboard?company=${dashboard?.id}&company-name=${dashboard?.name}`;
  const projectLink = `/app/project-status?project=${dashboard?.id}`;
  const link = activeDashboard?.partner ? companyLink : projectLink;

  const [imageLoaded, setImageLoaded] = useState(true);

  const handleError = () => {
    setImageLoaded(false);
  };

  const companyRoles = [
    UserRoles.COMPANY_ADMIN,
    UserRoles.COMPANY_USER,
    UserRoles.COMPANY_LEAK_ENGINEER,
    UserRoles.COMPANY_BUG_DEPLOYMENT_ENGINEER,
  ];

  const roleCode = profile?.role?.role_code;
  const notCompanyUser =
    roleCode !== undefined && !companyRoles.includes(roleCode);

  return (
    <>
      {dashboard && (
        <Styled.RowContainer>
          <Styled.ProjectTitleContainer>
            {imageLoaded && dashboard.logo && (
              <Link
                to={`/app/dashboard?company=${dashboard?.id}&company-name=${dashboard?.name}`}
              >
                <Styled.CompanyImage
                  data-testid="company-logo"
                  alt={`${dashboard?.name} Logo`}
                  src={dashboard.logo}
                  onError={handleError}
                />
              </Link>
            )}
            <Styled.ProjectName>
              <Link style={{ textDecoration: 'none' }} to={link}>
                {dashboard?.name}
              </Link>
            </Styled.ProjectName>
            {activeDashboard?.company && (
              <Styled.ProjectContainer>
                <Styled.ProjectTimeline>
                  {prefixedT('SUMMARY.TIMELINE.PROJECT')}
                </Styled.ProjectTimeline>
                <DateChoiceWidget
                  startDate={dashboard?.summary?.start_date}
                  endDate={dashboard?.summary?.end_date}
                  id={dashboard?.id}
                />
              </Styled.ProjectContainer>
            )}
          </Styled.ProjectTitleContainer>
          <Styled.DashboardContainer>
            <Styled.RowOne>
              <Styled.RowBox>
                <DetailWidget
                  volumetricIdentified={dashboard.summary.volumetric_identified}
                  totalSessions={dashboard.summary.total_sessions}
                  runtime={dashboard.summary.cost_runtime}
                />
              </Styled.RowBox>
              <Styled.RowBox>
                <MetricWidget
                  aiIdentified={dashboard.summary.available}
                  forRepair={dashboard.summary.for_repair}
                  delivered={dashboard.summary.delivered}
                />
              </Styled.RowBox>
            </Styled.RowOne>
            <Styled.RowTwo data-testid="progress-bars">
              <Styled.ProgressTab>
                <ProgressBar
                  insightLabel={{
                    title: t('COMPONENTS.PROGRESS_BAR.PIPELINE_COVERAGE'),
                    location: 'left',
                  }}
                  title={t('COMPONENTS.PROGRESS_BAR.PIPELINE_TITLE')}
                  keyValues={[
                    {
                      name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_D'),
                      color: colors.uploadedToFidoAI,
                    },
                    {
                      name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_E'),
                      color: colors.deployedPipeline,
                    },
                  ]}
                  progressValues={[
                    {
                      value: CalculatePercentage(
                        dashboard?.summary.uploaded_ai_pipeline.value,
                        targetPipeline !== null
                          ? targetPipeline
                          : dashboard?.summary?.target_pipeline.value,
                      ),
                      color: colors.uploadedToFidoAI,
                      hover: dashboard?.summary?.uploaded_ai_pipeline.value,
                      type: dashboard?.summary?.uploaded_ai_pipeline.type,
                    },
                    {
                      value: CalculatePercentage(
                        dashboard?.summary.deployed_pipeline.value,
                        targetPipeline !== null
                          ? targetPipeline
                          : dashboard?.summary?.target_pipeline.value,
                      ),
                      color: colors.deployedPipeline,
                      hover: dashboard?.summary?.deployed_pipeline.value,
                      type: dashboard?.summary?.deployed_pipeline.type,
                    },
                  ]}
                  targetProgress={{
                    value:
                      targetPipeline !== null
                        ? targetPipeline
                        : dashboard?.summary?.target_pipeline.value,
                    type: dashboard?.summary?.target_pipeline.type,
                    target: 'target_pipeline',
                    errorMsg: t(
                      'COMPONENTS.PROGRESS_BAR.TARGET_PIPELINE.ERROR_MSG',
                    ),
                  }}
                  id={dashboard.id}
                  isCompany={!activeDashboard?.company}
                />
              </Styled.ProgressTab>
              <Styled.ProgressTab>
                <ProgressBar
                  insightLabel={{
                    title: t('COMPONENTS.PROGRESS_BAR.LEAKAGE_REDUCTION'),
                    location: 'right',
                  }}
                  title={t('COMPONENTS.PROGRESS_BAR.LEAKAGE_TITLE')}
                  keyValues={[
                    {
                      name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_F'),
                      color: colors.validatedLeakage,
                    },
                    {
                      name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_G'),
                      color: colors.markedUpLeakage,
                    },
                    {
                      name: t('COMPONENTS.PROGRESS_BAR.KEY.ITEM_H'),
                      color: colors.predictedLeakage,
                    },
                  ]}
                  progressValues={[
                    {
                      value: CalculatePercentage(
                        dashboard?.summary.validated_leakage.value,
                        targetLeakageReduction !== null
                          ? targetLeakageReduction
                          : dashboard?.summary?.target_leakage_reduction.value,
                      ),
                      color: colors.validatedLeakage,
                      hover: dashboard?.summary.validated_leakage.value,
                      type: dashboard?.summary.validated_leakage.type,
                    },
                    {
                      value: CalculatePercentage(
                        dashboard?.summary.marked_up_leakage.value,
                        targetLeakageReduction !== null
                          ? targetLeakageReduction
                          : dashboard?.summary?.target_leakage_reduction.value,
                      ),
                      color: colors.markedUpLeakage,
                      hover: dashboard?.summary.marked_up_leakage.value,
                      type: dashboard?.summary.marked_up_leakage.type,
                    },
                    {
                      value: CalculatePercentage(
                        dashboard?.summary.predicted_leakage.value,
                        targetLeakageReduction !== null
                          ? targetLeakageReduction
                          : dashboard?.summary?.target_leakage_reduction.value,
                      ),
                      color: colors.predictedLeakage,
                      hover: dashboard?.summary.predicted_leakage.value,
                      type: dashboard?.summary.predicted_leakage.type,
                    },
                  ]}
                  targetProgress={{
                    value:
                      targetLeakageReduction !== null
                        ? targetLeakageReduction
                        : dashboard?.summary?.target_leakage_reduction.value,
                    type: dashboard?.summary?.target_leakage_reduction.type,
                    target: 'target_leakage_reduction',
                    errorMsg: t(
                      'COMPONENTS.PROGRESS_BAR.TARGET_LEAKAGE.ERROR_MSG',
                    ),
                  }}
                  id={dashboard.id}
                  isCompany={!activeDashboard?.company}
                />
              </Styled.ProgressTab>
            </Styled.RowTwo>
            <Styled.RowThree>
              <WaypointSizeWidget
                latest={dashboard?.summary?.latest}
                longest={dashboard?.summary?.longest}
                largest={dashboard?.summary?.largest}
              />
            </Styled.RowThree>
            <Styled.RowFour>
              <LeakSizeFunnels
                smallLeaks={dashboard.summary.small_leaks}
                mediumLeaks={dashboard.summary.medium_leaks}
                LargeLeaks={dashboard.summary.large_leaks}
              />
            </Styled.RowFour>
            <div>
              <WaypointFilter
                dmas={dmas}
                waypointData={data}
                projects={projects}
                statuses={statuses}
              />
              {engineers && <AllocateLeakEngineer engineers={engineers} />}
            </div>
            {activeDashboard?.company && notCompanyUser && (
              <div data-testid="report-links">
                <ReportLinks
                  projectId={dashboard.id}
                  companyId={companyId}
                  companyName={companyName}
                  projectName={dashboard.name}
                  roleCode={roleCode}
                  healthCheckUrl={dashboard.summary?.health_check_url}
                />
              </div>
            )}
          </Styled.DashboardContainer>
        </Styled.RowContainer>
      )}
    </>
  );
};

export default withPageLoader(DashboardInfo);
