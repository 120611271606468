import { ReportFilters } from '@/components';
import BreadCrumbContainer from '@/components/ReportFilters/components/BreadCrumbContainer/BreadCrumbContainer';
import SearchBar from '@/components/SearchBar/SearchBar';
import { FilterContext } from '@/Providers/FilterProvider';
import React, { FC, useContext } from 'react';
import * as Styled from './style';
import { SelectOption } from '@/types/report.type';

interface StickyMenuProps {
  dmaIds: string[];
  waypointIds: string[];
  filters: SelectOption | undefined;
  isLoading: boolean;
}

const StickyMenu: FC<StickyMenuProps> = ({
  filters,
  dmaIds,
  waypointIds,
  isLoading,
}) => {
  const { mapFilters, setDMASearch, setWaypointSearch } =
    useContext(FilterContext);

  const handleSearch = (searchText: string, option: string) => {
    if (option === 'dma') {
      setDMASearch(searchText.toLocaleUpperCase());
    }
    if (option === 'waypoint') {
      setWaypointSearch(searchText.toLocaleUpperCase());
    }
  };

  const waypointsExist = mapFilters.filters.includes('WAYPOINTS');
  const dmasExist =
    mapFilters.filters.includes('DMAS') || mapFilters.filters.length === 0;

  return (
    <Styled.RowWrapper>
      <Styled.Rows>
        <ReportFilters isLoading={isLoading} filters={filters} />
        {dmasExist && (
          <Styled.SearchBarContainer>
            <SearchBar
              ids={dmaIds}
              placeholder={'Search for DMA'}
              onSearch={(searchText) => handleSearch(searchText, 'dma')}
            />
          </Styled.SearchBarContainer>
        )}
        {waypointsExist && (
          <Styled.SearchBarContainer>
            <SearchBar
              ids={waypointIds}
              placeholder={'Search for Waypoint'}
              onSearch={(searchText) => handleSearch(searchText, 'waypoint')}
            />
          </Styled.SearchBarContainer>
        )}
      </Styled.Rows>
      <Styled.Rows>
        <BreadCrumbContainer />
      </Styled.Rows>
    </Styled.RowWrapper>
  );
};

export default StickyMenu;
