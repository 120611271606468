import React, { FC, useContext, useEffect, useMemo } from 'react';
import * as Styled from './style';
import { Button, Loader, Modal } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetPreviewGISQuery } from '@/redux/gisUpload/gisUpload.api';
import { formatMapData } from '@/containers/GISUpload/MapPreview/utils';
import { FilterContext, FilterName } from '@/Providers/FilterProvider';
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import { MapProvider } from 'react-map-gl';

export type PreviewModalProps = {
  open: boolean;
  uploadId: number;
  companyId: number;
  closeModal: () => void;
};

const PreviewModal: FC<PreviewModalProps> = ({
  open,
  uploadId,
  companyId,
  closeModal,
}) => {
  const { prefixedT } = useCustomTranslation('GIS_ADMIN.PREVIEW_MODAL');
  const { setFilterMapValues, setMapFilters, mapFilters } =
    useContext(FilterContext);

  const { data, isFetching, isError } = useGetPreviewGISQuery({
    companyId,
    uploadId,
  });

  const defaultFilterMapValues: FilterName[] = ['DMAS', 'PIPES', 'ASSETS'];

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    setFilterMapValues(defaultFilterMapValues);
    setMapFilters({ filters: ['DMAS', 'PIPES', 'ASSETS'] });
  }, []);

  const mapData = useMemo(
    () => formatMapData(data, mapFilters),
    [data, mapFilters],
  );

  return (
    <Modal isOpen={open} styles={{ maxWidth: '800px' }}>
      <Styled.Container data-testid="preview-modal">
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
        {isError && <Styled.Error>{prefixedT('ERROR_MSG')}</Styled.Error>}
        {isFetching && <Loader />}
        {mapData.length === 0 && !isFetching && (
          <Styled.NoData>{prefixedT('NO_DATA')}</Styled.NoData>
        )}
        {mapData.length > 0 && !isFetching && (
          <div data-testid="map-preview">
            <MapProvider>
              <GenerateMap
                fullMapData={mapData}
                readonly={false}
                viewportMapData={mapData}
                showMapFilters={true}
                isLoading={isFetching}
              />
            </MapProvider>
          </div>
        )}
        <Styled.Actions>
          <Button
            onClick={() => {
              void onClose();
            }}
            width="130px"
            height="40px"
          >
            {prefixedT('BTN.CLOSE')}
          </Button>
        </Styled.Actions>
      </Styled.Container>
    </Modal>
  );
};

export default PreviewModal;
