import React, { FC } from 'react';

import PlusIcon from '@/assets/icons/plus-icon.png';
import { Button } from '@/components';
import SearchableTable from '@/components/SearchableTable/SearchableTable';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useLazyGetProjectsListQuery } from '@/redux/portalProjects/portalProjects.api';

import * as Styled from './style';
import { getRowStructure, headers } from './utils/utils';
import EditProject from './EditProject/EditProject';

const ViewProjects: FC = () => {
  const [trigger, { data: projectList, isFetching, isError }] =
    useLazyGetProjectsListQuery();

  const { prefixedT } = useCustomTranslation('VIEW_PROJECTS');

  return (
    <SearchableTable
      queryResponse={projectList}
      isFetching={isFetching}
      isError={isError}
      ExtraHeaderComponent={
        <Styled.BtnContainer data-testid="add-projects-btn">
          <Styled.Link to={'/app/user-management/add-project'}>
            <Button>
              {prefixedT('BUTTON.ADD')}{' '}
              <Styled.PlusImg src={PlusIcon as string} alt="plus-icon" />
            </Button>
          </Styled.Link>
        </Styled.BtnContainer>
      }
      trigger={trigger}
      editModal={(idx, closeOverlay) => {
        const currentProject = projectList?.data[idx];
        if (currentProject !== undefined) {
          return (
            <EditProject
              key={idx}
              company={currentProject.company}
              dmas={currentProject.dmas}
              end_date={currentProject.end_date || ''}
              project_id={currentProject.id}
              name={currentProject.name}
              start_date={currentProject.start_date || ''}
              cost_per_distance={currentProject.cost_per_distance}
              closeOverlay={closeOverlay}
            />
          );
        }
      }}
      // deleteModal={() => <h1>TODO FS-926</h1>}
      getRowStructure={getRowStructure}
      headers={headers}
      translationPrefix="VIEW_PROJECTS"
    />
  );
};

export default ViewProjects;
