import { DateFilter } from '@/Providers/DeliveryPerformanceProvider';
import {
  DeliveryPerformanceDropdownType,
  DeliveryPerformanceLeakEngineerType,
  getDeliveryPerformanceQuery,
} from '@/types/report.type';
import moment from 'moment';

export const extractEngineerIds = (
  data: DeliveryPerformanceDropdownType | undefined,
): string[] => {
  const engineerIdsSet = new Set<string>();

  if (data) {
    data.engineers.forEach((engineer) => {
      engineerIdsSet.add(engineer.id);
    });
  }

  const uniqueEngineerIds = Array.from(engineerIdsSet);
  return uniqueEngineerIds;
};

export const toPercentage = (number: number | null) => {
  if (number === null) {
    return null;
  }
  const percentage = number * 100;
  return percentage % 1 === 0 ? percentage + '%' : percentage.toFixed(2) + '%';
};

export const QueryParams = (
  projectActiveValue: number | null,
  dmaActiveValue: number | null,
  dateFilters: DateFilter,
  companyActiveValue: number | null,
  partnerActiveValue: number | null,
) => {
  const queryParams: getDeliveryPerformanceQuery = {};

  if (projectActiveValue) {
    queryParams.branch_id = Number(projectActiveValue);
  }

  if (dmaActiveValue) {
    queryParams.dma_id = Number(dmaActiveValue);
  }

  if (companyActiveValue) {
    queryParams.company_id = Number(companyActiveValue);
  }

  if (partnerActiveValue) {
    queryParams.partner_id = Number(partnerActiveValue);
  }

  if (dateFilters.dateFrom) {
    queryParams.start_date = moment(dateFilters.dateFrom).format('YYYY-MM-DD');
  }

  if (dateFilters.dateTo) {
    queryParams.end_date = moment(dateFilters.dateTo).format('YYYY-MM-DD');
  }

  return queryParams;
};

export const generateHistoryTableData = (
  leakEngineerData: DeliveryPerformanceLeakEngineerType | undefined,
): { [key: string]: string | number | null }[] => {
  return (leakEngineerData ?? [])
    .map((item) => {
      const conversionRate =
        typeof item.info.total?.conversion_rate === 'number'
          ? parseFloat(item.info.total.conversion_rate.toFixed(3))
          : null;

      const data = {
        option1: item.name ?? null,
        option2: item.info.total?.leaks_marked_up ?? null,
        option3: conversionRate,
        option4: item.info.total?.in_progress_waypoints ?? null,
        option5: item.info.total?.not_found_waypoints ?? null,
        option6: item.info.total?.waypoints_investigated ?? null,
      };

      if (
        data.option2 === null &&
        data.option3 === null &&
        data.option4 === null &&
        data.option5 === null &&
        data.option6 === null
      ) {
        return null;
      } else {
        return data;
      }
    })
    .filter(
      (
        item,
      ): item is {
        option1: string;
        option2: number | null;
        option3: number | null;
        option4: number | null;
        option5: number | null;
        option6: number | null;
      } => !!item,
    );
};
