import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog/Dialog';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import { Modal } from '@/components/Modal';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import {
  ReportDataSource,
  ReportResultsSettings,
  ReportWidget,
} from '@/types/report.type';
import { Field, Form, Formik } from 'formik';
import React, { FC, useRef, useState } from 'react';
import {
  getSubTitle,
  getTitle,
  prepareInitialValues,
  validateTableOfResultsConfig,
} from './service';
import * as Styled from './style';

type Props = {
  open: boolean;
  sources: ReportDataSource[] | undefined;
  onClose: () => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportResultsSettings>,
    layoutId: string | number,
  ) => void;
  widget: ReportWidget<ReportResultsSettings>;
  layoutId: string | number;
};

type InitialValues = {
  source: number | null;
  columns: number[];
};
const initialTableValues: InitialValues = {
  source: null,
  columns: [],
};
const style = {
  color: {
    confirm: theme.colors.coral,
    cancel: theme.colors.blue,
  },
};

const TableOfResultsSettings: FC<Props> = ({
  open,
  sources = [],
  onClose,
  widget,
  onUpdateWidget,
  layoutId,
}) => {
  const [dialogState, setDialogState] = useState<null | string>(null);
  const tempSource = useRef('');
  const blockRef = useRef(null);
  const { t } = useCustomTranslation('');

  return (
    <Formik
      onSubmit={(values) => {
        const _widget = {
          ...widget,
          settings: {
            datasource: Number(values.source),
            columns: values.columns,
          },
        };
        onUpdateWidget(_widget, layoutId);
        onClose();
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={
        prepareInitialValues(widget.settings) || initialTableValues
      }
      validationSchema={validateTableOfResultsConfig}
    >
      {({ setFieldValue, values, dirty, resetForm, errors, submitForm }) => {
        const sourceColumns =
          sources.find((el) => el.id === values.source)?.ds_columns || [];
        const applySourceChange = (option: string) => {
          setFieldValue('source', option);
          if (dialogState) {
            setDialogState(null);
            setFieldValue('columns', []);
          }
        };
        const onChangeSource = (option: DropDownOption) => {
          if (!values.columns.length) {
            applySourceChange(option.value);
          } else {
            tempSource.current = option.value;
            setDialogState('change');
          }
        };
        const onModalClose = () => {
          if (dirty) {
            setDialogState('close');
          } else {
            onClose();
          }
        };
        const onConfirm = () => {
          if (dialogState === 'change') {
            applySourceChange(tempSource.current);
          } else {
            setDialogState(null);
            resetForm();
            onClose();
          }
        };
        return (
          <Form>
            <Modal
              modalRef={blockRef}
              title={t('TABLE_OF_RESULTS.MODAL_TITLE')}
              isOpen={open}
              onClose={onModalClose}
            >
              <Dialog
                open={!!dialogState}
                title={getTitle(String(dialogState))}
                subtitle={getSubTitle(String(dialogState))}
                onConfirm={onConfirm}
                onCancel={() => setDialogState(null)}
                style={style}
              />
              <Field
                as={DropDown}
                options={sources.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                label={t('TABLE_OF_RESULTS.FORM.SOURCE.LABEL')}
                placeholder={t('TABLE_OF_RESULTS.FORM.SOURCE.PLACEHOLDER')}
                name="source"
                onChange={onChangeSource}
                error={errors.source && t(errors.source)}
                blockRef={blockRef}
              />
              {values.source && (
                <Field
                  as={DropDown}
                  options={sourceColumns.map((item) => ({
                    value: item.column?.id,
                    label: item.column?.name,
                  }))}
                  label={t('TABLE_OF_RESULTS.FORM.COLUMNS_TO_SHOW.LABEL')}
                  placeholder={t(
                    'TABLE_OF_RESULTS.FORM.COLUMNS_TO_SHOW.PLACEHOLDER',
                  )}
                  name="columns"
                  countOptions={5}
                  onChange={(value: DropDownOption) =>
                    setFieldValue('columns', value.value)
                  }
                  error={errors.columns && t(errors.columns)}
                  blockRef={blockRef}
                  multiple
                />
              )}
              <Styled.ButtonContainer>
                <Button
                  disabled={!dirty}
                  width="120px"
                  height="34px"
                  type="submit"
                  onClick={submitForm}
                >
                  {t('REPORT_BUILDER.APPLY')}
                </Button>
              </Styled.ButtonContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TableOfResultsSettings;
