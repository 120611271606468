import { resetState } from '@/redux';
import {
  logout,
  setAzureToken,
  setToken,
} from '@/redux/authorization/authorization.slice';
import { userApi } from '@/redux/user/user.api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(setToken(''));
    dispatch(setAzureToken(''));
    dispatch(logout());
    dispatch(resetState());
    navigate('/auth');
    dispatch(userApi.util.resetApiState());
  };
  return logoutHandler;
};

export default useLogout;
