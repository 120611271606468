import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import { Button, DatePicker, Loader, Table } from '@/components';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import { getRowStructure, headers, isQueryEqual } from './JobLogsTableUtils';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointJobLog, WaypointJobLogsQuery } from '@/types/report.type';
import { usePostWaypointJobLogsMutation } from '@/redux/reports/reports.api';
import FilterColumn from './FilterColumn/FilterColumn';

export interface JobLogsTableProps {
  job_id: number;
}

const JobLogsTable: FC<JobLogsTableProps> = ({ job_id }) => {
  const [trigger, { data, isLoading }] = usePostWaypointJobLogsMutation();

  const { t, prefixedT } = useCustomTranslation('WAYPOINT_PAGE.JOB_TABLE');

  const tableHeaders = headers();

  const [isInitialFetch, setIsInitialFetch] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(20);

  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.desc);
  const [orderBy, setOrderBy] = useState<string>('created_at');

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [isDateError, setIsDateError] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const currentQuery: WaypointJobLogsQuery = {
    page,
    per_page: perPage,
    date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '',
    date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : '',
    sorted_by: orderBy,
    sorted_order: order,
    search: searchQuery,
  };

  const [lastQuery, setLastQuery] =
    useState<WaypointJobLogsQuery>(currentQuery);

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const refetchWithArgs = () => {
    if (
      dateTo !== null &&
      dateFrom !== null &&
      moment(dateTo).isBefore(dateFrom)
    ) {
      setIsDateError(true);
    } else {
      setIsDateError(false);

      setLastQuery(currentQuery);

      void trigger({ query: currentQuery, id: job_id });
    }
  };

  useEffect(() => {
    if ((data?.data || []).length > 0) {
      setIsInitialFetch(false);
    }
  }, [data]);

  useEffect(() => {
    refetchWithArgs();
  }, [page, perPage, job_id]);

  return (
    <Styled.Wrapper>
      <Styled.TableTitle>
        {t('WAYPOINT_PAGE.JOB_LOG_TABLE.TITLE')}
      </Styled.TableTitle>
      {isLoading ? (
        <Loader />
      ) : isInitialFetch && data?.data.length === 0 ? (
        <p>{prefixedT('NO_DATA_EXISTS')}</p>
      ) : data?.data !== undefined ? (
        <div data-testid="job-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {prefixedT('FILTERS')}
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        setDateFrom(value);
                      }}
                      placeholder={prefixedT('START_DATE')}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        setDateTo(value);
                      }}
                      placeholder={prefixedT('END_DATE')}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>
                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={prefixedT('SEARCH.PLACEHOLDER')}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                  disabled={isQueryEqual(lastQuery, currentQuery)}
                >
                  {prefixedT('SEARCH.BUTTON')}
                </Button>
              </Styled.SearchBarContainer>
              {isDateError && (
                <Styled.Error>{prefixedT('DATE_ERROR')}</Styled.Error>
              )}
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table data={data.data} headers={tableHeaders} minHeight="350px">
            <Table.Head
              getHeaderStructure={(header) => {
                return (
                  <FilterColumn
                    header={header}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showSortOption={header.id === 'created_at'}
                    isLastColumn={header.id === 'comment'}
                  />
                );
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(jobLog: WaypointJobLog, index: number) =>
                  getRowStructure(jobLog, index, t)
                }
                striped
              />
            ) : (
              <h3>{prefixedT('NO_DATA')}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Table.Pagination
              pages={Math.ceil(data.count / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      ) : null}
    </Styled.Wrapper>
  );
};

export default JobLogsTable;
