import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC, useLayoutEffect, useRef } from 'react';
import BouncingDotsLoader from '../BouncingDotsLoader/BouncingDotsLoader';
import { ListOfMessages } from '../types';
import Rating from './Rating/Rating';
import * as Styled from './style';

interface ChatbotMessagesProps {
  convoId: string | undefined;
  messages: ListOfMessages[];
  isLoading: boolean;
}

const ChatbotMessages: FC<ChatbotMessagesProps> = ({
  messages,
  isLoading,
  convoId,
}) => {
  const { prefixedT } = useCustomTranslation('CHATBOT');
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages]);

  const ChatHeader: React.FC<{ user?: boolean }> = ({ user }) => (
    <Styled.ChatHeader>
      {!user && <Styled.AskFidoSmall />}
      <Styled.ChatTitle>{user ? prefixedT('YOU') : 'askFIDO'}</Styled.ChatTitle>
    </Styled.ChatHeader>
  );

  return (
    <Styled.MessagesContainer ref={divRef}>
      {messages.map((msg, index) => (
        <div key={index}>
          {msg.user_msg && (
            <Styled.Chat>
              <ChatHeader user />
              <Styled.ChatMsgContainer user>
                <p>{msg.user_msg}</p>
              </Styled.ChatMsgContainer>
            </Styled.Chat>
          )}
          {msg.assistant_msg && (
            <Styled.Chat>
              <ChatHeader />
              <Styled.ChatMsgContainer>
                <p>{msg.assistant_msg}</p>
              </Styled.ChatMsgContainer>
              {convoId && msg.msgId && (
                <Rating convoId={convoId} msgId={msg.msgId} />
              )}
            </Styled.Chat>
          )}
          {isLoading && index > 0 && !msg.assistant_msg && (
            <Styled.Chat>
              <ChatHeader />
              <Styled.ChatMsgContainer>
                <BouncingDotsLoader />
              </Styled.ChatMsgContainer>
            </Styled.Chat>
          )}
        </div>
      ))}
    </Styled.MessagesContainer>
  );
};

export default ChatbotMessages;
