import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  min-height: auto;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};

  ::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};
  padding: 20px 2.5%;
`;

export const Th = styled.th`
  padding: 15px 0;
  min-width: 200px;
`;

export const HeaderTR = styled.tr`
  background: transparent;
`;

export const Thread = styled.thead`
  position: relative;
  height: 45px;
  border-radius: 8px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
  user-select: none;
  width: calc(100% - 1em);
  display: table;
`;

export const TD = styled.td`
  min-width: 200px;
  font-size: 12px;
  padding: 12px 0px;
  text-align: center;
  border-bottom: 5px solid
    ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.historianGrey};
`;

export const TR = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.midnight};

  :hover {
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.darkPurple};
  }
`;

export const Tbody = styled.tbody`
  display: block;
  max-height: 300px;
`;

export const LastTR = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.midnight};

  :hover {
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.darkPurple};
  }
  & > ${TD} {
    border: none;
  }
`;
