import { Button, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetWaypointsFromWaypointQuery,
  usePostMergeWaypointsMutation,
} from '@/redux/highlandPortal/highlandPortal.api';
import { Field, Formik } from 'formik';
import React, { FC, useEffect } from 'react';
import * as Styled from './style';

interface MergeWaypointProps {
  waypointId: number;
  closeOverlay: () => void;
  isUpdated: () => Promise<void>;
  isOpen: boolean;
}

interface MergeWaypointFormValues {
  waypointId: string;
  notes: string;
}

const MergeWaypoint: FC<MergeWaypointProps> = ({
  isOpen,
  closeOverlay,
  isUpdated,
  waypointId,
}) => {
  const { t, prefixedT } = useCustomTranslation('MERGE_WAYPOINT');
  const { data: waypointList } = useGetWaypointsFromWaypointQuery({
    merged_waypoint_id: waypointId,
  });
  const [mutate, { isSuccess, isError }] = usePostMergeWaypointsMutation();

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await isUpdated();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const waypointDropdownList: DropDownOption[] =
    waypointList?.map((waypoint) => ({
      value: waypoint.id.toString(),
      label: waypoint.name,
    })) || [];

  const initialValues: MergeWaypointFormValues = {
    waypointId: '',
    notes: '',
  };

  const onSubmit = async (values: MergeWaypointFormValues) => {
    const formData = new URLSearchParams();
    formData.append('notes', values.notes);
    const formDataString = formData.toString();

    await mutate({
      params: {
        kept_waypoint_id: waypointId,
        waypoint_ids: [Number(values.waypointId)],
      },
      body: formDataString,
    });
  };

  return (
    <>
      {isOpen && (
        <>
          <Styled.Underlay data-testid={'overlay'} onClick={closeOverlay} />
          <Styled.DialogBox open={isOpen}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
              initialValues={initialValues}
              enableReinitialize
            >
              {({ submitForm, setFieldValue, values }) => (
                <Styled.Wrapper>
                  {waypointDropdownList.length > 0 ? (
                    <>
                      <Field
                        name={'waypointId'}
                        as={DropDown}
                        testId={'waypointId'}
                        label={prefixedT('INPUT.MERGE_DROPDOWN.LABEL')}
                        styles={{ border: true }}
                        value={values.waypointId}
                        options={waypointDropdownList}
                        placeholder={prefixedT(
                          'INPUT.MERGE_DROPDOWN.PLACEHOLDER',
                        )}
                        onChange={(optionDropdown: DropDownOption) =>
                          setFieldValue('waypointId', optionDropdown.value)
                        }
                      />
                      <Styled.NotesContainer>
                        <Field
                          as={TextField}
                          name={'notes'}
                          label={prefixedT('INPUT.NOTES.LABEL')}
                          testId={'notes'}
                          placeholder={prefixedT('INPUT.NOTES.PLACEHOLDER')}
                          setFieldValue={setFieldValue}
                        />
                      </Styled.NotesContainer>
                      <Styled.SubmitBtnContainer>
                        <Button
                          onClick={() => {
                            void (async () => {
                              await submitForm();
                            })();
                          }}
                          type="submit"
                        >
                          {prefixedT('FORM.SUBMIT')}
                        </Button>
                      </Styled.SubmitBtnContainer>
                    </>
                  ) : (
                    <Styled.NoDataContainer>
                      <p>{prefixedT('NO_DATA')}</p>
                    </Styled.NoDataContainer>
                  )}
                  {isError && (
                    <Styled.ErrorMsgContainer>
                      <Styled.ErrorMsg>{t('ERROR.REQUEST')}</Styled.ErrorMsg>
                    </Styled.ErrorMsgContainer>
                  )}
                </Styled.Wrapper>
              )}
            </Formik>
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default MergeWaypoint;
