import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContentWrapper = styled.div<{
  isOpenSidebar: boolean;
}>`
  width: calc(100% - 250px);
  position: absolute;
  left: 250px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: 0.3s ease width;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.s}) {
    padding: 30px;
    width: calc(100% - 60px);
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) =>
      theme.breakpoints.xs}) {
    padding: 20px;
    width: calc(100% - 40px);
  }
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    font-size: 18px;
  }
`;

export const PageHeaderSubtitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumDark};
  margin-top: 4px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    font-size: 14px;
  }
`;
