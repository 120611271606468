import { Styles } from '@/types/styles.type';
import React, { FC } from 'react';
import * as Styled from './style';

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLElement>) => void;
  value: string | number;
  label?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  error?: string;
  testId?: string;
  styles?: Styles;
}

const TextArea: FC<IProps> = ({
  onChange,
  value = '',
  label,
  placeholder,
  name,
  disabled,
  required,
  error,
  testId,
  styles,
}) => (
  <Styled.Container>
    {label && (
      <Styled.Label labelStyle={styles?.label} isError={!!error}>
        {label} {required && <Styled.Required>*</Styled.Required>}
      </Styled.Label>
    )}
    <Styled.Field
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      error={error}
      data-testid={testId || 'text-field'}
    />
    {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
  </Styled.Container>
);
export default React.memo(TextArea);
