import { sessionData } from '@/types/report.type';
import moment from 'moment';

interface MergedItem {
  psd?: string;
  audio?: string;
  timeline?: string;
}

export const mergeSessionFiles = (
  files: sessionData['files'],
): MergedItem[] => {
  return files
    .filter((file) => file.type === 'psd' || file.type === 'audio')
    .reduce((acc: MergedItem[], file) => {
      file.urls.forEach((url, index) => {
        const existingItem = acc[index] || {};
        const timeline = file.timeline[index]
          ? moment(file.timeline[index])
              .utc()
              .format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
          : existingItem.timeline || '';

        acc[index] = {
          ...existingItem,
          [file.type]: url,
          timeline,
        };
      });
      return acc;
    }, []);
};
