import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, ContentLoader, DatePicker, Loader, Table } from '@/components';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import {
  useLazyPostSensorsQuery,
  useLazyPostRelayTrackingQuery,
  useLazyGetDistinctSensorFiltersQuery,
  useLazyGetDistinctRelayFiltersQuery,
} from '@/redux/portalUsers/portalUsers.api';
import { IStockFilter } from '@/types/rtk.type';
import {
  PostSensorsResponse,
  PostRelaysResponse,
  sensorQuery,
} from '@/types/stock.type';
import useCustomTranslation from '@/localization/useCustomTranslation';

import {
  getRowStructure,
  headers,
  isFilterColumn,
  isQueryEqual,
  isSortColumn,
} from './StockTableUtils';
import EditStock from './EditStock/EditStock';
import FilterColumn from './FilterColumn/FilterColumn';
import SelectColumn from './SelectColumn/SelectColumn';
import * as Styled from './style';

interface StockTableProps {
  isSensorManagement: boolean;
}

const StockTable: FC<StockTableProps> = ({ isSensorManagement }) => {
  const { prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE');
  const { prefixedT: statusesPrefixedT } = useCustomTranslation(
    'STOCK_MANAGEMENT.TABLE.STATUSES',
  );

  const tableHeaders = headers(isSensorManagement);

  const [searchParams, setSearchParams] = useSearchParams({});
  const pageParam = searchParams.get('page');
  const perPageParam = searchParams.get('perPage');

  const [page, setPage] = useState<number>(Number(pageParam) || 1);
  const [perPage] = useState<number>(Number(perPageParam) || 20);

  const [editStockIsOpen, setEditStockIsOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState<number[]>([]);

  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.desc);
  const [orderBy, setOrderBy] = useState<string>('');

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filter, setFilter] = useState<IStockFilter>({
    status: [],
    customer: [],
    person: [],
    bug_owner: [],
    relay_owner: [],
  });

  const [lastQuery, setLastQuery] = useState<sensorQuery>({
    page,
    per_page: perPage,
    sorted_by: orderBy,
    sorted_order: order,
    search: searchQuery,
    filter: {
      status: filter.status,
      customer: filter.customer,
      person: filter.person,
      bug_owner: filter.bug_owner,
      relay_owner: filter.bug_owner,
    },
    date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : '',
    date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '',
  });

  const currentQuery = {
    page,
    per_page: perPage,
    sorted_by: orderBy,
    sorted_order: order,
    search: searchQuery,
    filter: {
      status: filter.status,
      customer: filter.customer,
      person: filter.person,
      bug_owner: filter.bug_owner,
      relay_owner: filter.relay_owner,
    },
    date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : '',
    date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '',
  };

  const [triggerGetDistinctSensorFilters, { data: distinctSensorFilters }] =
    useLazyGetDistinctSensorFiltersQuery();
  const [triggerGetDistinctRelayFilters, { data: distinctRelayFilters }] =
    useLazyGetDistinctRelayFiltersQuery();

  useEffect(() => {
    setFilter({
      status: [],
      customer: [],
      person: [],
      bug_owner: [],
      relay_owner: [],
    });

    setOrderBy('');
    setOrder(TableSortOrders.desc);

    void (isSensorManagement
      ? triggerGetDistinctSensorFilters(undefined, true)
      : triggerGetDistinctRelayFilters(undefined, true));
  }, [isSensorManagement]);

  const [
    triggerSensors,
    {
      data: sensorData,
      isLoading: sensorIsLoading,
      isFetching: sensorIsFetching,
    },
  ] = useLazyPostSensorsQuery();
  const [
    triggerRelays,
    { data: relayData, isLoading: relayIsLoading, isFetching: relayIsFetching },
  ] = useLazyPostRelayTrackingQuery();

  const data = isSensorManagement ? sensorData : relayData;
  const isLoading = isSensorManagement ? sensorIsLoading : relayIsLoading;
  const isFetching = isSensorManagement ? sensorIsFetching : relayIsFetching;

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page]);

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  const refetchWithArgs = (useCache = true) => {
    const body = isSensorManagement
      ? {
          page,
          per_page: perPage,
          sorted_by: orderBy,
          sorted_order: order,
          search: searchQuery,
          filter: {
            status: filter.status,
            customer: filter.customer,
            person: filter.person,
            bug_owner: filter.bug_owner,
            relay_owner: filter.relay_owner,
          },
          date_to: dateTo !== null ? moment(dateTo).format('YYYY-MM-DD') : '',
          date_from:
            dateFrom !== null ? moment(dateFrom).format('YYYY-MM-DD') : '',
        }
      : {
          page,
          per_page: perPage,
          sorted_by: orderBy,
          sorted_order: order,
          search: searchQuery,
          filter: {
            status: filter.status,
            customer: filter.customer,
            person: filter.person,
            bug_owner: filter.bug_owner,
            relay_owner: filter.relay_owner,
          },
          date_to: dateTo !== null ? moment(dateTo).format('YYYY-MM-DD') : '',
          date_from:
            dateFrom !== null ? moment(dateFrom).format('YYYY-MM-DD') : '',
        };

    setLastQuery({ ...body });

    void (isSensorManagement
      ? triggerSensors(body, useCache)
      : triggerRelays(body, useCache));
  };

  useEffect(() => {
    refetchWithArgs(false);
  }, [page, perPage, isSensorManagement]);

  useEffect(() => {
    setSelectedStock([]);
  }, [isSensorManagement]);

  return (
    <Styled.Wrapper>
      <EditStock
        isSensorManagement={isSensorManagement}
        sensorIds={selectedStock}
        setSelectedStock={setSelectedStock}
        isOpen={editStockIsOpen}
        setIsOpen={setEditStockIsOpen}
        refetch={refetchWithArgs}
      />
      <Styled.TableTitle>{prefixedT('TITLE')}</Styled.TableTitle>
      <ContentLoader active={data?.data && isFetching} />
      {isLoading ? (
        <Loader />
      ) : (
        <div data-testid="stock-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {prefixedT('EDIT_SECTION')}:
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Button
                  onClick={() => {
                    setEditStockIsOpen(true);
                  }}
                  height="50px"
                  disabled={selectedStock.length === 0}
                >
                  {prefixedT('MODIFY')}{' '}
                  {selectedStock.length > 0 && (
                    <Styled.CountContainer>
                      ({selectedStock.length})
                    </Styled.CountContainer>
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setSelectedStock([]);
                  }}
                  height="50px"
                  disabled={selectedStock.length === 0}
                >
                  {prefixedT('EDIT.DESELECT')}
                </Button>
              </Styled.SearchBarContainer>
            </Styled.TableHeaderSection>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {prefixedT('FILTER_SECTION')}
              </Styled.TableHeaderTitle>

              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        setDateFrom(value);
                      }}
                      placeholder={prefixedT('DATE_FROM')}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        setDateTo(value);
                      }}
                      placeholder={prefixedT('DATE_TO')}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>

                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={prefixedT('SEARCH.PLACEHOLDER')}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                  disabled={isQueryEqual(lastQuery, currentQuery)}
                >
                  {prefixedT('SEARCH_BAR.APPLY.FILTER')}
                </Button>
              </Styled.SearchBarContainer>
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table
            data={(data?.data as PostRelaysResponse[]) || []}
            headers={tableHeaders}
            onSort={setSorting}
            minHeight="350px"
          >
            <Table.Head
              getHeaderStructure={(header) => {
                if (header.id === 'select') {
                  return (
                    <SelectColumn
                      selectedStock={selectedStock}
                      setSelectedStock={setSelectedStock}
                      currentStock={data?.data as PostRelaysResponse[]}
                    />
                  );
                } else {
                  return (
                    <FilterColumn
                      header={header}
                      order={order}
                      setOrder={setOrder}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      showFilterOption={isFilterColumn(header.id)}
                      showSortOption={isSortColumn(header.id)}
                      filterOptions={
                        isSensorManagement
                          ? distinctSensorFilters?.[header.id] || []
                          : distinctRelayFilters?.[header.id] || []
                      }
                      filter={filter}
                      setFilter={setFilter}
                      isLastColumn={header.id === 'comment'}
                    />
                  );
                }
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(
                  sensor: PostSensorsResponse | PostRelaysResponse,
                  index: number,
                ) =>
                  getRowStructure(
                    isSensorManagement,
                    sensor,
                    index,
                    selectedStock,
                    setSelectedStock,
                    statusesPrefixedT,
                  )
                }
                striped
              />
            ) : (
              <h3>{prefixedT('NO_DATA')}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Table.Pagination
              pages={Math.ceil((data?.count || 1) / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default StockTable;
