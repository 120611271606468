import styled from 'styled-components';

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SuccessMessage = styled.div``;

export const InfoWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 20px;
  flex: 1;
  background-color: #fff;
`;

export const TickBoxAndHistoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

export const InfoAndMapWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
`;

export const MapWrapper = styled.div`
  flex: 1;
`;

export const InfoWrapperTitle = styled.div`
  margin-bottom: 20px;
`;

export const BtnContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
`;

export const ErrorContainer = styled.div``;
