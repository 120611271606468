import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  margin: 30px 0;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  width: 300px;
`;

export const List = styled.ul`
  list-style-type: none;
  line-height: 40px;
  width: 100%;
  margin-top: 20px;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
