import { RootState } from '@/redux';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import config from '../config';

export const gisApiBaseQuery = fetchBaseQuery({
  baseUrl: config.GIS_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    const azureToken = (getState() as RootState).auth.azureToken;

    headers.set('X-SOURCE', 'cms');
    if (token) {
      headers.set('user-token', `${token}`);
    }
    if (azureToken) {
      headers.set('Authorization', `Bearer ${azureToken}`);
    }

    return headers;
  },
});

export const apiBaseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    headers.set('X-SOURCE', 'cms');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const authBaseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  credentials: 'include',
  prepareHeaders(headers) {
    headers.set('X-SOURCE', 'cms');
    return headers;
  },
});

export const aiAssistantBaseQuery = fetchBaseQuery({
  baseUrl: config.ASK_FIDO_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    headers.set('X-SOURCE', 'cms');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const genericFetchQuery = fetchBaseQuery();
