import React, { FC } from 'react';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Button } from '@/components';

interface EditModalProps {
  closeOverlay: (refetchValues: boolean) => Promise<void>;
  isOpen: boolean;
  translationPrefix: string;
  children: React.ReactNode;
}

const EditModal: FC<EditModalProps> = ({
  closeOverlay,
  isOpen,
  translationPrefix,
  children,
}) => {
  const { prefixedT } = useCustomTranslation(translationPrefix);

  return (
    isOpen && (
      <>
        <Styled.Underlay
          data-testid={'overlay'}
          onClick={() => {
            void (async () => {
              await closeOverlay(false);
            })();
          }}
        />
        <Styled.DialogBox open={isOpen}>
          <Styled.Wrapper>
            <Styled.TitleContainer>
              <h3>{prefixedT('PAGE_TITLE')}</h3>
            </Styled.TitleContainer>
            {children}
            <Styled.ButtonContainer>
              <Button
                type="submit"
                onClick={() => {
                  void (async () => {
                    await closeOverlay(false);
                  })();
                }}
              >
                {prefixedT('RETURN_BTN')}
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  // TODO do we pass in a trigger or something
                }}
              >
                {prefixedT('SUBMIT_BTN')}
              </Button>
            </Styled.ButtonContainer>
          </Styled.Wrapper>
        </Styled.DialogBox>
      </>
    )
  );
};

export default EditModal;
