import {
  InsertDateParams,
  UserRole,
  GetEngineersResponse,
  GetDashboardResponse,
  GetPartnerDashboardParams,
  GetCompanyDashboardParams,
  UserListData,
  PostSaveFlowDataParams,
  PostSaveFlowDataValues,
  PostViewSessionsResponse,
  PostViewSessionsBody,
  GetViewSessionsDistinctResponse,
  PostViewWaypointsResponse,
  PostViewWaypointsBody,
  GetViewWaypointsDistinctResponse,
  GetViewJobsDistinctResponse,
  PostViewJobsBody,
  PostViewJobsResponse,
  PostViewWaypointLogsBody,
  PostViewWaypointLogsResponse,
  GetViewWaypointLogsDistinctResponse,
  PostViewRelaysBody,
  PostViewRelaysResponse,
  GetViewRelaysDistinctResponse,
} from '@/types/portalUsers.type';
import {
  GetDeviceDistributionResponse,
  EditSensorValues,
  PostSensorsResponse,
  PostRelaysResponse,
  EditRelayValues,
  SensorFilterOptions,
  RelayFilterOptions,
} from '@/types/stock.type';
import {
  IDateSearchProps,
  IPaginationProps,
  IPaginationResponse,
  IPaginationSnakeCaseProps,
  iSearchProps,
  ISortBy,
  IStockFilterRTK,
  RealISort,
} from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';
export interface AddUserBody {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  role_id: number | null;
  partner_id: number | null;
  company_id: number | null;
  status?: string;
  active?: string;
  redirectUrl?: string;
}

export const portalUsersApi = createApi({
  reducerPath: 'portalUsersQuery',
  baseQuery: apiBaseQuery,
  tagTypes: [
    'InsertDates',
    'AddUser',
    'GetRoles',
    'DashboardInfo',
    'getEngineers',
    'getUserList',
    'getPortalUsers',
    'postSensors',
    'getSensorDistribution',
    'patchSensors',
    'postRelays',
    'getRelayDistribution',
    'patchRelays',
  ],
  endpoints: (builder) => ({
    insertDates: builder.mutation({
      query: (params: InsertDateParams) => ({
        url: `/portal_users/insert_dates?${buildQueryParams(params)}`,
        method: 'POST',
      }),
      invalidatesTags: ['InsertDates'],
    }),
    addUser: builder.mutation<undefined, { body: AddUserBody }>({
      query: ({ body }) => ({
        url: '/portal_users/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AddUser'],
    }),
    getRoles: builder.query<UserRole[], void>({
      query: () => '/portal_users/roles/list',
      providesTags: ['GetRoles'],
    }),
    getPartnerDashboard: builder.mutation<
      GetDashboardResponse[],
      GetPartnerDashboardParams
    >({
      query: (params: GetPartnerDashboardParams) =>
        `/portal_users/dashboard?${buildQueryParams(params || {})}`,
      invalidatesTags: ['DashboardInfo'],
    }),
    getCompanyDashboard: builder.mutation<
      GetDashboardResponse[],
      { id: number; params: GetCompanyDashboardParams }
    >({
      query: ({ id, params }) =>
        `/portal_users/dashboard/${id}/branches?${buildQueryParams(params || {})}`,
      invalidatesTags: ['DashboardInfo'],
    }),
    getEngineers: builder.query<GetEngineersResponse[], void>({
      query: () => 'portal_users/engineers',
      providesTags: ['getEngineers'],
    }),
    getUserList: builder.query<
      IPaginationResponse<UserListData>,
      IPaginationProps & iSearchProps
    >({
      query: (params) =>
        `portal_users/user_dashboard?${buildQueryParams(params || {})}`,
      providesTags: ['getUserList'],
    }),
    getPortalUsers: builder.query<UserListData[], void>({
      query: () => 'portal_users/list',
      providesTags: ['getPortalUsers'],
    }),
    postSensors: builder.query<
      IPaginationResponse<PostSensorsResponse>,
      IPaginationSnakeCaseProps &
        RealISort &
        iSearchProps &
        IStockFilterRTK &
        IDateSearchProps
    >({
      query: ({ filter, ...params }) => ({
        url: `portal_users/stock/sensors?${buildQueryParams(params)}`,
        method: 'POST',
        body: { filter },
      }),
      providesTags: ['postSensors'],
    }),
    getSensorDistribution: builder.query<
      GetDeviceDistributionResponse,
      IDateSearchProps
    >({
      query: (params) => ({
        url: `portal_users/stock/sensors/distribution?${buildQueryParams(params)}`,
        method: 'GET',
      }),
      providesTags: ['getSensorDistribution'],
    }),
    patchSensors: builder.mutation<undefined, { body: EditSensorValues }>({
      query: ({ body }) => ({
        url: 'portal_users/stock/sensors',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['patchSensors'],
    }),
    postRelayTracking: builder.query<
      IPaginationResponse<PostRelaysResponse>,
      IPaginationSnakeCaseProps &
        RealISort &
        iSearchProps &
        IStockFilterRTK &
        IDateSearchProps
    >({
      query: ({ filter, ...params }) => ({
        url: `portal_users/stock/relay/tracking?${buildQueryParams(params)}`,
        method: 'POST',
        body: { filter },
      }),
      providesTags: ['postRelays'],
    }),
    getRelayDistribution: builder.query<
      GetDeviceDistributionResponse,
      IDateSearchProps
    >({
      query: (params) => ({
        url: `portal_users/stock/relay/distribution?${buildQueryParams(params)}`,
        method: 'GET',
      }),
      providesTags: ['getRelayDistribution'],
    }),
    patchRelays: builder.mutation<undefined, { body: EditRelayValues }>({
      query: ({ body }) => ({
        url: 'portal_users/stock/relay',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['patchRelays'],
    }),
    getDistinctSensorFilters: builder.query<SensorFilterOptions, void>({
      query: () => 'portal_users/stock/sensors/distinct',
    }),
    getDistinctRelayFilters: builder.query<RelayFilterOptions, void>({
      query: () => 'portal_users/stock/relay/distinct',
    }),
    postSaveFlowData: builder.mutation<
      void,
      { body: PostSaveFlowDataValues; params: PostSaveFlowDataParams }
    >({
      query: ({ body, params }) => ({
        url: `/portal_users/save_flow_data?${buildQueryParams(params)}`,
        method: 'POST',
        body,
      }),
    }),
    postViewSessions: builder.query<
      PostViewSessionsResponse,
      {
        body: PostViewSessionsBody;
        query: IPaginationSnakeCaseProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/sessions?${buildQueryParams(query)}`,
        method: 'POST',
        body,
      }),
    }),
    getViewSessionsDistinct: builder.query<
      GetViewSessionsDistinctResponse,
      void
    >({
      query: () => ({
        url: '/portal_users/view/sessions/distinct',
      }),
    }),
    postViewWaypoints: builder.query<
      PostViewWaypointsResponse,
      {
        body: PostViewWaypointsBody;
        query: IPaginationSnakeCaseProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypoints?${buildQueryParams(query)}`,
        method: 'POST',
        body,
      }),
    }),
    getViewWaypointsDistinct: builder.query<
      GetViewWaypointsDistinctResponse,
      void
    >({
      query: () => ({
        url: '/portal_users/view/waypoints/distinct',
      }),
    }),
    postViewJobs: builder.query<
      PostViewJobsResponse,
      {
        body: PostViewJobsBody;
        query: IPaginationSnakeCaseProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypointjobs?${buildQueryParams(query)}`,
        method: 'POST',
        body,
      }),
    }),
    getViewJobsDistinct: builder.query<GetViewJobsDistinctResponse, void>({
      query: () => ({
        url: '/portal_users/view/waypointjobs/distinct',
      }),
    }),
    postViewWaypointLogs: builder.query<
      PostViewWaypointLogsResponse,
      {
        body: PostViewWaypointLogsBody;
        query: IPaginationSnakeCaseProps &
          ISortBy &
          iSearchProps &
          IDateSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/waypointlogs?${buildQueryParams(query)}`,
        method: 'POST',
        body,
      }),
    }),
    getViewWaypointLogsDistinct: builder.query<
      GetViewWaypointLogsDistinctResponse,
      void
    >({
      query: () => ({
        url: '/portal_users/view/waypointlogs/distinct',
      }),
    }),
    postViewRelays: builder.query<
      PostViewRelaysResponse,
      {
        body: PostViewRelaysBody;
        query: IPaginationSnakeCaseProps & ISortBy & iSearchProps;
      }
    >({
      query: ({ body, query }) => ({
        url: `/portal_users/view/relay?${buildQueryParams(query)}`,
        method: 'POST',
        body,
      }),
    }),
    getViewRelaysDistinct: builder.query<GetViewRelaysDistinctResponse, void>({
      query: () => ({
        url: '/portal_users/view/relay/distinct',
      }),
    }),
  }),
});

export const {
  useInsertDatesMutation,
  useGetPartnerDashboardMutation,
  useGetCompanyDashboardMutation,
  useAddUserMutation,
  useGetRolesQuery,
  useGetEngineersQuery,
  useGetUserListQuery,
  useLazyGetUserListQuery,
  useGetPortalUsersQuery,
  useLazyGetSensorDistributionQuery,
  useLazyPostSensorsQuery,
  usePatchSensorsMutation,
  useLazyGetRelayDistributionQuery,
  useLazyPostRelayTrackingQuery,
  usePatchRelaysMutation,
  useLazyGetDistinctSensorFiltersQuery,
  useLazyGetDistinctRelayFiltersQuery,
  usePostSaveFlowDataMutation,
  useLazyPostViewSessionsQuery,
  useGetViewSessionsDistinctQuery,
  useLazyPostViewWaypointsQuery,
  useGetViewWaypointsDistinctQuery,
  useLazyPostViewJobsQuery,
  useGetViewJobsDistinctQuery,
  useLazyPostViewWaypointLogsQuery,
  useGetViewWaypointLogsDistinctQuery,
  useLazyPostViewRelaysQuery,
  useGetViewRelaysDistinctQuery,
} = portalUsersApi;
