import { IAnimationProps, IRectConfig } from '@/types/common.type';
import moment from 'moment';
import React, { FC, RefObject, useState } from 'react';
import { RangeKeyDict, DateRange as RangePicker } from 'react-date-range';
import { createPortal } from 'react-dom';
import * as Styled from './style';

interface IProps {
  dateRangeRef: RefObject<HTMLDivElement>;
  animationProps: IAnimationProps;
  onChange: (data: (Date | undefined)[]) => void;
  value: [Date, Date];
  minMax: boolean;
  rectConfig: IRectConfig | undefined;
}

const DateRangePicker: FC<IProps> = ({
  dateRangeRef,
  animationProps,
  minMax,
  onChange,
  value,
  rectConfig,
}) => {
  const [firstDateSelected, setFirstDateSelected] = useState(false);

  const handleDateChange = (rangeValues: RangeKeyDict) => {
    const startDate = rangeValues.selection.startDate as Date;
    const endDate = rangeValues.selection.endDate as Date;

    if (startDate && !firstDateSelected) {
      const maxDate = moment(startDate).add(14, 'days').toDate();
      onChange([startDate, endDate]);
      setFirstDateSelected(true);
      return {
        ...rangeValues.selection,
        endDate: maxDate,
      };
    }
    onChange([startDate, endDate]);
    setFirstDateSelected(false);
    return rangeValues.selection;
  };

  const minSelectableDate = firstDateSelected
    ? moment(value[0]).subtract(14, 'days').toDate()
    : undefined;

  const maxSelectableDate = firstDateSelected
    ? moment(value[0]).toDate() // Allow selection up to the first selected date
    : undefined;

  const PickerList = (
    <Styled.PickerContainer
      config={{
        isOverflow: rectConfig?.isOverflow,
        width: rectConfig?.width || 0,
        top: rectConfig?.top || 0,
        left: rectConfig?.left || 0,
        isPortal: rectConfig?.isPortal,
      }}
      ref={dateRangeRef}
      {...animationProps}
    >
      <RangePicker
        editableDateInputs={true}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        minDate={minMax ? minSelectableDate : undefined}
        maxDate={minMax ? maxSelectableDate : undefined}
        dateDisplayFormat="dd MM yyyy"
        ranges={[
          {
            startDate: value ? value[0] : undefined,
            endDate: value ? value[1] : undefined,
            key: 'selection',
          },
        ]}
      />
    </Styled.PickerContainer>
  );

  return rectConfig?.isPortal
    ? createPortal(PickerList, document.body)
    : PickerList;
};

export default DateRangePicker;
