import { useGetProfileQuery } from '@/redux/user/user.api';
import { UserRoles } from '@/types/user.type';
import React from 'react';
import UserManagement from './UserManagement';

const UsersContainer = () => {
  const { data: profile } = useGetProfileQuery();

  const role = profile?.role?.role_code || ('' as UserRoles);
  return (
    <>
      <UserManagement role={role} />
    </>
  );
};

export default UsersContainer;
