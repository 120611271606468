import { Map } from 'mapbox-gl';
import * as turf from '@turf/turf';
import {
  MapFilters,
  SessionRelocationObject,
} from '@/Providers/FilterProvider';
import { getSourceData, layerID } from '../utils';
import { updateDraggedFeature } from './updateDraggedFeature';
import { onMove } from './onMove';
import { PointTooltipType } from '../../../../utils/types';
import { onUp } from './onUp';
import { updatePolyArea } from './updatePolyArea';

export const initializeDragOperation = (
  isDraggingRef: React.MutableRefObject<boolean>,
  map: Map,
  originalCoordsRef: React.MutableRefObject<{
    lng: number;
    lat: number;
  } | null>,
  mapFilters: MapFilters,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
  polygonCircleCoordinatesRef: React.MutableRefObject<
    turf.helpers.Position[][] | undefined
  >,
  setSessionRelocationObject: React.Dispatch<
    React.SetStateAction<SessionRelocationObject>
  >,
) => {
  map.on('mousedown', layerID, (event) => {
    isDraggingRef.current = true;
    event.preventDefault();
    const data = getSourceData(map);
    const eventFeature = event.features && event.features[0];
    const mouseDownItem =
      eventFeature && (eventFeature.properties?.tooltip as string);
    const tooltip =
      mouseDownItem && (JSON.parse(mouseDownItem) as PointTooltipType);
    originalCoordsRef.current = event.lngLat;
    if (
      tooltip &&
      tooltip.Session_id &&
      mapFilters.filters.includes('ASSETS')
    ) {
      const featureIndex = data.findIndex(
        (feat) =>
          feat.properties && feat.properties.id === eventFeature.properties?.id,
      );

      draggedFeatureIndexRef.current = featureIndex;

      if (draggedFeatureIndexRef.current !== -1) {
        updatePolyArea(map, event, polygonCircleCoordinatesRef);

        const coordinates = event.lngLat.toArray();
        updateDraggedFeature(map, draggedFeatureIndexRef, coordinates);
      }

      map.getCanvas().style.cursor = 'grab';

      map.on('mousemove', (e) =>
        onMove(map, draggedFeatureIndexRef, isDraggingRef, e),
      );
      map.once('mouseup', (e) =>
        onUp(
          map,
          isDraggingRef,
          polygonCircleCoordinatesRef,
          e,
          originalCoordsRef,
          draggedFeatureIndexRef,
          setSessionRelocationObject,
        ),
      );
    }
  });
};
