import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 15px;

  .pagination {
    display: flex;
    list-style: none;
    user-select: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    & > * {
      margin-right: 10px;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  .pagination__page,
  .pagination__next,
  .pagination__prev {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.gray};
    border-radius: 4px;
    border: 1px solid
      ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
    height: 30px;
    width: 30px;
    display: flex;
    background-color: transparent;
  }

  .pagination__page-active {
    background-color: ${({ theme }: ThemeProps<Theme>) =>
      theme.colors.lightBlue};
    border-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightBlue};
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }

  .pagination__link,
  .pagination__next-link,
  .pagination__prev-link {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .pagination__disabled {
    opacity: 0.5;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    justify-content: center;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-right: 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
`;
