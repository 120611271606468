import { Map } from 'mapbox-gl';
import { FeatureCollection, Point, Feature, GeoJsonProperties } from 'geojson';
import { clusterLayerID } from '../utils';

export const resetFeatLocation = (
  map: Map,
  data: Feature<Point, GeoJsonProperties>[],
  originalCoordsRef: React.MutableRefObject<{
    lng: number;
    lat: number;
  } | null>,
  draggedFeatureIndexRef: React.MutableRefObject<number>,
) => {
  if (originalCoordsRef.current) {
    data[draggedFeatureIndexRef.current].geometry.coordinates = [
      originalCoordsRef.current.lng,
      originalCoordsRef.current.lat,
    ];
    const setOriginalConfig: FeatureCollection = {
      type: 'FeatureCollection',
      features: data,
    };
    (map.getSource(clusterLayerID) as mapboxgl.GeoJSONSource).setData(
      setOriginalConfig,
    );
  }
};
