import Button from '@/components/Button/Button';
import Loader from '@/components/Loader/Loader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import { usePostAssignWaypointsMutation } from '@/redux/highlandPortal/highlandPortal.api';
import { GetEngineersResponse } from '@/types/portalUsers.type';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './style';
import Search from '@/components/Search/Search';

interface AllocateLeakEngineerProps {
  engineers: GetEngineersResponse[];
}

interface Engineer {
  id: number | null;
  name: string;
}

export const AllocateLeakEngineer: FC<AllocateLeakEngineerProps> = ({
  engineers,
}) => {
  const { prefixedT } = useCustomTranslation(
    'COMPONENTS.ALLOCATE_LEAK_ENGINEER',
  );
  const { selectedWaypoints, setSelectedWaypoints } = useContext(
    WaypointFilterContext,
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const defaultEngineer: Engineer = { id: null, name: '' };
  const [mutate, { isSuccess, isError, isLoading, reset }] =
    usePostAssignWaypointsMutation();

  const [selectedEngineer, setSelectedEngineer] =
    useState<Engineer>(defaultEngineer);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClick = () => {
    setOpen(!open);
    reset();
  };

  const sortedEngineers = useMemo(() => {
    const sortedEngineers = engineers.toSorted((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return sortedEngineers;
  }, [engineers]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredEngineers = useMemo(() => {
    return sortedEngineers.filter((engineer) =>
      engineer.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [sortedEngineers, searchQuery]);

  const handleSelectedEngineer = (engineer: Engineer) => {
    setSelectedEngineer(engineer);
    setSearchQuery('');
  };

  const handleSelectedWaypoint = (waypoint: number) => {
    setSelectedWaypoints([
      ...selectedWaypoints.filter((item) => item.id !== waypoint),
    ]);
  };

  const handleSubmit = () => {
    setError(false);
    if (selectedEngineer.id !== null && selectedWaypoints.length !== 0) {
      mutate({
        query: { user_id: selectedEngineer.id },
        body: selectedWaypoints.map((item) => item.id),
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedWaypoints([]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (selectedEngineer && selectedWaypoints.length !== 0) {
      setError(false);
    }
  }, [selectedWaypoints, selectedEngineer]);

  return (
    <Styled.Wrapper data-testid="allocateLeakEngineer">
      <Styled.Container>
        <Button onClick={handleClick}>{prefixedT('ALLOCATE_LE')}</Button>
        {open && (
          <>
            <Styled.Underlay onClick={handleClick} />
            <Styled.DialogBox open={Boolean(open)}>
              <Styled.Cross data-testid="cross" onClick={handleClick}>
                {prefixedT('CLOSE')}
              </Styled.Cross>
              {isLoading ? (
                <Loader />
              ) : (
                <Styled.StatusWrapper>
                  <Styled.StatusTitle>
                    {prefixedT('ASSIGN_WAYPOINTS')}
                  </Styled.StatusTitle>
                  <Styled.StatusContainer>
                    {selectedEngineer.id ? (
                      <div>
                        <Styled.EngineerDetails>
                          <p>
                            {prefixedT('OPTION_ENGINEER')}{' '}
                            {selectedEngineer.name}
                          </p>
                          <Button
                            onClick={() =>
                              handleSelectedEngineer(defaultEngineer)
                            }
                          >
                            {prefixedT('CHANGE')}
                          </Button>
                        </Styled.EngineerDetails>
                        {!isSuccess && (
                          <Styled.SelectedWaypoints>
                            {selectedWaypoints.length === 0 && (
                              <p>{prefixedT('NO_WAYPOINTS')}</p>
                            )}
                            {selectedWaypoints.map((waypoint, waypointKey) => (
                              <Styled.WaypointDetails key={waypointKey}>
                                <Styled.WaypointName>
                                  {waypoint.name}
                                </Styled.WaypointName>
                                <Styled.WaypointBtnContainer>
                                  <Button
                                    onClick={() =>
                                      handleSelectedWaypoint(waypoint.id)
                                    }
                                  >
                                    {prefixedT('REMOVE')}
                                  </Button>
                                </Styled.WaypointBtnContainer>
                              </Styled.WaypointDetails>
                            ))}
                          </Styled.SelectedWaypoints>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Styled.SearchContainer>
                          <Search
                            placeholder={prefixedT('SEARCH.PLACEHOLDER')}
                            handleSearch={handleSearch}
                            testid="allocateLeakEngineerSearch"
                          />
                        </Styled.SearchContainer>
                        <Styled.EngineerListContainer>
                          {filteredEngineers.map((engineer, key) => (
                            <Styled.StatusItem
                              onClick={() => handleSelectedEngineer(engineer)}
                              key={key}
                            >
                              <p>{engineer.name}</p>
                            </Styled.StatusItem>
                          ))}
                        </Styled.EngineerListContainer>
                      </div>
                    )}
                  </Styled.StatusContainer>
                  {error && (
                    <Styled.Status locationTop>
                      <p>{prefixedT('SELECTING_ERROR')}</p>
                    </Styled.Status>
                  )}
                  <Button onClick={handleSubmit}>
                    {prefixedT('SUBMIT_BTN')}
                  </Button>
                  {isError && (
                    <Styled.Status>
                      <p>{prefixedT('API_ERROR')}</p>
                    </Styled.Status>
                  )}
                  {isSuccess && (
                    <Styled.Status success>
                      <p>{prefixedT('API_SUCCESS')}</p>
                    </Styled.Status>
                  )}
                </Styled.StatusWrapper>
              )}
            </Styled.DialogBox>
          </>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};
