import React, { FC } from 'react';
import SearchableTable from '@/components/SearchableTable/SearchableTable';
import * as Styled from './style';
import { getRowStructure, headers } from './utils/utils';
import { Button } from '@/components';
import PlusIcon from '@/assets/icons/plus-icon.png';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useLazyGetPartnersQuery } from '@/redux/portalPartners/portalPartners.api';
import EditPartner from './EditPartner/EditPartner';

const ViewPartners: FC = () => {
  const [trigger, { data: partnerListData, isFetching, isError }] =
    useLazyGetPartnersQuery();

  const { prefixedT } = useCustomTranslation('VIEW_PARTNERS');

  return (
    <SearchableTable
      queryResponse={partnerListData}
      isFetching={isFetching}
      isError={isError}
      ExtraHeaderComponent={
        <Styled.BtnContainer data-testid="add-partners-btn">
          <Styled.Link to={'/app/user-management/add-partner'}>
            <Button>
              {prefixedT('BUTTON.ADD')}{' '}
              <Styled.PlusImg src={PlusIcon as string} alt="plus-icon" />
            </Button>
          </Styled.Link>
        </Styled.BtnContainer>
      }
      trigger={trigger}
      editModal={(idx, closeOverlay) => {
        const currentPartner = partnerListData?.data[idx];
        if (currentPartner !== undefined) {
          return (
            <EditPartner
              key={idx}
              partner_id={currentPartner.id}
              partner_name={currentPartner.partner_name}
              partner_code={currentPartner.partner_code}
              country_code={currentPartner.country_code}
              logo={''}
              closeOverlay={closeOverlay}
            />
          );
        }
      }}
      getRowStructure={getRowStructure}
      headers={headers}
      translationPrefix="VIEW_PARTNERS"
    />
  );
};

export default ViewPartners;
