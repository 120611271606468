import React from 'react';
import * as Styled from './style';
import { DatePicker, DropDown, TextField } from '@/components';

import { DropDownOption } from '@/components/DropDown/DropDown';
import TextArea from '@/components/TextArea/TextArea';
import { FormikErrors, Field } from 'formik';
import moment from 'moment';
import { patchWaypointJobBody } from '@/types/report.type';

export interface OptionProps {
  title: string;
  option: string | number | Date | null;
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<patchWaypointJobBody>>;
  placeholder?: string;
  testid: string;
  dropdown?: boolean;
  dropdownValues?: DropDownOption[];
  date?: boolean;
  name?: string;
  input?: boolean;
  type?: string;
}

export const Option = ({
  title,
  dropdown,
  dropdownValues,
  option,
  type,
  edit,
  input,
  placeholder,
  date,
  testid,
  name,
  setFieldValue,
}: OptionProps) => {
  const renderOption = () => {
    if (!edit && !date) {
      return <Styled.ListObject>{option?.toString()}</Styled.ListObject>;
    } else if (!edit && date && option) {
      return (
        <Styled.ListObject>
          {moment(option).format('YYYY-MM-DD')}
        </Styled.ListObject>
      );
    }
    return null;
  };

  const renderTextArea = () => {
    if (input && edit && setFieldValue && name && type === 'textarea') {
      return (
        <Field
          as={TextArea}
          name={name}
          type={type || 'text'}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDateField = () => {
    if (date && edit && setFieldValue && name) {
      return (
        <Field
          as={DatePicker}
          name={name}
          placeholder={placeholder}
          onChange={(dateValue: Date) => {
            void setFieldValue(name, dateValue ? dateValue : null);
          }}
          testId={testid}
        />
      );
    }
    return null;
  };

  const renderInputField = () => {
    if (input && edit && setFieldValue && name && type !== 'textarea') {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (type === 'number') {
          void setFieldValue(name, value === '' ? null : value);
        } else {
          void setFieldValue(name, value);
        }
      };
      return (
        <Field
          as={TextField}
          onChange={handleChange}
          name={name}
          type={type || 'text'}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDropdownField = () => {
    if (dropdown && edit && setFieldValue && name && dropdownValues) {
      return (
        <Styled.DropDownContainer>
          <Field
            name={name}
            as={DropDown}
            testId={testid}
            styles={{ border: true }}
            options={dropdownValues.map((value: DropDownOption) => ({
              value: value.value,
              label: value.label,
            }))}
            placeholder={option || placeholder}
            onChange={(optionDropdown: DropDownOption) =>
              setFieldValue(name, optionDropdown.value)
            }
          />
        </Styled.DropDownContainer>
      );
    }
    return null;
  };

  return (
    <Styled.OptionList edit={edit}>
      {title}: {renderOption()}
      <Styled.Option>
        {renderInputField()}
        {renderDropdownField()}
        {renderDateField()}
        {renderTextArea()}
      </Styled.Option>
    </Styled.OptionList>
  );
};
