import React from 'react';
import { Table } from '@/components';
import * as Styled from '../style';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import { GISAdminPageResponse } from '@/types/GISAdmin.type';
import moment from 'moment';
import useCustomTranslation from '@/localization/useCustomTranslation';

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('GIS_ADMIN.TABLE.HEADERS');
  return [
    {
      id: 'companyName',
      title: prefixedT('A'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'dateUploaded',
      title: prefixedT('B'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'UploadedBy',
      title: prefixedT('C'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'action',
      title: prefixedT('D'),
      sortable: false,
      align: TableCellAlignment.center,
    },
  ];
};

export const formatDate = (dateUploaded: string): string => {
  return moment(dateUploaded, moment.ISO_8601, true).isValid()
    ? moment(dateUploaded).format('YYYY-MM-DD')
    : '';
};

export const getRowStructure = (
  row: GISAdminPageResponse,
  idx: number,
  handleDeleteClick: (idx: number) => void,
  handleViewClick: (idx: number, companyId: number) => void,
) => {
  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.center} headerId="companyName">
        {row.company_name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="dateUploaded">
        {formatDate(row.date_uploaded)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="UploadedBy">
        {row.uploaded_by}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Styled.ImgContainer>
          <a
            onClick={() => handleViewClick(row.id, row.company_id)}
            data-testid="preview-icon"
          >
            <Styled.ViewIcon />
          </a>
          <a
            onClick={() => handleDeleteClick(row.id)}
            data-testid="delete-icon"
          >
            <Styled.DeleteIcon />
          </a>
        </Styled.ImgContainer>
      </Table.Cell>
    </Table.Row>
  );
};
