import React, { FC } from 'react';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Column } from '@/types/gisUpload.type';
import {
  ErrorModalText,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';

interface FileInformationProps {
  insufficientSampleData: boolean;
  unselectedColumns: {
    required: Column[];
    optional: Column[];
  };
  hasNonRequiredField: boolean;
}

export const FileInformation: FC<FileInformationProps> = ({
  insufficientSampleData,
  unselectedColumns,
  hasNonRequiredField,
}) => {
  const { prefixedT } = useCustomTranslation('PROCESS_FILES');
  const { isError } = useGISUploadValues();

  return (
    <Styled.FileContainer>
      {insufficientSampleData ? (
        <Styled.FileDescText>
          {prefixedT('ERROR.INSUFFICIENT_COLUMNS')}
        </Styled.FileDescText>
      ) : (
        <>
          <Styled.FileDescText>{prefixedT('INSTRUCTIONS')}</Styled.FileDescText>
          <div>
            {unselectedColumns.required.length > 0 ? (
              <Styled.FileColumnText
                error={isError?.includes(ErrorModalText.COLUMNS_NOT_SELECTED)}
                data-testid="file-column-text"
              >
                {prefixedT('COLUMNS_SELECT')}:{' '}
                {unselectedColumns.required
                  .map((column) => column.column_name)
                  .join(', ')}
              </Styled.FileColumnText>
            ) : (
              <Styled.FileColumnText>
                {prefixedT('COLUMNS_SELECTED')}
              </Styled.FileColumnText>
            )}
          </div>
          <div>
            {hasNonRequiredField && (
              <>
                {unselectedColumns.optional.length > 0 ? (
                  <Styled.FileColumnText data-testid="file-column-text">
                    {prefixedT('OPTION_COLUMNS_SELECT')}:{' '}
                    {unselectedColumns.optional
                      .map((column) => column.column_name)
                      .join(', ')}
                  </Styled.FileColumnText>
                ) : (
                  <Styled.FileColumnText>
                    {prefixedT('OPTIONAL_COLUMNS_SELECTED')}
                  </Styled.FileColumnText>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Styled.FileContainer>
  );
};
