import { ReactComponent as ArrowDown } from '@/assets/icons/arrow_down.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as LogoIcon } from '@/assets/icons/logo_white.svg';
import { ReactComponent as ExitIcon } from '@/assets/icons/sidebar/exit.svg';
import { Theme } from '@/theme';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import styled, { ThemeProps } from 'styled-components';

const EXPANDED_SIDEBAR = 250;
const COLLAPSED_SIDEBAR = 70;

export const Wrapper = styled.div<{
  open: boolean;
  openMobile: boolean;
}>`
  position: fixed;
  height: 100vh;
  min-width: ${({ open }) => (open ? EXPANDED_SIDEBAR : COLLAPSED_SIDEBAR)}px;
  width: ${({ open }) => (open ? EXPANDED_SIDEBAR : COLLAPSED_SIDEBAR)}px;
  transition:
    0.3s ease width,
    0.3s ease height,
    0.3s ease min-width,
    0.3s ease transform;
  z-index: 999;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    transform: ${({ openMobile }) =>
      openMobile ? 'translateX(0)' : 'translateX(-100%)'};
  }
`;

export const MobileBlocker = styled(motion.div)`
  display: none;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumDark}40;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumDark}40;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled.div<{
  open: boolean;
  openMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  width: 100%;
  overflow: hidden;
  position: ${({ open }) => (open ? 'absolute' : 'static')};
  z-index: 999;
  top: 0;
  left: 0;
  .sidebar__title {
    opacity: ${({ open, openMobile }) => (openMobile ? 1 : open ? 1 : 0)};
    transition: 0.3s ease opacity;
  }
  .sidebar__logo {
    width: ${({ open, openMobile }) =>
      openMobile ? '100px' : open ? '100px' : '50px'};
    height: ${({ open, openMobile }) =>
      openMobile ? '40px' : open ? '40px' : '20px'};
    margin-bottom: ${({ open, openMobile }) =>
      openMobile ? '20px' : open ? '20px' : '40px'};
    transition:
      0.3s ease width,
      0.3s ease height,
      0.3s ease margin-bottom;
  }
  ${({ open }) =>
    !open
      ? `
  &:hover{
    width: ${EXPANDED_SIDEBAR}px;
    .sidebar__title {
      opacity: 1;
    }
    .sidebar__logo {
      width: 100px;
      height: 40px;
      margin-bottom: 20px;
      transition: 0.3s ease width, 0.3s ease height, 0.3s ease margin-bottom;
    }
  }
`
      : null};
  overflow-y: auto;
  transition:
    0.3s ease width,
    0.3s ease height,
    0.3s ease margin-bottom;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
  }
`;

export const MainSection = styled.div``;

export const HeadBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
  position: relative;
  align-items: baseline;
  padding-left: 30px;
`;

export const CloseSidebarIcon = styled(CloseIcon)`
  display: none;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    right: calc(100% - 48px);
    transform: translateY(50%);
    cursor: pointer;
  }
`;

export const LogoContainer = styled(LogoIcon)`
  margin-top: 40px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  white-space: nowrap;
  height: 19px;
  overflow: hidden;
`;

export const MobileProfile = styled.div`
  display: none;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    margin-left: 25px;
  }
`;

export const MobileSeparator = styled.div`
  display: none;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
    width: 100%;
    border-top: 1px solid
      ${({ theme }: ThemeProps<Theme>) => theme.colors.gray}26;
    margin: 20px 0;
  }
`;

export const MenuBlock = styled.div``;

export const List = styled.div`
  & > * {
    margin-bottom: 15px;
  }
  & > * :last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    & > * {
      margin-bottom: 5px;
    }
    & > * :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ItemLink = styled(NavLink)`
  height: 44px;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.body};
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.body};
    transition: 0.3s ease fill;
  }
  & > svg {
    margin: 0 25px;
    min-width: 20px;
  }
  & > * :last-child {
    margin-right: 0;
  }
  &.active {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    & > svg path {
      fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    }
  }
  :hover {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    & > svg path {
      fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    }
  }
  transition: 0.3s ease background-color;
`;

export const ItemText = styled.p``;

export const SeparatorWrapper = styled.div`
  padding: 0 20px;
`;

export const Separator = styled.div`
  min-height: 1px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.gray}26;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    margin-bottom: 10px;
  }
`;

export const LogoutSection = styled.div`
  margin-bottom: 40px;
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.body};
  & > svg path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.body};
    transition: 0.3s ease fill;
  }
  :hover {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    & > svg path {
      fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
    }
  }
  transition: 0.3s ease background;
`;

export const Icon = styled(ExitIcon)`
  min-width: 20px;
  margin: 0 25px;
`;

export const Text = styled.p``;

export const VersionText = styled.p`
  font-size: 10px;
  padding-left: 10px;
  padding-top: 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const Dropdown = styled.div``;

export const DropdownParent = styled.div`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  font-size: 12px;
  font-weight: 500;
`;

export const DropdownContent = styled.div`
  a {
    margin-top: 10px;
  }
`;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
}>`
  fill: #fff;
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  padding-left: 10px;
`;

export const DropdownParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 44px;
`;
