import useCustomTranslation from '@/localization/useCustomTranslation';
import { RootState } from '@/redux';
import { setActiveAccount } from '@/redux/activeAccount/activeAccount.slice';
import { useGetPortalPartnersQuery } from '@/redux/reports/reports.api';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { PortalPartnersResponse } from '@/types/report.type';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MultiLevelDropdown, {
  MenuItem,
} from '../MultiLevelDropdown/MultiLevelDropdown';
import * as Styled from './style';
import { HandleAccountSwitch } from './utils/HandleAccountSwitch';

const AccountSwitcher: FC = () => {
  const { t } = useCustomTranslation('');
  const { data: portalPartners } = useGetPortalPartnersQuery();
  const { data: profile } = useGetProfileQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeAccount = useSelector(
    (state: RootState) => state.activeAccount.currentAccount,
  );

  React.useEffect(() => {
    HandleAccountSwitch(
      portalPartners,
      activeAccount,
      profile,
      dispatch,
      navigate,
    );
  }, [location]);

  const convertToMenuItems = (partners: PortalPartnersResponse[]): MenuItem[] =>
    partners.map((partner) => ({
      title: partner.name,
      id: partner.id,
      type: 'partner',
      ...(partner.companies &&
        partner.companies.length > 0 && {
          submenu: partner.companies.map((company) => ({
            title: company.name,
            id: company.id,
            type: 'company',
          })),
        }),
    }));

  const menuItems: MenuItem[] =
    portalPartners && portalPartners.length > 0
      ? [
          {
            title:
              activeAccount && activeAccount.name
                ? activeAccount.name
                : t('COMPANIES_PAGE.ACCOUNT_SWITCHER.DEFAULT_TITLE'),
            submenu: convertToMenuItems(portalPartners),
          },
        ]
      : [];

  const handleChange = (items: MenuItem) => {
    if (items.id && items.type) {
      dispatch(
        setActiveAccount({
          id: items.id,
          name: items.title,
          type: items.type,
        }),
      );
      const newUrl = `${window.location.pathname}?${items.type}=${items.id}&${items.type + '-name'}=${items.title}`;
      navigate(newUrl, { replace: true });
    }
  };

  return (
    <nav>
      <Styled.Menus>
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MultiLevelDropdown
              onChange={handleChange}
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })}
      </Styled.Menus>
    </nav>
  );
};

export default AccountSwitcher;
