import { RefObject, useEffect } from 'react';

const useOutsideClick = (
  elementRef: RefObject<HTMLElement>,
  handler: (arg0: boolean) => void,
  attached: boolean = true,
  parentRef?: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    if (!attached) {
      return;
    }

    const handleOutsideClick = (e: Event) => {
      if (
        !elementRef.current ||
        parentRef?.current?.contains(e.target as Node)
      ) {
        return;
      }
      if (!elementRef.current.contains(e.target as Node)) {
        handler(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [elementRef, parentRef, handler, attached]);
};

export default useOutsideClick;
