import { ReactComponent as MenuIcon } from '@/assets/icons/menu.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const HEADER_HEIGHT = 70;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 40px;
  height: calc(${HEADER_HEIGHT}px - 30px);
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 99;
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.s}) {
    padding: 15px 30px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) =>
      theme.breakpoints.xs}) {
    padding: 15px 20px;
  }
`;

export const MenuSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled(MenuIcon)`
  cursor: pointer;
`;

export const MobileSeparator = styled.div`
  display: none;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
    min-width: 1px;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }: ThemeProps<Theme>) =>
      theme.colors.lightGray};
    margin-left: 40px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.s}) {
    margin-left: 30px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) =>
      theme.breakpoints.xs}) {
    margin-left: 20px;
  }
`;

export const MainSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

export const Separator = styled.div`
  min-width: 1px;
  height: 100%;
  width: 1px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  margin: 0 20px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const ProfileContainer = styled.div`
  width: 200px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) =>
      theme.breakpoints.xl}) {
    width: 180px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
    width: 160px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const headerHeading = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
`;
