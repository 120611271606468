import styled from 'styled-components';

export const Form = styled.div``;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  column-gap: 20px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 250px;
`;
