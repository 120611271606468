import React, { FC } from 'react';
import * as Styled from './style';

interface HistoryItemProps {
  data: { [key: string]: string | number | undefined | null }[];
}

const HistoryItem: FC<HistoryItemProps> = ({ data }) => (
  <>
    {data.map(
      (
        item: { [key: string]: string | number | undefined | null },
        key: number,
      ) => (
        <Styled.TR key={key}>
          {Object.entries(item).map(([optionKey, optionValue]) => (
            <Styled.TD key={optionKey}>{optionValue}</Styled.TD>
          ))}
        </Styled.TR>
      ),
    )}
  </>
);

export default HistoryItem;
