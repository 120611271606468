import React, { PropsWithChildren, useEffect } from 'react';
import { ITableProps } from './index';
import { ITableHeader, TableSortOrders } from './types';

interface IContextValue<T> {
  data: T[] | undefined;
  order?: TableSortOrders;
  orderBy?: keyof T;
  onRequestSort: (p: keyof T) => void;
  onHeadersChange: (headers: ITableHeader[]) => void;
  headers: ITableHeader[];
}

export const TableContext = React.createContext<any>({
  order: TableSortOrders.asc,
  orderBy: '',
  onRequestSort: (_: string) => {},
  onHeadersChange: () => {},
  data: [],
  headers: [],
});

function Provider<T>({
  data,
  children,
  order = TableSortOrders.asc,
  orderBy,
  headers,
  onSort,
}: PropsWithChildren<ITableProps<T>>) {
  const [_order, setOrder] = React.useState<TableSortOrders>(
    order ?? TableSortOrders.asc,
  );
  const [_orderBy, setOrderBy] = React.useState<keyof T | undefined>(orderBy);
  const [_headers, setHeaders] = React.useState<ITableHeader[]>(headers);

  useEffect(() => {
    setHeaders(headers);
  }, [headers]);

  const onRequestSort = (property: keyof T) => {
    const isAsc = _orderBy === property && _order === TableSortOrders.asc;
    setOrder(isAsc ? TableSortOrders.desc : TableSortOrders.asc);
    setOrderBy(property);
    onSort?.(
      property as string,
      isAsc ? TableSortOrders.desc : TableSortOrders.asc,
    );
  };

  const onHeadersChange = (values: ITableHeader[]) => {
    setHeaders(values);
  };

  const contextValue: IContextValue<T> = {
    data,
    order: _order,
    orderBy: _orderBy,
    onRequestSort,
    onHeadersChange,
    headers: _headers,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
}

export const TableProvider = Provider;
