import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  border: 3px solid #f9f9f9;
  border-radius: 25px;
  background-color: #fff;
  margin: 20px 0;
`;

export const Row = styled.div`
  display: flex;
  color: #3075cf;
  align-items: center;
`;

export const DateWrapper = styled.div`
  padding-top: 16px;
  margin: 0 10px;
`;

export const ChartWrapper = styled.div`
  min-height: 700px;
`;
