import useCustomTranslation from '@/localization/useCustomTranslation';
import { WaypointFilterContext } from '@/Providers/WaypointFilterProvider';
import { GetStatusResponse } from '@/types/mobileApp.type';
import {
  GetDMAsResponse,
  GetProjectResponse,
} from '@/types/portalProject.type';
import React, { FC, useContext, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import * as Styled from './style';

interface SizeFilterProps {
  onClick: (selectedSize: string) => void;
  title: string;
}

const SizeFilter: FC<SizeFilterProps> = ({ title, onClick }) => {
  const sizes = ['Small', 'Medium', 'Large'];

  return (
    <Styled.StatusWrapper>
      <Styled.StatusTitle>{title}</Styled.StatusTitle>
      <Styled.StatusContainer>
        {sizes.map((size, key) => (
          <Styled.StatusItem onClick={() => onClick(size)} key={key}>
            <p>{size}</p>
          </Styled.StatusItem>
        ))}
      </Styled.StatusContainer>
    </Styled.StatusWrapper>
  );
};

interface StatusFilterProps {
  onClick: (selectedStatusId: number, selectedStatusName: string) => void;
  statuses: GetStatusResponse[];
  title: string;
}

const StatusFilter: FC<StatusFilterProps> = ({ title, onClick, statuses }) => (
  <Styled.StatusWrapper>
    <Styled.StatusTitle>{title}</Styled.StatusTitle>
    <Styled.StatusContainer>
      {statuses.map((status, key) => (
        <Styled.StatusItem
          onClick={() => onClick(status.id, status.status)}
          key={key}
        >
          <p>{status.status}</p>
        </Styled.StatusItem>
      ))}
    </Styled.StatusContainer>
  </Styled.StatusWrapper>
);

interface DMAFilterProps {
  onClick: (selectedStatusId: number, selectedDMAName: string) => void;
  dmas: GetDMAsResponse;
  title: string;
}

const DMAFilter: FC<DMAFilterProps> = ({ title, onClick, dmas }) => (
  <Styled.StatusWrapper>
    <Styled.StatusTitle>{title}</Styled.StatusTitle>
    <Styled.StatusContainer>
      {dmas.data.map((dma, key) => (
        <Styled.StatusItem onClick={() => onClick(dma.id, dma.name)} key={key}>
          <p>{dma.name}</p>
        </Styled.StatusItem>
      ))}
    </Styled.StatusContainer>
  </Styled.StatusWrapper>
);

interface ProjectFilterProps {
  onClick: (selectedStatusId: number, selectedProjectName: string) => void;
  projects: GetProjectResponse;
  title: string;
}

const ProjectFilter: FC<ProjectFilterProps> = ({
  title,
  onClick,
  projects,
}) => (
  <Styled.StatusWrapper>
    <Styled.StatusTitle>{title}</Styled.StatusTitle>
    <Styled.StatusContainer>
      {projects.data.map((project, key) => (
        <Styled.StatusItem
          onClick={() => onClick(project.id, project.name)}
          key={key}
        >
          <p>{project.name}</p>
        </Styled.StatusItem>
      ))}
    </Styled.StatusContainer>
  </Styled.StatusWrapper>
);

interface FilterProps {
  statuses: GetStatusResponse[] | undefined;
  dmas: GetDMAsResponse | undefined;
  projects: GetProjectResponse | undefined;
}

export const Filter: FC<FilterProps> = ({ dmas, statuses, projects }) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.FILTER');
  const { setWaypointSearch, setSize, setStatus, setDma, setProject } =
    useContext(WaypointFilterContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [openFilter, setOpenFilter] = useState<string | null>(null);

  const handleButtonClick = (filterType: string) => {
    setOpenFilter(filterType === openFilter ? null : filterType);
  };

  const handleSizeClick = (selectedSize: string) => {
    setSize(selectedSize);
    setOpenFilter('');
  };

  const handleStatusClick = (
    selectedStatusId: number,
    selectedStatusName: string,
  ) => {
    setStatus({ id: selectedStatusId, name: selectedStatusName });
    setOpenFilter('');
  };

  const handleDmaClick = (selectedDMAId: number, selectedDMAName: string) => {
    setDma({ id: selectedDMAId, name: selectedDMAName });
    setOpenFilter('');
  };

  const handleProjectClick = (
    selectedProjectId: number,
    selectedProjectName: string,
  ) => {
    setProject({ id: selectedProjectId, name: selectedProjectName });
    setOpenFilter('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    setWaypointSearch(value);
  };

  return (
    <Styled.FilterBarContainer>
      {openFilter && (
        <>
          <Styled.Underlay onClick={() => setOpenFilter('')} />
          <Styled.DialogBox open={Boolean(openFilter)}>
            <Styled.Cross onClick={() => setOpenFilter('')}>
              {prefixedT('CLOSE')}
            </Styled.Cross>
            {openFilter === 'size' && (
              <SizeFilter
                title={prefixedT('SELECT_A_SIZE')}
                onClick={handleSizeClick}
              />
            )}
            {openFilter === 'status' && statuses && (
              <StatusFilter
                title={prefixedT('SELECT_A_STATUS')}
                onClick={handleStatusClick}
                statuses={statuses}
              />
            )}
            {openFilter === 'dma' && dmas && (
              <DMAFilter
                title={prefixedT('SELECT_A_DMA')}
                onClick={handleDmaClick}
                dmas={dmas}
              />
            )}
            {openFilter === 'project' && projects && (
              <ProjectFilter
                title={prefixedT('SELECT_A_PROJECT')}
                onClick={handleProjectClick}
                projects={projects}
              />
            )}
          </Styled.DialogBox>
        </>
      )}
      {projects && projects.data?.length > 0 && (
        <Styled.Button onClick={() => handleButtonClick('project')}>
          <Styled.FilterIcon />
          {prefixedT('SELECT_PROJECT')}
        </Styled.Button>
      )}
      {dmas && dmas.data?.length > 0 && (
        <Styled.Button onClick={() => handleButtonClick('dma')}>
          <Styled.FilterIcon />
          {prefixedT('SELECT_DMA')}
        </Styled.Button>
      )}
      {statuses && statuses.length > 0 && (
        <Styled.Button onClick={() => handleButtonClick('status')}>
          <Styled.FilterIcon />
          {prefixedT('SELECT_STATUS')}
        </Styled.Button>
      )}
      <Styled.Button onClick={() => handleButtonClick('size')}>
        <Styled.FilterIcon />
        {prefixedT('SELECT_SIZE')}
      </Styled.Button>
      <Styled.InputWrapper>
        <Styled.Input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search Waypoint"
        />
        <Styled.Icon>
          <BsSearch size={20} />
        </Styled.Icon>
      </Styled.InputWrapper>
    </Styled.FilterBarContainer>
  );
};
