import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const Title = styled.h3`
  text-align: center;
`;

export const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const Error = styled.p`
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.artyClickRed};
`;
