import { Theme } from '@/theme';
import styled, { css, ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 20px 0;
  padding: 20px;
`;

export const HistoryContainer = styled.div`
  background: #eeeeee;
  text-align: center;
  width: calc(100% - 420px);
  border-radius: 10px;
  padding-top: 10px;
  margin-right: 20px;
`;

export const History = styled.div`
  overflow: auto;
  margin: 20px;
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 20px;
`;

export const Graph = styled.div`
  margin-right: 10px;
`;

export const OvernightGraphContainer = styled.div`
  margin-top: 10px;
`;

export const OvernightContainer = styled.div`
  background: white;
  text-align: center;
  padding-top: 10px;
`;

export const Image = styled.img`
  width: 400px;
  max-height: 300px;
`;

const sharedStyles = css`
  padding: 10px 20px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 10px;
  color: white;
  height: fit-content;
`;

export const TimelineContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumBlue};
  ${sharedStyles}
`;

export const ErrorContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
  ${sharedStyles}
`;

export const TitleContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
  ${sharedStyles}
`;

export const Timeline = styled.p``;

export const Title = styled.h3``;

export const ErrorTxt = styled.h3``;
