import React, { useState } from 'react';
import * as Styled from '../style';
import EditIcon from '@/assets/icons/edit-icon.png';
import { Button, Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Project } from '@/types/portalProject.type';
import moment from 'moment';

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('VIEW_PROJECTS.TITLE');

  return [
    {
      id: 'dmas',
      title: prefixedT('DMAS'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'name',
      title: prefixedT('NAME'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'company',
      title: prefixedT('COMPANY'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'start-date',
      title: prefixedT('START_DATE'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'end-date',
      title: prefixedT('END_DATE'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'action',
      title: prefixedT('ACTION'),
      sortable: false,
      align: TableCellAlignment.center,
    },
  ];
};

export const getRowStructure = (
  row: Project,
  idx: number,
  handleEditClick?: (idx: number) => void,
  _handleDeleteClick?: (idx: number) => void,
) => {
  const dmasMaxHeight = 250;

  const [show, setShow] = useState(false);
  const { prefixedT } = useCustomTranslation('VIEW_PROJECTS');

  return (
    <>
      <Table.Row key={idx}>
        <Table.Cell align={TableCellAlignment.center} headerId="dmas">
          <Button testId={`project-${idx}-dmas`} onClick={() => setShow(!show)}>
            <Styled.ClickableAreaIndicator open={show} />
          </Button>
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="name">
          {row.name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="company">
          {row.company.name}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="start-date">
          {row.start_date !== null
            ? moment(row.start_date).format('DD/MM/YYYY')
            : ''}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="end-date">
          {row.end_date !== null
            ? moment(row.end_date).format('DD/MM/YYYY')
            : ''}
        </Table.Cell>
        <Table.Cell align={TableCellAlignment.center} headerId="action">
          <Styled.ImgContainer>
            <Styled.Img
              onClick={() => handleEditClick && handleEditClick(idx)}
              alt={prefixedT('EDIT_ICON')}
              src={EditIcon as string}
            />
          </Styled.ImgContainer>
        </Table.Cell>
      </Table.Row>

      {/* Empty div is a hack to keep the alternating colours correct in the table */}
      <tr></tr>
      <tr>
        <Styled.DMACell colSpan={6} show={show}>
          <Styled.DMAsContainer show={show} maxHeight={dmasMaxHeight}>
            {row.dmas.length > 0 ? (
              <>
                <Styled.DMAHeader idx={idx}>
                  {prefixedT('TITLE.DMAS_SUBLIST_TITLE')}:
                </Styled.DMAHeader>
                <Styled.DMAList>
                  {row.dmas
                    .toSorted((a, b) => a.name.localeCompare(b.name))
                    .map((dma, dmaIdx) => (
                      <Styled.DMAListItem key={dmaIdx}>
                        {dma.name}
                      </Styled.DMAListItem>
                    ))}
                </Styled.DMAList>
              </>
            ) : (
              <h3>{prefixedT('TITLE.NO_DMAS')}</h3>
            )}
          </Styled.DMAsContainer>
        </Styled.DMACell>
      </tr>
    </>
  );
};
