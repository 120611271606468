import React from 'react';
import * as Styled from './style';

const BouncingDotsLoader = () => (
  <Styled.BouncingLoader data-testid="bouncing-dots-loader">
    <div />
    <div />
    <div />
  </Styled.BouncingLoader>
);

export default BouncingDotsLoader;
