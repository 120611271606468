import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetCorrelationInformationQuery,
  useGetDropdownQuery,
} from '@/redux/reports/reports.api';

import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';
import { MapProvider } from '@/Providers/MapProvider';
import { CorrelationGraphs } from './CorrelationGraphs/CorrelationGraphs';
import { StatusWrapper } from './StatusWrapper/StatusWrapper';

interface ConditionalListItemProps {
  item: string | number | null;
  text: string;
}

const CorrelationContainer: FC = () => {
  const location = useLocation();
  const [waypointID, setWaypointID] = useState<string>('');
  const [correlationID, setCorrelationID] = useState<string>('');

  const { prefixedT } = useCustomTranslation('CORRELATION_INFO_PAGE');
  const { data: dropDownData } = useGetDropdownQuery(waypointID, {
    refetchOnMountOrArgChange: true,
    skip: !waypointID,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedCorrelationID = queryParams.get('correlation') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setCorrelationID(updatedCorrelationID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: correlationData } = useGetCorrelationInformationQuery(
    correlationID,
    {
      refetchOnMountOrArgChange: true,
      skip: !correlationID,
    },
  );

  const totalWidthFactor = correlationData?.pipe_segments.reduce(
    (total, segment) => total + segment.pipe_seg_length,
    0,
  );

  const ConditionalListItem: React.FC<ConditionalListItemProps> = ({
    item,
    text,
  }) => (
    <li>
      {text}: {item && <Styled.ListObject>{item}</Styled.ListObject>}
    </li>
  );

  return (
    <Styled.Container>
      {dropDownData && (
        <WaypointHeadings
          correlations={dropDownData.correlations}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessions={dropDownData.sessions}
          jobs={dropDownData.jobs}
          active="Correlation"
        />
      )}

      <StatusWrapper
        correlationOutcome={correlationData?.correlation_outcome}
      />

      {correlationData && (
        <>
          {correlationData?.map_info?.data && (
            <Styled.MapContainer>
              <MapProvider>
                <GenerateMap
                  fullMapData={correlationData?.map_info?.data}
                  viewportMapData={correlationData?.map_info?.data}
                  readonly={true}
                  doNotAllowClickThrough={true}
                />
              </MapProvider>
            </Styled.MapContainer>
          )}
          <CorrelationGraphs
            sessionData={correlationData.session_data}
            correlationUrl={correlationData.correlation_files.correlation_urls}
          />

          <Styled.ListContainer>
            <Styled.ListWrapper>
              <Styled.ListContainerItem>
                <Styled.ListTitle>
                  {prefixedT('CORRELATION_INFO.DETAILS_TITLE')}
                </Styled.ListTitle>
                <Styled.List>
                  <ConditionalListItem
                    item={correlationData?.leak_distance}
                    text={prefixedT('CORRELATION_INFO.LEAK_DISTANCE')}
                  />
                  <ConditionalListItem
                    item={correlationData?.location_point_a}
                    text={prefixedT('CORRELATION_INFO.POINT_A')}
                  />
                  <ConditionalListItem
                    item={correlationData?.location_point_b}
                    text={prefixedT('CORRELATION_INFO.POINT_B')}
                  />
                  <ConditionalListItem
                    item={correlationData?.speed_of_sound}
                    text={prefixedT('CORRELATION_INFO.SPEED_OF_SOUND')}
                  />
                  <ConditionalListItem
                    item={correlationData?.timestamp}
                    text={prefixedT('CORRELATION_INFO.TIMESTAMP')}
                  />
                  <ConditionalListItem
                    item={correlationData?.total_length}
                    text={prefixedT('CORRELATION_INFO.TOTAL_LENGTH')}
                  />
                </Styled.List>
              </Styled.ListContainerItem>
              <Styled.ListContainerItem>
                <Styled.ListTitle>
                  {prefixedT('ENGINEER_INFO.TITLE')}
                </Styled.ListTitle>
                <Styled.List>
                  <ConditionalListItem
                    item={correlationData?.company}
                    text={prefixedT('ENGINEER_INFO.COMPANY')}
                  />
                  <ConditionalListItem
                    item={correlationData?.engineer}
                    text={prefixedT('ENGINEER_INFO.ENGINEER')}
                  />
                </Styled.List>
              </Styled.ListContainerItem>
            </Styled.ListWrapper>
          </Styled.ListContainer>

          <Styled.PipeSegments>
            {totalWidthFactor &&
              correlationData.pipe_segments.map((segment, key) => {
                const percentage =
                  ((segment.pipe_seg_diameter * segment.pipe_seg_length) /
                    totalWidthFactor) *
                  100;

                return (
                  <div key={key} style={{ width: `${percentage}%` }}>
                    <Styled.PipeSegment>
                      <p>{segment.pipe_seg_material}</p>
                    </Styled.PipeSegment>
                    <Styled.PipeSegmentText>
                      <p>
                        {prefixedT('MATERIAL_INFO.DIAMETER')}:{' '}
                        {segment.pipe_seg_diameter}
                        {prefixedT('MATERIAL_INFO.MILLIMETERS')}
                      </p>
                      <p>
                        {prefixedT('MATERIAL_INFO.LENGTH')}:{' '}
                        {segment.pipe_seg_length}m
                      </p>
                    </Styled.PipeSegmentText>
                  </div>
                );
              })}
          </Styled.PipeSegments>
        </>
      )}
    </Styled.Container>
  );
};

export default CorrelationContainer;
