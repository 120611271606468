import React, { FC, useEffect, useState } from 'react';
import * as Styled from './style';
import SequentialNav from '@/components/SequentialNav/SequentialNav';
import withPageLoader from '@/HOCs/withPageLoader';
import {
  addOrReplaceError,
  countTypes,
  extractTables,
  groupDataByType,
  updateGISRoutes,
} from './utils';
import { MapPreview, ProcessFiles, UploadShapefile } from '.';
import { useGetColumnAndSampleDataQuery } from '@/redux/gisUpload/gisUpload.api';
import { Loader } from '@/components';
import {
  ErrorModalText,
  GISRoutes,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import { FilterProvider } from '@/Providers/FilterProvider';
import { GISType } from './ProcessFiles/ProcessFiles';
import FileHeader from './Header/FileHeader/FileHeader';
import FileHeaderMapPreview from './Header/FileHeaderMapPreview/FileHeaderMapPreview';
import ErrorModal from './ErrorModal/ErrorModal';

const GISUpload: FC = () => {
  const {
    companyId,
    uploadId,
    currentStep,
    isLoading,
    isError,
    setIsError,
    fileIndex,
  } = useGISUploadValues();
  const [routes, setRoutes] = useState(updateGISRoutes(0, false));

  const {
    data,
    isFetching: queryLoading,
    isError: queryError,
    refetch,
  } = useGetColumnAndSampleDataQuery(
    { companyId, uploadId },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyId,
    },
  );

  useEffect(() => {
    if (queryError) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.DEFAULT_ERROR_MESSAGE),
      );
    }
  }, [queryError]);

  const lengthOfFiles = countTypes(data?.sample_data);

  const { dmaColumns, assetColumns, pipeColumns } = extractTables(
    data?.table_columns,
  );
  const { dmaSampleData, assetSampleData, pipeSampleData } = groupDataByType(
    data?.sample_data,
  );

  useEffect(() => {
    setRoutes(updateGISRoutes(currentStep, isLoading));
  }, [currentStep, isLoading]);

  const handleRefresh = async (): Promise<void> => {
    await refetch();
  };

  const currentStepData = (() => {
    switch (currentStep) {
      case GISRoutes.PROCESS_DMA:
        return {
          sampleData: dmaSampleData,
          tableColumns: dmaColumns,
          type: GISType.DMA,
        };
      case GISRoutes.PROCESS_PIPES:
        return {
          sampleData: pipeSampleData,
          tableColumns: pipeColumns,
          type: GISType.PIPES,
        };
      case GISRoutes.PROCESS_ASSETS:
        return {
          sampleData: assetSampleData,
          tableColumns: assetColumns,
          type: GISType.ASSETS,
        };
      case GISRoutes.MAP_PREVIEW:
        return {
          sampleData: null,
          tableColumns: null,
          type: null,
        };
      default:
        return {
          sampleData: null,
          tableColumns: null,
          type: null,
        };
    }
  })();

  const renderStepContent = () => {
    if (currentStep === GISRoutes.UPLOAD_SHAPEFILE) {
      return <UploadShapefile />;
    }

    const { sampleData, tableColumns, type } = currentStepData;

    if (currentStep === GISRoutes.MAP_PREVIEW) {
      return (
        <FilterProvider>
          <MapPreview />
        </FilterProvider>
      );
    }

    if (sampleData && tableColumns && type) {
      return (
        <ProcessFiles
          type={type}
          tableColumns={tableColumns}
          sampleData={sampleData}
        />
      );
    }

    return null;
  };

  return (
    <Styled.Wrapper>
      <ErrorModal />
      {currentStepData &&
        currentStepData.sampleData &&
        currentStep !== GISRoutes.MAP_PREVIEW && (
          <FileHeader
            numberOfFiles={currentStepData.sampleData?.length || 0}
            filename={currentStepData.sampleData?.[fileIndex]?.file_name || ''}
            sampleData={currentStepData.sampleData}
            tableColumns={currentStepData.tableColumns}
            refetch={handleRefresh}
            queryLoading={queryLoading}
            lengthOfFiles={lengthOfFiles}
            type={currentStepData.type}
          />
        )}
      {currentStep === GISRoutes.MAP_PREVIEW && (
        <FileHeaderMapPreview lengthOfFiles={lengthOfFiles} />
      )}
      <Styled.Container>
        <Styled.NavWrapper>
          <SequentialNav navigation={routes} />
        </Styled.NavWrapper>
        {queryLoading ? <Loader /> : renderStepContent()}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default withPageLoader(GISUpload);
