import React, { FC } from 'react';

import * as Sentry from '@sentry/react';
import { Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';

import {
  GetViewRelaysDistinctResponse,
  RelaysData,
} from '@/types/portalUsers.type';
import moment from 'moment';
import { DropDownOption } from '@/components/DropDown/DropDown';

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

export const headerNames = [
  'relay_id',
  'bug_mac',
  'session_id',
  'update_time',
  'create_time',
  'company',
  'partner',
  'project',
];

export const headers = (
  columnOrder: { name: string; show: boolean }[],
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('LIST_VIEW.RELAYS.HEADERS');

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center,
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name,
        );
        if (!matchedColumn) {
          throw new Error(`Column not found`);
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    // Something went wrong, reset the columns
    localStorage.removeItem('listView.Waypoints');
    return currentColumns;
  }
};

export const RowStructure: FC<{
  data: RelaysData;
  index: number;
  columnOrder: { name: string; show: boolean }[];
}> = ({ data, index, columnOrder }) => {
  const currentRowOrder = [
    {
      id: 'relay_id',
      child: data.relay_id,
    },
    { id: 'bug_mac', child: data.bug_mac },
    {
      id: 'session_id',
      child: (
        <a href={`/app/session?session=${data.session_id}`} target="_blank">
          {data.session_id}
        </a>
      ),
    },
    {
      id: 'update_time',
      child:
        data.update_time !== null &&
        moment(data.update_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'create_time',
      child:
        data.create_time !== null &&
        moment(data.create_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    { id: 'company', child: data.company_name },
    { id: 'partner', child: data.partner_name },
    { id: 'project', child: data.project_name },
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name,
      );
      if (!matchedColumn) {
        const err = `Column not found ${column.name}`;
        Sentry.captureException(err);
        throw new Error(err);
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns = ['company', 'partner', 'project'];

export const sortColumns = [
  'relay_id',
  'bug_mac',
  'session_id',
  'update_time',
  'create_time',
  'company',
  'partner',
  'project',
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewRelaysDistinctResponse | undefined,
) => {
  return {
    company:
      distinct?.company
        .map((currentCompany) => {
          if (currentCompany.id === null || currentCompany.name === null) {
            return { label: 'No Company', value: 'null' };
          }
          return {
            label: currentCompany.name,
            value: `${currentCompany.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    partner:
      distinct?.partner
        .map((currentPartner) => {
          if (currentPartner.id === null || currentPartner.name === null) {
            return { label: 'No Partner', value: 'null' };
          }
          return {
            label: currentPartner.name,
            value: `${currentPartner.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    project:
      distinct?.project
        .map((currentProject) => {
          if (currentProject.id === null || currentProject.name === null) {
            return { label: 'No Project', value: 'null' };
          }
          return {
            label: currentProject.name,
            value: `${currentProject.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
  };
};
