import React, { FC, useEffect, useState } from 'react';
import useOutsideClick from '@/hooks/useOutsideClick';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FormattedStockDataBarChart } from '@/types/stock.type';

interface BarChartProps {
  data: FormattedStockDataBarChart[];
  showTooltip?: boolean;
}

const StockBarChart: FC<BarChartProps> = ({ data, showTooltip = true }) => {
  const [filter, setFilter] = useState<{ company: string; isShow: boolean }[]>(
    [],
  );
  const [showFilter, setShowFilter] = useState(false);
  const filterRef = React.createRef<HTMLDivElement>();

  const { prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.DISTRIBUTION');

  useOutsideClick(filterRef, setShowFilter, showFilter);

  useEffect(() => {
    setFilter(data.map((item) => ({ company: item.name, isShow: true })));
  }, [data]);

  return (
    <Styled.Wrapper>
      <Styled.LegendContainer ref={filterRef}>
        <Styled.FilterButton onClick={() => setShowFilter(!showFilter)}>
          <Styled.FilterIcon /> <p>{prefixedT('FILTER')}</p>
        </Styled.FilterButton>
        {showFilter && (
          <Styled.UnorderedList data-testid="filter-list">
            <Styled.ListItem>
              <Styled.Checkbox
                type="checkbox"
                data-testid="filter-all"
                checked={filter.every((item) => item.isShow)}
                onChange={(e) => {
                  setFilter(
                    data.map((item) => ({
                      company: item.name,
                      isShow: e.target.checked,
                    })),
                  );
                }}
              />
              {prefixedT('TOGGLE_ALL')}
            </Styled.ListItem>
            <hr />
            {data.map((item, idx) => (
              <Styled.ListItem key={idx}>
                <Styled.Checkbox
                  type="checkbox"
                  data-testid={`filter-${item.name}`}
                  checked={filter[idx].isShow}
                  onChange={(e) => {
                    const newFilter = [...filter];
                    newFilter[idx].isShow = e.target.checked;
                    setFilter(newFilter);
                  }}
                />
                <Styled.ListItemText>
                  <p>{item.name}</p>
                  <p>{item.total}</p>
                </Styled.ListItemText>
              </Styled.ListItem>
            ))}
          </Styled.UnorderedList>
        )}
      </Styled.LegendContainer>

      <ResponsiveContainer width="100%" height={700}>
        <BarChart
          width={500}
          height={500}
          data={filter
            .map((item, idx) => (item.isShow ? data[idx] : null))
            .filter((item) => item !== null)}
          margin={{ top: 50, right: 10, left: 10, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#69A3FF" />
              <stop offset="100%" stopColor="#4182E9" />
            </linearGradient>
          </defs>
          <text
            x={0}
            y={10}
            fill="black"
            textAnchor="start"
            dominantBaseline="central"
            fontSize={24}
            fontWeight={500}
          >
            {prefixedT('CHART_TITLE')}
          </text>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            interval={0}
            angle={-90}
            textAnchor="end"
            height={190} // hardcoded eugh
            dx={-8} // -0.5 * fontSize
          />
          <YAxis tickCount={5} />
          {showTooltip && <Tooltip />}
          <Bar
            dataKey="total"
            fill="url(#colorUv)"
            radius={[8, 8, 0, 0]}
            label={{ position: 'top', fontSize: '12px' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Styled.Wrapper>
  );
};

export default StockBarChart;
