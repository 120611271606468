import { AccountSwitcher } from '@/components';
import SearchBar from '@/components/SearchBar/SearchBar';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useDashboardContext } from '@/Providers/DashboardProvider';
import { RootState } from '@/redux';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './style';

const StickyMenu: FC = () => {
  const { setSearch } = useDashboardContext();
  const { prefixedT } = useCustomTranslation('COMPONENTS.SEARCH_BAR');
  const activeAccount = useSelector(
    (state: RootState) => state.activeAccount.currentAccount,
  );

  const handleSearch = (searchString: string) => {
    setSearch(searchString);
  };

  const placeholder =
    activeAccount?.type === 'company'
      ? prefixedT('SEARCH_PROJECTS')
      : prefixedT('SEARCH_COMPANIES');

  return (
    <Styled.RowWrapper>
      <div data-testid="account-switcher">
        <AccountSwitcher />
      </div>
      <Styled.SearchBarContainer>
        <SearchBar placeholder={placeholder} onSearch={handleSearch} />
      </Styled.SearchBarContainer>
    </Styled.RowWrapper>
  );
};

export default StickyMenu;
