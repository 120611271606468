import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';

export enum STANDARDISING {
  MATERIAL = 'material',
  STATUS = 'status',
  TYPE = 'type', // type can be used for pipe_type or asset_type
  UNITS = 'diameter_units',
}
export interface PostUploadGISFilesParams {
  company_id: number;
}

export interface PostUploadGISDataParams {
  company_id: number;
  upload_id: number;
}

export enum GIS_COLUMN_MAPPING {
  ASSET_TYPE = 'asset_type',
  MATERIAL = 'material',
  PIPE_TYPE = 'pipe_type',
  STATUS = 'status',
  UNITS = 'units',
}

export interface MappedColumns {
  columns: {
    id: number;
    title: string;
  }[];
  material: Item | [];
  asset_type: Item | [];
  pipe_type: Item | [];
  status: Item | [];
  units: number | Item | [];
}

type Item = {
  title: string;
  type: string;
}[];

export interface ColumnMappingBody {
  upload_id: number | null;
  filename: string;
  mapped_columns: MappedColumns;
}

export interface Column {
  column_name: string;
  column_type?: string;
  id: number;
  required: boolean;
}
export interface TableColumns {
  table_name: string;
  columns: Column[];
}

export interface SampleData {
  file_name: string;
  type: string;
  data: unknown[];
}
export interface ColumnAndSampleDataResponse {
  column_mappings: [];
  table_columns: TableColumns[];
  sample_data: SampleData[];
}

interface AssetType {
  id: number;
  standardised_type: string;
}

interface PipeType {
  id: number;
  standardised_type: string;
  type: string | null;
}
interface PipeMaterial {
  id: number;
  material: string | null;
  standardised_material: string;
}

interface Units {
  id: number;
  standardised_unit: string;
  unit: string;
}

interface PipeStatus {
  id: number;
  standardised_status: string;
  status: string;
}

export interface UniqueValuesResponse {
  unique_values: string[];
  asset_type: AssetType[] | null;
  pipe_material: PipeMaterial[] | null;
  pipe_type: PipeType[] | null;
  pipe_status: PipeStatus[] | null;
  diameter_units: Units[] | null;
}

export interface DiscardFileBody {
  upload_id: number | null;
  company_id: number | null;
  filename: string;
}

interface Preview {
  data: MapElement[];
}

export interface PreviewValuesResponse {
  assets: Preview;
  dmas: Preview;
  pipes: Preview;
}
