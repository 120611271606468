import {
  useGetViewSessionsDistinctQuery,
  useLazyPostViewSessionsQuery,
} from '@/redux/portalUsers/portalUsers.api';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Styled from './style';
import {
  Button,
  ContentLoader,
  DatePicker,
  DropDown,
  Loader,
  Table,
} from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  getRowStructure,
  headerNames,
  headers,
  pageValues,
  processDistinctValues,
} from './ListViewUtils';
import FilterColumn from './FilterColumn/FilterColumn';
import { isFilterColumn, isSortColumn } from './ListViewUtils';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import { PostViewSessionsFilter, SessionData } from '@/types/portalUsers.type';
import EditView from './EditView/EditView';
import { DropDownOption } from '@/components/DropDown/DropDown';
import moment from 'moment';
// import PieCharts from '@/components/PieCharts/PieCharts'; // TODO: Uncomment when PieCharts is ready
// import IconInsight from '@/components/IconInsight/IconInsight';

const SessionListView: FC = () => {
  const [trigger, { data, isLoading, isFetching }] =
    useLazyPostViewSessionsQuery();
  const { data: distinct } = useGetViewSessionsDistinctQuery();

  const { t, prefixedT } = useCustomTranslation('LIST_VIEW.SESSIONS');

  const distinctProcessed: {
    [key: string]: { label: string; value: string }[];
  } = useMemo(() => {
    return processDistinctValues(distinct, prefixedT);
  }, [distinct]);

  const listViewSessionsView = localStorage.getItem('listView.Sessions.view');
  const listViewSessionsFullQuery = localStorage.getItem(
    'listView.Sessions.fullQuery',
  );
  const listViewSessionsFullQueryParsed = listViewSessionsFullQuery
    ? (JSON.parse(listViewSessionsFullQuery) as FullQuery)
    : null;

  const [columnOrder, setColumnOrder] = useState<
    { name: string; show: boolean }[]
  >(
    listViewSessionsView
      ? (JSON.parse(listViewSessionsView) as {
          name: string;
          show: boolean;
        }[])
      : headerNames.map((id) => ({ name: id, show: true })),
  );

  const tableHeaders = headers(columnOrder);

  const [page, setPage] = useState<number>(
    listViewSessionsFullQueryParsed?.query.page || 1,
  );
  const [perPage, setPerPage] = useState<number>(
    listViewSessionsFullQueryParsed?.query.per_page || 25,
  );

  const [order, setOrder] = useState<TableSortOrders>(
    listViewSessionsFullQueryParsed?.query.sort_order || TableSortOrders.desc,
  );
  const [orderBy, setOrderBy] = useState<string>(
    listViewSessionsFullQueryParsed?.query.sort_by || 'last_update',
  );

  type DateType =
    | 'created'
    | 'start_time'
    | 'end_time'
    | 'last_update'
    | 'expected_finish';
  const [dateType, setDateType] = useState<DateType>('created');

  type FullQuery = {
    body: {
      filter: PostViewSessionsFilter;
      date_filter: {
        [x: string]: {
          from: string;
          to: string;
        };
      };
    };
    query: {
      page: number;
      per_page: number;
      sort_by: string;
      sort_order: TableSortOrders;
      search: string;
    };
  };

  const dateRangeValues = [
    { value: 'created', label: t('LIST_VIEW.SESSIONS.HEADERS.created') },
    { value: 'start_time', label: t('LIST_VIEW.SESSIONS.HEADERS.start_time') },
    { value: 'end_time', label: t('LIST_VIEW.SESSIONS.HEADERS.end_time') },
    {
      value: 'last_update',
      label: t('LIST_VIEW.SESSIONS.HEADERS.last_update'),
    },
    {
      value: 'expected_finish',
      label: t('LIST_VIEW.SESSIONS.HEADERS.expected_finish'),
    },
  ];

  const [dateFrom, setDateFrom] = useState<Date | null>(
    listViewSessionsFullQueryParsed?.body.date_filter[dateType]?.from
      ? new Date(
          listViewSessionsFullQueryParsed.body.date_filter[dateType].from,
        )
      : null,
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    listViewSessionsFullQueryParsed?.body.date_filter[dateType]?.to
      ? new Date(listViewSessionsFullQueryParsed.body.date_filter[dateType].to)
      : null,
  );

  const defaultFilter = {
    status_id: [],
    partner_id: [],
    company_id: [],
    project_id: [],
    type: [],
    leak_size: [],
    dma_id: [],
    deployed_by: [],
    collected_by: [],
  };

  const [searchQuery, setSearchQuery] = useState<string>(
    listViewSessionsFullQueryParsed?.query.search || '',
  );
  const [filter, setFilter] = useState<PostViewSessionsFilter>(
    listViewSessionsFullQueryParsed
      ? listViewSessionsFullQueryParsed.body.filter
      : defaultFilter,
  );

  const currentQuery = {
    body: {
      filter,
      date_filter: {
        [dateType]: {
          from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '',
          to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : '',
        },
      },
    },
    query: {
      page,
      per_page: perPage,
      sort_by: orderBy,
      sort_order: order,
      search: searchQuery,
    },
  };

  const refetchWithArgs = (optionalQuery?: FullQuery) => {
    const thisQuery = optionalQuery || currentQuery;

    localStorage.setItem(
      'listView.Sessions.fullQuery',
      JSON.stringify(thisQuery),
    );
    void trigger(thisQuery);
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleColumnOrderChange = (
    newColumn: string,
    draggedColumn: string,
  ) => {
    const newColumnOrder = [...columnOrder];

    const draggedColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === draggedColumn,
    );
    const newColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === newColumn,
    );

    newColumnOrder.splice(draggedColumnIndex, 1);
    newColumnOrder.splice(newColumnIndex, 0, columnOrder[draggedColumnIndex]);
    setColumnOrder(newColumnOrder);
  };

  useEffect(() => {
    localStorage.setItem('listView.Sessions.view', JSON.stringify(columnOrder));
  }, [columnOrder]);

  useEffect(() => {
    refetchWithArgs();
  }, [page, perPage]);

  // TODO: Uncomment when PieCharts is ready
  // const leakData = useMemo(
  //   () =>
  //     data?.summary.leak_size.map((item) => ({
  //       values: item.count,
  //       labels: item.leak_size,
  //     })),
  //   [data?.summary],
  // );
  // const typeData = useMemo(
  //   () =>
  //     data?.summary.type.map((item) => ({
  //       values: item.count,
  //       labels: item.type,
  //     })),
  //   [data?.summary],
  // );

  const reset = () => {
    setFilter(defaultFilter);
    setDateFrom(null);
    setDateTo(null);
    setDateType('created');
    setSearchQuery('');
    setOrder(TableSortOrders.desc);
    setOrderBy('last_update');
    setPage(1);
    setPerPage(25);

    refetchWithArgs({
      body: {
        filter: defaultFilter,
        date_filter: {
          created: {
            from: '',
            to: '',
          },
        },
      },
      query: {
        page: 1,
        per_page: 25,
        sort_by: 'last_update',
        sort_order: TableSortOrders.desc,
        search: '',
      },
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.TableTitle>{prefixedT('TITLE')}</Styled.TableTitle>
      <ContentLoader active={data?.data && isFetching} />
      {isLoading ? (
        <Loader />
      ) : (
        <div data-testid="stock-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {prefixedT('FILTER_SECTION')}
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  {prefixedT('DATE_TYPE')}:
                  <Styled.DateTypeContainer>
                    <DropDown
                      options={dateRangeValues}
                      placeholder={prefixedT('DATE_TYPE.PLACEHOLDER')}
                      value={dateType}
                      onChange={(e) => {
                        const newValue = e as DropDownOption;
                        setDateType(
                          newValue.value as
                            | 'created'
                            | 'start_time'
                            | 'end_time'
                            | 'last_update'
                            | 'expected_finish',
                        );
                      }}
                      multiple={false}
                    />
                  </Styled.DateTypeContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        setDateFrom(value);
                      }}
                      placeholder={prefixedT('DATE_FROM')}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        setDateTo(value);
                      }}
                      placeholder={prefixedT('DATE_TO')}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>

                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={prefixedT('SEARCH.PLACEHOLDER')}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                >
                  {prefixedT('SEARCH_BAR.APPLY.FILTER')}
                </Button>
                <Button onClick={reset}>{prefixedT('SEARCH_BAR.RESET')}</Button>
              </Styled.SearchBarContainer>
            </Styled.TableHeaderSection>
            <Styled.TableHeaderSection>
              <EditView
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                handleColumnOrderChange={handleColumnOrderChange}
              />
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table
            data={data?.data || []}
            headers={tableHeaders}
            onSort={setSorting}
            minHeight="350px"
          >
            <Table.Head
              getHeaderStructure={(header) => {
                return (
                  <FilterColumn
                    header={header}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFilterOption={isFilterColumn(header.id)}
                    showSortOption={isSortColumn(header.id)}
                    filterOptions={distinctProcessed[header.id] || []}
                    filter={filter}
                    setFilter={setFilter}
                    isLastColumn={header.id === 'comment'}
                  />
                );
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(data: SessionData, index: number) =>
                  getRowStructure(data, index, columnOrder)
                }
                striped
              />
            ) : (
              <h3>{prefixedT('NO_DATA')}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Styled.DropDownContainer>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) => {
                  setPerPage(Number((value as DropDownOption).value));
                  setPage(1);
                }}
                placeholder={t('VIEW_USERS.PAGINATION.TITLE')}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={Math.ceil((data?.count || 1) / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      )}
      {/* TODO: Uncomment when PieCharts is ready */}
      {/* {leakData !== undefined &&
        leakData.length > 0 &&
        typeData !== undefined &&
        typeData.length > 0 && (
          <>
            <Styled.SummariesHeading>
              <h1>{prefixedT('SUMMARY.TITLE')}</h1>
              <IconInsight
                title={prefixedT('SUMMARY.TOOLTIP.TITLE')}
                label={prefixedT('SUMMARY.TOOLTIP.DESC')}
                location={'bottom left'}
              />
            </Styled.SummariesHeading>
            <PieCharts
              data={[leakData, typeData]}
              type={[
                prefixedT('SUMMARY.LEAK_SIZE'),
                prefixedT('SUMMARY.SESSION_TYPE'),
              ]}
              layout={{ rows: 1, columns: 2 }}
              total={data?.count}
            />
          </>
        )} */}
    </Styled.Wrapper>
  );
};

export default SessionListView;
