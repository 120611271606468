import { DropDownOption } from '../DropDown/DropDown';

export interface setContextFromUrlParamsProps {
  context: {
    setCompanyActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setPartnerActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setProjectActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
  };
}

export const setContextFromUrlParams = ({
  context,
}: setContextFromUrlParamsProps) => {
  const queryParams = new URLSearchParams(location.search);
  const {
    setPartnerActiveValue,
    setCompanyActiveValue,
    setProjectActiveValue,
  } = context;

  const urlCompanyId = queryParams.get('company') as string;
  const urlCompanyName = queryParams.get('company-name') as string;
  const urlPartnerId = queryParams.get('partner') as string;
  const urlPartnerName = queryParams.get('partner-name') as string;
  const urlProjectId = queryParams.get('project') as string;
  const urlProjectName = queryParams.get('project-name') as string;

  if (urlCompanyId) {
    setCompanyActiveValue({
      value: Number(urlCompanyId),
      name: urlCompanyName || '',
    });
  }

  if (urlPartnerId && urlPartnerName) {
    setPartnerActiveValue({
      value: Number(urlPartnerId),
      name: urlPartnerName,
    });
  } else {
    setPartnerActiveValue({ value: 0, name: '' });
  }

  if (urlProjectId && urlProjectName) {
    setProjectActiveValue({
      value: Number(urlProjectId),
      name: urlProjectName,
    });
  }
};

export interface setUrlFromStateUpdatesProps {
  context: {
    projectActiveValue: { value: number; name: string };
    partnerActiveValue: { value: number; name: string };
    companyActiveValue: { value: number; name: string };
  };
}

export const setUrlFromStateUpdates = ({
  context,
}: setUrlFromStateUpdatesProps) => {
  const queryParams = new URLSearchParams(location.search);
  const { projectActiveValue, partnerActiveValue, companyActiveValue } =
    context;

  if (projectActiveValue.name) {
    queryParams.set('project', projectActiveValue.value.toString());
    queryParams.set('project-name', projectActiveValue.name);
  }

  if (partnerActiveValue.name) {
    queryParams.set('partner', partnerActiveValue.value.toString());
    queryParams.set('partner-name', partnerActiveValue.name);
  }

  if (companyActiveValue.name) {
    queryParams.set('company', companyActiveValue.value.toString());
    queryParams.set('company-name', companyActiveValue.name);
  }

  const newUrl = `${location.pathname}?${queryParams}`;
  window.history.replaceState({}, '', newUrl);
};

type ContextFunctions = {
  [key: string]: (data: { value: number; name: string }) => void;
};

export interface setDeliveryPerformanceContextProps {
  option: DropDownOption;
  keyValue: string;
  context: {
    setCompanyActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setPartnerActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setProjectActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setEngineerActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
    setDmaActiveValue: React.Dispatch<
      React.SetStateAction<{ value: number; name: string }>
    >;
  };
}

export const setDeliveryPerformanceContext = ({
  option,
  keyValue,
  context,
}: setDeliveryPerformanceContextProps) => {
  const contextFunctions: ContextFunctions = {
    partners: context.setPartnerActiveValue,
    companies: context.setCompanyActiveValue,
    projects: context.setProjectActiveValue,
    engineers: context.setEngineerActiveValue,
    DMAs: context.setDmaActiveValue,
  };

  const selectedContextFunction = contextFunctions[keyValue];

  if (selectedContextFunction) {
    selectedContextFunction({
      value: Number(option.value),
      name: option.label,
    });

    if (keyValue === 'partners') {
      context.setCompanyActiveValue({ value: 0, name: '' });
      context.setProjectActiveValue({ value: 0, name: '' });
      context.setEngineerActiveValue({ value: 0, name: '' });
      context.setDmaActiveValue({ value: 0, name: '' });
    }
  }
};
