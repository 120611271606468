import React, { FC } from 'react';
import * as Styled from './style';

import * as Sentry from '@sentry/react';
import { Button, Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';

import {
  GetViewWaypointsDistinctResponse,
  WaypointsData,
} from '@/types/portalUsers.type';
import moment from 'moment';
import { DropDownOption } from '@/components/DropDown/DropDown';

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

export const headerNames = [
  'name',
  'status_id',
  'predicted_size',
  'predicted_leak_type_id',
  'partner_id',
  'company_id',
  'branch_name',
  'dma_key',
  'last_scheduled_engineer_id',
  'w3w',
  'comment',
  'sessions',
  'jobs',
  'created_at',
  'date_time',
];

export const headers = (
  columnOrder: { name: string; show: boolean }[],
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('LIST_VIEW.WAYPOINTS.HEADERS');

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center,
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name,
        );
        if (!matchedColumn) {
          throw new Error(`Column not found`);
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    // Something went wrong, reset the columns
    localStorage.removeItem('listView.Waypoints');
    return currentColumns;
  }
};

const ExpandableCell = ({
  children,
  showChildren,
  setShowChildren,
}: {
  children: React.ReactNode;
  showChildren: boolean;
  setShowChildren: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Styled.ExpandableCell>
      {children && (
        <Styled.ButtonContainer>
          <Button width={'40px'} onClick={() => setShowChildren(!showChildren)}>
            <Styled.ClickableAreaIndicator open={showChildren} />
          </Button>
        </Styled.ButtonContainer>
      )}
      <Styled.ChildWrapper show={showChildren}>{children}</Styled.ChildWrapper>
    </Styled.ExpandableCell>
  );
};

const ExpandableList = ({
  children,
  showChildren,
  setShowChildren,
}: {
  children: React.ReactNode;
  showChildren: boolean;
  setShowChildren: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Styled.ExpandableCell>
      {children && (
        <Styled.ButtonContainer>
          <Button width={'40px'} onClick={() => setShowChildren(!showChildren)}>
            <Styled.ClickableAreaIndicator open={showChildren} />
          </Button>
        </Styled.ButtonContainer>
      )}
      <Styled.ExpandableList show={showChildren}>
        {children}
      </Styled.ExpandableList>
    </Styled.ExpandableCell>
  );
};

export const RowStructure: FC<{
  data: WaypointsData;
  index: number;
  columnOrder: { name: string; show: boolean }[];
}> = ({ data, index, columnOrder }) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const currentRowOrder = [
    {
      id: 'name',
      child: (
        <a href={`/app/waypoint?waypoint=${data.id}`} target="_blank">
          {data.name}
        </a>
      ),
    },
    {
      id: 'partner_id',
      child: data.partner_name,
    },
    {
      id: 'company_id',
      child: data.company_name,
    },
    {
      id: 'status_id',
      child: data.status,
    },
    {
      id: 'predicted_size',
      child: data.predicted_size,
    },
    {
      id: 'last_scheduled_engineer_id',
      child: data.last_scheduled_engineer,
    },
    {
      id: 'branch_name',
      child: data.branch_name,
    },
    {
      id: 'dma_key',
      child: data.dma_key,
    },
    {
      id: 'w3w',
      child: data.w3w,
    },
    {
      id: 'created_at',
      child:
        data.created_at !== null &&
        moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'date_time',
      child:
        data.date_time !== null &&
        moment(data.date_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'predicted_leak_type_id',
      child: data.predicted_leak_type,
    },
    {
      id: 'comment',
      child: (
        <ExpandableCell
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          children={data.comment}
        />
      ),
    },
    {
      id: 'sessions',
      child: (
        <ExpandableList
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          children={
            data.sessions.length > 0 && (
              <Styled.CellList>
                {data.sessions.map((session, idx) => (
                  <li key={idx}>
                    <a
                      href={`/app/session?waypoint=${data.id}&session=${session}`}
                      target="_blank"
                    >
                      {session}
                    </a>
                  </li>
                ))}
              </Styled.CellList>
            )
          }
        />
      ),
    },
    {
      id: 'jobs',
      child: (
        <ExpandableList
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          children={
            data.jobs.length > 0 && (
              <Styled.CellList>
                {data.jobs.map((job, idx) => (
                  <li key={idx}>
                    <a href={`/app/job?job=${job.id}`} target="_blank">
                      {job.id}
                    </a>
                  </li>
                ))}
              </Styled.CellList>
            )
          }
        />
      ),
    },
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name,
      );
      if (!matchedColumn) {
        const err = `Column not found ${column.name}`;
        Sentry.captureException(err);
        throw new Error(err);
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns = [
  'status_id',
  'dma_id',
  'company_id',
  'partner_id',
  'branch_id',
  'last_scheduled_engineer_id',
  'predicted_size',
  'predicted_leak_type_id',
];

export const sortColumns = [
  'partner_id',
  'company_id',
  'name',
  'status_id',
  'predicted_size',
  'last_scheduled_engineer_id',
  'branch_name',
  'dma_key',
  'w3w',
  'created_at',
  'date_time',
  'predicted_leak_type_id',
  'comment',
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewWaypointsDistinctResponse | undefined,
) => {
  return {
    status_id:
      distinct?.status
        .map((currentStatus) => {
          if (currentStatus.name === null || currentStatus.id === null) {
            return {
              label: 'No Status',
              value: 'null',
            };
          } else {
            return {
              label: currentStatus.name,
              value: `${currentStatus.id}`,
            };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    predicted_leak_type_id:
      distinct?.on_site_leak_type
        .map((currentLeakSize) => {
          if (currentLeakSize.name === null || currentLeakSize.id === null) {
            return { label: 'No Leak Size', value: 'null' };
          } else {
            return {
              label: currentLeakSize.name,
              value: `${currentLeakSize.id}`,
            };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    predicted_size:
      distinct?.predicted_size
        .map((currentPredictedSize) => {
          if (currentPredictedSize === null) {
            return { label: 'No Predicted Size', value: 'null' };
          } else {
            return {
              label: currentPredictedSize,
              value: currentPredictedSize,
            };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    company_id:
      distinct?.company
        .map((currentCompany) => {
          if (currentCompany.id === null || currentCompany.name === null) {
            return { label: 'No Company', value: 'null' };
          }
          return {
            label: currentCompany.name,
            value: `${currentCompany.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    partner_id:
      distinct?.partner
        .map((currentPartner) => {
          if (currentPartner.id === null || currentPartner.name === null) {
            return { label: 'No Partner', value: 'null' };
          }
          return {
            label: currentPartner.name,
            value: `${currentPartner.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    project_id:
      distinct?.project
        .map((currentProject) => {
          if (currentProject.id === null || currentProject.name === null) {
            return { label: 'No Project', value: 'null' };
          }
          return {
            label: currentProject.name,
            value: `${currentProject.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    last_scheduled_engineer_id:
      distinct?.assign_engineer
        .map((currentCollectedEngineer) => {
          if (
            currentCollectedEngineer.id === null ||
            currentCollectedEngineer.name === null
          ) {
            return { label: 'No Engineer', value: 'null' };
          }
          return {
            label: currentCollectedEngineer.name,
            value: `${currentCollectedEngineer.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
  };
};
