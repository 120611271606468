import { Notification } from '@/components';
import { appRouter } from '@/routes/AppRouter';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { LocalizationProvider } from './localization';
import { persistor, store } from './redux';

import theme from './theme';

const loadingText = 'Loading...';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={loadingText} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider>
          <Notification />
          <RouterProvider router={appRouter} />
        </LocalizationProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
export default App;
