import {
  defaultSystemFields,
  ErrorModalText,
  FileSelectedValues,
  GISRoutes,
  SelectedValue,
  useGISUploadValues,
} from '@/Providers/GISUploadProvider';
import {
  usePostColumnMappingMutation,
  usePostDiscardFileMutation,
} from '@/redux/gisUpload/gisUpload.api';
import { Column, GIS_COLUMN_MAPPING, SampleData } from '@/types/gisUpload.type';
import { useEffect, useMemo } from 'react';
import {
  goForwardStep,
  goBackStep,
  addOrReplaceError,
  AllowedType,
} from '../utils';
import {
  getSelectedColumnIds,
  getUnselectedColumns,
  mappedColumns,
} from './utils';

interface GISHookProps {
  sampleData: SampleData[];
  tableColumns: Column[];
  refetch?: () => Promise<void>;
  lengthOfFiles?: Record<AllowedType, number> | undefined;
  type?: string;
}

export const GISHook = ({
  sampleData,
  tableColumns,
  refetch,
  lengthOfFiles,
  type,
}: GISHookProps) => {
  const {
    selectedValues,
    uploadId,
    companyId,
    currentStep,
    setMappedSystemFields,
    setCurrentStep,
    setIsError,
    fileIndex,
    setFileIndex,
    isError,
    resetProvider,
    mappedSystemFields,
    setIsErrorModalVisible,
  } = useGISUploadValues();

  const [
    discardFileMutate,
    {
      isSuccess: discardFileSuccess,
      isError: discardError,
      isLoading: discardFileLoading,
      reset: discardFileReset,
    },
  ] = usePostDiscardFileMutation();

  const [
    columnMappingMutate,
    {
      isSuccess: mappingSuccess,
      isError: mappingError,
      isLoading: mappingLoading,
    },
  ] = usePostColumnMappingMutation();

  useEffect(() => {
    if (discardError || mappingError) {
      setIsError(
        addOrReplaceError(isError, ErrorModalText.DEFAULT_ERROR_MESSAGE),
      );
    }
  }, [discardError, mappingError]);

  useEffect(() => {
    if (discardFileSuccess) {
      const fetchData = async () => {
        if (refetch) {
          await refetch();
        }

        if (fileIndex === sampleData.length - 1) {
          setCurrentStep((prevStep: number) => goForwardStep(prevStep));
          setFileIndex(0);
          setIsError(null);
        }
      };

      discardFileReset();

      void fetchData();
    }
  }, [discardFileSuccess]);

  const discardFile = async (filename: string) => {
    await discardFileMutate({
      body: { company_id: companyId, upload_id: uploadId, filename },
    });
  };

  const incrementFileIndex = async () => {
    const body = mappedColumns(selectedValues, uploadId, sampleData, fileIndex);
    let updatedErrors = isError;

    const hasStatusColumn: boolean = selectedValues.some((values) => {
      return values.data.some((selectedValue: SelectedValue) => {
        return selectedValue.title === 'status';
      });
    });

    if (sampleData[fileIndex]?.data && unselectedColumns.required.length > 0) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.COLUMNS_NOT_SELECTED,
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_ASSETS &&
      !mappedSystemFields.asset_type &&
      sampleData[fileIndex]
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_PIPES &&
      sampleData[fileIndex] &&
      (!mappedSystemFields.pipe_type || !mappedSystemFields.material)
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_PIPES &&
      sampleData[fileIndex] &&
      !mappedSystemFields.units
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
      );
    }

    if (
      currentStep === GISRoutes.PROCESS_PIPES &&
      sampleData[fileIndex] &&
      hasStatusColumn &&
      !mappedSystemFields.status
    ) {
      updatedErrors = addOrReplaceError(
        updatedErrors,
        ErrorModalText.SYSTEM_FIELDS_NOT_MAPPED,
      );
    }

    if (updatedErrors) {
      setIsError(updatedErrors);
      setIsErrorModalVisible(true);
    }

    if (sampleData.length === 0) {
      setCurrentStep((prevStep: number) => goForwardStep(prevStep));
      return;
    }

    if (updatedErrors) {
      return;
    }

    await columnMappingMutate({ body });
  };

  useEffect(() => {
    if (mappingSuccess) {
      if (sampleData.length === 0 || fileIndex === sampleData.length - 1) {
        setCurrentStep((prevStep: number) => goForwardStep(prevStep));
        setFileIndex(0);
        setMappedSystemFields(defaultSystemFields);

        return;
      }

      setFileIndex(fileIndex + 1);
      setMappedSystemFields(defaultSystemFields);
    }
  }, [mappingSuccess]);

  const decrementFileIndex = () => {
    if (sampleData.length === 0) {
      setCurrentStep((prevStep: number) => goBackStep(prevStep));
      if (type === 'Pipes') {
        setFileIndex((lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0);
      } else if (type === 'Assets') {
        setFileIndex((lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0);
      }
      setIsError(null);
      return;
    }

    if (fileIndex === 0) {
      setIsError(null);
      setCurrentStep((prevStep: number) => goBackStep(prevStep));
      if (type === 'Pipes') {
        setFileIndex((lengthOfFiles?.dma && lengthOfFiles?.dma - 1) || 0);
      } else if (type === 'Assets') {
        setFileIndex((lengthOfFiles?.pipe && lengthOfFiles?.pipe - 1) || 0);
      }
      return;
    }

    setIsError(null);
    setFileIndex(fileIndex - 1);
  };

  const selectedColumnIds = useMemo(() => {
    return getSelectedColumnIds(sampleData, selectedValues, fileIndex);
  }, [sampleData, selectedValues, fileIndex]);

  const unselectedColumns = useMemo(() => {
    const columns = getUnselectedColumns(tableColumns, selectedColumnIds);

    return columns;
  }, [tableColumns, selectedColumnIds]);

  const requiredFieldsCount = tableColumns.filter(
    (column) => column.required,
  ).length;

  const insufficientSampleData =
    sampleData.length > 0 &&
    sampleData[fileIndex] !== undefined &&
    sampleData[fileIndex].data.length > 0 &&
    sampleData[fileIndex].data[0] !== undefined &&
    Object.keys(sampleData[fileIndex].data[0] as object).length <
      requiredFieldsCount;

  const reset = () => {
    resetProvider();
  };

  const hasNonRequiredField = tableColumns.some((column) => !column.required);

  const selectedFile = (filename: string): FileSelectedValues | undefined =>
    selectedValues?.find((el) => el.file === filename);

  const currentFile = selectedFile(sampleData[fileIndex]?.file_name);

  const getSelectedItems = (): SelectedValue[] => {
    const material = currentFile?.data.find((ele) => ele.title === 'material');
    const asset_type =
      currentStep === GISRoutes.PROCESS_ASSETS
        ? currentFile?.data.find((ele) => ele.title === 'type')
        : undefined;

    const pipe_type =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === 'type')
        : undefined;

    const status =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === 'status')
        : undefined;

    const units =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === 'units')
        : undefined;

    const diameter =
      currentStep === GISRoutes.PROCESS_PIPES
        ? currentFile?.data.find((ele) => ele.title === 'diameter')
        : undefined;

    const selectedValues: SelectedValue[] = [];

    if (material) {
      selectedValues.push({ ...material, type: GIS_COLUMN_MAPPING.MATERIAL });
    }

    if (diameter) {
      selectedValues.push({ ...diameter, type: GIS_COLUMN_MAPPING.UNITS });
    }

    if (units) {
      selectedValues.push({ ...units, type: GIS_COLUMN_MAPPING.UNITS });
    }

    if (status) {
      selectedValues.push({ ...status, type: GIS_COLUMN_MAPPING.STATUS });
    }

    if (pipe_type) {
      selectedValues.push({
        ...pipe_type,
        type: GIS_COLUMN_MAPPING.PIPE_TYPE,
      });
    }

    if (asset_type) {
      selectedValues.push({
        ...asset_type,
        type: GIS_COLUMN_MAPPING.ASSET_TYPE,
      });
    }

    return selectedValues;
  };

  return {
    discardFile,
    incrementFileIndex,
    decrementFileIndex,
    selectedColumnIds,
    unselectedColumns,
    hasNonRequiredField,
    insufficientSampleData,
    reset,
    discardFileLoading,
    mappingLoading,
    currentFile,
    getSelectedItems,
  };
};
