export enum ShapeType {
  LINE = 'line',
  POLYGON = 'polygon',
  POINT = 'point',
}

export type PointTooltipType = {
  Address?: string;
  Engineer?: string;
  Waypoint_name?: string | null;
  Deployment_order?: string | null;
  Waypoint_id?: string | null;
  Asset_id?: number | null;
  Asset_key?: string | null;
  Diameter_mm?: number | null;
  Icon?: string | null;
  Dma_id?: number | null;
  Standarised_asset_type?: string;
  Deployed_by?: string;
  FFT_graph?: string;
  Fido_result?: string;
  Latitude?: number | null;
  Leak_size?: string;
  Longitude?: number | null;
  Session_id?: string;
  days_from_last_update?: number | null;
  W3W?: string;
  audio_urls?: string[];
  Material?: string;
  standardized_material?: string;
  pipe_type?: string;
};

export type PolygonTooltipType = {
  DMA_ID: number;
  DMA_Name: string;
};

export type ToolTipType = {
  Waypoint_id?: string;
  Dma_key?: string | null;
  Dma_id?: number | null;
  Dma_name?: string | null;
  Dma_long?: string | null;
  fft_graph?: string | null;
  Address?: string | null;
  Region?: string | null;
  waypoint_ids?: string[];
  Zone?: string | null;
  Engineer?: string | null;
  Waypoint_name?: string;
  Relay_session?: string;
  Comment?: string | null;
  Status?: string | null;
  W3W?: string | null;
  Pollen_graph?: string | null;
  days_from_last_update?: string | null;
};

export type MapElement = {
  id: string;
  type: ShapeType;
  geometry: number[][];
  tooltip: ToolTipType;
  icon_type: string;
  map_type: string;
  color?: string;
};
