import React, { FC } from 'react';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';

type Props = {
  title: string;
  desc: string;
  isSuccess: boolean;
  handleClose: () => void;
};

export const StatusModal: FC<Props> = ({
  title,
  desc,
  isSuccess,
  handleClose,
}) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.STATUS_MODAL');
  return (
    <div>
      <div>
        <Styled.SvgContainer isSuccess={isSuccess}>
          {isSuccess ? (
            <Styled.PostiveIcon aria-label={prefixedT('ICON.POSITIVE.ALT')} />
          ) : (
            <Styled.NegativeIcon aria-label={prefixedT('ICON.NEGATIVE.ALT')} />
          )}
        </Styled.SvgContainer>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Desc>{desc}</Styled.Desc>
      </div>

      <Styled.SubmitBtn isSuccess={isSuccess} onClick={handleClose}>
        {prefixedT('OK')}
      </Styled.SubmitBtn>
    </div>
  );
};

export default StatusModal;
