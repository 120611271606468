import config from '@/config';
import useModalStatus from '@/hooks/useModalStatus';
import { WidgetLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDataSourcesQuery } from '@/redux/reports/reports.api';
import { ReportMapSettings, ReportWidget } from '@/types/report.type';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapbox from 'mapbox-gl';
import React, { FC, useMemo } from 'react';
import useDragMove from '../useDragMove';
import MapViewSettings from './components/MapViewSettings/MapViewSettings';
import GenerateMap from './GenerateMap';
import * as Styled from './style';
import { MapProvider } from '@/Providers/MapProvider';

mapbox.accessToken = String(config.MAPBOX_KEY);

type Props = {
  data: ReportWidget<ReportMapSettings>;
  id: string | number;
  readonly: boolean;
  onRemoveWidget: (
    widget: ReportWidget<ReportMapSettings>,
    layoutId: string | number,
  ) => void;
  onUpdateWidget: (
    widget: ReportWidget<ReportMapSettings>,
    layoutId: string | number,
  ) => void;
};

const MapView: FC<Props> = ({
  data,
  id,
  readonly,
  onRemoveWidget,
  onUpdateWidget,
}) => {
  const { ref } = useDragMove(data, id, Boolean(readonly));
  const modal = useModalStatus();
  const { data: sources } = useGetDataSourcesQuery();
  const { prefixedT } = useCustomTranslation('WIDGET_LAYOUT');

  const onOpenSettings = () => {
    modal.show();
  };

  const items = useMemo(
    () => [
      {
        title: prefixedT('SETTINGS'),
        handler: onOpenSettings,
        icon: Styled.GearIcon,
      },
    ],
    [],
  );

  return (
    <WidgetLayout
      dropRef={ref}
      name={prefixedT('MAP_VIEW')}
      readonly={readonly}
      items={items}
      onDelete={() => onRemoveWidget(data, id)}
    >
      <MapViewSettings
        open={modal.isOpen}
        sources={sources}
        onClose={modal.hide}
        onUpdateWidget={onUpdateWidget}
        widget={data}
        layoutId={id}
      />
      <MapProvider>
        <GenerateMap fullMapData={data.data} readonly={readonly} />
      </MapProvider>
    </WidgetLayout>
  );
};

export default React.memo(MapView);
