import { FileSelectedValues } from '@/Providers/GISUploadProvider';
import {
  Column,
  ColumnMappingBody,
  MappedColumns,
  SampleData,
} from '@/types/gisUpload.type';

export const mappedColumns = (
  selectedValues: FileSelectedValues[],
  uploadId: number | null,
  sampleData: SampleData[],
  fileIndex: number,
): ColumnMappingBody => {
  const columns = selectedValues.reduce<MappedColumns>(
    (acc, column) => {
      if (column.file === sampleData[fileIndex]?.file_name) {
        const newColumns = column.data
          .map((data) => {
            if (data.required) {
              return {
                id: Number(data.value),
                title: data.name,
              };
            } else {
              return;
            }
          })
          .filter(
            (column): column is { id: number; title: string } => !!column,
          );
        acc.columns.push(...newColumns);
        acc.material = column.material || [];
        acc.asset_type = column.asset_type || [];
        acc.pipe_type = column.pipe_type || [];
        acc.status = column.status || [];
        acc.units = column.units || [];
      }
      return acc;
    },
    {
      columns: [],
      material: [],
      asset_type: [],
      pipe_type: [],
      status: [],
      units: [],
    },
  );

  return {
    upload_id: uploadId,
    filename: sampleData[fileIndex]?.file_name,
    mapped_columns: columns,
  };
};

interface UnselectedColumns {
  required: Column[];
  optional: Column[];
}

export const getUnselectedColumns = (
  tableColumns: Column[],
  selectedColumnIds: number[],
): UnselectedColumns => {
  const required: Column[] = [];
  const optional: Column[] = [];

  tableColumns.forEach((column) => {
    if (!selectedColumnIds.includes(column.id)) {
      if (column.required) {
        required.push(column);
      } else {
        optional.push(column);
      }
    }
  });

  return {
    required,
    optional,
  };
};

export const getSelectedColumnIds = (
  sampleData: { file_name: string }[],
  selectedValues: FileSelectedValues[],
  fileIndex: number,
): number[] => {
  if (sampleData.length === 0) {
    return [];
  }

  const selectedFile = selectedValues.find(
    (column) => column?.file === sampleData[fileIndex]?.file_name,
  );

  return selectedFile
    ? selectedFile.data
        .map((data) => {
          const numberValue = Number(data.value);
          return isNaN(numberValue) ? null : numberValue;
        })
        .filter((value): value is number => value !== null)
    : [];
};
