import React, { useState } from 'react';
import * as Styled from './style';
import { useGetGISAdminPageQuery } from '@/redux/GISAdmin/GISAdmin.api';
import { Button, DropDown, Loader, Table } from '@/components';
import { getRowStructure, headers } from './utils/Utils';
import { GISAdminPageResponse } from '@/types/GISAdmin.type';
import useCustomTranslation from '@/localization/useCustomTranslation';
import DeleteModal from './Components/DeleteModal/DeleteModal';
import PreviewModal from './Components/PreviewModal/PreviewModal';
import { FilterProvider } from '@/Providers/FilterProvider';
import { DropDownOption } from '@/components/DropDown/DropDown';
import SearchBar from '@/components/SearchBar/SearchBar';
import { ITablePaginate } from '@/components/Table/types';

export const defaultPageCount = 1;
export const defaultPerPage = 10;

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

const GISAdmin = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { t, prefixedT } = useCustomTranslation('GIS_ADMIN');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [orderBy, _setOrderBy] = useState<string>('asc');
  const [uploadId, setUploadId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(defaultPerPage);
  const { data, isFetching, isError, refetch } = useGetGISAdminPageQuery(
    {
      page,
      per_page: perPage,
      search: searchQuery,
      order_type: orderBy,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const handleRowAmountChange = (value: DropDownOption) => {
    setPerPage(Number(value.value));
    setPage(1);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const currentPageCount = Math.ceil(
    (data?.count || defaultPageCount) / perPage,
  );

  const handleDeleteClick = (idx: number) => {
    setUploadId(idx);
    setIsDeleteModalOpen(true);
  };

  const handlePreviewClick = (idx: number, companyId: number) => {
    setUploadId(idx);
    setCompanyId(companyId);
    setIsPreviewModalOpen(true);
  };

  const closeDeleteModal = async (shouldRefetch: boolean) => {
    if (shouldRefetch) {
      await refetch();
    }
    setUploadId(null);
    setIsDeleteModalOpen(false);
  };

  const closePreviewModal = () => {
    setUploadId(null);
    setCompanyId(null);
    setIsPreviewModalOpen(false);
  };

  const handleSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  const handleSearchClick = () => {
    setSearchQuery(searchInput);
  };

  return (
    <Styled.Wrapper>
      {uploadId && (
        <DeleteModal
          open={isDeleteModalOpen}
          uploadId={uploadId}
          closeModal={closeDeleteModal}
        />
      )}
      {uploadId && companyId && (
        <FilterProvider>
          <PreviewModal
            open={isPreviewModalOpen}
            uploadId={uploadId}
            companyId={companyId}
            closeModal={closePreviewModal}
          />
        </FilterProvider>
      )}
      <Styled.MainTitleContainer>
        <Styled.MainTitle>{prefixedT('TITLE')}</Styled.MainTitle>
      </Styled.MainTitleContainer>
      <Styled.SearchContainer>
        <Styled.SearchBar>
          <SearchBar
            placeholder={prefixedT('SEARCH.PLACEHOLDER')}
            onSearch={handleSearchInputChange}
          />
        </Styled.SearchBar>
        <Button onClick={handleSearchClick}>{prefixedT('SEARCH.TEXT')}</Button>
      </Styled.SearchContainer>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT('TABLE.TITLE')}</Styled.Title>
      </Styled.TitleContainer>
      <Table data={data?.data || []} headers={headers()}>
        <Table.Head getHeaderStructure={(header) => header.title} />
        {isFetching && (
          <Styled.LoaderContainer>
            <Loader />
          </Styled.LoaderContainer>
        )}
        {isError && <p>{t('SERVER_ERRORS.-1')}</p>}
        {!isFetching && !isError && (
          <Table.Body
            getRowStructure={(row: GISAdminPageResponse, idx: number) =>
              getRowStructure(row, idx, handleDeleteClick, handlePreviewClick)
            }
            striped
          />
        )}
      </Table>
      <Styled.PaginationWrapper>
        <Styled.PagniationContainer>
          <Styled.DropDownContainer>
            <p>{t('VIEW_USERS.PAGINATION.TITLE')}</p>
            <DropDown
              value={perPage.toString()}
              options={pageValues}
              onChange={(value) =>
                handleRowAmountChange(value as DropDownOption)
              }
              placeholder={'Number of rows'}
            />
          </Styled.DropDownContainer>
          <Table.Pagination
            pages={currentPageCount}
            onChange={onPageChange}
            initialPage={page}
          />
        </Styled.PagniationContainer>
      </Styled.PaginationWrapper>
    </Styled.Wrapper>
  );
};

export default GISAdmin;
