import React, { FC } from 'react';
import HistoryItem from './HistoryItem/HistoryItem';
import * as Styled from './style';

interface HistoryTableProps {
  headingList: string[];
  data: { [key: string]: string | number | undefined | null }[];
}

const HistoryTable: FC<HistoryTableProps> = ({ data, headingList }) => (
  <Styled.Wrapper>
    <Styled.HistoryLogWrapper>
      <Styled.Table>
        <thead>
          <tr>
            {headingList.map((heading: string, key: number) => (
              <Styled.TH key={key}>
                <Styled.TableHeading>{heading}</Styled.TableHeading>
              </Styled.TH>
            ))}
          </tr>
        </thead>
        <Styled.TBody>
          <HistoryItem data={data} />
        </Styled.TBody>
      </Styled.Table>
    </Styled.HistoryLogWrapper>
  </Styled.Wrapper>
);

export default HistoryTable;
