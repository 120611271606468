import styled from 'styled-components';

export const Wrapper = styled.div<{
  status?: string;
}>`
  width: fit-content;
  display: flex;
  align-items: center;
  background: ${({ status }) => {
    switch (status) {
      case 'error':
        return '#fef2f2';
      case 'warning':
        return '#fefaed';
      case 'success':
        return '#ecfdf5';
      default:
        return 'blue';
    }
  }};
  border-radius: 5px;
  border: 1px solid;
  padding: 20px;
  border-color: ${({ status }) => {
    switch (status) {
      case 'error':
        return '#f98888';
      case 'warning':
        return '#fbc947';
      case 'success':
        return '#36c595';
      default:
        return 'blue';
    }
  }};
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const DescriptionContainer = styled.div``;

export const ImageContainer = styled.div`
  margin-right: 20px;
`;

export const Title = styled.h3``;

export const Description = styled.p``;
