import React, { FC } from 'react';
import * as Styled from './style';
import { Option } from './FormOptions';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetLeakTypesQuery } from '@/redux/reports/reports.api';
import { FormikErrors } from 'formik';
import {
  patchWaypointJobBody,
  patchWaypointJobForm,
  WaypointJob,
} from '@/types/report.type';
import { useGetEngineersQuery } from '@/redux/portalUsers/portalUsers.api';
import { jobStatuses, leakTypes } from '../Waypoint/utils';
import moment from 'moment';

interface WaypointInfoSectionProps {
  waypointID: string;
  waypointJob: WaypointJob;
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<patchWaypointJobBody>>;
  values: patchWaypointJobForm;
}

export const WaypointInfoSection: FC<WaypointInfoSectionProps> = ({
  waypointID,
  waypointJob,
  edit,
  setFieldValue,
  values,
}) => {
  const { t, prefixedT } = useCustomTranslation('JOB_DETAILS');
  const { data: engineers } = useGetEngineersQuery();
  const { data: leakTypesData } = useGetLeakTypesQuery();

  return (
    <Styled.List>
      <h2>{t('WAYPOINT_PAGE.WAYPOINT_INFO.TITLE')}</h2>
      <Option
        edit={false}
        testid="waypoint-id"
        title={prefixedT('WAYPOINT')}
        option={waypointID}
      />
      <Option
        edit={false}
        testid="project"
        title={prefixedT('PROJECT')}
        option={waypointJob.branch_name}
      />
      <Option
        edit={false}
        testid="dma"
        title={prefixedT('DMA')}
        option={waypointJob.dma_name}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="estimate-leak-size"
        input
        type="number"
        placeholder={'Estimated Leak Size'}
        name={'waypoint_job_info.estimate_leak_size'}
        title={prefixedT('LEAK_SIZE')}
        option={values.waypoint_job_info.estimate_leak_size}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="engineer"
        dropdown
        dropdownValues={
          engineers?.map((engineer) => ({
            value: `${engineer.id}`,
            label: engineer.name,
          })) || []
        }
        placeholder={'Engineer'}
        name={'waypoint_job_info.engineer'}
        title={prefixedT('ENGINEER')}
        option={waypointJob.last_scheduled_engineer}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="leak-type"
        placeholder={'add leak type'}
        dropdown
        dropdownValues={
          leakTypesData?.map((leak_type) => ({
            value: `${leak_type.id}`,
            label: t(
              'WAYPOINT_PAGE.ADD_JOB.' + leakTypes[leak_type.id] ||
                leak_type.type,
            ),
          })) || []
        }
        name={'waypoint_job_info.leak_type_id'}
        title={prefixedT('LEAK_TYPE')}
        option={waypointJob.leak_type}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="comments"
        name={'waypoint_job_info.comment'}
        input
        type="textarea"
        title={prefixedT('COMMENTS')}
        option={values.waypoint_job_info.comment}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        name={'waypoint_job_info.environmental_notes'}
        placeholder={'Enter a comment'}
        testid={'environmental_notes'}
        input
        type={'textarea'}
        title={prefixedT('ENVIRONMENTAL_NOTE')}
        option={values.waypoint_job_info.environmental_notes}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="pipe-material"
        input
        placeholder={'Add Pipe Material'}
        name={'waypoint_job_info.material'}
        title={prefixedT('MATERIAL')}
        option={values.waypoint_job_info.material}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="pipe_diameter"
        input
        type={'number'}
        placeholder={'Add Pipe Size'}
        name={'waypoint_job_info.pipe_diameter'}
        title={prefixedT('SIZE')}
        option={values.waypoint_job_info.pipe_diameter}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="surface_type"
        input
        placeholder={'Add Road Surface'}
        name={'waypoint_job_info.surface_type'}
        title={prefixedT('SURFACE')}
        option={values.waypoint_job_info.surface_type}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="flow-reduction"
        input
        type={'number'}
        placeholder={'Add Flow Reduction'}
        name={'waypoint_job_info.agreed_flow_reduction'}
        title={prefixedT('FLOW_REDUCTION')}
        option={values.waypoint_job_info.agreed_flow_reduction}
      />
    </Styled.List>
  );
};

interface JobInfoSectionProps {
  jobID: number;
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<patchWaypointJobBody>>;
  values: patchWaypointJobForm;
}

export const JobInfoSection: FC<JobInfoSectionProps> = ({
  jobID,
  edit,
  setFieldValue,
  values,
}) => {
  const { t, prefixedT } = useCustomTranslation('JOB_DETAILS');

  return (
    <Styled.List>
      <h2>{prefixedT('JOB_INFO_TITLE')}</h2>
      <Option
        edit={false}
        testid="job-id"
        title={prefixedT('ID')}
        option={jobID}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        input
        testid="work-order"
        name={'waypoint_job_info.work_order'}
        title={prefixedT('WORK_ORDER')}
        option={values.waypoint_job_info?.work_order || null}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="status"
        dropdown
        dropdownValues={jobStatuses.map((jobStatus) => ({
          value: jobStatus.value,
          label: t('WAYPOINT_PAGE.ADD_JOB.' + jobStatus.label),
        }))}
        placeholder={'Add waypoint status'}
        name={'waypoint_job_info.job_status'}
        title={prefixedT('STATUS')}
        option={values.waypoint_job_info.job_status}
      />
    </Styled.List>
  );
};

interface LocationInfoSectionProps {
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<patchWaypointJobBody>>;
  values: patchWaypointJobForm;
}

export const LocationInfoSection: FC<LocationInfoSectionProps> = ({
  edit,
  setFieldValue,
  values,
}) => {
  const { prefixedT } = useCustomTranslation('JOB_DETAILS');

  return (
    <Styled.List>
      <h2>{prefixedT('LOCATION_INFO_TITLE')}</h2>
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="address"
        name={'waypoint_job_info.address'}
        input
        type="textarea"
        title={prefixedT('ADDRESS')}
        option={values.waypoint_job_info.address}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="job-w3w"
        name={'waypoint_job_info.w3w'}
        input
        title={prefixedT('W3W')}
        option={values.waypoint_job_info.w3w}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="job-road-speed"
        name={'waypoint_job_info.road_speed'}
        input
        type="number"
        title={prefixedT('ROAD_SPEED')}
        option={values.waypoint_job_info.road_speed}
      />
    </Styled.List>
  );
};

interface DateInfoSectionProps {
  edit: boolean;
  waypointJob: WaypointJob;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<patchWaypointJobBody>>;
  values: patchWaypointJobForm;
}

export const DateInfoSection: FC<DateInfoSectionProps> = ({
  edit,
  waypointJob,
  setFieldValue,
  values,
}) => {
  const { t, prefixedT } = useCustomTranslation('JOB_DETAILS');
  return (
    <Styled.List>
      <h2>{prefixedT('DATE_INFO_TITLE')}</h2>
      <Option
        edit={false}
        testid="date-created"
        title={prefixedT('DATE_SECTION.CREATED')}
        option={moment(waypointJob.created_at).format('YYYY-MM-DD')}
      />
      <Option
        edit={false}
        testid="date-updated"
        title={prefixedT('DATE_SECTION.UPDATED')}
        option={moment(waypointJob.updated_at).format('YYYY-MM-DD')}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="repair-date"
        date
        placeholder={'Date Raised Internally'}
        name={'waypoint_job_info.date_raised_internally'}
        title={t('WAYPOINT_PAGE.ADD_JOB.DATE_INTERNALLY')}
        option={values.waypoint_job_info.date_raised_internally}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="repair-date"
        date
        placeholder={'Date Raised Externally'}
        name={'waypoint_job_info.date_raised_externally'}
        title={t('WAYPOINT_PAGE.ADD_JOB.DATE_EXTERNALLY')}
        option={values.waypoint_job_info.date_raised_externally}
      />
      <Option
        edit={edit}
        setFieldValue={setFieldValue}
        testid="repair-date"
        date
        placeholder={'Repair Date'}
        name={'waypoint_job_info.repair_date'}
        title={prefixedT('DATE_SECTION.REPAIR')}
        option={values.waypoint_job_info.repair_date}
      />
    </Styled.List>
  );
};
