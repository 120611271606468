import React, { FC, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import Select, { createFilter } from 'react-select';
import * as Yup from 'yup';

import * as Styled from './style';

import useCustomTranslation from '@/localization/useCustomTranslation';
import { Button, DatePicker, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { useGetCompanyListQuery } from '@/redux/portalCompanies/portalCompanies.api';
import theme from '@/theme';
import i18n from '@/localization/i18n';
import { dropDownCountryData } from '@/utils/countryData';
import {
  useGetUnassignedDMAsQuery,
  usePatchUpdateProjectMutation,
} from '@/redux/portalProjects/portalProjects.api';
import {
  PatchUpdateProjectBody,
  UpdateProjectForm,
} from '@/types/portalProject.type';
import moment from 'moment';

interface EditProjectProps {
  project_id: number;
  name: string;
  company: { id: number; name: string };
  dmas: { id: number; name: string }[];
  start_date: string;
  end_date: string;
  cost_per_distance?: number;
  closeOverlay: (refetchValues: boolean) => Promise<void>;
}

const EditProject: FC<EditProjectProps> = ({
  company,
  dmas,
  end_date,
  project_id,
  name,
  start_date,
  cost_per_distance,
  closeOverlay,
}) => {
  const [mutate, { isSuccess, isError, reset }] =
    usePatchUpdateProjectMutation();

  const { data: unassignedDmas } = useGetUnassignedDMAsQuery(
    {
      project_id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const allDmaOptions = [
    ...dmas,
    ...(unassignedDmas !== undefined ? unassignedDmas : []),
  ];

  const { data: companyList } = useGetCompanyListQuery();
  const { t, prefixedT } = useCustomTranslation('EDIT_PROJECT');

  const initialValues: UpdateProjectForm = {
    branch_name: name,
    start_date,
    end_date,
    cost_per_distance,
    dmas: dmas.map((dma) => ({
      value: dma.id,
      label: dma.name,
    })),
    metric_system: 'Metric',
    currency: 'GBP',
    company_id: company.id,
  };

  const [_countryCodes, setCountryCodes] = useState<DropDownOption[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const countryData = await dropDownCountryData();

      setCountryCodes(countryData);
    };

    void fetchData();
  }, [i18n.language]);

  const [isNoChange, setIsNoChange] = useState(false);

  const onSubmit = async (values: UpdateProjectForm) => {
    setIsNoChange(false);
    // check what has changed and mutate those values only
    const changedValues: UpdateProjectForm = {};

    Object.entries(initialValues).forEach(([key, value]) => {
      const typedKey = key as keyof UpdateProjectForm;
      if (JSON.stringify(values[typedKey]) !== JSON.stringify(value)) {
        (changedValues[typedKey] as
          | string
          | number
          | { value: number; label: string }[]
          | undefined) = values[typedKey];
      }
    });

    if (Object.entries(changedValues).length === 0) {
      setIsNoChange(true);
    } else {
      // format the object to send to the API
      const { dmas: _dmas, ...rest } = changedValues;
      const patchBody: PatchUpdateProjectBody = {
        project_info: rest,
        dma_ids: values.dmas?.map((dma) => dma.value),
      };
      await mutate({ id: project_id, body: patchBody });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await closeOverlay(true);
        reset();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    branch_name: Yup.string().required(prefixedT('NAME.VALIDATION')),
    start_date: Yup.date().max(
      Yup.ref('end_date'),
      prefixedT('START_DATE.VALIDATION'),
    ),
    end_date: Yup.date().min(
      Yup.ref('start_date'),
      prefixedT('END_DATE.VALIDATION'),
    ),
    cost_per_distance: Yup.number()
      .typeError(prefixedT('COST_PER_DISTANCE.VALIDATION'))
      .required(prefixedT('COST_PER_DISTANCE.VALIDATION')),
    company_id: Yup.number().required(prefixedT('COMPANY.VALIDATION')),
  });

  return (
    <>
      <Styled.Underlay
        data-testid={'overlay'}
        onClick={() => {
          void (async () => {
            await closeOverlay(false);
          })();
        }}
      />
      <Styled.DialogBox open={true}>
        <Styled.Wrapper>
          <Styled.TitleContainer>
            <h3>{prefixedT('TITLE')}</h3>
          </Styled.TitleContainer>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ values, errors, submitForm, setFieldValue }) => {
              return (
                <div>
                  <Styled.FormRow cols={3}>
                    <Styled.FormInput>
                      <Field
                        as={TextField}
                        required
                        name="branch_name"
                        testId="branch-name"
                        error={errors.branch_name}
                        label={prefixedT('NAME.LABEL')}
                        placeholder={prefixedT('NAME.PLACEHOLDER')}
                      />
                    </Styled.FormInput>
                    <Styled.FormInput>
                      <Field
                        as={DropDown}
                        required
                        testId="company"
                        name="company_id"
                        styles={{ border: true, width: '50%' }}
                        options={companyList?.map((company) => {
                          return {
                            label: company.name,
                            value: company.id,
                          };
                        })}
                        label={prefixedT('COMPANY.LABEL')}
                        placeholder={prefixedT('COMPANY.PLACEHOLDER')}
                        onChange={(option: DropDownOption) =>
                          setFieldValue('company_id', option.value)
                        }
                        error={errors.company_id?.toString()}
                        value={values.company_id}
                      />
                    </Styled.FormInput>
                    <Styled.FormInput>
                      <Field
                        as={TextField}
                        required
                        name="cost_per_distance"
                        testId="cost-per-distance"
                        error={errors.cost_per_distance}
                        label={prefixedT('COST_PER_DISTANCE.LABEL')}
                        type="number"
                        placeholder={prefixedT('COST_PER_DISTANCE.PLACEHOLDER')}
                      />
                    </Styled.FormInput>
                  </Styled.FormRow>
                  <Styled.FormRow cols={2}>
                    <Styled.FormInput>
                      <Field
                        as={DatePicker}
                        name="startDate"
                        label={prefixedT('START_DATE.LABEL')}
                        placeholder={prefixedT('START_DATE.PLACEHOLDER')}
                        value={
                          values.start_date ? new Date(values.start_date) : null
                        }
                        onChange={(date: string) => {
                          const formattedDate =
                            moment(date).format('YYYY-MM-DD');
                          if (
                            moment(formattedDate, 'YYYY-MM-DD', true).isValid()
                          ) {
                            void setFieldValue('start_date', formattedDate);
                          }
                        }}
                        testId="start-date"
                        error={errors.start_date}
                      />
                    </Styled.FormInput>
                    <Styled.FormInput>
                      <Field
                        as={DatePicker}
                        name="endDate"
                        label={prefixedT('END_DATE.LABEL')}
                        placeholder={prefixedT('END_DATE.PLACEHOLDER')}
                        value={
                          values.end_date ? new Date(values.end_date) : null
                        }
                        onChange={(date: string) => {
                          const formattedDate =
                            moment(date).format('YYYY-MM-DD');
                          if (
                            moment(formattedDate, 'YYYY-MM-DD', true).isValid()
                          ) {
                            void setFieldValue('end_date', formattedDate);
                          }
                        }}
                        testId="end-date"
                        error={errors.end_date}
                      />
                    </Styled.FormInput>
                  </Styled.FormRow>

                  <label data-testid="dmas">
                    {prefixedT('DMAS.LABEL')}
                    <Select
                      inputId="dma-input-id"
                      isMulti
                      isClearable={false}
                      name="dmas"
                      options={allDmaOptions
                        .sort((a, b) => {
                          if (a.name !== null && b.name !== null) {
                            return a.name.localeCompare(b.name);
                          } else {
                            return 0;
                          }
                        })
                        .map((dma) => ({
                          value: dma.id,
                          label: dma.name,
                        }))}
                      filterOption={createFilter({
                        matchFrom: 'any',
                        stringify: (option) => `${option.label}`,
                      })}
                      noOptionsMessage={() => prefixedT('DMAS.NO_MATCHING')}
                      styles={{
                        menuList: (base) => ({
                          ...base,
                          maxHeight: 200,
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          maxHeight: 100,
                          overflowY: 'auto',
                          gap: 4,
                        }),
                        multiValue: (base) => ({
                          ...base,
                          color: 'white',
                          backgroundColor: theme.colors.frenchBlue,
                          borderRadius: 10,
                          padding: 5,
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          color: 'white',
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          cursor: 'pointer',
                        }),
                      }}
                      placeholder={prefixedT('DMAS.PLACEHOLDER')}
                      onChange={(selectedOptions) =>
                        void setFieldValue(
                          'dmas',
                          selectedOptions.toSorted((a, b) =>
                            a.label.localeCompare(b.label),
                          ),
                        )
                      }
                      value={values.dmas}
                    />
                  </label>
                  <Styled.ButtonContainer>
                    <Button
                      color={theme.colors.coral}
                      onClick={() => {
                        void (async () => {
                          await closeOverlay(false);
                        })();
                      }}
                    >
                      {prefixedT('DISCARD')}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                    >
                      {prefixedT('SUBMIT')}
                    </Button>
                  </Styled.ButtonContainer>
                  {isNoChange ? (
                    <Styled.ErrorContainer>
                      <Styled.ErrorText>
                        {prefixedT('NO_CHANGES')}
                      </Styled.ErrorText>
                    </Styled.ErrorContainer>
                  ) : (
                    isError && (
                      <Styled.ErrorContainer>
                        <Styled.ErrorText>
                          {t('ERROR.REQUEST')}
                        </Styled.ErrorText>
                      </Styled.ErrorContainer>
                    )
                  )}
                </div>
              );
            }}
          </Formik>
        </Styled.Wrapper>
      </Styled.DialogBox>
    </>
  );
};

export default EditProject;
