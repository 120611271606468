import { resetState } from '@/redux';
import { logout, setToken } from '@/redux/authorization/authorization.slice';
import { setError, setSuccess } from '@/redux/request/request.slice';
import type { ApiEntities, ApiEntity } from '@/types/redux.type';
import {
  AnyAction,
  isFulfilled,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const isFulfilledWithValue = isFulfilled();
const GENERAL_ERROR_CODE = -1;

const rtkQueryErrorHandler: Middleware =
  (store) => (next) => (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as FetchBaseQueryError;
      const statusCode = payload?.status || GENERAL_ERROR_CODE;
      store.dispatch(setError(statusCode));

      const arg = action?.meta?.arg as { endpointName: string };
      const loginEndpoint = arg?.endpointName === 'login';

      if (!loginEndpoint && statusCode === 401) {
        store.dispatch(setToken(''));
        store.dispatch(resetState());
        store.dispatch(logout());
      }
    } else if (isFulfilledWithValue(action)) {
      const type = (action.meta.arg as { endpointName: string })?.endpointName;
      if (type) {
        store.dispatch(setSuccess(type));
      }
    }

    return next(action);
  };

export const initialEntities: ApiEntities = {
  reducers: {},
  middlewares: [rtkQueryErrorHandler],
};

const getApiEntitiesCallback = <T extends ApiEntity>(
  data: ApiEntities,
  item: T,
) => ({
  ...data,
  reducers: {
    ...data.reducers,
    [item.reducerPath]: item.reducer,
  },
  middlewares: [...data.middlewares, item.middleware],
});

export const getApiEntities = <T extends ApiEntity>(list: T[]) =>
  list.reduce<ApiEntities>(getApiEntitiesCallback, initialEntities);

export const getTranslationFormatNameFromAction = (name: string) =>
  name
    .split(/(?=[A-Z])/)
    .map((str: string) => str.toUpperCase())
    .join('_');
