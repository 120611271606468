import PlusIcon from '@/assets/icons/plus-icon.png';
import { Button } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useLazyGetUserListQuery } from '@/redux/portalUsers/portalUsers.api';
import React, { FC } from 'react';
import * as Styled from './style';
import { getRowStructure, headers } from './utils/utils';
import SearchableTable from '@/components/SearchableTable/SearchableTable';
import EditUser from './EditUser/EditUser';
import { PartnerAndCompany } from '../UserManagement';
import DeleteUser from './DeleteUser/DeleteUser';
import { UserRoles } from '@/types/user.type';
import { UserRole } from '@/types/portalUsers.type';

interface ViewUserProps {
  roles: UserRole[] | undefined;
  partnersAndCompanies: PartnerAndCompany;
  role: UserRoles;
}

const ViewUsers: FC<ViewUserProps> = ({
  roles,
  partnersAndCompanies,
  role,
}) => {
  const { prefixedT } = useCustomTranslation('VIEW_USERS');

  const [trigger, { data: userListData, isFetching, isError }] =
    useLazyGetUserListQuery();

  return (
    <SearchableTable
      queryResponse={userListData}
      isFetching={isFetching}
      isError={isError}
      ExtraHeaderComponent={
        <Styled.BtnContainer data-testid="add-users-btn">
          <Styled.Link to={'/app/user-management/add-user'}>
            <Button>
              {prefixedT('BUTTON.ADD')}{' '}
              <Styled.PlusImg src={PlusIcon as string} alt="plus-icon" />
            </Button>
          </Styled.Link>
        </Styled.BtnContainer>
      }
      trigger={trigger}
      editModal={(idx, closeOverlay) => {
        const currentUser = userListData?.data[idx];
        if (currentUser !== undefined) {
          return (
            <EditUser
              first_name={currentUser.first_name}
              last_name={currentUser.last_name}
              id={currentUser.id}
              email={currentUser.email}
              role_id={currentUser.role_id}
              partner_id={currentUser.partner_id}
              company_id={currentUser.company?.id}
              roles={roles}
              partnersAndCompanies={partnersAndCompanies}
              closeOverlay={closeOverlay}
            />
          );
        }
      }}
      deleteModal={(idx, closeOverlay) => {
        const currentUser = userListData?.data[idx];
        if (currentUser !== undefined) {
          return <DeleteUser id={currentUser.id} closeOverlay={closeOverlay} />;
        }
      }}
      getRowStructure={getRowStructure}
      headers={headers}
      translationPrefix="VIEW_USERS"
      role={role}
    />
  );
};

export default ViewUsers;
