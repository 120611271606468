import { Map, MapMouseEvent } from 'mapbox-gl';
import { LatLngType } from '@/Providers/MapProvider';
import { linesLayerID } from '../lines/utils';
import { layerID } from '../clusters/utils/utils';

export const hoverEvents = (
  map: Map,
  setLngLat: React.Dispatch<React.SetStateAction<LatLngType>>,
  setTooltip: React.Dispatch<React.SetStateAction<string>>,
) => {
  let isMouseDown = false;
  const handleTooltipHover = (e: MapMouseEvent) => {
    if (isMouseDown) return;
    map.getCanvas().style.cursor = 'pointer';

    const clickedFeature = map.queryRenderedFeatures(e.point)[0];

    if (clickedFeature) {
      const tooltip = clickedFeature.properties?.tooltip as string;
      const containsDMAKey = tooltip && tooltip.includes('Dma_key');
      if (tooltip && !containsDMAKey) {
        setLngLat(e.lngLat);
        setTooltip(tooltip);
      }
    }
  };

  map.on('mousedown', () => {
    isMouseDown = true;
  });

  map.on('mouseup', () => {
    isMouseDown = false;
  });

  const mapLayers = map.getStyle().layers;

  mapLayers?.forEach((layer) => {
    if (layer.type === 'fill') {
      map.on('mouseenter', layer.id, handleTooltipHover);
    }
  });

  map.on('mouseenter', layerID, handleTooltipHover);
  map.on('mouseenter', linesLayerID, handleTooltipHover);
};
