import { DatePicker, DropDown, Loader } from '@/components';
import Button from '@/components/Button/Button';
import { DropDownOption } from '@/components/DropDown/DropDown';
import TextField from '@/components/TextField/TextField';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { usePatchUpdateWaypointMutation } from '@/redux/highlandPortal/highlandPortal.api';
import {
  useGetLeakTypesQuery,
  useGetStatusesQuery,
} from '@/redux/mobileApp/mobileApp.api';
import { useGetEngineersQuery } from '@/redux/portalUsers/portalUsers.api';
import { PatchUpdateWaypointPortalBody } from '@/types/highlandPortal';
import { WaypointInformationType } from '@/types/report.type';
import { Field, Formik, FormikErrors } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import * as Styled from './style';
import {
  engineerDropDownValues,
  leakTypeDropDownValues,
  statusDropDownValues,
  createInitialValues,
  ISO8601DateFormat,
  getChangedValues,
} from './Utils/Utils';
import { isEmptyObject } from '@/utils/isEmptyObject';
import TextArea from '@/components/TextArea/TextArea';

export interface OptionProps {
  title: string;
  option: string | number | null;
  edit: boolean;
  setFieldValue?: (
    field: string,
    value: string | number | null | Date | undefined,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<PatchUpdateWaypointPortalBody>>;
  placeholder?: string;
  testid: string;
  dropdown?: boolean;
  dropdownValues?: DropDownOption[];
  date?: boolean;
  name?: string;
  input?: boolean;
  type?: string;
}

export const Option = ({
  title,
  dropdown,
  dropdownValues,
  option,
  type,
  edit,
  input,
  placeholder,
  date,
  testid,
  name,
  setFieldValue,
}: OptionProps) => {
  const renderOption = () => {
    if (!edit && !date) {
      return <Styled.ListObject>{option}</Styled.ListObject>;
    } else if (!edit && date && option) {
      return (
        <Styled.ListObject>
          {moment(option).format('YYYY-MM-DD')}
        </Styled.ListObject>
      );
    }
    return null;
  };

  const renderTextArea = () => {
    if (input && edit && setFieldValue && name && type === 'textarea') {
      return (
        <Field
          as={TextArea}
          name={name}
          type={type || 'text'}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDateField = () => {
    if (date && edit && setFieldValue && name) {
      return (
        <Field
          as={DatePicker}
          name={name}
          placeholder={placeholder}
          onChange={(dateValue: Date) => {
            void setFieldValue(name, dateValue ? dateValue : null);
          }}
          testId={testid}
        />
      );
    }
    return null;
  };

  const renderInputField = () => {
    if (input && edit && setFieldValue && name && type !== 'textarea') {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (type === 'number') {
          void setFieldValue(name, value === '' ? null : value);
        } else {
          void setFieldValue(name, value);
        }
      };
      return (
        <Field
          as={TextField}
          onChange={handleChange}
          name={name}
          type={type || 'text'}
          testId={testid}
          placeholder={placeholder}
          setFieldValue={setFieldValue}
        />
      );
    }
    return null;
  };

  const renderDropdownField = () => {
    if (dropdown && edit && setFieldValue && name && dropdownValues) {
      return (
        <Styled.DropDownContainer>
          <Field
            name={name}
            as={DropDown}
            testId={testid}
            styles={{ border: true }}
            options={dropdownValues.map((value: DropDownOption) => ({
              value: value.value,
              label: value.label,
            }))}
            placeholder={option || placeholder}
            onChange={(optionDropdown: DropDownOption) =>
              setFieldValue(name, optionDropdown.value)
            }
          />
        </Styled.DropDownContainer>
      );
    }
    return null;
  };

  return (
    <Styled.OptionList edit={edit}>
      {title}: {renderOption()}
      <Styled.Option>
        {renderInputField()}
        {renderDropdownField()}
        {renderDateField()}
        {renderTextArea()}
      </Styled.Option>
    </Styled.OptionList>
  );
};

interface EditWaypointProps {
  data: WaypointInformationType;
  edit: boolean;
  waypointId: string;
  hideEdit: () => void;
  isUpdated: () => Promise<void>;
}

const EditWaypoint: FC<EditWaypointProps> = ({
  waypointId,
  edit,
  data,
  hideEdit,
  isUpdated,
}) => {
  const [mutate, { isLoading, isSuccess, isError }] =
    usePatchUpdateWaypointMutation();
  const { data: Engineers } = useGetEngineersQuery();
  const { data: statuses } = useGetStatusesQuery();
  const { data: leakTypes } = useGetLeakTypesQuery();
  const { t, prefixedT } = useCustomTranslation('WAYPOINT_PAGE.WAYPOINT_INFO');

  const [initialValues, setInitialValues] =
    useState<PatchUpdateWaypointPortalBody>(createInitialValues(data));

  useEffect(() => {
    setInitialValues(createInitialValues(data));
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await isUpdated();
      }
    };

    void fetchData();
  }, [isSuccess]);

  const handleCancel = () => {
    hideEdit();
  };

  const onSubmit = async (values: PatchUpdateWaypointPortalBody) => {
    const changedValues = getChangedValues(values, initialValues);

    if (changedValues?.repair_time) {
      changedValues.repair_time = ISO8601DateFormat(
        values?.repair_time as Date,
      );
    }

    if (!isEmptyObject(changedValues)) {
      await mutate({ query: waypointId, body: changedValues });
    }
  };

  const leakTypeDropDown = leakTypeDropDownValues(leakTypes);

  const engineerDropDown = engineerDropDownValues(Engineers);

  const statusDropDown = statusDropDownValues(statuses);

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ submitForm, setFieldValue, values }) => {
            const changedValues = getChangedValues(values, initialValues);
            return (
              <>
                <Styled.List>
                  <Option
                    edit={false}
                    testid="dma"
                    title={'DMA Reference'}
                    option={data?.dma_key}
                  />
                  <Option
                    edit={false}
                    testid="ai_leak"
                    title={'AI predicted leak size'}
                    option={data?.AI_size}
                  />
                  <Option
                    edit={false}
                    testid="w3w"
                    title={'What 3 Words'}
                    option={data?.what3words}
                  />
                  <Option
                    edit={edit}
                    setFieldValue={setFieldValue}
                    testid="last scheduled engineer"
                    dropdown
                    dropdownValues={engineerDropDown}
                    placeholder={prefixedT('PLACEHOLDER.ENGINEER')}
                    name={'last_scheduled_engineer_id'}
                    title={prefixedT('LAST_SCHEDULED_ENGINEER')}
                    option={data?.last_scheduled_engineer}
                  />
                  <Option
                    edit={edit}
                    setFieldValue={setFieldValue}
                    testid="status"
                    dropdown
                    dropdownValues={statusDropDown}
                    placeholder={prefixedT('PLACEHOLDER.STATUS')}
                    name={'status_id'}
                    title={prefixedT('CURRENT_STATUS')}
                    option={data?.current_status}
                  />
                  <Option
                    edit={edit}
                    name={'material'}
                    placeholder={prefixedT('PLACEHOLDER.MATERIAL')}
                    testid={'material'}
                    input
                    setFieldValue={setFieldValue}
                    title={prefixedT('PIPE_MATERIAL')}
                    option={data?.pipe_material}
                  />
                  <Option
                    edit={edit}
                    name={'surface_type'}
                    placeholder={prefixedT('SURFACE_TYPE')}
                    testid={'surface_type'}
                    input
                    setFieldValue={setFieldValue}
                    title={prefixedT('SURFACE_TYPE')}
                    option={data?.surface_type}
                  />
                  <Option
                    edit={edit}
                    name={'address'}
                    placeholder={prefixedT('ADDRESS')}
                    testid={'address'}
                    input
                    setFieldValue={setFieldValue}
                    title={prefixedT('ADDRESS')}
                    option={data?.address}
                  />
                  <Option
                    edit={edit}
                    name={'estimated_size_engineer'}
                    placeholder={prefixedT('ESTIMATED_SIZE_ENGINEER')}
                    testid={'estimated_size_engineer'}
                    input
                    type={'number'}
                    setFieldValue={setFieldValue}
                    title={prefixedT('ESTIMATED_SIZE_ENGINEER')}
                    option={data?.estimated_size_engineer}
                  />
                  <Option
                    edit={edit}
                    name={'comment'}
                    placeholder={prefixedT('COMMENT')}
                    testid={'comment'}
                    input
                    type={'textarea'}
                    setFieldValue={setFieldValue}
                    title={prefixedT('COMMENT')}
                    option={data?.comment}
                  />
                  <Option
                    edit={edit}
                    name={'environmental_notes'}
                    placeholder={prefixedT('ENVIRONMENTAL_NOTES')}
                    testid={'environmental-notes'}
                    input
                    type={'textarea'}
                    setFieldValue={setFieldValue}
                    title={prefixedT('ENVIRONMENTAL_NOTES')}
                    option={data?.environmental_notes}
                  />
                  <Option
                    edit={edit}
                    setFieldValue={setFieldValue}
                    testid="leak size"
                    placeholder={prefixedT('PLACEHOLDER.LEAK_TYPE')}
                    dropdown
                    dropdownValues={leakTypeDropDown}
                    name={'predicted_leak_type_id'}
                    title={prefixedT('PREDICTED_LEAK_SIZE')}
                    option={data?.predicted_leak_type}
                  />
                  <Option
                    edit={edit}
                    setFieldValue={setFieldValue}
                    testid="actual leak type"
                    placeholder={prefixedT('PLACEHOLDER.LEAK_TYPE')}
                    dropdown
                    dropdownValues={leakTypeDropDown}
                    name={'actual_leak_type_id'}
                    title={prefixedT('ACTUAL_LEAK_TYPE')}
                    option={data?.actual_leak_type}
                  />
                  <Option
                    edit={edit}
                    name={'estimated_size_client'}
                    placeholder={prefixedT('ESTIMATED_SIZE_CLIENT')}
                    testid={'estimated_size_client'}
                    input
                    setFieldValue={setFieldValue}
                    title={prefixedT('ESTIMATED_SIZE_CLIENT')}
                    option={data?.estimated_size_client}
                    type={'number'}
                  />
                  <Option
                    edit={edit}
                    name={'estimated_size'}
                    placeholder={prefixedT('ESTIMATED_SIZE')}
                    testid={'estimated_size'}
                    input
                    setFieldValue={setFieldValue}
                    title={prefixedT('ESTIMATED_SIZE')}
                    option={data?.estimated_size}
                    type={'number'}
                  />
                </Styled.List>

                {isError && (
                  <div>
                    <p>{t('ERROR.REQUEST')}</p>
                  </div>
                )}

                {edit && (
                  <Styled.SaveContainer>
                    <Button onClick={handleCancel}>
                      {prefixedT('CANCEL')}
                    </Button>
                    <Button
                      disabled={isEmptyObject(changedValues)}
                      onClick={() => {
                        void (async () => {
                          await submitForm();
                        })();
                      }}
                      type="submit"
                    >
                      {prefixedT('SUBMIT')}
                    </Button>
                  </Styled.SaveContainer>
                )}
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default withPageLoader(EditWaypoint);
