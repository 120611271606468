import { useTranslation, TFunction } from 'react-i18next';

const useCustomTranslation = (customPrefix: string) => {
  const { t }: { t: TFunction } = useTranslation();

  const prefixedT = (path: string, options?: Record<string, any>) => {
    return t(`${customPrefix}.${path}`, options);
  };

  return { t, prefixedT };
};

export default useCustomTranslation;
