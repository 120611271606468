import React, { FC, useRef, useState } from 'react';
import * as Styled from './style';
import { ITableHeader, TableSortOrders } from '@/components/Table/types';
import useOutsideClick from '@/hooks/useOutsideClick';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { PostViewWaypointLogsFilter } from '@/types/portalUsers.type';

interface FilterColumnProps {
  header: ITableHeader;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  order: TableSortOrders;
  setOrder: React.Dispatch<React.SetStateAction<TableSortOrders>>;
  showFilterOption?: boolean;
  showSortOption?: boolean;
  filterOptions?: { value: string; label: string }[];
  filter: PostViewWaypointLogsFilter;
  setFilter: React.Dispatch<React.SetStateAction<PostViewWaypointLogsFilter>>;
  isLastColumn?: boolean;
}

const FilterColumn: FC<FilterColumnProps> = ({
  header,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  showFilterOption = true,
  showSortOption = true,
  filterOptions,
  filter,
  setFilter,
  isLastColumn = false,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const { t, prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE');

  useOutsideClick(filterRef, setShowFilter, showFilter);

  const currentFilterCount = filter[header.id]?.length || 0;

  return (
    <Styled.ColumnHeader id={header.id} ref={filterRef}>
      {header.title}
      {(showFilterOption || showSortOption) && (
        <>
          <Styled.FilterButton
            data-testid={'filter-button'}
            onClick={() => setShowFilter(!showFilter)}
          >
            <Styled.FilterIcon />
          </Styled.FilterButton>
          {currentFilterCount > 0 && (
            <Styled.CountContainer data-testid={`counter-${header.id}`}>
              {currentFilterCount}
            </Styled.CountContainer>
          )}
          {orderBy === header.id && (
            <Styled.CountContainer data-testid={`sort-${header.id}`}>
              {order === TableSortOrders.asc ? '↑' : '↓'}
            </Styled.CountContainer>
          )}
          {showFilter && (
            <Styled.FilterContainer
              data-testid="filter-container"
              align={`${isLastColumn && 'right'}`}
            >
              {showSortOption && (
                <>
                  {prefixedT('SORT')}:
                  <Styled.SortOption>
                    <input
                      data-testid="asc"
                      type="radio"
                      checked={
                        orderBy === header.id && order === TableSortOrders.asc
                      }
                      onChange={() => {
                        setOrder(TableSortOrders.asc);
                        setOrderBy(header.id);
                      }}
                      id="asc"
                    />
                    <label htmlFor="asc">{prefixedT('SORT.ASC')}</label>
                  </Styled.SortOption>
                  <Styled.SortOption>
                    <input
                      data-testid="desc"
                      type="radio"
                      checked={
                        orderBy === header.id && order === TableSortOrders.desc
                      }
                      onChange={() => {
                        setOrder(TableSortOrders.desc);
                        setOrderBy(header.id);
                      }}
                      id="desc"
                    />
                    <label htmlFor="desc">{prefixedT('SORT.DESC')}</label>
                  </Styled.SortOption>
                  <Styled.SortOption>
                    <input
                      data-testid="none"
                      type="radio"
                      checked={orderBy !== header.id}
                      onChange={() => {
                        setOrder(TableSortOrders.desc);
                        setOrderBy('');
                      }}
                      id="none"
                    />
                    <label htmlFor="none">{prefixedT('SORT.NONE')}</label>
                  </Styled.SortOption>
                </>
              )}

              {showFilterOption && filterOptions !== undefined && (
                <>
                  <Styled.HR />
                  {prefixedT('FILTER')}:
                  {filterOptions.toSorted().map(({ label, value }, idx) => (
                    <Styled.SortOption key={idx}>
                      <input
                        type="checkbox"
                        data-testid={`filter-${idx}`}
                        id={value}
                        checked={filter?.[header.id]?.includes(value)}
                        onChange={(e) => {
                          setFilter((prev) => {
                            const expandedFilterOptions = [
                              ...(prev?.[header.id] || []),
                              value,
                            ];
                            const reducedFilterOptions = [
                              ...(prev?.[header.id] || []).filter(
                                (item: string) => item !== value,
                              ),
                            ];

                            return {
                              ...prev,
                              [header.id]: e.target.checked
                                ? expandedFilterOptions
                                : reducedFilterOptions,
                            };
                          });
                        }}
                      />
                      <label htmlFor={value}>
                        {label !== null
                          ? label
                          : t('STOCK_MANAGEMENT.TABLE.SORT.NONE')}
                      </label>
                    </Styled.SortOption>
                  ))}
                </>
              )}
            </Styled.FilterContainer>
          )}
        </>
      )}
    </Styled.ColumnHeader>
  );
};

export default FilterColumn;
