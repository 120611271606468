import {
  WaypointInfoResponse,
  WaypointInfoQuery,
  PortalPartnersResponse,
  getPostProjectReportQuery,
  RelaySessionData,
  SessionInformationType,
  DMAPopUpType,
  getDMAPopupQuery,
  postSessionRelocation,
  DeliveryPerformanceBugDeploymentEngineerType,
  getDeliveryPerformanceQuery,
  DeliveryPerformanceDropdownType,
  DeliveryPerformanceLeakEngineerType,
  ConsumptionProfileInformationType,
  getDeliveryPerformanceDropdownQuery,
  CorrelationInformationType,
  DropdownType,
  TopSoundInformationtype,
  WaypointInformationType,
  getWaypointInformationQuery,
  ProjectStatusReportType,
  ProjectStatusParams,
  ProjectStatusRequestBody,
  ReportDataSource,
  Layouts,
  createReportBody,
  Layout,
  UpdateReportValues,
  getRunningReportQuery,
  Report,
  AssetSessionsInfoResponse,
  getAssetSessionsInfoQuery,
  leakType,
  postWaypointJobBody,
  WaypointJobQuery,
  DistinctJobValues,
  WaypointJob,
  EditWaypointJobQuery,
  patchWaypointJobBody,
  WaypointJobLogsQuery,
  WaypointJobLog,
} from '@/types/report.type';
import {
  IDateSearchProps,
  IJobFilterRTK,
  IPaginationProps,
  IPaginationResponse,
  IPaginationSnakeCaseOptionalProps,
  iSearchProps,
  ISort,
  RealISort,
} from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const reportApi = createApi({
  reducerPath: 'realReportQuery',
  baseQuery: apiBaseQuery,
  tagTypes: [
    'waypointInfo',
    'idsNames',
    'PostProjectReport',
    'RelaySessionData',
    'SessionInformation',
    'getDMAPopup',
    'SessionRelocation',
    'DeliveryPerformanceBugDeploymentEngineer',
    'DropdownDeliveryPerformance',
    'Dropdown',
    'DeliveryPerformanceLeakEngineer',
    'ConsumptionProfileInformation',
    'CorrelationInformation',
    'TopSoundingInformation',
    'WaypointInformation',
    'PortalCompanies',
    'ProjectStatus',
    'DataSources',
    'reportLayout',
    'Layouts',
    'Widgets',
    'Reports',
    'RunningReport',
    'Report',
    'assetSessionsInfo',
    'postWaypointJobs',
    'WaypointJob',
    'EditWaypointJob',
    'LeakTypes',
    'DistinctJobValues',
    'WaypointJobLogs',
    'AudioTrigger',
  ],
  endpoints: (builder) => ({
    getAssetSessionsInfo: builder.query<
      AssetSessionsInfoResponse,
      { query: getAssetSessionsInfoQuery }
    >({
      query: ({ query }) =>
        `/portal_reports/asset_sessions_info?${buildQueryParams(query || {})}`,
      providesTags: ['assetSessionsInfo'],
    }),
    getWaypointInfo: builder.mutation<WaypointInfoResponse, WaypointInfoQuery>({
      query: (query: WaypointInfoQuery) =>
        `/portal_reports/waypoint_info?${buildQueryParams(query || {})}`,
      invalidatesTags: ['waypointInfo'],
    }),
    getPortalPartners: builder.query<PortalPartnersResponse[], void>({
      query: () => '/portal_partners/ids_names',
      providesTags: ['idsNames'],
    }),
    getPostProjectReport: builder.mutation<
      void,
      { id: number; query: getPostProjectReportQuery }
    >({
      query: ({ id, query }) =>
        `/portal_reports/post-project/${id}?${buildQueryParams(query || {})}`,
      invalidatesTags: ['PostProjectReport'],
    }),
    getRelaySessionData: builder.mutation<
      RelaySessionData,
      { id: string | number; query: { date_from?: string; date_to?: string } }
    >({
      query: ({ id, query }) =>
        `/portal_reports/relay_session_raw_data/${id}?${buildQueryParams(query || {})}`,
      invalidatesTags: ['RelaySessionData'],
    }),
    getSessionInformation: builder.mutation<
      SessionInformationType,
      string | number
    >({
      query: (id: string | number) => `/portal_reports/${id}/information`,
      invalidatesTags: ['SessionInformation'],
    }),
    getDMAPopup: builder.query<DMAPopUpType, getDMAPopupQuery>({
      query: (query: getDMAPopupQuery) =>
        `/portal_reports/project_status/dma_popup?${buildQueryParams(
          query || {},
        )}`,
      providesTags: ['getDMAPopup'],
    }),
    postSessionRelocation: builder.mutation<
      void,
      { query: postSessionRelocation }
    >({
      query: ({ query }) => ({
        url: `/portal_reports/${query.sessionId}/${query.assetId}`,
        method: 'POST',
      }),
      invalidatesTags: ['SessionRelocation'],
    }),
    getDeliveryPerformanceBugDeploymentEngineer: builder.mutation<
      DeliveryPerformanceBugDeploymentEngineerType,
      { body: (string | number)[]; query: getDeliveryPerformanceQuery }
    >({
      query: ({ body, query }) => ({
        url: `/portal_reports/delivery_performance/bug_deployment_engineers?${buildQueryParams(
          query || {},
        )}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DeliveryPerformanceBugDeploymentEngineer'],
    }),
    getDeliveryPerformanceDropdown: builder.mutation<
      DeliveryPerformanceDropdownType,
      getDeliveryPerformanceDropdownQuery
    >({
      query: (query: getDeliveryPerformanceDropdownQuery) =>
        `/portal_reports/delivery_performance/dropdown?${buildQueryParams(
          query || {},
        )}`,
      invalidatesTags: ['DropdownDeliveryPerformance'],
    }),
    getDeliveryPerformanceLeakEngineer: builder.mutation<
      DeliveryPerformanceLeakEngineerType,
      { body: (string | number)[]; query: getDeliveryPerformanceQuery }
    >({
      query: ({ body, query }) => ({
        url: `/portal_reports/delivery_performance/leak_engineers?${buildQueryParams(
          query || {},
        )}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DeliveryPerformanceLeakEngineer'],
    }),
    getConsumptionProfileInformation: builder.query<
      ConsumptionProfileInformationType,
      string | number
    >({
      query: (id: string | number) =>
        `/portal_reports/consumption_profile/${id}`,
      providesTags: ['ConsumptionProfileInformation'],
    }),
    getCorrelationInformation: builder.query<
      CorrelationInformationType,
      string | number
    >({
      query: (id: string | number) => `/portal_reports/correlation/${id}`,
      providesTags: ['CorrelationInformation'],
    }),
    getDropdown: builder.query<DropdownType, string | number>({
      query: (id: string | number) => `/portal_reports/dropdown/${id}`,
      providesTags: ['Dropdown'],
    }),
    getTopSoundInformation: builder.query<
      TopSoundInformationtype,
      string | number
    >({
      query: (id: string | number) => `/portal_reports/topsounding/${id}`,
      providesTags: ['TopSoundingInformation'],
    }),
    getWaypointInformation: builder.query<
      WaypointInformationType,
      { id: string | number; query: getWaypointInformationQuery }
    >({
      query: ({ id, query }) =>
        `/portal_reports/waypoint/${id}?${buildQueryParams(query || {})}`,
      providesTags: ['WaypointInformation'],
    }),
    postProjectStatus: builder.mutation<
      ProjectStatusReportType,
      { body: ProjectStatusRequestBody; query: ProjectStatusParams }
    >({
      query: ({ body, query }) => ({
        url: `/portal_reports/project_status?${buildQueryParams(query || {})}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectStatus'],
    }),
    getDataSources: builder.query<ReportDataSource[], void>({
      query: () => '/portal_reports/datasources/list',
      providesTags: ['DataSources'],
    }),
    getReportLayouts: builder.query<Layouts, void>({
      query: () => '/portal_reports/layout/list',
      providesTags: ['Layouts'],
    }),
    getReportLayout: builder.mutation<Layout, { id: number }>({
      query: ({ id }) => `/portal_reports/layout/${id}`,
      invalidatesTags: ['reportLayout'],
    }),
    getReportWidgets: builder.query({
      query: () => '/portal_reports/widgets/list',
      providesTags: ['Widgets'],
    }),
    createReport: builder.mutation({
      query: (body: createReportBody) => ({
        url: '/portal_reports/report',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
    updateReport: builder.mutation({
      query: ({ id, ...body }: UpdateReportValues & { id: number }) => ({
        url: `/portal_reports/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
    getRunningReport: builder.query({
      query: ({
        id,
        query,
      }: {
        id: string | number;
        query: getRunningReportQuery;
      }) =>
        query
          ? `/portal_reports/${id}/running?${buildQueryParams(query || {})}`
          : `/portal_reports/${id}/running`,
      providesTags: ['RunningReport'],
    }),
    getReport: builder.query({
      query: (id: string | number) => `/portal_reports/report/${id}`,
      providesTags: ['Report'],
    }),
    getReports: builder.query<
      IPaginationResponse<Report> | Report[],
      (IPaginationProps & ISort) | void
    >({
      query: (params) =>
        `/portal_reports/report/list?${buildQueryParams(params || {})}`,
      providesTags: (result) => {
        const data = (result as IPaginationResponse<Report>)?.data || result;
        return data
          ? [
              ...data.map(({ id }) => ({ type: 'Reports' as const, id })),
              { type: 'Reports', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Reports', id: 'PARTIAL-LIST' }];
      },
    }),
    postWaypointJobs: builder.query<
      IPaginationResponse<WaypointJob>,
      IPaginationSnakeCaseOptionalProps &
        RealISort &
        iSearchProps &
        IJobFilterRTK &
        IDateSearchProps & { id: string }
    >({
      query: ({ filter, id, ...params }) => ({
        url: `/portal_reports/waypoint/${id}/jobs?${buildQueryParams(params)}`,
        method: 'POST',
        body: { filter },
      }),
      providesTags: ['postWaypointJobs'],
    }),
    postWaypointJob: builder.mutation<
      number,
      { body: postWaypointJobBody; query: WaypointJobQuery }
    >({
      query: ({ body, query }) => ({
        url: `/portal_reports/waypoint/${query.waypoint_id}/job`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WaypointJob'],
    }),
    patchWaypointJob: builder.mutation<
      number,
      { body: patchWaypointJobBody; query: EditWaypointJobQuery }
    >({
      query: ({ body, query }) => ({
        url: `/portal_reports/waypoint_job/${query.job_id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['EditWaypointJob'],
    }),
    getLeakTypes: builder.query<leakType[], void>({
      query: () => `/portal_reports/leak_type`,
      providesTags: ['LeakTypes'],
    }),
    getDistinctJobValues: builder.query<DistinctJobValues, void>({
      query: () => `portal_reports/waypoint_job/distinct`,
      providesTags: ['DistinctJobValues'],
    }),
    postWaypointJobLogs: builder.mutation<
      IPaginationResponse<WaypointJobLog>,
      { query: WaypointJobLogsQuery; id: number }
    >({
      query: ({ query, id }) => ({
        url: `/portal_reports/waypoint_job/${id}/history?${buildQueryParams(query)}`,
        method: 'POST',
      }),
      invalidatesTags: ['WaypointJobLogs'],
    }),
    postAudioTrigger: builder.mutation<
      void,
      { sessionId: string; toggle: string }
    >({
      query: ({ sessionId, toggle }) => ({
        url: `/portal_reports/relay/audio_trigger/${sessionId}/${toggle}/portal`,
        method: 'POST',
      }),
      invalidatesTags: ['AudioTrigger'],
    }),
  }),
});

export const {
  useGetAssetSessionsInfoQuery,
  useGetWaypointInfoMutation,
  useGetPortalPartnersQuery,
  useGetPostProjectReportMutation,
  useGetRelaySessionDataMutation,
  useGetSessionInformationMutation,
  useGetDMAPopupQuery,
  usePostSessionRelocationMutation,
  useGetDeliveryPerformanceBugDeploymentEngineerMutation,
  useGetDeliveryPerformanceDropdownMutation,
  useGetDeliveryPerformanceLeakEngineerMutation,
  useGetConsumptionProfileInformationQuery,
  useGetCorrelationInformationQuery,
  useGetDropdownQuery,
  useGetTopSoundInformationQuery,
  useGetWaypointInformationQuery,
  usePostProjectStatusMutation,
  useGetDataSourcesQuery,
  useLazyGetReportLayoutsQuery,
  useGetReportLayoutMutation,
  useGetReportWidgetsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useGetRunningReportQuery,
  useGetReportQuery,
  useGetReportsQuery,
  useLazyPostWaypointJobsQuery,
  useGetLeakTypesQuery,
  usePostWaypointJobMutation,
  useGetDistinctJobValuesQuery,
  usePatchWaypointJobMutation,
  usePostWaypointJobLogsMutation,
  usePostAudioTriggerMutation,
} = reportApi;
