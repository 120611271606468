import {
  useGetViewWaypointLogsDistinctQuery,
  useLazyPostViewWaypointLogsQuery,
} from '@/redux/portalUsers/portalUsers.api';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Styled from './style';
import {
  Button,
  ContentLoader,
  DatePicker,
  DropDown,
  Loader,
  Table,
} from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  getRowStructure,
  headerNames,
  headers,
  pageValues,
  processDistinctValues,
} from './ListViewUtils';
import FilterColumn from './FilterColumn/FilterColumn';
import { isFilterColumn, isSortColumn } from './ListViewUtils';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import {
  PostViewWaypointLogsFilter,
  WaypointLogsData,
} from '@/types/portalUsers.type';
import EditView from './EditView/EditView';
import { DropDownOption } from '@/components/DropDown/DropDown';
import moment from 'moment';

const defaultPage = 1;
const defaultPerPage = 25;

const WaypointLogsListView: FC = () => {
  const [trigger, { data, isLoading, isFetching }] =
    useLazyPostViewWaypointLogsQuery();

  const { data: distinct } = useGetViewWaypointLogsDistinctQuery();
  const distinctProcessed: {
    [key: string]: { label: string; value: string }[];
  } = useMemo(() => {
    return processDistinctValues(distinct);
  }, [distinct]);

  const { t, prefixedT } = useCustomTranslation('LIST_VIEW.WAYPOINT_LOGS');

  const listViewWaypointsView = localStorage.getItem(
    'listView.WaypointsLogs.view',
  );
  const listViewWaypointsFullQuery = localStorage.getItem(
    'listView.WaypointsLogs.fullQuery',
  );
  const listViewWaypointsFullQueryParsed = listViewWaypointsFullQuery
    ? (JSON.parse(listViewWaypointsFullQuery) as FullQuery)
    : null;

  const [columnOrder, setColumnOrder] = useState<
    { name: string; show: boolean }[]
  >(
    listViewWaypointsView
      ? (JSON.parse(listViewWaypointsView) as {
          name: string;
          show: boolean;
        }[])
      : headerNames.map((id) => ({ name: id, show: true })),
  );

  const tableHeaders = headers(columnOrder);

  const [page, setPage] = useState<number>(
    listViewWaypointsFullQueryParsed?.query.page || defaultPage,
  );
  const [perPage, setPerPage] = useState<number>(
    listViewWaypointsFullQueryParsed?.query.per_page || defaultPerPage,
  );

  const [order, setOrder] = useState<TableSortOrders>(
    listViewWaypointsFullQueryParsed?.query.sort_order || TableSortOrders.desc,
  );
  const [orderBy, setOrderBy] = useState<string>(
    listViewWaypointsFullQueryParsed?.query.sort_by || 'datetime',
  );

  type FullQuery = {
    body: PostViewWaypointLogsFilter;
    query: {
      page: number;
      per_page: number;
      sort_by: string;
      sort_order: TableSortOrders;
      search: string;
      date_from: string;
      date_to: string;
    };
  };

  const [dateFrom, setDateFrom] = useState<Date | null>(
    listViewWaypointsFullQueryParsed?.query.date_from
      ? new Date(listViewWaypointsFullQueryParsed.query.date_from)
      : null,
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    listViewWaypointsFullQueryParsed?.query.date_to
      ? new Date(listViewWaypointsFullQueryParsed.query.date_to)
      : null,
  );

  const defaultFilter = {
    company: [],
    partner: [],
    project: [],
  };

  const [searchQuery, setSearchQuery] = useState<string>(
    listViewWaypointsFullQueryParsed?.query.search || '',
  );
  const [filter, setFilter] = useState<PostViewWaypointLogsFilter>(
    listViewWaypointsFullQueryParsed
      ? listViewWaypointsFullQueryParsed.body
      : defaultFilter,
  );
  const currentQuery = {
    body: filter,
    query: {
      page,
      per_page: perPage,
      sort_by: orderBy,
      sort_order: order,
      search: searchQuery,
      date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '',
      date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : '',
    },
  };

  const refetchWithArgs = (optionalQuery?: FullQuery) => {
    const thisQuery = optionalQuery || currentQuery;

    localStorage.setItem(
      'listView.WaypointsLogs.fullQuery',
      JSON.stringify(thisQuery),
    );
    void trigger(thisQuery);
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleColumnOrderChange = (
    newColumn: string,
    draggedColumn: string,
  ) => {
    const newColumnOrder = [...columnOrder];

    const draggedColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === draggedColumn,
    );
    const newColumnIndex = newColumnOrder.findIndex(
      (column) => column.name === newColumn,
    );

    newColumnOrder.splice(draggedColumnIndex, 1);
    newColumnOrder.splice(newColumnIndex, 0, columnOrder[draggedColumnIndex]);
    setColumnOrder(newColumnOrder);
  };

  useEffect(() => {
    localStorage.setItem(
      'listView.WaypointsLogs.view',
      JSON.stringify(columnOrder),
    );
  }, [columnOrder]);

  useEffect(() => {
    refetchWithArgs();
  }, [page, perPage]);

  const reset = () => {
    setFilter(defaultFilter);
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery('');
    setOrder(TableSortOrders.desc);
    setOrderBy('datetime');
    setPage(1);
    setPerPage(25);

    refetchWithArgs({
      body: defaultFilter,
      query: {
        page: 1,
        per_page: 25,
        sort_by: 'datetime',
        sort_order: TableSortOrders.desc,
        search: '',
        date_from: '',
        date_to: '',
      },
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.TableTitle>{prefixedT('TITLE')}</Styled.TableTitle>
      <ContentLoader active={data?.data && isFetching} />
      {isLoading ? (
        <Loader />
      ) : (
        <div data-testid="stock-table">
          <Styled.TableHeader>
            <Styled.TableHeaderSection>
              <Styled.TableHeaderTitle>
                {t('LIST_VIEW.SESSIONS.FILTER_SECTION')}
              </Styled.TableHeaderTitle>
              <Styled.SearchBarContainer>
                <Styled.SearchBarContainer>
                  {t('LIST_VIEW.SESSIONS.DATE_TYPE')}:
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateFrom}
                      onChange={(value) => {
                        if (dateTo && value < dateTo) {
                          setDateFrom(value);
                        } else if (!dateTo) {
                          setDateFrom(value);
                        }
                      }}
                      placeholder={t('LIST_VIEW.SESSIONS.DATE_FROM')}
                    />
                  </Styled.DatePickerContainer>
                  <Styled.DatePickerContainer>
                    <DatePicker
                      value={dateTo}
                      onChange={(value) => {
                        if (dateFrom && value > dateFrom) {
                          setDateTo(value);
                        } else if (!dateFrom) {
                          setDateTo(value);
                        }
                      }}
                      placeholder={t('LIST_VIEW.SESSIONS.DATE_TO')}
                    />
                  </Styled.DatePickerContainer>
                </Styled.SearchBarContainer>

                <Styled.SearchBarContainerInner>
                  <Styled.Input
                    placeholder={t('LIST_VIEW.SESSIONS.SEARCH.PLACEHOLDER')}
                    value={searchQuery}
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPage(1);
                        refetchWithArgs();
                      }
                    }}
                  />
                </Styled.SearchBarContainerInner>
                <Button
                  onClick={() => {
                    setPage(1);
                    refetchWithArgs();
                  }}
                >
                  {t('LIST_VIEW.SESSIONS.SEARCH_BAR.APPLY.FILTER')}
                </Button>
                <Button onClick={reset}>
                  {t('LIST_VIEW.SESSIONS.SEARCH_BAR.RESET')}
                </Button>
              </Styled.SearchBarContainer>
            </Styled.TableHeaderSection>
            <Styled.TableHeaderSection>
              <EditView
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                handleColumnOrderChange={handleColumnOrderChange}
              />
            </Styled.TableHeaderSection>
          </Styled.TableHeader>
          <Table
            data={data?.data || []}
            headers={tableHeaders}
            onSort={setSorting}
            minHeight="350px"
          >
            <Table.Head
              getHeaderStructure={(header) => {
                return (
                  <FilterColumn
                    header={header}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    showFilterOption={isFilterColumn(header.id)}
                    showSortOption={isSortColumn(header.id)}
                    filterOptions={distinctProcessed[header.id] || []}
                    filter={filter}
                    setFilter={setFilter}
                    isLastColumn={header.id === 'comment'}
                  />
                );
              }}
            />
            {data !== undefined && data.count > 0 ? (
              <Table.Body
                getRowStructure={(data: WaypointLogsData, index: number) =>
                  getRowStructure(data, index, columnOrder)
                }
                striped
              />
            ) : (
              <h3>{t('LIST_VIEW.SESSIONS.NO_DATA')}</h3>
            )}
          </Table>
          <Styled.PaginationWrapper key={page}>
            <Styled.DropDownContainer>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) => {
                  setPerPage(Number((value as DropDownOption).value));
                  setPage(1);
                }}
                placeholder={t('VIEW_USERS.PAGINATION.TITLE')}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={Math.ceil((data?.count || 1) / perPage)}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PaginationWrapper>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default WaypointLogsListView;
