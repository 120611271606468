import Button from '@/components/Button/Button';
import Loader from '@/components/Loader/Loader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { SessionRelocationObject } from '@/Providers/FilterProvider';
import { usePostSessionRelocationMutation } from '@/redux/reports/reports.api';
import React, { FC, useContext, useEffect } from 'react';
import * as Styled from './style';
import {
  clusterLayerID,
  getSourceData,
} from '../../geospatialFeatures/clusters/utils/utils';
import { MapContext } from '@/Providers/MapProvider';
import * as Sentry from '@sentry/react';
import { PointTooltipType } from '../../utils/types';

interface SessionRelocationProps {
  relocationIds: SessionRelocationObject;
  onClose: () => void;
}

const SessionRelocation: FC<SessionRelocationProps> = ({
  relocationIds,
  onClose,
}) => {
  const { map } = useContext(MapContext);
  const { t, prefixedT } = useCustomTranslation(
    'COMPONENTS.SESSION_RELOCATION',
  );
  const [mutate, { isSuccess, isLoading, isError }] =
    usePostSessionRelocationMutation();
  const confirmEndpoint = async () => {
    await mutate({
      query: {
        sessionId: relocationIds.sessionId,
        assetId: relocationIds.assetId,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (map) {
        try {
          const data = getSourceData(map);

          const filteredData = data.filter((feature) => {
            return (
              (feature?.properties?.tooltip as PointTooltipType).Session_id !==
              relocationIds.sessionId
            );
          });

          (map.getSource(clusterLayerID) as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: filteredData,
          });
        } catch (err) {
          Sentry.captureException(err);
        }
      }

      const timeoutId = setTimeout(() => {
        onClose();
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isSuccess]);

  return (
    <Styled.SessionContainer>
      {isLoading && <Loader width={'5vmax'} height={'5vmax'} />}
      {!isLoading && !isSuccess && (
        <>
          <Styled.ParagraphContainer>
            <p>
              {prefixedT('PARAGRAPH_A')}:{' '}
              <span style={{ fontWeight: '500' }}>
                {relocationIds.sessionId}
              </span>
            </p>
            <p>
              {prefixedT('PARAGRAPH_B')}:{' '}
              <span style={{ fontWeight: '500' }}>
                {relocationIds.assetKey}
              </span>
            </p>
          </Styled.ParagraphContainer>
          <Styled.ButtonContainer>
            <Button
              onClick={() => {
                void (async () => {
                  await confirmEndpoint();
                })();
              }}
            >
              {prefixedT('BUTTON_CONFIRM')}
            </Button>
            <Button onClick={onClose}>{prefixedT('BUTTON_CANCEL')}</Button>
          </Styled.ButtonContainer>
          {isError && <p>{t('ERROR.REQUEST')}</p>}
        </>
      )}
      {isSuccess && <p>{prefixedT('SUCCESS.REQUEST')}</p>}
    </Styled.SessionContainer>
  );
};

export default SessionRelocation;
