import { IRootState } from '@/types/redux.type';
import { createSelector } from 'reselect';

export const mainSelector = (state: IRootState) => state.request;

export const requestErrorSelector = createSelector(
  mainSelector,
  (request) => request?.error,
);

export const requestSuccessSelector = createSelector(
  mainSelector,
  (request) => request?.success,
);
