import React from 'react';
import * as Styled from '../style';
import EditIcon from '@/assets/icons/edit-icon.png';
import { Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { Company } from '@/types/portalCompany.type';

export const headers = (): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('VIEW_COMPANIES.TITLE');

  return [
    {
      id: 'name',
      title: prefixedT('NAME'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'partner',
      title: prefixedT('PARTNER'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'company-code',
      title: prefixedT('COMPANY_CODE'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'currency',
      title: prefixedT('CURRENCY'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'unit',
      title: prefixedT('UNIT'),
      sortable: false,
      align: TableCellAlignment.center,
    },
    {
      id: 'action',
      title: prefixedT('ACTION'),
      sortable: false,
      align: TableCellAlignment.center,
    },
  ];
};

export const getRowStructure = (
  row: Company,
  idx: number,
  handleEditClick?: (idx: number) => void,
  _handleDeleteClick?: (idx: number) => void,
) => {
  const { prefixedT } = useCustomTranslation('VIEW_COMPANIES');
  return (
    <Table.Row key={idx}>
      <Table.Cell align={TableCellAlignment.centerLeft} headerId="name">
        {row.name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="partner">
        {row.partner}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="company-code">
        {row.code}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="currency">
        {row.currency}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="unit">
        {row.unit}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        <Styled.ImgContainer>
          <Styled.Img
            onClick={() => handleEditClick && handleEditClick(idx)}
            alt={prefixedT('EDIT_ICON')}
            src={EditIcon as string}
          />
          {/* <Styled.Img
            onClick={() => handleDeleteClick && handleDeleteClick(idx)}
            alt={prefixedT('DELETE_ICON')}
            src={DeleteIcon as string}
          /> */}
        </Styled.ImgContainer>
      </Table.Cell>
    </Table.Row>
  );
};
