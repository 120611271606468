import {
  CreateProjectBody,
  DMAsResponse,
  GetDMAsParams,
  GetDMAsResponse,
  GetProjectParams,
  GetProjectResponse,
  GetUnassignedDMAsParams,
  PatchUpdateProjectBody,
  Project,
} from '@/types/portalProject.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';
import {
  IPaginationResponse,
  iSearchProps,
  IPaginationProps,
} from '@/types/rtk.type';

export const portalProjectsApi = createApi({
  reducerPath: 'portalProjectsQuery',
  baseQuery: apiBaseQuery,
  tagTypes: [
    'PostCreateProject',
    'GetProjects',
    'GetDMAs',
    'GetUnassignedDMAs',
    'GetProjectList',
    'PatchUpdateProject',
    'UploadHealthCheckUrl',
  ],
  endpoints: (builder) => ({
    PostCreateProject: builder.mutation<
      undefined,
      { id: string; body: CreateProjectBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_branches/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PostCreateProject'],
    }),
    getProjects: builder.query<GetProjectResponse, GetProjectParams>({
      query: (params: GetProjectParams) =>
        `/portal_branches/project/dropdown/?${buildQueryParams(params || {})}`,
      providesTags: ['GetProjects'],
    }),
    getProjectsList: builder.query<
      IPaginationResponse<Project>,
      IPaginationProps & iSearchProps
    >({
      query: (params) =>
        `/portal_branches/branches/?${buildQueryParams(params || {})}`,
      providesTags: ['GetProjectList'],
    }),
    getDMAs: builder.query<GetDMAsResponse, GetDMAsParams>({
      query: (params: GetDMAsParams) =>
        `/portal_branches/dma/dropdown/?${buildQueryParams(params || {})}`,
      providesTags: ['GetDMAs'],
    }),
    getUnassignedDMAs: builder.query<DMAsResponse[], GetUnassignedDMAsParams>({
      query: (params: GetUnassignedDMAsParams) =>
        `/portal_branches/dmas/unassigned/?${buildQueryParams(params || {})}`,
      providesTags: ['GetUnassignedDMAs'],
    }),
    patchUpdateProject: builder.mutation<
      undefined,
      { id: number; body: PatchUpdateProjectBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_branches/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PatchUpdateProject'],
    }),
    PostUploadHealthCheckUrl: builder.mutation<
      undefined,
      { id: number; body: string }
    >({
      query: ({ id, body }) => ({
        url: `/portal_branches/report/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UploadHealthCheckUrl'],
    }),
  }),
});

export const {
  usePostCreateProjectMutation,
  useGetProjectsQuery,
  useGetDMAsQuery,
  useLazyGetProjectsListQuery,
  useGetUnassignedDMAsQuery,
  useLazyGetUnassignedDMAsQuery,
  usePatchUpdateProjectMutation,
  usePostUploadHealthCheckUrlMutation,
} = portalProjectsApi;
