import React, { FC } from 'react';
import * as Styled from './style';
import useCustomTranslation from '@/localization/useCustomTranslation';

export interface SequentialNavProps {
  navigation: Navigation[];
}

interface Navigation {
  title: string;
  selected: boolean;
  completed: boolean;
  isLoading: boolean;
}

const SequentialNav: FC<SequentialNavProps> = ({ navigation }) => {
  const { prefixedT } = useCustomTranslation('GIS_UPLOAD');
  return (
    <Styled.ListWrap>
      <Styled.List>
        {navigation.map((item, index) => (
          <Styled.ListItem
            completed={item.completed}
            selected={item.selected}
            key={index}
          >
            {item.completed && <Styled.TickIcon />}
            {item.isLoading ? (
              <Styled.Loader>{prefixedT('LOADING')}</Styled.Loader>
            ) : (
              <Styled.Paragraph>{prefixedT(item.title)}</Styled.Paragraph>
            )}
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.ListWrap>
  );
};

export default SequentialNav;
