import { Feature, GeoJsonProperties, Point } from 'geojson';

export const getUniqueIcons = (
  combinedFeatures: Feature<Point, GeoJsonProperties>[],
) => {
  const iconSet = new Set<string>();

  combinedFeatures.forEach((feature) => {
    if (feature.properties && feature.properties.icon) {
      iconSet.add(`${feature.properties.icon}`);
    }
  });

  return Array.from(iconSet);
};
