import React, { useEffect, useState } from 'react';

import { DropDown, Loader, Table } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import SearchBar from '@/components/SearchBar/SearchBar';
import { ITableHeader, ITablePaginate } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';

import EditModal from './Modal/EditModal';
import DeleteModal from './Modal/DeleteModal';
import * as Styled from './style';
import {
  QueryDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { UserRoles } from '@/types/user.type';

export type LazyGetTriggerType = LazyQueryTrigger<
  QueryDefinition<
    { page: number; perPage: number; search: string }, // Request parameters type
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    never,
    unknown,
    string
  >
>;

export const defaultPageCount = 1;
export const defaultPerPage = 10;

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

interface SearchableTableProps<T> {
  queryResponse?: { count: number; data: T[] };
  isFetching: boolean;
  isError: boolean;
  trigger: LazyGetTriggerType;
  ExtraHeaderComponent?: React.ReactNode;
  translationPrefix?: string;
  headers: (translationPrefix: string) => ITableHeader[];
  editModal?: (
    selectedRowIdx: number,
    closeOverlay: (refetchValues: boolean) => Promise<void>,
  ) => React.ReactNode;
  deleteModal?: (
    selectedRowIdx: number,
    closeOverlay: (refetchValues: boolean) => Promise<void>,
  ) => React.ReactNode;
  getRowStructure: (
    data: T,
    idx: number,
    handleEditClick?: (idx: number) => void,
    handleDeleteClick?: (idx: number) => void,
    role?: UserRoles,
  ) => React.JSX.Element;
  role?: UserRoles;
}

/**
 * A searchable table component that displays data in a table format with search, pagination, and edit/delete functionality.
 *
 * The parent component should use a lazy query to fetch the data. The passed in props should be from the following:
 *  const [trigger, { data: queryResponse, isFetching, isError }] = useLazyQuery();
 *
 * @param queryResponse - The response data from the query, must have a count and data property.
 * @param isFetching - Indicates if the data is currently being fetched.
 * @param isError - Indicates if an error occurred while fetching the data.
 * @param trigger - The lazy query trigger function.
 * @param editModal - The function that returns the edit modal component for a selected row.
 * @param deleteModal - The function that returns the delete modal component for a selected row.
 * @param ExtraHeaderComponent - An optional extra header component to be displayed next to the search bar.
 * @param translationPrefix - Optional translation prefix for localization.
 * @param getRowStructure - The function that returns the JSX element for a row in the table.
 * @param headers - The function that returns an array of table headers.
 * @param role - The User Role of the profile being passed through.
 */
const SearchableTable = <T extends object>({
  queryResponse,
  isFetching,
  isError,
  trigger,
  editModal,
  deleteModal,
  ExtraHeaderComponent,
  translationPrefix = '',
  getRowStructure,
  headers,
  role,
}: SearchableTableProps<T>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedRowIdx, setSelectedRowIdx] = useState(-1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(defaultPerPage);
  const { t, prefixedT } = useCustomTranslation(translationPrefix);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setPage(1);
    // reset page to 1 when the search query changes to ensure we always show results
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const handleRowAmountChange = (value: DropDownOption) => {
    setPerPage(Number(value.value));
    setPage(1);
    // reset page to 1 when the number per page changes to ensure we always show results
  };

  const currentPageCount = Math.ceil(
    (queryResponse?.count || defaultPageCount) / perPage,
  );

  const handleEdit = (idx: number) => {
    setIsEditOpen(true);
    setSelectedRowIdx(idx);
  };

  const handleDeleteClick = (idx: number) => {
    setIsDeleteOpen(true);
    setSelectedRowIdx(idx);
  };

  const closeOverlay = async (triggerValues: boolean) => {
    setIsEditOpen(false);
    setIsDeleteOpen(false);

    if (triggerValues && trigger) {
      await trigger({ page, perPage, search: searchQuery });
    }
  };

  useEffect(() => {
    if (trigger) {
      void trigger({ page, perPage, search: searchQuery });
    }
  }, [page, perPage, searchQuery]);

  return (
    <Styled.Wrapper>
      <DeleteModal
        isOpen={isDeleteOpen}
        closeOverlay={closeOverlay}
        translationPrefix={translationPrefix}
      >
        {deleteModal && deleteModal(selectedRowIdx, closeOverlay)}
      </DeleteModal>
      <EditModal
        isOpen={isEditOpen}
        closeOverlay={closeOverlay}
        translationPrefix={translationPrefix}
      >
        {editModal && editModal(selectedRowIdx, closeOverlay)}
      </EditModal>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT('TITLE.PAGE')}</Styled.Title>
      </Styled.TitleContainer>
      <Styled.HeaderContainer>
        <Styled.SearchContainer>
          <SearchBar
            placeholder={t('COMPONENTS.SEARCH_BAR.GENERIC')}
            onSearch={handleSearch}
          />
        </Styled.SearchContainer>
        {ExtraHeaderComponent}
      </Styled.HeaderContainer>
      <Styled.Container>
        <Styled.TitleContainer>
          <Styled.Title>{prefixedT('TITLE.LIST')}</Styled.Title>
        </Styled.TitleContainer>
        <Table
          data={queryResponse?.data || []}
          headers={headers(translationPrefix)}
        >
          <Table.Head getHeaderStructure={(header) => header.title} />
          {isFetching && (
            <Styled.LoaderContainer>
              <Styled.InnerLoaderContainer>
                <Loader />
              </Styled.InnerLoaderContainer>
            </Styled.LoaderContainer>
          )}
          {isError && <p>{t('ERROR.REQUEST')}</p>}
          {!isFetching && !isError && (
            <Table.Body
              getRowStructure={(row: T, idx: number) =>
                getRowStructure(
                  row,
                  idx,
                  editModal ? handleEdit : undefined,
                  deleteModal ? handleDeleteClick : undefined,
                  role,
                )
              }
              striped
            />
          )}
        </Table>
        <Styled.PaginationWrapper>
          <Styled.PagniationContainer>
            <Styled.DropDownContainer>
              <p>{t('VIEW_USERS.PAGINATION.TITLE')}</p>
              <DropDown
                value={perPage.toString()}
                options={pageValues}
                onChange={(value) =>
                  handleRowAmountChange(value as DropDownOption)
                }
                placeholder={'Number of rows'}
              />
            </Styled.DropDownContainer>
            <Table.Pagination
              pages={currentPageCount}
              onChange={onPageChange}
              initialPage={page}
            />
          </Styled.PagniationContainer>
        </Styled.PaginationWrapper>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default SearchableTable;
