import React from 'react';

import { Table } from '@/components';
import { ITableHeader, TableCellAlignment } from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';

import {
  GetViewSessionsDistinctResponse,
  SessionData,
} from '@/types/portalUsers.type';
import moment from 'moment';
import { DropDownOption } from '@/components/DropDown/DropDown';

export const pageValues: DropDownOption[] = [
  {
    value: '10',
    label: '10',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '50',
    label: '50',
  },
];

export const headerNames = [
  'session_id',
  'partner_id',
  'company_id',
  'project_id',
  'dma_id',
  'sensor-id',
  'type',
  'leak_size',
  'status_id',
  'what3words',
  'relay_connected',
  'asset_id',
  'deployed_by',
  'collected_by',
  'created',
  'start_time',
  'end_time',
  'last_update',
  'expected_finish',
];

export const headers = (
  columnOrder: { name: string; show: boolean }[],
): ITableHeader[] => {
  const { prefixedT } = useCustomTranslation('LIST_VIEW.SESSIONS.HEADERS');

  const currentColumns = headerNames.map((header) => ({
    id: header,
    title: prefixedT(header),
    sortable: false,
    align: TableCellAlignment.center,
  }));

  try {
    const result = columnOrder
      .filter((column) => column.show)
      .map((column) => {
        const matchedColumn = currentColumns.find(
          (col) => col.id === column.name,
        );
        if (!matchedColumn) {
          throw new Error('Column not found');
        }
        return matchedColumn;
      });

    return result;
  } catch (error) {
    // Something went wrong, reset the columns
    localStorage.removeItem('listView.Sessions');
    return currentColumns;
  }
};

export const getRowStructure = (
  data: SessionData,
  index: number,
  columnOrder: { name: string; show: boolean }[],
) => {
  const currentRowOrder = [
    {
      id: 'session_id',
      child: (
        <a
          href={`/app/session?${data.waypoint_id ? '&waypoint=' + data.waypoint_id + '&' : ''}session=${data.session_id}`}
          target="_blank"
        >
          {data.session_id}
        </a>
      ),
    },
    {
      id: 'partner_id',
      child: data.partner,
    },
    {
      id: 'company_id',
      child: data.company,
    },
    {
      id: 'project_id',
      child: data.project,
    },
    {
      id: 'dma_id',
      child: data.dma_key,
    },
    {
      id: 'sensor-id',
      child: data.bug_id ?? data.device_id, // Note bug_id is preferred over device_id if it's available
    },
    {
      id: 'type',
      child: data.type,
    },
    {
      id: 'leak_size',
      child: data.leak_size,
    },
    {
      id: 'status_id',
      child: data.overdue,
    },
    {
      id: 'what3words',
      child: data.what3words,
    },
    {
      id: 'relay_connected',
      child: data.connected_relay_device ? '✓' : '✗',
    },
    {
      id: 'asset_id',
      child: data.asset_id,
    },
    {
      id: 'deployed_by',
      child: data.deployed_by,
    },
    {
      id: 'collected_by',
      child: data.collected_by,
    },
    {
      id: 'created',
      child:
        data.created !== null &&
        moment(data.created).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'start_time',
      child:
        data.start_time !== null &&
        moment(data.start_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'end_time',
      child:
        data.end_time !== null &&
        moment(data.end_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'last_update',
      child:
        data.last_update !== null &&
        moment(data.last_update).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 'expected_finish',
      child:
        data.expected_finish !== null &&
        moment(data.expected_finish).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const result = columnOrder
    .filter((column) => column.show)
    .map((column) => {
      const matchedColumn = currentRowOrder.find(
        (col) => col.id === column.name,
      );
      if (!matchedColumn) {
        throw new Error('Column not found');
      }
      return matchedColumn;
    });

  return (
    <Table.Row key={index}>
      {result.map((row, idx) => (
        <Table.Cell
          key={idx}
          align={TableCellAlignment.center}
          headerId={row.id}
        >
          {row.child}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export const filterColumns = [
  'partner_id',
  'company_id',
  'type',
  'leak_size',
  'status_id',
  'project_id',
  'deployed_by',
  'collected_by',
];

export const sortColumns = [
  'partner_id',
  'company_id',
  'session_id',
  'type',
  'leak_size',
  'status_id',
  'dma',
  'what3words',
  'asset_id',
  'deployed_by',
  'collected_by',
  'created',
  'start_time',
  'end_time',
  'last_update',
  'expected_finish',
];

export const isFilterColumn = (column: string): boolean => {
  return filterColumns.includes(column);
};

export const isSortColumn = (column: string): boolean => {
  return sortColumns.includes(column);
};

/**
 * Formats the response from the API to be used in the filter dropdowns
 */
export const processDistinctValues = (
  distinct: GetViewSessionsDistinctResponse | undefined,
  prefixedT: (path: string) => string,
) => {
  return {
    status_id:
      distinct?.status
        .map((currentStatus) => ({
          label: prefixedT('DISTINCT.' + currentStatus.name),
          value: `${currentStatus.id}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    leak_size:
      distinct?.leak_size
        .map((currentLeakSize) => {
          if (currentLeakSize === null) {
            return { label: 'No Leak Size', value: 'null' };
          } else {
            return { label: currentLeakSize, value: currentLeakSize };
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    type:
      distinct?.type
        .map((currentType) => {
          if (currentType === null) {
            return { label: 'No Type', value: 'null' };
          }
          return {
            label: currentType,
            value: currentType,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    company_id:
      distinct?.company
        .map((currentCompany) => {
          if (currentCompany.id === null || currentCompany.name === null) {
            return { label: 'No Company', value: 'null' };
          }
          return {
            label: currentCompany.name,
            value: `${currentCompany.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    partner_id:
      distinct?.partner
        .map((currentPartner) => {
          if (currentPartner.id === null || currentPartner.name === null) {
            return { label: 'No Partner', value: 'null' };
          }
          return {
            label: currentPartner.name,
            value: `${currentPartner.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    project_id:
      distinct?.project
        .map((currentProject) => {
          if (currentProject.id === null || currentProject.name === null) {
            return { label: 'No Project', value: 'null' };
          }
          return {
            label: currentProject.name,
            value: `${currentProject.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    deployed_by:
      distinct?.deployed_engineer
        .map((currentDeployedEngineer) => {
          if (
            currentDeployedEngineer.id === null ||
            currentDeployedEngineer.name === null
          ) {
            return { label: 'No Engineer', value: 'null' };
          }
          return {
            label: currentDeployedEngineer.name,
            value: `${currentDeployedEngineer.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    collected_by:
      distinct?.collected_engineer
        .map((currentCollectedEngineer) => {
          if (
            currentCollectedEngineer.id === null ||
            currentCollectedEngineer.name === null
          ) {
            return { label: 'No Engineer', value: 'null' };
          }
          return {
            label: currentCollectedEngineer.name,
            value: `${currentCollectedEngineer.id}`,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
  };
};
