import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';
import { ReactComponent as Delete } from '@/assets/icons/delete.svg';
import { ReactComponent as View } from '@/assets/icons/view.svg';

export const Wrapper = styled.div`
  min-height: calc(100vh - 70px);
  background: white;
  padding: 40px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  font-weight: 500;
`;

export const LoaderContainer = styled.div``;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-top: 10px;
`;

export const PagniationContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DropDownContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-right: 10px;
`;

export const ImgContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const MainTitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const MainTitle = styled.h2`
  font-weight: 500;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumBlue};
`;

export const SearchContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

export const SearchBar = styled.div`
  margin-right: 60px;
`;

export const DeleteIcon = styled(Delete)`
  width: 23px;
  cursor: pointer;
  height: 23px;
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumDark};
  }

  :hover {
    path {
      fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.gray};
    }
  }
`;

export const ViewIcon = styled(View)`
  width: 23px;
  cursor: pointer;
  height: 23px;
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumDark};
  }

  :hover {
    path {
      fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.gray};
    }
  }
`;
