import Modal from '@/components/Modal/Modal';
import config from '@/config';
import { EventData, Map, MapMouseEvent } from 'mapbox-gl';
import {
  FilterContext,
  MapFilters,
  defaultSessionRelocationValues,
} from '@/Providers/FilterProvider';
import * as turf from '@turf/turf';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapbox from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import FilterMap from './components/FilterMap/FilterMap';
import FilterMapView from './components/FilterMapView/FilterMapView';
import SessionInformation from './components/SessionInformation/sessionInformation';
import SessionRelocation from './components/SessionRelocation/sessionRelocation';
import * as Styled from './style';
import adjustMapViewportToData from './utils/adjustMapViewport';
import {
  INITIAL_LATITUDE,
  INITIAL_LONGITUDE,
  INITIAL_ZOOM,
} from './utils/config';
import createMap from './utils/createMap';
import onMapLoad from './utils/onMapLoad';
import { MapElement } from './utils/types';
import { MapContext } from '@/Providers/MapProvider';
import Popup from './popup/popup';
import { hoverEvents } from './geospatialFeatures/utils/hoverEvents';
import { createDraggableMarker } from './geospatialFeatures/clusters/utils/createDraggableMarker';
import Loader from '@/components/Loader/Loader';

mapbox.accessToken = String(config.MAPBOX_KEY);

type GenerateMapProps = {
  fullMapData: MapElement[] | undefined;
  viewportMapData?: MapElement[] | undefined;
  readonly: boolean;
  modal?: boolean;
  showMapFilters?: boolean;
  doNotAllowClickThrough?: boolean;
  isLoading?: boolean;
};

/**
 * Generates a map component.
 *
 * @param {MapElement[]} props.fullMapData - All data that will be plotted on the map. If this
 * changes, the map will be re-rendered, but the position will be restored so the user does not lose their place.
 * @param {MapElement[]} props.viewportMapData - Update this to not change the plotted data but to adjust the map viewport.
 * @param {boolean} props.readonly - Indicates if the map is read-only.
 * @param {boolean} props.doNotAllowClickThrough - Indicates if click-through is not allowed.
 * @param {boolean} props.modal - Indicates if a modal is present.
 * @param {boolean} props.showMapFilters - Indicates if map filters should be shown.
 * @param {boolean} props.isLoading - Indicates if the map is currently loading. Will render a spinner on the
 * right hand side of the map if true, without removing the map from the DOM.
 * @returns {JSX.Element} The map component.
 */
const GenerateMap: FC<GenerateMapProps> = ({
  fullMapData,
  viewportMapData,
  readonly,
  doNotAllowClickThrough,
  modal,
  showMapFilters,
  isLoading = false,
}: GenerateMapProps): JSX.Element => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const [showFilters, setShowFilters] = useState(false);
  const [mapContainerWidth, setMapContainerWidth] = useState(0);
  const [modalId, setModalId] = useState('');
  const [sessionInformationOpen, setSessionInformationOpen] = useState(false);
  const isDraggingRef = useRef(false);
  const draggedFeatureIndexRef = useRef(-1);
  const polygonCircleCoordinatesRef = useRef<
    turf.helpers.Position[][] | undefined
  >();
  const originalCoordsRef = useRef<{ lng: number; lat: number } | null>(null);
  const [sessionRelocationOpen, setSessionRelocationOpen] = useState(false);
  const {
    setMapFilters,
    mapFilters,
    sessionRelocationObject,
    setSessionRelocationObject,
  } = useContext(FilterContext);

  const { lngLat, setLngLat, setTooltip, setMap } = useContext(MapContext);

  const [moveEvent, setMoveEvent] = useState<MapMouseEvent & EventData>();

  useEffect(() => {
    // Set the width of the map container on first render
    if (mapContainer.current) {
      setMapContainerWidth(mapContainer.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    // Full map data has changed, create a new map, restore the position
    const currentZoom = map.current?.getZoom();
    const currentLngLat = map.current?.getCenter();
    map.current = createMap(
      mapContainer,
      currentLngLat?.lng || INITIAL_LONGITUDE,
      currentLngLat?.lat || INITIAL_LATITUDE,
      currentZoom || INITIAL_ZOOM,
    );

    const onClickModalOpen = (id: string) => {
      setModalId(id);
      setSessionInformationOpen(true);
    };

    const setUp = async () => {
      setMap(map.current as Map);
      await onMapLoad(
        fullMapData,
        map,
        onClickModalOpen,
        modal,
        doNotAllowClickThrough,
      );
      hoverEvents(map.current as Map, setLngLat, setTooltip);
      if (!readonly) {
        createDraggableMarker(
          map.current as Map,
          mapFilters,
          setSessionRelocationObject,
          isDraggingRef,
          draggedFeatureIndexRef,
          polygonCircleCoordinatesRef,
          originalCoordsRef,
        );
      }
    };

    map.current?.addControl(
      new MapboxGeocoder({
        accessToken: String(config.MAPBOX_KEY),
        mapboxgl,
      }),
    );
    map.current?.addControl(new mapboxgl.ScaleControl());
    map.current?.addControl(new mapboxgl.NavigationControl(), 'top-left');
    map.current?.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    map.current?.on('mousemove', (e) => {
      setMoveEvent(e);
    });

    map.current?.on('style.load', () => {
      void setUp();
    });

    return () => {
      map.current?.remove();
    };
  }, [fullMapData]);

  useEffect(() => {
    if (sessionRelocationObject.assetId && sessionRelocationObject.sessionId) {
      setSessionRelocationOpen(true);
    }
  }, [sessionRelocationObject]);

  useEffect(() => {
    // Adjust the map viewport only when the viewportMapData changes
    if (map.current !== null && viewportMapData) {
      adjustMapViewportToData(map, viewportMapData);
    }
  }, [viewportMapData]);

  const onSessionInformationModalClose = () => {
    setSessionInformationOpen(false);
  };

  const onSessionRelocationModalClose = () => {
    setSessionRelocationObject(defaultSessionRelocationValues);
    setSessionRelocationOpen(false);
  };

  const toggleFilters = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowFilters(!showFilters);
  };

  const onSubmit = (values: MapFilters) => {
    setMapFilters(values);
    setShowFilters(!showFilters);
  };

  return (
    <Styled.Wrapper>
      <Styled.Container ref={mapContainer}>
        {lngLat && <Popup />}
        {showMapFilters && (
          <>
            <FilterMap
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              mapContainerWidth={mapContainerWidth}
              onSubmit={onSubmit}
              toggleFilters={toggleFilters}
            />
            <FilterMapView map={map} />
          </>
        )}
        {moveEvent && (
          <Styled.LngLat>
            <strong>Lng:</strong> {moveEvent.lngLat.lng}
            <br />
            <strong>Lat:</strong> {moveEvent.lngLat.lat}
          </Styled.LngLat>
        )}
        {isLoading && (
          <Styled.LoadingContainer data-testid="map-loading-spinner">
            <Loader />
          </Styled.LoadingContainer>
        )}
      </Styled.Container>
      <Modal
        isOpen={sessionInformationOpen}
        onClose={onSessionInformationModalClose}
        styles={{ maxWidth: '800px' }}
        domNode={mapContainer.current || document.body}
      >
        <SessionInformation id={modalId} />
      </Modal>
      <Modal
        isOpen={sessionRelocationOpen}
        onClose={onSessionRelocationModalClose}
        styles={{ maxWidth: '800px' }}
        domNode={mapContainer.current || document.body}
      >
        <SessionRelocation
          onClose={onSessionRelocationModalClose}
          relocationIds={sessionRelocationObject}
        />
      </Modal>
    </Styled.Wrapper>
  );
};

export default React.memo(GenerateMap);
