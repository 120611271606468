import React from 'react';
import GISUpload from './GISUpload';
import { GISUploadProvider } from '@/Providers/GISUploadProvider';

const GISUploadContainer: React.FC = () => {
  return (
    <GISUploadProvider>
      <GISUpload />
    </GISUploadProvider>
  );
};

export default GISUploadContainer;
