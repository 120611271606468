import React, { FC } from 'react';
import { KeyValues } from '../ProgressBar';
import * as Styled from './style';

type Props = {
  keyValues: KeyValues[];
};

const KeyContainer: FC<Props> = ({ keyValues }) => (
  <Styled.KeyContainer data-testid="key-container">
    {keyValues.map((keyValue, key) => (
      <Styled.KeyItem key={key}>
        <Styled.Block data-testid="block" color={keyValue.color} />{' '}
        {keyValue.name}
      </Styled.KeyItem>
    ))}
  </Styled.KeyContainer>
);

export default KeyContainer;
