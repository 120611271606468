import styled from 'styled-components';

export const ThumbsWrapper = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 5px;
`;

export const ThumbImage = styled.img`
  width: 16px;
  height: 16px;
  opacity: 0.7;
`;

export const ThumbsContainer = styled.div`
  background-color: #fff;
  padding: 4px 5px 1px 5px;
  border-radius: 8px;
  border: 2px solid #e4e4e4;
  cursor: pointer;

  :hover {
    img {
      opacity: 1;
    }
  }
`;

export const FeedbackContainer = styled.div`
  background: #39bb2a;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
`;
