import {
  GetPartnerListResponse,
  Partner,
  PatchUpdatePartnerBody,
  PostCreatePartnerBody,
} from '@/types/portalPartner.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';
import {
  IPaginationResponse,
  IPaginationProps,
  iSearchProps,
} from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';

export const portalPartnersApi = createApi({
  reducerPath: 'portalPartnersQuery',
  baseQuery: apiBaseQuery,
  tagTypes: [
    'GetPartnerList',
    'PostCreatePartner',
    'GetPartners',
    'PatchUpdatePartner',
  ],
  endpoints: (builder) => ({
    getPartnerList: builder.query<GetPartnerListResponse[], void>({
      query: () => '/portal_partners/list',
      providesTags: ['GetPartnerList'],
    }),
    postCreatePartner: builder.mutation<
      undefined,
      { body: PostCreatePartnerBody }
    >({
      query: ({ body }) => ({
        url: '/portal_partners/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PostCreatePartner'],
    }),
    getPartners: builder.query<
      IPaginationResponse<Partner>,
      IPaginationProps & iSearchProps
    >({
      query: (params) => `/portal_partners/?${buildQueryParams(params || {})}`,
      providesTags: ['GetPartners'],
    }),
    patchUpdatePartner: builder.mutation<
      undefined,
      { id: number; body: PatchUpdatePartnerBody }
    >({
      query: ({ id, body }) => ({
        url: `/portal_partners/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PatchUpdatePartner'],
    }),
  }),
});

export const {
  usePostCreatePartnerMutation,
  useGetPartnerListQuery,
  useLazyGetPartnersQuery,
  usePatchUpdatePartnerMutation,
} = portalPartnersApi;
