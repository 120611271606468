import { ReactComponent as Co2Icon } from '@/assets/icons/co2Icon.svg';
import { ReactComponent as CostIcon } from '@/assets/icons/costIcon.svg';
import { ReactComponent as WaterIcon } from '@/assets/icons/waterIcon.svg';
import styled from 'styled-components';

export const WaterImg = styled(WaterIcon)`
  width: 40px;
  height: 40px;
`;

export const CostImg = styled(CostIcon)`
  width: 40px;
  height: 40px;
`;

export const Co2Img = styled(Co2Icon)`
  width: 40px;
  height: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Button = styled.button<{ selected?: boolean }>`
  flex-grow: 1;
  background-color: ${({ selected }) => (selected ? '#007CBB' : '#F3F3F3')};
  border: none;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ValueText = styled.p`
  font-size: 18px;
  color: #009fe3;
  font-weight: 700;
`;

export const DayText = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #77c2e5;
  line-height: 20px;
`;

export const IdentifierText = styled.p`
  font-size: 12px;
  font-weight: 600;
`;
