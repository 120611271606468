import { userAuthorizedSelector } from '@/redux/authorization/authorization.selector';
import { GuardComponent, IReactChildren } from '@/types/common.type';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps {
  redirect?: string;
  fallbackRedirect?: string;
  children?: IReactChildren;
}

const AuthGuard: GuardComponent<AuthGuardProps> = ({
  children,
  redirect,
  fallbackRedirect,
}) => {
  const { isAuthorized } = useSelector(userAuthorizedSelector);

  if (fallbackRedirect && !isAuthorized) {
    return <Navigate to={fallbackRedirect} />;
  }

  if (redirect && isAuthorized) {
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
