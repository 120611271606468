import styled from 'styled-components';

export const Container = styled.div<{
  minHeight?: string;
}>`
  width: 100%;
  overflow-x: auto;
  min-height: ${({ minHeight }) => minHeight || 'auto'};
`;

export const TableComponent = styled.table`
  min-width: 100%;
`;

export const Wrapper = styled.div``;
