import mapbox, { Map, MapMouseEvent } from 'mapbox-gl';
import React from 'react';
import { renderToString } from 'react-dom/server';
import GeneralToolTip from '../../tooltips/GeneralTooltip';
import { MapElement } from '../../utils/types';

export const setCursorPointer = (map: Map, item: MapElement) => {
  map.on('mouseenter', `${item.id}-fill`, () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', `${item.id}-fill`, () => {
    map.getCanvas().style.cursor = '';
  });
};

export const handleClickWithTooltipCheck = (
  map: Map,
  item: MapElement,
  onClickModalOpen: (id: string) => void,
  modal?: boolean,
) => {
  const handleTooltipClick = (e: MapMouseEvent) => {
    const tooltip = item.tooltip;
    if (!tooltip.Waypoint_id) {
      if (checkToolTipsAreEmpty(map, e)) {
        if (modal && tooltip.Dma_key) {
          onClickModalOpen(tooltip.Dma_key);
        } else {
          new mapbox.Popup()
            .setLngLat(e.lngLat)
            .setHTML(renderToString(<GeneralToolTip tooltip={item.tooltip} />))
            .addTo(map);
        }
      }
    } else {
      window.open(`/app/waypoint?waypoint=${tooltip.Waypoint_id}`, '_blank');
    }
  };

  map.on('click', `${item.id}-fill`, handleTooltipClick);
};

export const checkToolTipsAreEmpty = (map: Map, e: MapMouseEvent) => {
  const layers = ['clusters', 'unclustered-point', 'lines-layer'];

  return layers.every((layer) => {
    const layerExists = map.getLayer(layer);
    const features = layerExists
      ? map.queryRenderedFeatures(e.point, { layers: [layer] })
      : [];
    return features.length < 1;
  });
};

// Copied from https://github.com/FIDO-Tech/fido_api/blob/development/src/database/utils.py#L1653
const polygonColours = [
  '#000099',
  '#003366',
  '#660099',
  '#66CC00',
  '#999900',
  '#CC6600',
  '#FF3300',
  '#000000',
];

export const polygonLayers = (map: Map, item: MapElement, idx = 0): void => {
  if (!map.getSource(item.id)) {
    map.addSource(item.id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {
          tooltip: item.tooltip,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [item.geometry],
        },
      },
    });
  }

  map.addLayer({
    id: `${item.id}-outline`,
    type: 'line',
    source: item.id,
    layout: {},
    paint: {
      'line-color': polygonColours[idx % polygonColours.length],
      'line-opacity': 0.5,
      'line-width': 3,
    },
  });

  map.addLayer({
    id: `${item.id}-fill`,
    type: 'fill',
    source: item.id,
    layout: {},
    paint: {
      'fill-color': polygonColours[idx % polygonColours.length],
      'fill-opacity': 0.2,
    },
  });

  map.addLayer({
    id: `${item.id}-text`,
    type: 'symbol',
    source: item.id || '',
    layout: {
      'text-field': item.tooltip.Dma_key || '',
    },
    paint: {
      'text-color': '#000000',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1,
    },
    minzoom: 10,
  });
};
