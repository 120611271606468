import styled from 'styled-components';

export const ToolTipContainer = styled.div``;

export const Title = styled.p`
  span {
    font-weight: 600;
  }
  max-width: 300px;
`;
