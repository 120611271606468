import { ReactComponent as AskFidoIcon } from '@/assets/icons/chatbot/askFido.svg';
import styled from 'styled-components';

export const AskFidoSmall = styled(AskFidoIcon)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const ChatMsgContainer = styled.div<{
  user?: boolean;
}>`
  background-color: ${({ user }) => (user ? '#007cbb' : '#fff')};
  color: ${({ user }) => (user ? '#fff' : '#000')};
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  align-self: ${({ user }) => (user ? 'flex-end' : 'flex-start;')};
`;

export const ChatTitle = styled.p`
  font-size: 12px;
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Chat = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
`;

export const MessagesContainer = styled.div`
  max-height: 461px;
  overflow: auto;
`;
