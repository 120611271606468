import { Button, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import ImageUploadBox from '@/components/ImageUploadBox/ImageUploadBox';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { usePostCreateCompanyMutation } from '@/redux/portalCompanies/portalCompanies.api';
import { PostCreateCompanyBody } from '@/types/portalCompany.type';
import { currencyOptions } from '@/utils/currency';
import { Field, Formik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { PartnerAndCompany } from '../UserManagement';
import * as Styled from './style';
export interface AddCompanyValues {
  name: string;
  code: string;
  partner_id: string;
  country: string;
  currency: string;
  metric_system: string;
}

interface AddCompanyProps {
  refetch: () => Promise<void>;
  partnersAndCompanies: PartnerAndCompany;
  countryData: DropDownOption[];
}

const AddCompany: FC<AddCompanyProps> = ({
  partnersAndCompanies,
  countryData,
  refetch,
}) => {
  const [mutate, { isSuccess, isLoading, isError }] =
    usePostCreateCompanyMutation();
  const { t, prefixedT } = useCustomTranslation('ADD_COMPANY');
  const [uploadedImage, setUploadedImage] = useState<string>('');

  const handleImageUpload = (imageString: string[]) => {
    setUploadedImage(imageString[0]);
  };

  const onSubmit = async (values: AddCompanyValues) => {
    let body: PostCreateCompanyBody = {
      company_name: values.name,
      company_code: values.code,
      partner_id: values.partner_id,
      country_code: values.country,
      metric_system: values.metric_system,
      currency: values.currency,
    };

    if (uploadedImage) {
      body = { ...body, logo_image: uploadedImage };
    }

    await mutate({ body });
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(prefixedT('VALIDATION.NAME')),
    code: Yup.string().required(prefixedT('VALIDATION.CODE')),
    metric_system: Yup.string().required(
      prefixedT('VALIDATION.MEASUREMENT_TYPE'),
    ),
    country: Yup.string().required(prefixedT('VALIDATION.COUNTRY')),
    currency: Yup.string().required(prefixedT('VALIDATION.CURRENCY')),
    partner_id: Yup.string().required(prefixedT('VALIDATION.PARTNER')),
  });

  const measurementType: DropDownOption[] = [
    {
      label: t('MEASUREMENT_TYPE.METRIC'),
      value: 'Metric',
    },
    {
      label: t('MEASUREMENT_TYPE.IMPERIAL'),
      value: 'Imperial',
    },
  ];

  const initialValues = useMemo(
    (): AddCompanyValues => ({
      name: '',
      code: '',
      partner_id: '',
      currency: '',
      metric_system: '',
      country: '',
    }),
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (isSuccess) {
        await refetch();
      }
    };

    void fetchData();
  }, [isSuccess]);

  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
      </Styled.TitleContainer>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors, values, submitForm, setFieldValue }) => {
            useEffect(() => {
              const fetchData = async () => {
                if (uploadedImage) {
                  await setFieldValue('image', true);
                }
              };

              void fetchData();
            }, [uploadedImage]);

            return (
              <div>
                <div>
                  <ImageUploadBox
                    label={prefixedT('LOGO.LABEL')}
                    onImageUpload={handleImageUpload}
                  />
                </div>
                <Styled.FormRow>
                  <Field
                    as={TextField}
                    required
                    name="name"
                    testId="name"
                    error={errors.name}
                    label={prefixedT('NAME.LABEL')}
                  />
                  <Field
                    as={TextField}
                    required
                    testId="code"
                    name="code"
                    error={errors.code}
                    label={prefixedT('CODE.LABEL')}
                  />
                  <Field
                    as={DropDown}
                    required={true}
                    styles={{ border: true }}
                    options={
                      measurementType as DropDownOption | DropDownOption[]
                    }
                    label={prefixedT('MEASUREMENT_TYPE.LABEL')}
                    placeholder={prefixedT('MEASUREMENT_TYPE.PLACEHOLDER')}
                    onChange={(option: DropDownOption) =>
                      setFieldValue('metric_system', option.value)
                    }
                    countOptions={4}
                    error={errors.metric_system?.toString()}
                    value={values.metric_system?.toString()}
                    testId="measurement"
                  />
                  <Field
                    as={DropDown}
                    required={true}
                    styles={{ border: true }}
                    options={
                      partnersAndCompanies.partners as
                        | DropDownOption
                        | DropDownOption[]
                    }
                    label={prefixedT('PARTNER.LABEL')}
                    placeholder={prefixedT('PARTNER.PLACEHOLDER')}
                    onChange={(option: DropDownOption) =>
                      setFieldValue('partner_id', Number(option.value))
                    }
                    countOptions={4}
                    error={errors.partner_id?.toString()}
                    value={values.partner_id?.toString()}
                    testId="partner"
                  />
                  <div style={{ paddingTop: '20px' }}>
                    <Field
                      as={DropDown}
                      required={true}
                      styles={{ border: true }}
                      options={countryData as DropDownOption | DropDownOption[]}
                      label={prefixedT('COUNTRY.LABEL')}
                      placeholder={prefixedT('COUNTRY.PLACEHOLDER')}
                      onChange={(option: DropDownOption) =>
                        setFieldValue('country', option.value)
                      }
                      countOptions={4}
                      error={errors.country?.toString()}
                      value={values.country?.toString()}
                      testId="country"
                    />
                  </div>
                  <div style={{ paddingTop: '20px' }}>
                    <Field
                      as={DropDown}
                      required={true}
                      styles={{ border: true }}
                      options={
                        currencyOptions() as DropDownOption | DropDownOption[]
                      }
                      label={prefixedT('CURRENCY.LABEL')}
                      placeholder={prefixedT('CURRENCY.PLACEHOLDER')}
                      onChange={(option: DropDownOption) =>
                        setFieldValue('currency', option.value)
                      }
                      countOptions={4}
                      error={errors.currency?.toString()}
                      value={values.currency?.toString()}
                      testId="currency"
                    />
                  </div>
                </Styled.FormRow>
                <Styled.ButtonContainer>
                  <Button
                    type="submit"
                    onClick={() => {
                      void (async () => {
                        await submitForm();
                      })();
                    }}
                    disabled={isLoading}
                  >
                    {prefixedT('SUBMIT')}
                  </Button>
                </Styled.ButtonContainer>
                {isSuccess && (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>
                      {prefixedT('RESPONSE.SUCCESS')}
                    </Styled.ReturnText>
                  </Styled.ReturnContainer>
                )}
                {isError && (
                  <Styled.ReturnContainer>
                    <Styled.ReturnText>{t('ERROR.REQUEST')}</Styled.ReturnText>
                  </Styled.ReturnContainer>
                )}
              </div>
            );
          }}
        </Formik>
      </div>
    </Styled.Wrapper>
  );
};

export default withPageLoader(AddCompany);
