import { createApi } from '@reduxjs/toolkit/query/react';
import { gisApiBaseQuery } from '../api';
import { buildQueryParams } from '@/utils/rtk';
import {
  ColumnAndSampleDataResponse,
  ColumnMappingBody,
  DiscardFileBody,
  PostUploadGISDataParams,
  PostUploadGISFilesParams,
  PreviewValuesResponse,
  STANDARDISING,
  UniqueValuesResponse,
} from '@/types/gisUpload.type';

export const gisUploadApi = createApi({
  reducerPath: 'gisUploadApi',
  baseQuery: gisApiBaseQuery,
  tagTypes: [
    'PostUploadGISData',
    'PostUploadGISFiles',
    'GetColumnAndSampleData',
    'PostDiscardFile',
    'GetUniqueValues',
    'getPreviewGIS',
  ],
  endpoints: (builder) => ({
    PostUploadGISData: builder.mutation<
      undefined,
      { params: PostUploadGISDataParams }
    >({
      query: ({ params }) => {
        return {
          url: `/gis_upload/upload_gis_data?${buildQueryParams(params || {})}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['PostUploadGISData'],
    }),
    PostUploadGISFiles: builder.mutation<
      undefined,
      { params: PostUploadGISFilesParams; file: File }
    >({
      query: ({ params, file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: `/gis_upload/upload_gis_files?${buildQueryParams(params || {})}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['PostUploadGISFiles'],
    }),
    PostColumnMapping: builder.mutation<undefined, { body: ColumnMappingBody }>(
      {
        query: ({ body }) => {
          return {
            url: `/column_mapping/`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['PostUploadGISFiles'],
      },
    ),
    GetColumnAndSampleData: builder.query<
      ColumnAndSampleDataResponse,
      { companyId: number | null; uploadId: number | null }
    >({
      query: ({ companyId, uploadId }) =>
        `/gis_upload/data_init/company_id/${companyId}/upload_id/${uploadId}`,
      providesTags: ['GetColumnAndSampleData'],
    }),
    GetUniqueValues: builder.query<
      UniqueValuesResponse,
      {
        companyId: number | null;
        uploadId: number | null;
        filename: string;
        columnTitle: string;
        standardising: STANDARDISING;
      }
    >({
      query: ({ companyId, uploadId, filename, columnTitle, standardising }) =>
        `/gis_upload/unique_values/company_id/${companyId}/upload_id/${uploadId}/filename/${filename}/column_title/${columnTitle}/standardising/${standardising}`,
      providesTags: ['GetUniqueValues'],
    }),
    GetPreviewGIS: builder.query<
      PreviewValuesResponse,
      {
        companyId: number | null;
        uploadId: number | null;
      }
    >({
      query: ({ companyId, uploadId }) =>
        `/gis_upload/preview_gis?company_id=${companyId}&upload_id=${uploadId}`,
      providesTags: ['getPreviewGIS'],
    }),
    PostDiscardFile: builder.mutation<undefined, { body: DiscardFileBody }>({
      query: ({ body }) => {
        return {
          url: `/gis_upload/discard_file`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['PostDiscardFile'],
    }),
  }),
});

export const {
  usePostUploadGISDataMutation,
  usePostUploadGISFilesMutation,
  useGetColumnAndSampleDataQuery,
  useGetUniqueValuesQuery,
  useGetPreviewGISQuery,
  usePostColumnMappingMutation,
  usePostDiscardFileMutation,
} = gisUploadApi;
