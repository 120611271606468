import { ReactComponent as ArrowDown } from '@/assets/icons/arrow_down.svg';
import { Theme } from '@/theme';
import { Styles } from '@/types/styles.type';
import { motion } from 'framer-motion';
import styled, {
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

const getBorderStyle = (border?: boolean, error?: boolean) => {
  if (error) {
    return '1px solid #e95f70';
  }
  return border ? '1px solid #e4e6ee' : 'none';
};

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div<{ border?: boolean; error?: boolean }>`
  border: ${({ border, error }) => getBorderStyle(border, error)};
`;

export const ClickableAreaText = styled.p<{
  isPlaceholder: boolean;
  disabled?: boolean;
  styles: Styles;
}>`
  text-wrap: wrap;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: ${({ theme, styles }) => styles?.textColor || theme.colors.black};
`;

export const ClickableArea = styled.div<{
  open: boolean;
  disabled: boolean;
  activeElement: boolean;
  error: boolean;
  styles: Styles;
  background?: string;
}>`
  min-height: 40px;
  background-color: ${({
    theme,
    disabled,
    activeElement,
    styles,
    background,
  }: ThemeProps<Theme> & {
    disabled: boolean;
    activeElement: boolean;
    styles: Styles;
    background?: string;
  }) => {
    if (activeElement) {
      return '#edfcff';
    }

    if (disabled) {
      return theme.colors.disabled;
    }

    return background || styles.background || 'white';
  }};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: ${({ styles }) =>
    styles.center ? 'center' : 'space-between'};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;
  text-transform: capitalize;
`;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
  disabled?: boolean;
}>`
  fill: #00699d;
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
  padding-left: 10px;

  path {
    fill: #00699d !important;
  }
`;

export const OptionsContainer = styled.div`
  position: absolute;
`;

export const OptionsList = styled(motion.ul)<{
  open: boolean;
  disabled?: boolean;
  config: {
    isOverflow: boolean;
    countOptions: number;
    width: number;
    top: number;
    left: number;
    isPortal: boolean;
  };
  styles?: Styles;
}>`
  ${({ config, styles, theme }) => `
    color: ${styles?.textColor || theme.colors.black};
    top: ${config.isPortal ? `${config.top}px` : `100%`};
    left: ${config.isPortal ? `${config.left}px` : `0`};
    width: ${config.isPortal ? `${config.width - 2}px` : `100%`};
    max-height: ${config.isPortal ? 'auto' : '250px'};
    overflow: ${config.isPortal ? 'visible' : 'auto'};
    position: absolute;
    background-color: ${styles?.background || theme.colors.white};
    ${
      styles?.dropdownFitWidth
        ? `
      opacity: 1;
      width: fit-content;
      border: none;
      margin: 0 auto;
      right: 0;
    `
        : `
      border: 1px solid ${theme.colors.lightGray};
    `
    }
  `}
  border-top: 0px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  z-index: 999;
  list-style: none;
  border-radius: ${({ config }) =>
    config.isOverflow ? '8px 8px 0 0' : '0 0 8px 8px'};
`;

export const OptionItem = styled.li<{
  selected: boolean;
  multiple: boolean | undefined;
}>`
  ${({ multiple }) =>
    multiple
      ? `
  display: flex;
  align-items: center;
`
      : null}
  padding: 10px 20px;
  transition: 0.3s ease background;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  background-color: ${({
    selected,
  }: ThemeProps<Theme> & { selected: boolean }) =>
    selected ? '#4298d733' : null};
  &:hover {
    background-color: ${({
      selected,
    }: ThemeProps<Theme> & { selected: boolean }) =>
      selected ? null : '#4298d733'};
  }
  white-space: pre-wrap;
`;

export const Label = styled.span<{
  labelStyle?: FlattenSimpleInterpolation;
  error?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme, error }: ThemeProps<Theme> & { error?: boolean }) =>
    theme.colors[error ? 'coral' : 'deepDark']};
  margin-bottom: 8px;
  ${({ labelStyle }) => labelStyle || null};
`;

export const Error = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  margin-top: 5px;
`;

export const Checkbox = styled.input`
  margin-right: 5px;
  &[type='checkbox'] {
    position: relative;
    border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 15px;
    width: 15px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    &:checked {
      background-color: ${({ theme }: ThemeProps<Theme>) =>
        theme.colors.mediumBlue};
      opacity: 1;
    }
    &:before {
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      height: 7px;
      border: solid ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
      border-width: 0;
      margin: -1px -1px 0 -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 2;
    }
  }
`;

export const Required = styled.span`
  color: #e11d48;
`;
