import { TableSortOrders } from '@/components/Table/types';
import {
  useGetDataSourcesQuery,
  useGetRunningReportQuery,
} from '@/redux/reports/reports.api';
import React, { useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import RunningReport from './RunningReport';

const RunningReportContainer = () => {
  const { id: reportId = '' } = useParams();
  const [query, setQuery] = useState({ page: 1, perPage: 10 });

  const { data: report, isFetching: isReportLoading } =
    useGetRunningReportQuery(
      { id: reportId, query },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const { isLoading: isDataSourcesLoading } = useGetDataSourcesQuery();
  const orderRef = useRef(TableSortOrders.asc);
  const orderByRef = useRef('');

  const setSorting = (
    orderBy: string | undefined,
    order: string | undefined,
  ) => {
    orderRef.current = order as TableSortOrders;
    orderByRef.current = orderBy ?? '';
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <RunningReport
        report={report}
        pageNumber={query.page}
        loading={isReportLoading || isDataSourcesLoading}
        setQuery={setQuery}
        setSorting={setSorting}
        order={orderRef.current}
        orderBy={orderByRef.current}
      />
    </DndProvider>
  );
};

export default RunningReportContainer;
