import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './style';

interface DropdownProps {
  name: string;
  values: { name: string; url: string; image: string }[];
  onOptionSelect: (selectedOption: string) => void;
  selected: string;
  keyValue: number;
}

const UserManagementDropdown: React.FC<DropdownProps> = ({
  keyValue,
  selected,
  name,
  values,
  onOptionSelect,
}) => {
  const navigate = useNavigate();

  const handleOptionClick = (selectedOption: string) => {
    onOptionSelect(selectedOption);
    navigate(`/app/user-management/${selectedOption}`);
  };

  return (
    <Styled.Wrapper firstChild={keyValue === 0}>
      <Styled.Title>{name}</Styled.Title>
      <Styled.DropdownContainer>
        {values.map((value, index) => (
          <React.Fragment key={index}>
            <Styled.LayerTitle
              key={index}
              selected={selected === value.url}
              className="dropdown-item"
              onClick={() => handleOptionClick(value.url)}
            >
              {selected === value.url && <Styled.SelectedBorder />}
              {value.image && (
                <Styled.Image alt={value.name} src={value.image} />
              )}
              {value.name}
            </Styled.LayerTitle>
          </React.Fragment>
        ))}
      </Styled.DropdownContainer>
    </Styled.Wrapper>
  );
};

export default UserManagementDropdown;
