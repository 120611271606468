import { ReactComponent as PlusButtonIcon } from '@/assets/icons/plus.svg';
import { ModalFormButton } from '@/components/Modal/style';
import {
  PageHeaderSubtitle,
  PageHeaderTitle,
  PageHeaderWrapper,
} from '@/layouts/AppLayout/style';
import { Theme } from '@/theme';
import { Form } from 'formik';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  padding: 40px;
`;

export const Wrapper = styled(PageHeaderWrapper)``;
export const Title = styled(PageHeaderTitle)``;
export const Text = styled(PageHeaderSubtitle)``;

export const TableHeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
`;

export const PlusIcon = styled(PlusButtonIcon)`
  margin-right: 9px;
`;

export const ReportForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormButton = styled(ModalFormButton)``;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
`;

export const LayoutsWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const LayoutLabel = styled.span<{
  isError?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isError, theme }: ThemeProps<Theme> & { isError?: boolean }) =>
    theme.colors[isError ? 'coral' : 'deepDark']};
  margin-bottom: 8px;
`;

export const LayoutsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-bottom: 25px;
`;

export const Layout = styled.div<{
  choosen: boolean;
}>`
  border: 2px dashed
    ${({ choosen, theme }: ThemeProps<Theme> & { choosen: boolean }) =>
      choosen ? theme.colors.mediumBlue : theme.colors.softBlue};
  padding: 10px;
  border-radius: 4px;
  height: 150px;
  cursor: pointer;
  :hover {
    border: 2px dashed
      ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumBlue};
  }
`;

export const LayoutImage = styled.div<{
  imageSrc: string;
}>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
`;

export const LayoutError = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  left: 0;
`;

export const CreateFormFooter = styled.footer`
  display: flex;
  justify-content: space-between;
`;
