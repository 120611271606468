import {
  ArchiveConversationQuery,
  CreateConversationResponse,
  MessageStarRatingQuery,
  SendMessageBody,
  SendMessageQuery,
  SendMessageResponse,
} from '@/types/aiAssistant.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { aiAssistantBaseQuery } from '../api';

export const aiAssistantApi = createApi({
  reducerPath: 'aiAssistantQuery',
  baseQuery: aiAssistantBaseQuery,
  tagTypes: [
    'createConversation',
    'sendMessage',
    'archiveConversation',
    'messageStarRating',
  ],
  endpoints: (builder) => ({
    createConversation: builder.mutation<CreateConversationResponse, void>({
      query: () => ({
        url: '/ai_assistant/create_conversation',
        method: 'POST',
        invalidatesTags: ['createConversation'],
      }),
    }),
    sendMessage: builder.mutation<
      SendMessageResponse,
      { query: SendMessageQuery; body: SendMessageBody }
    >({
      query: ({ query, body }) => ({
        url: `/ai_assistant/message?${buildQueryParams(query || {})}`,
        method: 'POST',
        body,
        invalidatesTags: ['sendMessage'],
      }),
    }),
    archiveConversation: builder.mutation<void, ArchiveConversationQuery>({
      query: (query) => ({
        url: `/ai_assistant/archive_conversation?${buildQueryParams(query || {})}`,
        method: 'PATCH',
        invalidatesTags: ['archiveConversation'],
      }),
    }),
    messageStarRating: builder.mutation<void, MessageStarRatingQuery>({
      query: (query) => ({
        url: `/ai_assistant/message_star_rating?convo_id=${query.convo_id}&msg_id=${query.msg_id}&star_rating=${query.star_rating}`,
        method: 'POST',
        invalidatesTags: ['messageStarRating'],
      }),
    }),
  }),
});

export const {
  useCreateConversationMutation,
  useSendMessageMutation,
  useArchiveConversationMutation,
  useMessageStarRatingMutation,
} = aiAssistantApi;
