import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';
import { ReactComponent as CrossIcon } from '@/assets/icons/chatbot/cross.svg';

export const CrossImg = styled(CrossIcon)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;

  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  }
`;

export const Container = styled.div`
  padding: 40px;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

export const ListWrapper = styled.div`
  columns: 2 auto;
  column-gap: 1rem;
  width: 100%;
`;

export const JobDetailsContainer = styled.div`
  margin-bottom: 20px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    grid-template-columns: 1fr;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  font-weight: bold;
  line-height: 35px;
  margin: 10px;
`;

export const ListTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 10px;
`;

export const ListContainerItem = styled.div`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  color: white;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  padding: 10px 0;
  width: 100%;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const ButtonContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const FormContainer = styled.div`
  margin: 20px 0;
`;

export const ListObject = styled.span`
  font-weight: normal;
`;

export const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const OptionList = styled.div<{
  edit: boolean;
}>`
  gap: 10px;
  align-items: ${({ edit }) => (edit ? 'center' : 'baseline')};
  justify-content: ${({ edit }) => (edit ? 'space-between' : 'unset')};
`;

export const DropDownContainer = styled.div`
  margin-bottom: 10px;
`;

export const Option = styled.div`
  width: 100%;
`;

export const Figure = styled.figure`
  margin: 10px;
`;

export const ImageCaption = styled.figcaption`
  font-weight: 800;
  font-size: 1.5rem;
`;

export const Error = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div`
  margin: 10px;
  max-width: 200px;
`;

export const Image = styled.img`
  width: 100%;
`;
