import React, { FC, useEffect } from 'react';
import * as Styled from './style';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  Button,
  DropDown,
  DatePicker,
  TextAreaField,
  Loader,
} from '@/components';
import {
  useGetPortalUsersQuery,
  usePatchSensorsMutation,
  usePatchRelaysMutation,
} from '@/redux/portalUsers/portalUsers.api';
import { SensorStockData } from '@/types/stock.type';
import { sensorStatuses } from '../StockTableUtils';
import { DropDownOption } from '@/components/DropDown/DropDown';
import { useGetPortalPartnersQuery } from '@/redux/reports/reports.api';
import theme from '@/theme';

interface EditStockProps {
  isSensorManagement?: boolean;
  sensorIds: number[];
  setSelectedStock: React.Dispatch<React.SetStateAction<number[]>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (useCache?: boolean) => void;
}

const initialValues: SensorStockData = {};

const EditStock: FC<EditStockProps> = ({
  isSensorManagement = true,
  sensorIds,
  setSelectedStock,
  isOpen,
  setIsOpen,
  refetch,
}) => {
  const [
    mutateSensor,
    {
      isSuccess: sensorIsSuccess,
      isError: sensorIsError,
      reset: sensorReset,
      isLoading: sensorIsLoading,
    },
  ] = usePatchSensorsMutation();
  const [
    mutateRelay,
    {
      isSuccess: relayIsSuccess,
      isError: relayIsError,
      reset: relayReset,
      isLoading: relayIsLoading,
    },
  ] = usePatchRelaysMutation();

  const isSuccess = isSensorManagement ? sensorIsSuccess : relayIsSuccess;
  const isError = isSensorManagement ? sensorIsError : relayIsError;
  const reset = isSensorManagement ? sensorReset : relayReset;
  const isLoading = isSensorManagement ? sensorIsLoading : relayIsLoading;

  const { data: portalUsers } = useGetPortalUsersQuery();
  const { data: portalPartners, isSuccess: partnersSuccess } =
    useGetPortalPartnersQuery();

  const { t, prefixedT } = useCustomTranslation('STOCK_MANAGEMENT.TABLE.EDIT');

  const onSubmit = async (values: SensorStockData) => {
    if (sensorIds) {
      const isCustomer = values.status === 'customer';

      try {
        if (isSensorManagement) {
          const sendValues = {
            status: values.status,
            comment: values.comment,
            timestamp: values.timestamp,
            customer: isCustomer ? values.customer : undefined,
            bug_owner: isCustomer ? values.bug_owner : undefined,
          };
          await mutateSensor({
            body: { asset_ids: sensorIds, stock_data: sendValues },
          }).unwrap();
        } else {
          const sendValues = {
            status: values.status,
            comment: values.comment,
            timestamp: values.timestamp,
            customer: isCustomer ? values.customer : undefined,
            relay_owner: isCustomer ? values.bug_owner : undefined,
          };

          await mutateRelay({
            body: { ids: sensorIds, stock_info: sendValues },
          }).unwrap();
        }

        // if we got here then the mutate was successful
        setSelectedStock([]);
        refetch(false);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      reset();
    }
  }, [isSuccess]);

  const ValidationSchema = Yup.object().shape({
    status: Yup.string()
      .required(prefixedT('NO_STATUS'))
      .oneOf(sensorStatuses, 'Invalid status'),
    partnerIdx: Yup.string().notRequired(),
    customer: Yup.string().when('status', (status) => {
      return status === 'customer'
        ? Yup.string().required(prefixedT('NO_CUSTOMER'))
        : Yup.string().notRequired();
    }),
    comment: Yup.string().required(prefixedT('NO_COMMENTS')),
    timestamp: Yup.date().required(prefixedT('NO_TIMESTAMP')),
    bug_owner: Yup.string(),
  });

  return (
    <>
      {isOpen && partnersSuccess && (
        <>
          <Styled.Underlay
            data-testid={'overlay'}
            onClick={() => setIsOpen(false)}
          />
          <Styled.DialogBox data-testid={'edit-stock-box'} open={isOpen}>
            {isLoading && <Loader />}
            <Styled.Wrapper display={!isLoading}>
              <Styled.TitleContainer>
                <h3>{prefixedT('TITLE')}</h3>
              </Styled.TitleContainer>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
              >
                {({ values, errors, submitForm, setFieldValue, setValues }) => {
                  return (
                    <div>
                      <Styled.FormRow>
                        <Field
                          as={DropDown}
                          required
                          styles={{
                            border: true,
                          }}
                          options={sensorStatuses.map((status) => ({
                            value: status,
                            label: t(
                              `STOCK_MANAGEMENT.TABLE.STATUSES.${status}`,
                            ),
                          }))}
                          label={prefixedT('STATUS')}
                          onChange={(option: DropDownOption) =>
                            setValues({
                              status: option.value,
                            })
                          }
                          countOptions={sensorStatuses.length}
                          name="status"
                          testId="status"
                          error={errors.status}
                          value={values.status}
                        />
                        {values.status === 'customer' && (
                          <Styled.FormColumn>
                            <Field
                              as={DropDown}
                              required
                              testId="partner"
                              styles={{
                                border: true,
                              }}
                              options={
                                portalPartners !== undefined
                                  ? portalPartners.map((partner, idx) => ({
                                      value: `${idx}`,
                                      label: partner.name,
                                    }))
                                  : []
                              }
                              name="partnerIdx"
                              error={errors.customer}
                              label={prefixedT('PARTNER')}
                              onChange={(option: DropDownOption) =>
                                setValues((prev) => ({
                                  ...prev,
                                  partnerIdx: option.value,
                                  customer: undefined,
                                }))
                              }
                              value={values.partnerIdx}
                            />
                            <Field
                              as={DropDown}
                              required
                              testId="customer"
                              styles={{
                                border: true,
                              }}
                              options={
                                values.partnerIdx !== undefined
                                  ? portalPartners[
                                      parseInt(values.partnerIdx, 10)
                                    ].companies.map((company) => {
                                      return {
                                        value: `${company.id}`,
                                        label: company.name,
                                      };
                                    })
                                  : []
                              }
                              name="customer"
                              error={errors.customer}
                              label={prefixedT('CUSTOMER')}
                              onChange={(option: DropDownOption) => {
                                setValues((prev) => ({
                                  ...prev,
                                  customer: option.value,
                                }));
                              }}
                              value={values.customer}
                            />
                            <Field
                              as={DropDown}
                              styles={{
                                border: true,
                              }}
                              options={
                                portalUsers !== undefined
                                  ? portalUsers?.map((portalUser) => ({
                                      value: portalUser.id,
                                      label: `${portalUser.first_name} ${portalUser.last_name}`,
                                    }))
                                  : []
                              }
                              testId="bug_owner"
                              name="bug_owner"
                              error={errors.bug_owner}
                              label={prefixedT(
                                isSensorManagement
                                  ? 'SENSOR_OWNER'
                                  : 'RELAY_OWNER',
                              )}
                              onChange={(option: DropDownOption) =>
                                setFieldValue('bug_owner', option.value)
                              }
                            />
                          </Styled.FormColumn>
                        )}
                        <Field
                          as={TextAreaField}
                          styles={{
                            input: {
                              height: '50px',
                              resize: 'none',
                              lineHeight: '1.5rem',
                            },
                          }}
                          required
                          testId="comment"
                          name="comment"
                          error={errors.comment}
                          label={prefixedT('COMMENTS')}
                        />
                        <Field
                          as={DatePicker}
                          required
                          testId="timestamp"
                          name="timestamp"
                          error={errors.timestamp}
                          label={prefixedT('TIMESTAMP')}
                          showTimeSelect={true}
                          onChange={(datetime: Date) =>
                            setFieldValue('timestamp', datetime)
                          }
                        />
                      </Styled.FormRow>
                      <Styled.ButtonContainer>
                        <Button
                          type="submit"
                          color={theme.colors.coral}
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          {prefixedT('DISCARD')}
                        </Button>
                        <Button
                          type="submit"
                          onClick={() => {
                            void submitForm();
                          }}
                        >
                          {prefixedT('SUBMIT')}
                        </Button>
                      </Styled.ButtonContainer>
                      {isError && (
                        <Styled.ErrorContainer>
                          <Styled.ErrorText>
                            {t('ERROR.REQUEST')}
                          </Styled.ErrorText>
                        </Styled.ErrorContainer>
                      )}
                    </div>
                  );
                }}
              </Formik>
            </Styled.Wrapper>
          </Styled.DialogBox>
        </>
      )}
    </>
  );
};

export default EditStock;
