import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  min-height: auto;
  padding-left: 40px;
  padding-top: 15px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HR = styled.hr`
  margin-bottom: 20px;
`;

export const SystemContainer = styled.div`
  margin-top: 20px;
`;

export const SystemFieldContainer = styled.div``;

export const DiameterUnitWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TitleContainer = styled.div`
  width: 100%;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};
  color: #fff;
  padding: 20px;
`;

export const ContainerA = styled.div`
  flex-grow: 1;
  max-width: 25%;
  margin-bottom: 20px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};
  padding: 20px;
`;

export const ContainerB = styled.div`
  flex-grow: 1;
  max-width: 75%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.mascaraTears};
  padding-bottom: 20px;
`;
