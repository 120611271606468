import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './style';
import { Button } from '@/components';
import Description from './Description';
import { usePostAudioTriggerMutation } from '@/redux/reports/reports.api';
import useCustomTranslation from '@/localization/useCustomTranslation';

enum LastAction {
  UserOn = 'USER_ON',
  UserOff = 'USER_OFF',
  AutoOn = 'AUTO_ON',
  AutoOff = 'AUTO_OFF',
  NA = 'NA',
}

type AudioRetrievalProps = {
  sessionId: string;
  relayInfo?: {
    status: 'on' | 'off';
    device: 'portal' | 'auto';
  };
};

const AudioRetrieval: React.FC<AudioRetrievalProps> = ({
  sessionId,
  relayInfo,
}) => {
  const { prefixedT } = useCustomTranslation('SESSION_PAGE.GET_AUDIO');
  const [postAudioTrigger, { isLoading, isError }] =
    usePostAudioTriggerMutation();

  const [lastAction, setLastAction] = useState<LastAction>(LastAction.NA);

  useEffect(() => {
    if (relayInfo === undefined) {
      setLastAction(LastAction.NA);
    } else if (relayInfo.status === 'on' && relayInfo.device === 'portal') {
      setLastAction(LastAction.UserOn);
    } else if (relayInfo.status === 'off' && relayInfo.device === 'portal') {
      setLastAction(LastAction.UserOff);
    } else if (relayInfo.status === 'on' && relayInfo.device === 'auto') {
      setLastAction(LastAction.AutoOn);
    } else if (relayInfo.status === 'off' && relayInfo.device === 'auto') {
      setLastAction(LastAction.AutoOff);
    } else {
      setLastAction(LastAction.NA);
    }
  }, [relayInfo]);

  const handleOnClick = useCallback(async () => {
    switch (lastAction) {
      case LastAction.AutoOff:
      case LastAction.UserOff:
        setLastAction(LastAction.UserOn);
        await postAudioTrigger({ sessionId, toggle: 'true' });
        break;
      case LastAction.AutoOn:
      case LastAction.UserOn:
        setLastAction(LastAction.UserOff);
        await postAudioTrigger({ sessionId, toggle: 'false' });
        break;
      default:
        break;
    }
  }, [lastAction]);

  const renderedText = useMemo(() => {
    switch (lastAction) {
      case LastAction.AutoOff:
      case LastAction.UserOff:
        return {
          status: prefixedT('STATUS.NOT_GET'),
          button: prefixedT('BUTTON.GET'),
        };
      case LastAction.AutoOn:
      case LastAction.UserOn:
        return {
          status: prefixedT('STATUS.GET'),
          button: prefixedT('BUTTON.CANCEL'),
        };
      default:
        return {
          status: prefixedT('STATUS.NA'),
          button: prefixedT('BUTTON.NA'),
        };
    }
  }, [lastAction]);

  return (
    <>
      <h1>{prefixedT('TITLE')}</h1>
      <Styled.Container>
        <p>
          {prefixedT('STATUS')}: <b>{renderedText.status}</b>
        </p>
        <p>
          {prefixedT('LAST_ACTION')}:{' '}
          <b>{prefixedT('LAST_ACTION.' + lastAction)}</b>
        </p>
        <Button
          testId="audio-retrieval-button"
          disabled={lastAction === LastAction.NA || isError}
          onClick={() => void handleOnClick()}
          loading={isLoading}
        >
          {renderedText.button}
        </Button>
        {isError && <Styled.Error>{prefixedT('ERROR')}</Styled.Error>}
      </Styled.Container>
      <Description />
    </>
  );
};

export default AudioRetrieval;
