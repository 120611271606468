import { PointTooltipType } from '../../../utils/types';
import { clusterLayerID, layerID } from './utils';
import { Map, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';

export const handleClusterClick = (map: Map) => {
  map.on('click', clusterLayerID, (e) => {
    const features: MapboxGeoJSONFeature[] = map.queryRenderedFeatures(
      e.point,
      {
        layers: [clusterLayerID],
      },
    );
    if (features.length > 0) {
      const clusterId = features[0].properties?.cluster_id as number;
      const source = map.getSource(clusterLayerID);

      if (!source) {
        return;
      }
      if (
        'type' in source &&
        source.type === 'geojson' &&
        'getClusterExpansionZoom' in source
      ) {
        source.getClusterExpansionZoom(
          clusterId,
          (err: Error | null, zoom: number | null) => {
            if (err) {
              return;
            }
            map.easeTo({
              center:
                features[0].geometry.type === 'Point'
                  ? (features[0].geometry.coordinates as [number, number])
                  : [0, 0],
              zoom: zoom as number,
            });
          },
        );
      }
    }
  });
};

export const unclusteredClick = (
  map: Map,
  doNotAllowClickThrough: boolean,
  queryParams: URLSearchParams,
) => {
  map.on('click', layerID, (e: MapMouseEvent) => {
    const features: MapboxGeoJSONFeature[] = map.queryRenderedFeatures(
      e.point,
      {
        layers: [layerID],
      },
    );
    const clickedFeature = features[0];

    const clickedTooltip = clickedFeature.properties?.tooltip as string;

    const tooltip =
      clickedTooltip && (JSON.parse(clickedTooltip) as PointTooltipType);

    if (tooltip) {
      const waypointId = tooltip.Waypoint_id || queryParams.get('waypoint');

      if (tooltip.Session_id && waypointId) {
        return window.open(
          `/app/session?waypoint=${waypointId}&session=${tooltip.Session_id}`,
        );
      }
      if (tooltip.Session_id && !waypointId) {
        return window.open(`/app/session?session=${tooltip.Session_id}`);
      }
      if (!doNotAllowClickThrough && tooltip.Waypoint_id) {
        return window.open(
          `/app/waypoint?waypoint=${tooltip.Waypoint_id}`,
          '_blank',
        );
      }
    }
  });
};

export const clickEvents = (map: Map, doNotAllowClickThrough: boolean) => {
  const queryParams = new URLSearchParams(location.search);

  handleClusterClick(map);

  unclusteredClick(map, doNotAllowClickThrough, queryParams);
};
