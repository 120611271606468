import useCustomTranslation from '@/localization/useCustomTranslation';

export const BuildFunnelChartData = (
  leaks_identified: number | undefined,
  number_scheduled: number | undefined,
  number_marked: number | undefined,
  number_repaired: number | undefined,
) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE.SUMMARY');
  return [
    {
      value: leaks_identified || 0,
      name: prefixedT('LEAKS_IDENTIFIED'),
      fill: '#009FE3',
    },
    {
      value: number_scheduled || 0,
      name: prefixedT('NUMBER_SCHEDULED'),
      fill: '#59C4E9',
    },
    {
      value: number_marked || 0,
      name: prefixedT('NUMBER_REPAIRED'),
      fill: '#F09425',
    },
    {
      value: number_repaired || 0,
      name: prefixedT('NUMBER_VERIFIED_REPAIRED'),
      fill: '#F3F3F3',
    },
  ];
};
