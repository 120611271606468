import React, { createContext, FC, useState } from 'react';
import { Map } from 'mapbox-gl';

export interface LatLngType {
  lng: number | null;
  lat: number | null;
}

export interface MapContextProps {
  lngLat: LatLngType;
  setLngLat: React.Dispatch<React.SetStateAction<LatLngType>>;
  map: Map | null;
  setMap: React.Dispatch<React.SetStateAction<Map | null>>;
  tooltip: string;
  setTooltip: React.Dispatch<React.SetStateAction<string>>;
}

export const MapContext = createContext<MapContextProps>({
  tooltip: '',
  setTooltip: () => {},
  lngLat: { lng: null, lat: null },
  setLngLat: () => {},
  map: null,
  setMap: () => {},
});

interface MapProviderProps {
  children: React.ReactNode;
}

export const MapProvider: FC<MapProviderProps> = ({ children }) => {
  const [lngLat, setLngLat] = useState<LatLngType>({ lng: null, lat: null });
  const [map, setMap] = useState<Map | null>(null);
  const [tooltip, setTooltip] = useState<string>('');

  return (
    <MapContext.Provider
      value={{
        lngLat,
        setLngLat,
        map,
        setMap,
        tooltip,
        setTooltip,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
