import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const FileContainer = styled.div`
  margin-bottom: 20px;
`;

export const FileText = styled.h2`
  font-size: 16px;
  font-weight: 500;
`;

export const FileDescText = styled.p`
  margin-top: 10px;
`;

export const FileColumnText = styled.p<{
  error?: boolean;
}>`
  color: ${({ theme, error }: ThemeProps<Theme> & { error?: boolean }) =>
    error && theme.colors.artyClickRed};
  font-weight: 500;
  margin-top: 10px;
`;
